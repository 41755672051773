import { Component, ElementRef, forwardRef, QueryList, ViewChildren } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'one-time-password-input',
  template: `
    <input
      #digit
      *ngFor="let digit of digits; let i = index"
      type="text"
      inputmode="numeric"
      [disabled]="disabled"
      [value]="digit"
      (keydown)="onInput(i, $event)"
      (paste)="onPaste($event)"
    />
  `,
  styles: [
    `
    :host {
      display: inline-flex;
    }

    input[type="text"] {
      width: 3ch;
      height: 3ch;
      padding: calc(2ch / 3);
      text-align: center;
      border: 1px solid #545454;
      border-radius: 2px;
    }

    input[type="text"]:not(:first-of-type) {
      margin-left: calc(1ch / 2);
    }

    input[type="text"]:disabled {
      opacity: 0.67;
      background-color: rgba(0, 0, 0, 0.1);
    }
  `,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OneTimePasswordInputComponent),
      multi: true,
    },
  ],
})
export class OneTimePasswordInputComponent implements ControlValueAccessor {
  private _isDisabled = false;

  @ViewChildren('digit')
  inputs: QueryList<ElementRef<HTMLInputElement>>;

  digits: (string | null)[] = Array.from({ length: 6 }, () => null);

  get disabled(): boolean {
    return this._isDisabled;
  }

  _onTouched!: () => void;
  _onChange!: (value: string | null) => void;

  writeValue(obj: string): void {
    if (typeof obj !== 'string' || obj.length !== this.digits.length) {
      return;
    }
    this.digits = obj.split('');
  }

  registerOnChange(fn: (value: string | null) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this._isDisabled = isDisabled;
  }

  onInput(index: number, event: KeyboardEvent): void {
    const digitInput = this.inputs.toArray()[index].nativeElement;
    const keyCode = event.key;

    if (event.metaKey && keyCode === 'v') {
      return;
    }

    if (['Backspace', 'Tab'].includes(keyCode)) {
      if (keyCode === 'Backspace' && !digitInput.value) {
        const prevElement = this.inputs.toArray()[index - 1]?.nativeElement;
        if (prevElement) {
          prevElement.value = null;
        }
        this.emitChange();
        prevElement?.focus();
      }
      return;
    }

    event.preventDefault();

    if (!Number.isNaN(Number(keyCode))) {
      digitInput.value = keyCode;
      this.emitChange();
      const nextInput = this.inputs.toArray()[index + 1];
      if (nextInput) {
        nextInput.nativeElement.focus();
      }
    }
  }

  onPaste(event: ClipboardEvent): void {
    const numbers = (event.clipboardData?.getData('text') ?? '').slice(0, this.digits.length);
    if (/^\d{6}$/.test(numbers)) {
      this.digits = numbers.split('');
      this._onChange(numbers);
    } else {
      event.preventDefault();
    }
  }

  private emitChange(): void {
    const value = this.inputs.map(input => input.nativeElement.value).join('');
    this._onChange(value.length === 0 ? null : value);
  }
}

import { Component, OnInit, ViewChild, NgZone, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { AppService } from 'src/app/services/app.service';
import { OnlyCountriesService } from 'src/app/services/only-countries.service';

//https://developers.google.com/maps/documentation/javascript/examples/places-autocomplete-addressform
//https://www.c-sharpcorner.com/article/how-to-integrate-google-maps-in-angular-14-app/

declare var $: any;
declare var paypal: any

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrl: './order.component.css'
})
export class OrderComponent implements OnInit {
  packTypeList = ['Envelope', 'Pack', 'Box']
  paymodeList = ['Pay Online']
  envelopeTypeList = ['Small', 'Medium', 'Large']
  username = localStorage.getItem('username');
  app_role_name = localStorage.getItem('app_role_name');
  company_name = localStorage.getItem('company')
  user_role = localStorage.getItem('role')
  user = localStorage.getItem('main_username');
  name = localStorage.getItem('name')
  cus_transaction_id = localStorage.getItem('id')
  DOC = new Date()
  actsList = ['Estimation', 'Quote', 'Order', 'Delivery', 'Invoice', 'Return']
  actas: any
  kyc_verified = false
  zone: any
  locationsList: any
  allLocationsList: any
  branchesList: any
  currencyList: any
  accountList: any
  post_on_act: any
  allTaxRates: any
  taxRateList: any
  tax_act: any
  total_tax_per: any
  sub_taxes: any
  currency: any
  vendor: any
  selectedlocation: any
  show_summary = true
  pack_loader: any
  discount_amt: any
  discount_freight_amt: any
  coupon_code: any
  gst_price: any
  total_selling_price: any
  p_country: any;
  p_state: any;
  p_city: any;
  p_zipcode: any;
  p_street_name: any;
  p_flat_no: any;
  p_district: any;
  p_mandal: any;
  s_country: any;
  s_state: any;
  s_city: any;
  s_zipcode: any;
  s_street_name: any;
  s_flat_no: any;
  s_district: any;
  s_mandal: any;
  s_email: any
  bill_lat: any
  bill_lng: any
  ship_lat: any
  ship_lng: any
  zoom = 12;
  s_picker: any
  p_picker: any
  view = false
  freight_rate: any
  vendorList: any
  modal_heading: any
  openType = 'add'
  descriptionModal: any
  categoryList: any
  category: any
  sub_category: any
  subcategoryList: any
  item: any
  no_of_items: any
  value_of_goods: any
  itemsList = []
  all_subcategoryList: any
  segmentList: any
  packagesList: any
  package: any
  pack_price: any;
  custom_weight: any
  Coupon: any
  coupon_offer: any
  notes: any
  docs_for_ver: any
  load_docs = false
  order_status: any
  Comments: any
  payment_type = 'Pay Online'
  pop_up_error: any
  itemIndex: any
  s_scheduled_date: any
  s_pickerid: any
  p_pickerid: any
  p_scheduled_date: any
  discount_type: any
  offer_on = 'Percentage'
  offer_price: any
  offer_price_error: any
  customersList: any
  customer: any
  customerInfo: any
  delivery_mode_list: any
  delivery_mode: any
  delivery_mode_id: any

  image_file_selected: any
  image_file_count = 0
  item_file_error: any
  itemFilename: any
  transaction_id: any
  item_actual_name: any
  kyc_state: any
  kycList: any
  id_proof_name: any
  id_proof_id: any
  if_proof_file: any
  id_proof_file_loc: any
  address_proof_doc: any
  loc_val: any
  p_mobileno: any;
  s_mobileno: any
  private geoCoder;
  rate_card_id: any
  pack_id: any
  rate_card_name: any
  p_landmark: any
  s_landmark: any
  p_name: any
  s_name: any
  itemsList1 = []
  package_type: any
  envelope_type: any
  pack_width: any
  pack_height: any
  pack_Depth: any
  no_of_packs: any
  packDetails = []
  custom_category: any
  custom_charges: any
  orderStatusList = []
  couponsList: any
  shipping_by: any
  show_pickUp = false
  box_type = false
  document_type = false
  showEmail = false

  consignmentOrder: LogisticConsignmentOrder = new LogisticConsignmentOrder();

  @ViewChild('search', { static: false }) public searchElementRef: ElementRef;
  @ViewChild('sec_search', { static: false }) public sec_searchElementRef: ElementRef;
  constructor(private appService: AppService, private onlyCountriesService: OnlyCountriesService,
    private route: ActivatedRoute, public router: Router, private ngZone: NgZone,
    // private mapsAPILoader: MapsAPILoader
    // private location: Location
  ) { }//

  countryList = this.onlyCountriesService.countriesList
  selected_country = 'India'
  image_url = this.appService.get_image_url()
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: false,
    navSpeed: 700,

    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 2
      },
      900: {
        items: 3
      }
    },
    nav: false
  };
  onClickPackingMode(option) {
    this.package_type = option
  }

  onClickDelmode(option) {
    this.delivery_mode = option.name
    this.delivery_mode_id = option.transaction_id
  }

  onClickPaymentMode(option) {
    this.payment_type = option
  }

  onChangenoOfpacks() {
    if (this.no_of_packs) {
      this.packDetails = []
      for (let i = 0; i < this.no_of_packs; i++) {
        this.packDetails.push({ 'width': '', 'height': '', 'depth': '', 'charges': 0, 'weight': '', 'description': '', 'transaction_id': this.appService.generate_random_stringsmall(21) })
      }
    }
    else {
      this.packDetails = []
    }
  }

  saveModalData() {
    if (!this.category) {
      this.pop_up_error = 'Please Select Category'
      return
    }
    if (!this.no_of_items) {
      this.pop_up_error = 'Please Enter No Of Items'
      return
    }
    this.pop_up_error = ''
    if (this.openType == 'add') {
      let transaction_id = this.appService.generate_random_stringsmall(21)
      this.itemsList.push({ 'category': this.category, 'subCategory': this.sub_category, 'item': this.item, 'no_of_items': this.no_of_items, 'value_of_items': this.value_of_goods, 'description': this.descriptionModal, 'filename': this.itemFilename, 'actual_filename': this.item_actual_name, 'transaction_id': transaction_id })
      let index = this.itemsList1.findIndex(x => x.category == this.category)
      if (index == -1) {
        this.itemsList1.push({ 'category': this.category, items: [{ 'category': this.category, 'subCategory': this.sub_category, 'item': this.item, 'no_of_items': this.no_of_items, 'value_of_items': this.value_of_goods, 'description': this.descriptionModal, 'filename': this.itemFilename, 'actual_filename': this.item_actual_name, 'transaction_id': transaction_id }] })
      }
      else {
        this.itemsList1[index].items.push({ 'category': this.category, 'subCategory': this.sub_category, 'item': this.item, 'no_of_items': this.no_of_items, 'value_of_items': this.value_of_goods, 'description': this.descriptionModal, 'filename': this.itemFilename, 'actual_filename': this.item_actual_name, 'transaction_id': transaction_id })
      }
      this.closeModel()
    }
    else {
      this.itemsList[this.itemIndex] = { 'category': this.category, 'subCategory': this.sub_category, 'item': this.item, 'no_of_items': this.no_of_items, 'value_of_items': this.value_of_goods, 'description': this.descriptionModal, 'filename': this.itemFilename, 'actual_filename': this.item_actual_name }
      this.itemsList1[this.categoryIndex].items[this.itemIndex] = { 'category': this.category, 'subCategory': this.sub_category, 'item': this.item, 'no_of_items': this.no_of_items, 'value_of_items': this.value_of_goods, 'description': this.descriptionModal, 'filename': this.itemFilename, 'actual_filename': this.item_actual_name }
    }
  }

  removeItem(j, option, type) {
    let type1 = this.route.snapshot.paramMap.get('type');
    let cat = option.category
    if (type == 'category') {
      this.itemsList1.splice(j, 1)
      for (let i = 0; i < this.itemsList.length; i++) {
        if (this.itemsList[i]['category'] == cat) {
          let transId = this.itemsList[i]['transaction_id']
          this.itemsList.splice(i, 1)
          if (type1 == 'edit') {
            this.appService.get_data('/delete_oneitem/' + transId)
              .subscribe(data => {

              })
          }
          i = i - 1
        }
      }
    }
    else {
      let transId = this.itemsList[j]['transaction_id']
      this.itemsList.splice(j, 1)
      let index = this.itemsList1.findIndex(x => x.category == cat)
      this.itemsList1[index].items.splice(j, 1)
      if (this.itemsList1[index].items.length == 0) {
        this.itemsList1.splice(index, 1)
      }
      if (type1 == 'edit') {
        this.appService.get_data('/delete_oneitem/' + transId)
          .subscribe(data => {

          })
      }
    }

  }

  details(desc) {
    this.appService.openModelWithText('Description', desc, '')
  }

  categoryIndex: any
  openModel(msg_head, openType, i, j, option) {
    $("#myModal2").modal('show');
    this.modal_heading = msg_head
    this.openType = openType
    this.item_actual_name = undefined
    this.itemFilename = undefined
    if (this.openType == 'add') {
      this.sub_category = undefined
      this.item = undefined
      this.no_of_items = undefined
      this.value_of_goods = undefined
      this.descriptionModal = undefined
    }
    else {
      this.itemIndex = i
      this.categoryIndex = j
      this.category = option.category
      // this.sub_category = option.subCategory
      this.item = option.item
      this.no_of_items = option.no_of_items
      this.value_of_goods = option.value_of_items
      this.descriptionModal = option.description
    }
  }

  closeModel() {
    $("#myModal2").modal('hide');
  }

  numberOnlyMob(event): boolean {

    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    // this.offer_price_error = ''
    return true;
  }

  numberOnlyPerVal(event): boolean {

    const charCode = (event.which) ? event.which : event.keyCode;
    let a = event.srcElement.value + event.key
    // console.log(a)
    let b = parseInt(a)
    if (this.offer_on == 'Flat') {
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        // return false;
        if (charCode == 46) {
          return true;
        }
        else {
          return false;
        }

      }
      this.offer_price_error = ''
      return true;
    }
    else {
      if (b < 100) {
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
          // return false;
          if (charCode == 46) {
            return true;
          }
          else {
            return false;
          }

        }
        this.offer_price_error = ''
        return true;
      }
      else {
        this.offer_price_error = 'Offer Price Percentage should be <100'
        return false;
      }
    }

  }

  address_proof_image: any
  address_proof_actual_image: any
  id_proof_image: any
  id_proof_actual_image: any
  file_error: any
  fileChangeListener(event, type) {
    var files = event.target.files
    this.image_file_selected = ' ' + this.image_file_count + ' Files Selected'
    let a = 0
    this.file_error = ''
    // if(!this.customer){
    //   this.file_error = 'Please select Customer'
    //   return
    // }
    if (files.length > 1) {
      this.file_error = 'Maximum Images Should be 1'
    }
    else {
      for (let i = 0; i < files.length; i++) {
        let fileItem = files[i];
        if ((fileItem.name.endsWith(".png")) || (fileItem.name.endsWith(".jpg")) || (fileItem.name.endsWith(".jpeg"))) {
          if (fileItem.size > 10242880) {
            this.file_error = "Each Image size should be < 10Mb"

          }
          else if (fileItem.size < 1000) {
            this.file_error = 'Each Image size should be > 1kb'

          }
          else {
            let transid = this.appService.generate_random_string(16)
            let d = fileItem.name
            let b = d.split('.')
            let l = b.length
            let c = transid + '.' + b[l - 1]
            if (type == 'id_proof') {
              this.id_proof_image = '/static/' + this.cus_transaction_id + '/kyc/' + c
              this.id_proof_actual_image = d
            }
            else {
              this.address_proof_image = '/static/' + this.cus_transaction_id + '/kyc/' + c
              this.address_proof_actual_image = d
            }
            const formData = new FormData();
            formData.append('file', fileItem);
            let url = '/fileupload_with_name_project/' + this.cus_transaction_id + '/' + c + '/kyc'
            // let url ='/fileupload_with_name_subFolder1/cargo/'+c+'/'+this.transaction_id+'/orders'
            this.appService.upload(formData, url)
              .subscribe(data => {
                // console.log(data)
              })
            let base64img
            var reader = new FileReader();
            reader.readAsDataURL(files[0]);

            reader.onload = (data) => {
              base64img = reader.result

            }

            reader.onerror = function (error) {
              // console.log('Error: ', error);
            };
            // this.file_error = ''
            a = a + 1
          }
        }
        else {
          this.file_error = 'File type should be jpg,png,pdf only'
        }
      }
    }
    console.log(this.id_proof_image)
  }


  fileChangeListenerItem(event) {
    var files = event.target.files
    this.image_file_selected = ' ' + this.image_file_count + ' Files Selected'
    let a = 0
    this.itemFilename = undefined
    this.item_actual_name = undefined
    if (files.length > 1) {
      this.item_file_error = 'Maximum Images Should be 10'
    }
    else {
      for (let i = 0; i < files.length; i++) {
        let fileItem = files[i];
        if ((fileItem.name.endsWith(".png")) || (fileItem.name.endsWith(".jpg")) || (fileItem.name.endsWith(".jpeg"))) {
          if (fileItem.size > 10242880) {
            this.item_file_error = "Each Image size should be < 10Mb"

          }
          else if (fileItem.size < 1000) {
            this.item_file_error = 'Each Image size should be > 1kb'

          }
          else {
            let transid = this.appService.generate_random_string(16)
            let d = fileItem.name
            let b = d.split('.')
            let l = b.length
            let c = transid + '.' + b[l - 1]
            this.itemFilename = '/static/cargo/orders/' + this.transaction_id + '/' + c
            this.item_actual_name = d

            const formData = new FormData();
            formData.append('file', fileItem);
            let url = '/fileupload_with_name_subFolder1/cargo/' + c + '/' + this.transaction_id + '/orders'
            this.appService.upload(formData, url)
              .subscribe(data => {
                // console.log(data)
              })
            let base64img
            var reader = new FileReader();
            reader.readAsDataURL(files[0]);

            reader.onload = (data) => {
              base64img = reader.result
              // this.image_file_list1.push({'file_name':c,'sno':i+1,'mime_type':files[i].type,'type':'image','actual_filename':files[i].name,'base64Img':base64img})

            }

            reader.onerror = function (error) {
              // console.log('Error: ', error);
            };
            // this.file_error = ''
            a = a + 1
          }
        }
        else {
          this.item_file_error = 'File type should be jpg,png,pdf only'
        }
      }
    }
  }

  onChangedeliveryMode() {
    if (this.delivery_mode) {
      let list = this.delivery_mode_list.find(x => x.name == this.delivery_mode)
      this.delivery_mode_id = list.transaction_id
    }
  }

  offered_price: any
  onChangeOffer() {
    if (this.offer_on && this.offer_price && this.freight_rate) {
      if (this.offer_on == 'Percentage') {
        this.offered_price = this.freight_rate * (this.offer_price / 100)
        this.discount_amt = this.offered_price
        this.discount_freight_amt = this.freight_rate - this.offered_price
        this.gst_price = this.discount_freight_amt * (this.total_tax_per / 100)
        this.total_selling_price = this.gst_price + this.discount_freight_amt
        //add-package.component.ts:563 11850 592.5 11257.5 2026.35 13283.85
        // console.log(this.freight_rate, this.offered_price ,this.discount_freight_amt,this.gst_price,this.discount_price,this.total_tax_per)
      }
      else {
        this.offered_price = this.offer_price
        this.discount_amt = this.offered_price
        this.discount_freight_amt = this.freight_rate - this.offer_price
        this.gst_price = this.discount_freight_amt * (this.total_tax_per / 100)
        this.total_selling_price = this.gst_price + this.discount_freight_amt
      }
    }
  }

  couponError: any
  onCouponApply() {
    this.couponError = ''
    if (this.Coupon && this.freight_rate) {
      if (this.couponsList.length > 0) {
        let list = this.couponsList.find(x => x.Coupon_id == this.Coupon)
        if (list) {
          if (list.is_Apply_Kg == 'true') {
            if (this.pack_loader == list.Kgs) {
              this.applyCoupon(list)
              return
            }
          }
          if (list.is_Apply_Slab == 'true') {
            if ((this.pack_loader >= list.Slab_Wise_Kgs) && (this.pack_loader <= list.slab_wise_kgto)) {
              this.applyCoupon(list)
              return
            }

          }
          if (list.is_loaded_Kg == 'true') {
            if (this.package != 'custom_pack') {
              if (list.Package_Loader.includes(this.package)) {
                this.applyCoupon(list)
                return
              }
            }
          }
          if ((list.is_Apply_Kg != 'true') && (list.is_Apply_Slab != 'true') && (list.is_loaded_Kg != 'true')) {
            this.applyCoupon(list)
            return
          }
        }
        else {
          this.couponError = 'Please Enter a Valid Coupon'
        }
      }
    }
    else {
      // this.appService.openModel('Please enter Coupon ')
    }
  }

  showCouponOffer = false
  couponType: any
  applyCoupon(list) {
    if (list.country.trim()) {
      if (this.cur_country) {
        if (list.country.includes(this.cur_country)) {
          this.couponError = ''
        }
        else {
          this.couponError = 'You Are not eligible for this Coupon'
          return
        }
      }
    }
    this.showCouponOffer = true
    if (list.offer_on == 'Percentage') {
      this.couponType = 'Percentage'
      this.offer_price = list.Coupon_discount
      this.offered_price = this.freight_rate * (this.offer_price / 100)
      this.discount_amt = this.offered_price
      this.discount_freight_amt = this.freight_rate - this.offered_price
      this.gst_price = this.discount_freight_amt * (this.total_tax_per / 100)
      this.total_selling_price = this.gst_price + this.discount_freight_amt
    }
    else {
      this.couponType = 'Flat'
      this.offer_price = list.Coupon_discount
      this.offered_price = this.offer_price
      this.discount_amt = this.offered_price
      this.discount_freight_amt = this.freight_rate - this.offer_price
      this.gst_price = this.discount_freight_amt * (this.total_tax_per / 100)
      this.total_selling_price = this.gst_price + this.discount_freight_amt
    }
  }


  cusMobileNo: any

  onChangeCustomer() {
    this.customerInfo = this.customersList.find(x => x.username == this.customer)
    this.kyc_state = this.customerInfo.kyc_state
    this.cus_transaction_id = this.customerInfo.transaction_id
    this.cusMobileNo = this.customerInfo.mobile_no
    if ((this.kyc_state == 'uploaded') || (this.kyc_state == 'verified')) {
      this.appService.get_data('/get_kyc/' + this.cus_transaction_id)
        .subscribe(data => {
          this.kycList = data as JSON
          if (this.kycList) {
            this.id_proof_name = this.kycList.id_proof
            this.id_proof_id = this.kycList.id_proof_no
            this.if_proof_file = this.kycList.file_name
            this.id_proof_file_loc = this.image_url + this.kycList.file
          }
        })
    }
    else {
      this.kycList = undefined
    }

  }

  onAttach() {
    this.id_proof_image = this.kycList.file
  }

  onClickCustomerInfo() {
    let msg = '<span style="float:left;">Username : ' + this.customerInfo.username + '</span><br>'
    msg += '<span style="float:left;">Name : ' + this.customerInfo.name + '</span><br>'
    msg += '<span style="float:left;">Mobile No : ' + this.customerInfo.mobile_no + '</span><br>'

    if (this.customerInfo.kyc_state == "uploaded") {
      msg += '<span style="float:left;">Kyc State : Uploaded</span><br>'
    }
    else if (this.customerInfo.kyc_state == "verified") {
      msg += '<span style="float:left;">Kyc State : Verified</span><br>'
    }
    this.appService.openModelWithText('Customer info', msg, '')
  }

  onchangeZone() {
    if (this.zone) {
      this.locationsList = this.allLocationsList.filter(x => x.branch.toLowerCase().indexOf(this.zone.toLowerCase()) > -1)
    }
  }


  getControls() {
    this.categoryList = []
    this.subcategoryList = []
    this.all_subcategoryList = []
    this.appService.get_data('/getCategorySegmentNoperModule/Cargo')
      .subscribe(data => {
        if (data) {
          if (typeof (data) == 'object') {
            this.segmentList = data as JSON
            for (let i = 0; i < this.segmentList.length; i++) {

              if (this.segmentList[i]['segment_control'] == 'Category') {
                this.categoryList.push(this.segmentList[i])
              }
              else if (this.segmentList[i]['segment_control'] == 'SubCategory') {
                this.subcategoryList.push(this.segmentList[i])
                this.all_subcategoryList.push(this.segmentList[i])
              }
            }
          }
        }
      })
  }


  onChangePackage() {
    console.log(this.package)
    if (!this.p_country) {
      this.appService.openModel('Please Select Delivery Country', 'warning')
      return
    }
    if (!this.delivery_mode) {
      this.appService.openModel('Please Select Delivery Mode', 'warning')
      return
    }
    if (this.package == 'custom_pack') {

      // this.rateCardList

    }
    else {
      let list = this.packagesList.find(x => x.packDetails.transaction_id = this.package)

      this.rate_card_id = list.packDetails.transaction_id
      this.rate_card_name = list.packDetails.pack_title
      this.pack_loader = list.packDetails.package_loader
      this.shipping_by = list.packDetails.shipping_type
      this.vendor_details = list.packDetails.packDetails
      console.log(this.shipping_by)
      let prices = list.RateCard_details
      if (prices.length > 0) {
        for (let i = 0; i < prices.length; i++) {
          if ((prices[i]['delivery_mode'] == this.delivery_mode) || (prices[i]['delivery_mode_id'] == this.delivery_mode_id)) {

            this.pack_price = prices[i].discount_freight_amt
            this.freight_rate = prices[i].discount_freight_amt
            this.discount_freight_amt = prices[i].discount_freight_amt
            this.gst_price = prices[i].gst_price
            this.total_tax_per = prices[i].Total_tax_per
            this.total_selling_price = parseInt(this.discount_freight_amt + this.gst_price)
            this.actual_pack_price = this.total_selling_price
            this.actual_pack_tax = this.gst_price
            this.discount_amt = 0
            this.actual_pack_price = prices[i].discount_price
            this.actual_pack_tax = prices[i].gst_price




          }
        }
        this.getDetailsFromPurchaseRatecard()
      }
      // let prices = list.RateCard_details.find(x=>x.)



    }
  }
  actual_pack_price: any
  actual_pack_tax: any
  onChangeBoxType() {
    if ((this.box_type == false) && (this.document_type == true)) {
      this.pack_loader = 0.5
      this.onChangeCustomPackage()
    }
  }

  onChangeCustomPackage() {
    if (!this.p_country) {
      this.appService.openModel('Please Select Delivery Country', 'warning')
      return
    }
    if (!this.delivery_mode) {
      this.appService.openModel('Please Select Delivery Mode', 'warning')
      return
    }
    if (!this.pack_loader) {
      // this.appService.openModel('Please Select Pack Loader','warning')
      return
    }
    let ratesList = []
    console.log(this.rateCardList)
    for (let i = 0; i < this.rateCardList.length; i++) {
      let delivery_mode_details = JSON.parse(this.rateCardList[i].Gen_details.delivery_mode_details)
      let list = delivery_mode_details.find(x => x.transaction_id == this.delivery_mode_id)
      if ((this.rateCardList[i].Gen_details.Eligibility_country.includes(this.p_country)) && (list)) {

        let rateCardRates = this.rateCardList[i]['RateCard_details']
        for (let j = 0; j < rateCardRates.length; j++) {
          if ((rateCardRates[j]['Weight'] == this.pack_loader) && ((rateCardRates[j]['delivery_mode'] == this.delivery_mode) || (rateCardRates[j]['delivery_mode_id'] == this.delivery_mode_id))) {
            let rates = rateCardRates[j]
            rates.vendor = this.rateCardList[i].Gen_details.Vendor
            rates.vendor_details = this.rateCardList[i].Gen_details.vendor_details
            rates.rateCardId = this.rateCardList[i].Gen_details.transaction_id
            rates.Rate_card_info = this.rateCardList[i].Gen_details.Rate_card_info
            ratesList.push(rates)
          }
        }
      }
    }
    if (ratesList.length > 0) {
      var lowest = Number.POSITIVE_INFINITY;
      var lowest_id
      var tmp
      for (let i = 0; i < ratesList.length; i++) {
        tmp = ratesList[i].Total_Rate;
        if (tmp < lowest) {
          lowest = tmp
          lowest_id = i
        }
      }

      let list = ratesList[lowest_id]
      console.log(list)
      this.shipping_by = list.vendor
      if (list.vendor_details) {
        this.vendor_details = JSON.parse(list.vendor_details)
      }
      console.log(this.vendor_details)
      this.actual_pack_price = list.Total_Rate
      this.actual_pack_tax = list.Total_Tax
      this.pack_price = list.Freight_Rate
      this.freight_rate = list.Freight_Rate
      this.discount_freight_amt = list.Freight_Rate
      this.total_tax_per = list.Total_tax_per
      this.gst_price = list.Total_Tax
      this.total_selling_price = list.Total_Rate
      this.rate_card_name = list.Rate_card_info
      this.rate_card_id = list.rateCardId
      this.discount_amt = 0
    }
    this.getDetailsFromPurchaseRatecard()
  }

  vendor_details: any
  purchase_price: any
  purchase_tax: any
  getDetailsFromPurchaseRatecard() {
    let ratesList = []
    for (let i = 0; i < this.purRateCardList.length; i++) {
      let delivery_mode_details = JSON.parse(this.rateCardList[i].Gen_details.delivery_mode_details)
      let list = delivery_mode_details.find(x => x.transaction_id == this.delivery_mode_id)
      if ((this.purRateCardList[i].Gen_details.Eligibility_country.includes(this.p_country)) && (list)) {

        let rateCardRates = this.purRateCardList[i]['RateCard_details']
        for (let j = 0; j < rateCardRates.length; j++) {
          if ((rateCardRates[j]['Weight'] == this.pack_loader) && ((rateCardRates[j]['delivery_mode'] == this.delivery_mode) || (rateCardRates[j]['delivery_mode_id'] == this.delivery_mode_id))) {
            let rates = rateCardRates[j]

            ratesList.push(rates)
          }
        }
      }
    }
    if (ratesList.length > 0) {
      var lowest = Number.POSITIVE_INFINITY;
      var lowest_id
      var tmp
      for (let i = 0; i < ratesList.length; i++) {
        tmp = ratesList[i].Total_Rate;
        if (tmp < lowest) {
          lowest = tmp
          lowest_id = i
        }
      }
      let list = ratesList[lowest_id]
      this.purchase_price = list.Freight_Rate
      this.purchase_tax = list.Total_Tax

    }
    if (!this.purchase_price) {
      this.purchase_price = this.freight_rate

    }
    if (!this.purchase_tax) {
      this.purchase_tax = this.gst_price
    }
  }



  getPackages() {
    this.appService.get_data('/getAllPAcksRatecardPrices')
      .subscribe(data => {
        this.packagesList = data as JSON
      })
  }

  getBranches() {
    this.appService.get_data('/get_defaultBranch')
      .subscribe(data => {
        let msg
        msg = data as JSON
        if (typeof (msg) == 'object') {
          this.branchesList = msg
          this.zone = this.branchesList['branch']
          console.log(this.zone)
        }
      })
  }

  getLocations() {
    this.appService.get_data('/get_defaultLocation')
      .subscribe(data => {
        let msg
        msg = data as JSON
        if (typeof (msg) == 'object') {
          this.locationsList = msg
          this.selectedlocation = this.locationsList['location']
          // this.allLocationsList = this.locationsList
          console.log(this.selectedlocation)
        }
      })
  }
  getCurrencyList() {
    this.appService.get_data('/getCurrency')
      .subscribe(data => {
        if (typeof (data) == 'object') {
          let msg
          msg = data as JSON
          if (msg.length > 0) {
            this.currencyList = data as JSON
          }
        }
      })
    //[{"subTaxes":[{"name":"CGST","value":"92.25"},{"name":"SGST","value":"92.25"}],"Freight_Rate": "1025","Rate": "2050","Total_Rate": "1178.75","Total_Tax": "184.50","Weight": "0.5"}]
  }

  getAccount() {
    this.accountList = []
    this.appService.get_data('/get_account_info_type/Incomes')
      .subscribe(data => {
        if (typeof (data) == 'object') {
          let msg
          msg = data as JSON
          if (msg.length > 0) {
            this.accountList = data as JSON
            for (let i = 0; i < this.accountList.length; i++) {
              if (this.accountList[i]['is_default'] == 'true') {
                this.post_on_act = this.accountList[i]['account_name']
              }
            }
          }
        }
      })
  }


  getTaxesAccount() {
    this.taxRateList = []
    this.appService.get_data('/getdefault_taxaccountBasedType/GST')
      .subscribe(data => {
        if (typeof (data) == 'object') {
          let msg
          msg = data as JSON
          if (msg.length > 0) {
            this.allTaxRates = data as JSON
            for (let i = 0; i < this.allTaxRates.length; i++) {
              if (this.allTaxRates[i]['account_relation'] == 'Tax On Income') {
                this.taxRateList.push(this.allTaxRates[i])
                if (this.allTaxRates[i]['is_default'] == 'true') {
                  this.tax_act = this.allTaxRates[i]['account_name']
                  this.total_tax_per = this.allTaxRates[i]['tax_rate']
                  if (this.allTaxRates[i]['tax_rates_subtype'].trim()) {
                    this.sub_taxes = JSON.parse(this.allTaxRates[i]['tax_rates_subtype'])
                  }

                }
              }
            }
          }
        }
      })
  }

  onChangeTaxAccount() {
    if (this.tax_act) {
      let list = this.taxRateList.find(x => x.account_name == this.tax_act)
      if (list) {
        this.tax_act = list['account_name']
        this.total_tax_per = list['tax_rate']
        if (list['tax_rates_subtype'].trim()) {
          this.sub_taxes = JSON.parse(list['tax_rates_subtype'])
        }
      }

    }
  }




  save(data) {
    console.log(this.id_proof_image)
    let type = this.route.snapshot.paramMap.get('type');
    let name = this.route.snapshot.paramMap.get('id');
    let rate_card_id
    let rate_card_name
    let pack_type
    if ((this.document_type == false) && (this.box_type == false)) {
      this.appService.openModel('Please select a Packing Type', 'warning')
      return
    }

    if (!this.delivery_mode) {
      this.appService.openModel('Please select a delivery mode', 'warning')
      return
    }
    if (!this.p_country) {
      this.appService.openModel('Please select Country in Delivery Address ', 'warning')
      return
    }
    if (!this.p_flat_no) {
      this.appService.openModel('Please Enter Flat No in Delivery Address ', 'warning')
      return
    }
    if (!this.p_street_name) {
      this.appService.openModel('Please Enter  Street Name Delivery Address', 'warning')
      return
    }
    if (!this.p_zipcode) {
      this.appService.openModel('Please Enter ZipCode in Delivery Address ', 'warning')
      return
    }
    if (!this.p_city) {
      this.appService.openModel('Please Enter City in Delivery Address ', 'warning')
      return
    }
    if (!this.p_state) {
      this.appService.openModel('Please State in Delivery Address ', 'warning')
      return
    }
    if (!this.p_mobileno) {
      this.appService.openModel('Please Mobile No in Delivery Address ', 'warning')
      return
    }
    if (!this.p_name) {
      this.appService.openModel('Please Name in Delivery Address ', 'warning')
      return
    }
    if (!this.s_country) {
      this.appService.openModel('Please select Country in Pickup Address ', 'warning')
      return
    }
    if (!this.s_flat_no) {
      this.appService.openModel('Please Enter Flat No in Pickup Address ', 'warning')
      return
    }
    if (!this.s_street_name) {
      this.appService.openModel('Please Enter  Street Name Pickup Address', 'warning')
      return
    }
    if (!this.s_zipcode) {
      this.appService.openModel('Please Enter ZipCode in Pickup Address ', 'warning')
      return
    }
    if (!this.s_city) {
      this.appService.openModel('Please Enter City in Pickup Address ', 'warning')
      return
    }
    if (!this.s_state) {
      this.appService.openModel('Please State in Pickup Address ', 'warning')
      return
    }
    if (!this.s_mobileno) {
      this.appService.openModel('Please Mobile No in Pickup Address ', 'warning')
      return
    }
    if (!this.s_name) {
      this.appService.openModel('Please Name in Pickup Address ', 'warning')
      return
    }

    // if(!this.package){
    //   this.appService.openModel('Please Select a Package','warning')
    //   return
    // }
    if (!this.total_selling_price) {
      this.appService.openModel('No Selling Price', 'warning')
      return
    }
    if (!this.payment_type) {
      this.appService.openModel('Please select a Payment Type', 'warning')
      return
    }

    if (!this.id_proof_image) {
      this.appService.openModel('Please Upload  Documents', 'warning')
      return
    }
    if (this.box_type == true) {
      if (this.itemsList.length == 0) {
        this.appService.openModel('Add Atleast one item', 'warning')
        return
      }
      if (this.packDetails.length > 0) {
        let count = 0
        for (let i = 0; i < this.packDetails.length; i++) {
          if ((!this.packDetails[i]['width']) || (!this.packDetails[i]['height']) || (!this.packDetails[i]['depth']) || (!this.packDetails[i]['weight'])) {
            this.appService.openModel('Please Enter Pack Detals', 'warning')
            return
          }
          count += this.packDetails[i]['weight']
        }
        if (count > this.pack_loader) {
          this.appService.openModel('Package Weights should not be greater than Choosed Weight', 'warning')
          return
        }
      }
    }
    let vendor
    if (this.vendor_details) {
      vendor = JSON.stringify(this.vendor_details)
    }
    if (this.package) {
      if (this.package == 'custom_pack') {
        rate_card_id = this.rate_card_id
        rate_card_name = this.rate_card_name
        pack_type = 'custom_pack'
      }
      else {
        rate_card_id = this.pack_id
        rate_card_name = this.package
        pack_type = 'package'
      }
    }
    else {
      rate_card_id = this.rate_card_id
      rate_card_name = this.rate_card_name
      pack_type = 'custom_pack'
    }


    let schedule_date
    let Comments = encodeURIComponent(this.Comments)
    let notes = encodeURIComponent(this.notes)
    if (type == 'add') {
      // if(!this.order_status){
      //   this.appService.openModel('Please select a Order Status','warning')
      //   return
      // }
      let kyc_state = 'not_verified'
      // if(this.customerInfo.kyc_state=='verified'){
      //   kyc_state = 'verified'
      // }
      // else{
      //   kyc_state = 'not_verified'
      // }
      this.appService.postXml('/insert_Ordersinfo/' + this.username + '/' + this.app_role_name + '?discounted_amt=' + this.discount_amt + '&total_tax=' + this.gst_price + '&rate_card_id=' + rate_card_id + '&rate_card_name=' + rate_card_name + '&pack_type=' + pack_type + '&Zone=' + this.zone + '&Location=' + this.selectedlocation + '&Exchange_currency=' + this.currency + '&Party_name=' + this.s_name + '&Post_on_acc=' + this.post_on_act + '&Tax_account=' + this.tax_act + '&verified_kyc=' + kyc_state
        + '&Customer_info=' + this.s_name + '&Act_as=' + this.actas + '&Description=&username=' + this.username + '&delivery_mode=' + this.delivery_mode + '&delivery_mode_id=' + this.delivery_mode_id + '&p_country=' + this.p_country + '&p_state=' + this.p_state + '&p_house_no=' + this.p_flat_no + '&p_streetadress=' + this.p_street_name + '&p_city=' + this.p_city + '&p_Land_mark=' + this.p_landmark + '&p_Zipcode=' + this.p_zipcode + '&p_latitude=' + this.bill_lat + '&p_longitude=' + this.bill_lng
        + '&s_country=' + this.s_country + '&s_state=' + this.s_state + '&s_house_no=' + this.s_flat_no + '&s_streetadress=' + this.s_street_name + '&s_city=' + this.s_city + '&s_Land_mark=' + this.s_landmark + '&s_Zipcode=' + this.s_zipcode + '&s_latitude=' + this.ship_lat + '&s_longitude=' + this.ship_lng
        + '&Allotted_picker=' + this.s_picker + '&Scheduled_date=' + this.s_scheduled_date + '&Package_price_kgs=' + this.actual_pack_tax + '&Package_amount=' + this.actual_pack_price + '&Package_kgs=' + this.pack_loader + '&Discount=&Apply_coupon_code=' + this.Coupon + '&Notes=' + notes + '&Packing_category=&Package_info=&Custom_category=' + this.custom_category
        + '&Custom_amount=' + this.custom_charges + '&Is_kyc=' + kyc_state + '&oad_package=&Add_proof_details=' + this.address_proof_doc + '&Id_proof=' + this.docs_for_ver + '&Address_proof=' + this.address_proof_image + '&front_side=' + this.id_proof_image + '&back_side=&Address_details=&Image=&Payment_details=' + this.payment_type + '&Transaction_details=&Transaction_status=' + this.order_status + '&Order_status=' + this.order_status + '&Comments=' + Comments
        + '&transaction_id=' + this.transaction_id + '&total_selling_price=' + this.total_selling_price + '&dicount_per=' + this.offer_price + '&discount_type=' + this.discount_type + '&coupon_code=' + this.Coupon + '&discount_val_type=' + this.offer_on + '&freight_rate=' + this.freight_rate + '&p_mobile_no=' + this.p_mobileno + '&s_mobile_no=' + this.s_mobileno + '&p_name=' + this.p_name + '&s_name=' + this.s_name
        + '&package_type=' + this.package_type + '&envelope_type=' + this.envelope_type + '&no_of_packs=' + this.no_of_packs + '&discount_freight_amt=' + this.discount_freight_amt + '&shipping_by=' + this.shipping_by + '&purchase_freight_rate=' + this.purchase_price + '&purchase_tax=' + this.purchase_tax + '&vendor_details=' + vendor + '&payment_status=' + this.payment_status
        + '&payer_id=' + data.payerID + '&paymentOrderId=' + data.orderID + '&payment_id=' + data.paymentID + '&failitator_token=' + data.facilitatorAccessToken + '&billing_token=' + data.billingToken + '&doc_type=' + this.document_type + '&box_type=' + this.box_type)
        // billingToken: null
        // facilitatorAccessToken: "A21AALo8jszq8TtP3Z2aqw1LHiL19UYSNmKY1eAmBxY5cfm2rBD-XbaYfnHZf06vy0UEQdHbTQbug2yDqSRbkikK_BSvR1yUg"
        // orderID: "4V312572GF950252T"
        // payerID: "K4C5NSDYBWLW6"
        // paymentID: null
        .subscribe(data => {
          let msg
          msg = data as JSON
          if (typeof (msg) == 'object') {
            for (let i = 0; i < this.itemsList.length; i++) {
              //this.itemsList.push({'category':this.category,'subCategory':this.sub_category,'item':this.item,'no_of_items':this.no_of_items,'value_of_items':this.value_of_goods,'description':this.descriptionModal,'filename':this.itemFilename,'actual_filename':this.item_actual_name})
              let item = this.itemsList[i]
              let desc = encodeURIComponent(item.description)
              let filename
              if (item['filename']) {
                filename = item['filename']
              }
              this.appService.postXml('/insert_Iteminfo/' + this.username + '/' + this.app_role_name + '?Item=' + item.item + '&Quantity=' + item.no_of_items + '&Value_of_goods=' + item.value_of_items + '&description=' + desc + '&username=' + this.username + '&filename=' + filename + '&actual_filename=' + item.actual_filename + '&order_id=' + this.transaction_id + '&category=' + item.category + '&transaction_id=' + item.transaction_id)
                .subscribe(data => {

                })
            }
            if (this.no_of_packs && this.packDetails.length > 0) {
              for (let i = 0; i < this.packDetails.length; i++) {
                let pack = this.packDetails[i]
                let desc = encodeURIComponent(pack.description)
                this.appService.postXml('/insert_Packinfo/' + this.username + '/' + this.app_role_name + '?width=' + pack.width + '&height=' + pack.height + '&depth=' + pack.depth + '&weight=' + pack.weight + '&username=' + this.username + '&charges=' + pack.charges + '&order_id=' + this.transaction_id + '&description=' + desc + '&transaction_id=' + pack.transaction_id)
                  .subscribe(data => {

                  })
              }
            }
            this.sendMail()
            this.appService.openModelWithText('Success', 'Order Received succesfully with order id <br>' + msg.id + '<br>Please save this order id for future References.', 'success')
            this.load_inputs()
          }
          else if (msg == 'exists') {
            this.appService.openModel('Order  Already Exists ', 'error')
          }
          else {
            this.appService.openModel(msg, 'warning')
          }
        })
    }
    else {
      this.appService.postXml('/update_Ordersinfo/' + this.username + '/' + this.app_role_name + '/' + this.transaction_id + '?discounted_amt=' + this.discount_amt + '&total_tax=' + this.gst_price + '&rate_card_id=' + rate_card_id + '&rate_card_name=' + rate_card_name + '&pack_type=' + pack_type + '&Zone=' + this.zone + '&Location=' + this.selectedlocation + '&Exchange_currency=' + this.currency + '&Party_name=' + this.s_name + '&Post_on_acc=' + this.post_on_act + '&Tax_account=' + this.tax_act + '&verified_kyc=' + this.kyc_state
        + '&Customer_info=' + this.s_name + '&Act_as=' + this.actas + '&Description=&username=' + this.username + '&delivery_mode=' + this.delivery_mode + '&delivery_mode_id=' + this.delivery_mode_id + '&p_country=' + this.p_country + '&p_state=' + this.p_state + '&p_house_no=' + this.p_flat_no + '&p_streetadress=' + this.p_street_name + '&p_city=' + this.p_city + '&p_Land_mark=' + this.p_landmark + '&p_Zipcode=' + this.p_zipcode + '&p_latitude=' + this.bill_lat + '&p_longitude=' + this.bill_lng
        + '&s_country=' + this.s_country + '&s_state=' + this.s_state + '&s_house_no=' + this.s_flat_no + '&s_streetadress=' + this.s_street_name + '&s_city=' + this.s_city + '&s_Land_mark=' + this.s_landmark + '&s_Zipcode=' + this.s_zipcode + '&s_latitude=' + this.ship_lat + '&s_longitude=' + this.ship_lng
        + '&Allotted_picker=' + this.s_picker + '&Scheduled_date=' + this.s_scheduled_date + '&Package_price_kgs=' + this.actual_pack_tax + '&Package_amount=' + this.actual_pack_price + '&Package_kgs=' + this.pack_loader + '&Discount=&Apply_coupon_code=' + this.coupon_code + '&Notes=' + notes + '&Packing_category=&Package_info=&Custom_category=' + this.custom_category
        + '&Custom_amount=' + this.custom_charges + '&Is_kyc=' + this.kyc_state + '&oad_package=&Add_proof_details=' + this.address_proof_doc + '&Id_proof=' + this.docs_for_ver + '&Address_proof=' + this.address_proof_image + '&front_side=' + this.id_proof_image + '&back_side=&Address_details=&Image=&Payment_details=' + this.payment_type + '&Transaction_details=&Transaction_status=' + this.order_status + '&Order_status=' + this.order_status + '&Comments=' + Comments
        + '&transaction_id=' + this.transaction_id + '&total_selling_price=' + this.total_selling_price + '&dicount_per=' + this.offer_price + '&discount_type=' + this.discount_type + '&coupon_code=' + this.Coupon + '&discount_val_type=' + this.offer_on + '&freight_rate=' + this.freight_rate + '&p_mobile_no=' + this.p_mobileno + '&s_mobile_no=' + this.s_mobileno + '&p_name=' + this.p_name + '&s_name=' + this.s_name
        + '&package_type=' + this.package_type + '&envelope_type=' + this.envelope_type + '&no_of_packs=' + this.no_of_packs + '&discount_freight_amt=' + this.discount_freight_amt + '&Order_id=' + this.order_id + '&shipping_by=' + this.shipping_by + '&purchase_freight_rate=' + this.purchase_price + '&purchase_tax=' + this.purchase_tax + '&payment_status=' + this.payment_status + '&vendor_details=' + vendor
        + '&doc_type=' + this.document_type + '&box_type=' + this.box_type)
        .subscribe(data => {//package_type,envelope_type,no_of_packs
          let msg
          msg = data as JSON
          if (typeof (msg) == 'object') {
            for (let i = 0; i < this.itemsList.length; i++) {
              //this.itemsList.push({'category':this.category,'subCategory':this.sub_category,'item':this.item,'no_of_items':this.no_of_items,'value_of_items':this.value_of_goods,'description':this.descriptionModal,'filename':this.itemFilename,'actual_filename':this.item_actual_name})
              let item = this.itemsList[i]
              let desc = encodeURIComponent(item.description)
              let filename
              if (item['filename']) {
                filename = '/static/cargo/orders/' + this.transaction_id + '/' + item['filename']
              }
              this.appService.postXml('/insert_Iteminfo/' + this.username + '/' + this.app_role_name + '?Item=' + item.item + '&Quantity=' + item.no_of_items + '&Value_of_goods=' + item.value_of_items + '&description=' + desc + '&username=' + this.username + '&filename=' + filename + '&actual_filename=' + item.actual_filename + '&order_id=' + this.transaction_id + '&category=' + item.category + '&transaction_id=' + item.transaction_id)
                .subscribe(data => {

                })
            }
            if (this.no_of_packs && this.packDetails.length > 0) {
              for (let i = 0; i < this.packDetails.length; i++) {
                let pack = this.packDetails[i]
                let desc = encodeURIComponent(pack.description)
                this.appService.postXml('/insert_Packinfo/' + this.username + '/' + this.app_role_name + '?width=' + pack.width + '&height=' + pack.height + '&depth=' + pack.depth + '&weight=' + pack.weight + '&username=' + this.username + '&charges=' + pack.charges + '&order_id=' + this.transaction_id + '&description=' + desc + '&transaction_id=' + pack.transaction_id)
                  .subscribe(data => {

                  })
              }
            }
            //{'width':'','height':'','depth':'','charges':0,'weight':'','description':'','transaction_id':this.appService.generate_random_stringsmall(21)}

            this.appService.openModelWithText('Success', 'Order Updated succesfully with order id <br>' + msg.id + '<br>Please save this order id for future References.', 'success')
            this.load_inputs()
          }
          else if (msg == 'exists') {
            this.appService.openModel('Order  Already Exists ', 'error')
          }
          else {
            this.appService.openModel(msg, 'warning')
          }
        })
    }

  }


  sendMail() {
    if (!this.app_role_name) {
      let pass = this.appService.generate_random_string(5)
      let password = pass + '@1'
      let transaction_id = this.appService.generate_random_string(16)
      this.appService.postXml('/register?name=' + this.s_name + '&email=' + this.username + '&mobile_no=' + this.s_mobileno + '&password=' + password + '&transaction_id=' + transaction_id + '&app_role_name=Customer&user_role=Customer')
        .subscribe(data => {
          let regMSg
          regMSg = data as JSON
          if (regMSg == 'success') {
            let userShow = true
            localStorage.setItem('id', transaction_id)
            localStorage.setItem('name', this.s_name)
            localStorage.setItem('username', this.username)
            localStorage.setItem('app_role_name', 'Customer')
            // let cartList = JSON.stringify(this.mailList)
            // this.appService.postXml('/send_mail1/'+this.bill_email+'?invoice_no='+invoice_no+'&name='+this.bill_first_name+'&address='+this.bill_address+'&pincode='+this.bill_post+'&state='+this.bill1_state+'&city='+this.bill1_city+'&country='+this.bill1_country+'&mobile='+this.bill_mobile+'&gst='+this.gst_number+'&total_price='+this.total_price+'&sub_total='+this.excl_gst_price+'&gst_price='+this.incl_gst+'&list='+cartList+'&showreg='+userShow+'&password='+this.password)
            //       .subscribe(data=>{
            //         // console.log(data)
            // })

            this.appService.insert_contact(transaction_id, this.username, this.s_name, this.s_mobileno, 'Customer')
            this.appService.postXml('/sendCredentials?username=' + this.username + '&password=' + password)
              .subscribe(data => {

              })
          }
          else {
            // let cartList = JSON.stringify(this.mailList)
            // this.appService.postXml('/send_mail_invoice/'+this.bill_email+'?invoice_no='+invoice_no+'&name='+this.bill_first_name+'&address='+this.bill_address+'&pincode='+this.bill_post+'&state='+this.bill1_state+'&city='+this.bill1_city+'&country='+this.bill1_country+'&mobile='+this.bill_mobile+'&gst='+this.gst_number+'&total_price='+this.total_price+'&sub_total='+this.excl_gst_price+'&gst_price='+this.incl_gst+'&list='+cartList)
            //   .subscribe(data=>{
            //   })
          }
        })

    }
    else {
      // let cartList = JSON.stringify(this.mailList)
      // this.appService.postXml('/send_mail1/'+this.bill_email+'?invoice_no='+invoice_no+'&name='+this.bill_first_name+'&address='+this.bill_address+'&pincode='+this.bill_post+'&state='+this.bill1_state+'&city='+this.bill1_city+'&country='+this.bill1_country+'&mobile='+this.bill_mobile+'&gst='+this.gst_number+'&total_price='+this.total_price+'&sub_total='+this.excl_gst_price+'&gst_price='+this.incl_gst+'&list='+cartList)
      //   .subscribe(data=>{
      //   })
    }
  }


  load_inputs() {
    let type = this.route.snapshot.paramMap.get('type');
    this.zone = undefined
    this.selectedlocation = undefined
    this.customer = undefined
    this.currency = undefined
    this.customer_name = undefined
    this.customerInfo = undefined
    this.delivery_mode = undefined
    this.delivery_mode_id = undefined
    this.p_country = undefined
    this.p_flat_no = undefined
    this.p_street_name = undefined
    this.p_landmark = undefined
    this.bill_lat = undefined
    this.bill_lng = undefined
    this.p_city = undefined
    this.p_zipcode = undefined
    this.p_state = undefined
    this.p_name = undefined
    this.p_mobileno = undefined
    this.s_country = undefined
    this.s_flat_no = undefined
    this.s_street_name = undefined
    this.s_landmark = undefined
    this.ship_lat = undefined
    this.ship_lng = undefined
    this.s_city = undefined
    this.s_zipcode = undefined
    this.s_state = undefined
    this.s_name = undefined
    this.s_mobileno = undefined
    this.package_type = undefined
    this.package = undefined
    this.no_of_packs = undefined
    this.pack_loader = undefined
    this.discount_type = undefined
    this.offer_on = 'Percentage'
    this.offer_price = undefined
    this.discount_amt = undefined
    this.actual_pack_price = undefined
    this.actual_pack_tax = undefined
    this.pack_price = undefined
    this.freight_rate = undefined
    this.discount_freight_amt = undefined
    // this.total_tax_per = list.Total_tax_per
    this.gst_price = undefined
    this.total_selling_price = undefined
    this.offered_price = undefined
    this.custom_category = undefined
    this.custom_charges = undefined
    this.docs_for_ver = undefined
    this.address_proof_doc = undefined
    this.order_id = undefined
    this.shipping_by = undefined
    this.purchase_price = undefined
    this.purchase_tax = undefined
    this.payment_status = undefined
    this.address_proof_image = undefined
    this.id_proof_image = undefined


    this.payment_type = undefined
    this.rate_card_name = undefined
    this.rate_card_id = undefined

    this.Coupon = undefined
    this.kyc_state = false
    this.notes = undefined
    this.itemsList = []
    this.itemsList1 = []
    this.packDetails = []
    this.orderStatusList = []
    // if(type=='edit'){
    //   this.route.snapshot.paramMap['params'] = {type:'add'}
    //   this.location.replaceState("/orderForm/add");
    // }
  }

  cancel() {
    let type = this.route.snapshot.paramMap.get('type');
    if (type == 'add') {
      this.zone = undefined
      this.selectedlocation = undefined
      this.customer = undefined
      this.currency = undefined
      this.customer_name = undefined
      this.customerInfo = undefined
      this.delivery_mode = undefined
      this.delivery_mode_id = undefined
      this.p_country = undefined
      this.p_flat_no = undefined
      this.p_street_name = undefined
      this.p_landmark = undefined
      this.bill_lat = undefined
      this.bill_lng = undefined
      this.p_city = undefined
      this.p_zipcode = undefined
      this.p_state = undefined
      this.p_name = undefined
      this.p_mobileno = undefined
      this.s_country = undefined
      this.s_flat_no = undefined
      this.s_street_name = undefined
      this.s_landmark = undefined
      this.ship_lat = undefined
      this.ship_lng = undefined
      this.s_city = undefined
      this.s_zipcode = undefined
      this.s_state = undefined
      this.s_name = undefined
      this.s_mobileno = undefined
      this.package_type = undefined
      this.package = undefined
      this.no_of_packs = undefined
      this.pack_loader = undefined
      this.discount_type = undefined
      this.offer_on = 'Percentage'
      this.offer_price = undefined
      this.discount_amt = undefined
      this.actual_pack_price = undefined
      this.actual_pack_tax = undefined
      this.pack_price = undefined
      this.freight_rate = undefined
      this.discount_freight_amt = undefined
      // this.total_tax_per = list.Total_tax_per
      this.gst_price = undefined
      this.total_selling_price = undefined
      this.offered_price = undefined
      this.custom_category = undefined
      this.custom_charges = undefined
      this.docs_for_ver = undefined
      this.address_proof_doc = undefined
      this.order_id = undefined
      this.shipping_by = undefined
      this.purchase_price = undefined
      this.purchase_tax = undefined
      this.payment_status = undefined
      this.address_proof_image = undefined
      this.id_proof_image = undefined


      this.payment_type = undefined
      this.rate_card_name = undefined
      this.rate_card_id = undefined

      this.Coupon = undefined
      this.kyc_state = false
      this.notes = undefined
      this.itemsList = []
      this.itemsList1 = []
      this.packDetails = []
      this.orderStatusList = []
    }
    else {
      this.router.navigate(['/cargoOrdersList']);
    }
  }



  update_order_status() {
    if (!this.order_status) {
      this.appService.openModel('Please select a Order status', 'success')
      return
    }
    let desc = encodeURIComponent(this.Comments)
    this.appService.postXml('/update_order_status?name=' + this.order_status + '&description=' + desc + '&transaction_id=' + this.transaction_id + '&username=' + this.username)
      .subscribe(data => {
        let msg
        msg = data as JSON
        if (msg == 'success') {
          this.appService.openModel('Updated Order Status', 'success')
        }
        else {
          this.appService.openModel('Failed to Update', 'success')
        }
      })
  }

  changeAdr(val) {
    debugger;
    this.loc_val = val


    // this.geoCoder = new google.maps.Geocoder;
    // if(this.loc_val=='prim'){
    //   let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
    //   autocomplete.addListener("place_changed", () => {
    //     // console.log('hello')
    //     this.ngZone.run(() => {
    //       //get the place result
    //       let place: google.maps.places.PlaceResult = autocomplete.getPlace();
    //       // console.log(place)
    //       //verify result
    //       if (place.geometry === undefined || place.geometry === null) {
    //         return;
    //       }

    //       //set latitude, longitude and zoom
    //       this.bill_lat = place.geometry.location.lat();
    //       this.bill_lng = place.geometry.location.lng();
    //       debugger
    //       this.getAddress(place)
    //       this.zoom = 12;
    //     });

    //   })
    // }
    // else{

    //   let autocomplete = new google.maps.places.Autocomplete(this.sec_searchElementRef.nativeElement);
    //   autocomplete.addListener("place_changed", () => {
    //     // console.log('hello')
    //     this.ngZone.run(() => {
    //       //get the place result
    //       let place: google.maps.places.PlaceResult = autocomplete.getPlace();

    //       //verify result
    //       if (place.geometry === undefined || place.geometry === null) {
    //         return;
    //       }

    //       //set latitude, longitude and zoom
    //       this.ship_lat = place.geometry.location.lat();
    //       this.ship_lng = place.geometry.location.lng();
    //       this.getSecAddress(place)
    //       this.zoom = 12;
    //     });

    //   })
    // }


  }

  getAddress(place) {

    for (var i = 0; i < place.address_components.length; i++) {
      if (place.address_components[i].types[0] == "locality") {
        //this is the object you are looking for City
        let loc_city = place.address_components[i];
        this.p_city = loc_city.long_name
      }
      if (place.address_components[i].types[0] == "administrative_area_level_1") {
        //this is the object you are looking for State
        let loc_region = place.address_components[i];
        this.p_state = loc_region.long_name
      }
      if (place.address_components[i].types[0] == "country") {
        //this is the object you are looking for
        let loc_country = place.address_components[i];
        this.p_country = loc_country.long_name
      }
      if (place.address_components[i].types[0] == "postal_code") {
        let loc_pin = place.address_components[i];
        this.p_zipcode = loc_pin.long_name
      }
      else {
        this.p_zipcode = ''
      }
      this.p_district = ''
      this.p_mandal = ''
      this.p_street_name = ''
      this.p_flat_no = ''
    }

  }

  getSecAddress(place) {
    for (var i = 0; i < place.address_components.length; i++) {
      if (place.address_components[i].types[0] == "locality") {
        //this is the object you are looking for City
        let loc_city = place.address_components[i];
        this.s_city = loc_city.long_name
      }
      if (place.address_components[i].types[0] == "administrative_area_level_1") {
        //this is the object you are looking for State
        let loc_region = place.address_components[i];
        this.s_state = loc_region.long_name
      }
      if (place.address_components[i].types[0] == "country") {
        //this is the object you are looking for
        let loc_country = place.address_components[i];
        this.s_country = loc_country.long_name
      }
      if (place.address_components[i].types[0] == "postal_code") {
        let loc_pin = place.address_components[i];
        this.s_zipcode = loc_pin.long_name
      }
      else {
        this.s_zipcode = ''
      }
      this.s_district = ''
      this.s_mandal = ''
      this.s_street_name = ''
      this.s_flat_no = ''
    }

  }

  private setCurrentLocation() {
    // this.geoCoder = new google.maps.Geocoder;
    // if ('geolocation' in navigator) {
    //   navigator.geolocation.getCurrentPosition((position) => {
    //     // console.log(position)
    //     let lat = position.coords.latitude;
    //     let lng = position.coords.longitude;
    //     this.zoom = 8;
    //     this.getCurAddress(lat, lng);
    //   });
    // }
  }

  cur_country: any
  getCurAddress(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {

        if (results[1]) {
          var indice = 0;
          var pin_indice = -1;
          for (var j = 0; j < results.length; j++) {
            if (results[j].types[0] == 'locality') {
              indice = j;
            }
            if (results[j].types[0] == 'postal_code') {
              pin_indice = j
              // continue;
            }
          }
          // alert('The good number is: '+j);
          j = indice
          // console.log(results[j]);
          for (var i = 0; i < results[j].address_components.length; i++) {
            if (results[j].address_components[i].types[0] == "country") {
              //this is the object you are looking for
              let loc_country = results[j].address_components[i];
              this.cur_country = loc_country.long_name
            }

          }

        }
        else {
          // alert("No results found");
          this.appService.openModel('No results found', 'warning')
        }
        //}

      }
      else {
        // alert("Geocoder failed due to: " + status);
        this.appService.openModel("Geocoder failed due to: " + status, 'warning')
      }




    });
    // console.log(this.cur_country)
  }

  i_accept = false
  apply_couponcheck = false
  onChangeCoupon() {
    if (this.apply_couponcheck == true) {
      this.discount_type = 'Coupon'
    }
    else {
      this.discount_type = undefined
    }
  }
  placeMarker($event) {
    this.bill_lat = $event.coords.lat
    this.bill_lng = $event.coords.lng
    //this.getAddress($event.coords.lat,$event.coords.lng)
  }
  initPayPalButton() {
    if (!this.zone) {
      this.appService.openModel('Please Select Branch', 'warning')
      return
    }
    if (!this.selectedlocation) {
      this.appService.openModel('Please Select Location', 'warning')
      return
    }
    if (!this.i_accept) {
      this.appService.openModel('Please Accept Terms & Conditions', 'warning')
      return
    }
    if ((this.document_type == false) && (this.box_type == false)) {
      this.appService.openModel('Please select a Package Type', 'warning')
      return
    }

    if (!this.delivery_mode) {
      this.appService.openModel('Please select a delivery mode', 'warning')
      return
    }
    if (!this.p_country) {
      this.appService.openModel('Please select Country in Delivery Address ', 'warning')
      return
    }
    if (!this.p_flat_no) {
      this.appService.openModel('Please Enter Flat No in Delivery Address ', 'warning')
      return
    }
    if (!this.p_street_name) {
      this.appService.openModel('Please Enter  Street Name Delivery Address', 'warning')
      return
    }
    if (!this.p_zipcode) {
      this.appService.openModel('Please Enter ZipCode in Delivery Address ', 'warning')
      return
    }
    if (!this.p_city) {
      this.appService.openModel('Please Enter City in Delivery Address ', 'warning')
      return
    }
    if (!this.p_state) {
      this.appService.openModel('Please State in Delivery Address ', 'warning')
      return
    }
    if (!this.p_mobileno) {
      this.appService.openModel('Please Mobile No in Delivery Address ', 'warning')
      return
    }
    if (!this.p_name) {
      this.appService.openModel('Please Name in Delivery Address ', 'warning')
      return
    }
    if (!this.s_country) {
      this.appService.openModel('Please select Country in Pickup Address ', 'warning')
      return
    }
    if (!this.s_flat_no) {
      this.appService.openModel('Please Enter Flat No in Pickup Address ', 'warning')
      return
    }
    if (!this.s_street_name) {
      this.appService.openModel('Please Enter  Street Name Pickup Address', 'warning')
      return
    }
    if (!this.s_zipcode) {
      this.appService.openModel('Please Enter ZipCode in Pickup Address ', 'warning')
      return
    }
    if (!this.s_city) {
      this.appService.openModel('Please Enter City in Pickup Address ', 'warning')
      return
    }
    if (!this.s_state) {
      this.appService.openModel('Please State in Pickup Address ', 'warning')
      return
    }
    if (!this.s_mobileno) {
      this.appService.openModel('Please Mobile No in Pickup Address ', 'warning')
      return
    }
    if (!this.s_name) {
      this.appService.openModel('Please Name in Pickup Address ', 'warning')
      return
    }

    // if(!this.package){
    //   this.appService.openModel('Please Select a Package','warning')
    //   return
    // }
    if (!this.total_selling_price) {
      this.appService.openModel('No Selling Price', 'warning')
      return
    }
    if (!this.payment_type) {
      this.appService.openModel('Please select a Payment Type', 'warning')
      return
    }

    if (!this.id_proof_image) {
      this.appService.openModel('Please Upload  Documents', 'warning')
      return
    }
    if (this.box_type == true) {
      if (this.itemsList.length == 0) {
        this.appService.openModel('Add Atleast one item', 'warning')
        return
      }
      if (this.packDetails.length > 0) {
        let count = 0
        for (let i = 0; i < this.packDetails.length; i++) {
          if ((!this.packDetails[i]['width']) || (!this.packDetails[i]['height']) || (!this.packDetails[i]['depth']) || (!this.packDetails[i]['weight'])) {
            this.appService.openModel('Please Enter Pack Detals', 'warning')
            return
          }
          count += this.packDetails[i]['weight']
        }
        if (count > this.pack_loader) {
          this.appService.openModel('Package Weights should not be greater than Choosed Weight', 'warning')
          return
        }
      }
    }
    paypal.Buttons({
      style: {
        shape: 'rect',
        color: 'blue',
        layout: 'vertical',
        label: 'pay',

      },

      createOrder: (data, actions) => {

        return actions.order.create({

          purchase_units: [{ "amount": { "currency_code": "USD", "value": this.total_selling_price } }]
        });
      },

      onApprove: (data, actions) => {
        return actions.order.capture().then((details) => {

          console.log('hello', data, details)
          // data- billingToken: null
          // facilitatorAccessToken: "A21AALo8jszq8TtP3Z2aqw1LHiL19UYSNmKY1eAmBxY5cfm2rBD-XbaYfnHZf06vy0UEQdHbTQbug2yDqSRbkikK_BSvR1yUg"
          // orderID: "4V312572GF950252T"
          // payerID: "K4C5NSDYBWLW6"
          // paymentID: null
          this.payment_status = 'Success'
          this.save(data)
          // this.add_order(orderid,data,invoiceno)
          // this.app
          // alert('Transaction completed by ' + details.payer.name.given_name + '!');
        });
      },

      onError: (err) => {
        console.log(err);
      }
    }).render('#paypal-button-container');
  }

  getCustomers() {
    this.appService.get_data('/getCustomers')
      .subscribe(data => {
        this.customersList = data as JSON
      })
  }

  getDeliveryMode() {
    this.appService.get_data('/getfieldsMain/delivery_mode')
      .subscribe(data => {
        this.delivery_mode_list = data as JSON
      })
  }

  rateCardList: any
  getRateCards() {
    this.appService.get_data('/get_sales_rateCardBasedOnType/Sales')
      .subscribe(data => {
        let msg
        msg = data as JSON
        if (typeof (msg) == 'object') {
          this.rateCardList = msg
        }
        console.log(msg)
      })
  }

  purRateCardList: any
  getPurRateCards() {
    this.appService.get_data('/get_sales_rateCardBasedOnType/Purchase')
      .subscribe(data => {
        let msg
        msg = data as JSON
        if (typeof (msg) == 'object') {
          this.purRateCardList = msg
        }
      })
  }


  customer_name: any
  show_id_proof = false
  show_address_proof = false
  order_id: any
  payment_status: any
  date1 = new Date()
  order_date = this.date1.getDate() + '-' + (this.date1.getMonth() + 1) + '-' + this.date1.getFullYear();

  fill_values(name) {
    this.appService.get_data('/get_OneOrderDetails/' + name)
      .subscribe(data => {
        let msg
        msg = data as JSON
        console.log(msg)
        if (typeof (msg) == 'object') {
          this.zone = msg.Zone
          this.selectedlocation = msg.Location
          this.tax_act = msg.Tax_account
          this.post_on_act = msg.Post_on_acc
          this.customer = msg.username
          this.currency = msg.Exchange_currency
          this.customer_name = msg.Customer_info
          this.delivery_mode = msg.delivery_mode
          this.delivery_mode_id = msg.delivery_mode_id
          this.p_country = msg.D_country
          this.p_flat_no = msg.D_houseno
          this.p_street_name = msg.D_streetadr
          this.p_landmark = msg.D_landmark
          this.bill_lat = msg.D_lat
          this.bill_lng = msg.D_lat
          this.p_city = msg.d_city
          this.p_zipcode = msg.d_zipcode
          this.p_state = msg.D_state
          this.p_name = msg.d_name
          this.p_mobileno = msg.d_mobileno
          this.s_country = msg.country
          this.s_flat_no = msg.house_no
          this.s_street_name = msg.street_address
          this.s_landmark = msg.Land_mark
          this.ship_lat = msg.p_lat
          this.ship_lng = msg.p_lng
          this.s_city = msg.city
          this.s_zipcode = msg.Zipcode
          this.s_state = msg.loc_state
          this.s_name = msg.p_name
          this.s_mobileno = msg.p_mobileno
          this.package_type = msg.package_type
          this.package = msg.pack_type
          this.no_of_packs = msg.no_of_packs
          this.pack_loader = msg.Package_kgs
          this.discount_type = msg.discount_type
          this.offer_on = msg.discount_val_type
          this.offer_price = msg.dicount_per
          this.discount_amt = msg.discounted_amt
          this.actual_pack_price = msg.Package_amount
          this.actual_pack_tax = msg.package_tax
          this.pack_price = msg.freight_rate
          this.freight_rate = msg.freight_rate
          this.discount_freight_amt = msg.discount_freight_amt
          // this.total_tax_per = list.Total_tax_per
          this.gst_price = msg.total_tax
          this.total_selling_price = msg.total_selling_price
          this.offered_price = this.offer_price
          this.custom_category = msg.Customs_category
          this.custom_charges = msg.Custom_amount
          this.docs_for_ver = msg.Id_proof
          this.address_proof_doc = msg.Add_proof_details
          this.order_id = msg.Order_id
          this.shipping_by = msg.shipping_by
          this.purchase_price = msg.purchase_freight_rate
          this.purchase_tax = msg.purchase_tax
          this.payment_status = msg.payment_status
          if (msg.Address_proof.trim()) {
            this.show_address_proof = true
            this.address_proof_image = msg.Address_proof
          }
          if (msg.front_side.trim()) {
            this.show_id_proof = true
            this.id_proof_image = msg.front_side
          }

          this.payment_type = msg.Payment_details
          if (this.package == 'custom_pack') {
            this.rate_card_name = msg.rate_card_name
            this.rate_card_id = msg.rate_card_id
          }
          else {
            this.package = msg.rate_card_name
            this.pack_id = msg.rate_card_id
          }
          this.Coupon = msg.Apply_coupon_code.trim()
          this.kyc_state = msg.Is_kyc
          this.notes = msg.Note
          this.order_date = msg.order_date
          if (msg.order_state != 'new') {
            this.s_picker = msg.Allotted_picker
            this.s_pickerid = msg.Allotted_picker_id
            if (msg.Scheduled_date) {
              this.s_scheduled_date = new Date(msg.Scheduled_date)
            }
          }
          if (msg.vendor_details) {
            this.vendor_details = JSON.parse(msg.vendor_details)
          }
          if (((this.package_type == 'Pack') || (this.package_type == 'Box')) && (this.no_of_packs)) {
            this.getPacksList(name)
          }
          this.getItemsList(name)
          this.getOrderStatusList(name)
        }
      })
  }

  getItemsList(name) {
    this.appService.get_data('/get_Itemdetails/' + this.transaction_id)
      .subscribe(data => {
        let msg
        msg = data as JSON
        if (typeof (msg) == 'object') {
          this.itemsList = msg
          for (let i = 0; i < this.itemsList.length; i++) {
            let item = this.itemsList[i]
            let index = this.itemsList1.findIndex(x => x.category == item.category)
            if (index == -1) {
              this.itemsList1.push({ 'category': item.category, items: [{ 'category': item.category, 'item': item.item, 'no_of_items': item.no_of_items, 'value_of_items': item.value_of_items, 'description': item.description, 'filename': item.filename, 'actual_filename': item.actual_filename, 'transaction_id': item.transaction_id }] })

            }
            else {
              this.itemsList1[index].items.push({ 'category': item.category, 'item': item.item, 'no_of_items': item.no_of_items, 'value_of_items': item.value_of_items, 'description': item.description, 'filename': item.filename, 'actual_filename': item.actual_filename, 'transaction_id': item.transaction_id })
            }
          }
        }
      })
  }
  getPacksList(name) {
    this.appService.get_data('/get_Packsdetails/' + this.transaction_id)
      .subscribe(data => {
        let msg
        msg = data as JSON
        if (typeof (msg) == 'object') {
          this.packDetails = msg
        }
      })
  }


  getOrderStatusList(name) {
    this.appService.get_data('/get_all_order_status/' + this.transaction_id)
      .subscribe(data => {
        let msg
        msg = data as JSON
        if (typeof (msg) == 'object') {
          this.orderStatusList = msg
        }
      })
  }


  getAllCoupons() {
    this.appService.get_data('/get_allCargocoupondetails')
      .subscribe(data => {
        let msg
        msg = data as JSON
        if (typeof (msg) == 'object') {
          this.couponsList = msg
        }
      })
  }
  checkType() {
    let type = this.route.snapshot.paramMap.get('type');
    let name = this.route.snapshot.paramMap.get('id');
    if (type == 'add') {
      this.file_open_type = 'add'
      this.transaction_id = this.appService.generate_random_string(16)
      if (!this.cus_transaction_id) {
        this.cus_transaction_id = this.transaction_id
      }
    }
    else {
      this.file_open_type = 'edit'
      this.transaction_id = name
      this.fill_values(name)
    }
  }

  file_open_type: any
  ngOnInit(): void {
    // this.appService.openModelWithText('Success','Order added succesfully with order id  <br>Please save this order id for future References.','success')
    this.getBranches()
    this.getLocations()
    this.getCustomers()
    this.getCurrencyList()
    this.getAccount()
    this.getTaxesAccount()
    this.getDeliveryMode()
    this.getControls()
    this.getPackages()
    this.getAllCoupons()
    this.getRateCards()
    this.getPurRateCards()

    this.setCurrentLocation()
    this.checkType()

    this.getDestinationAddress();
  }


  getDestinationAddress(){
    this.appService.getMaps().subscribe(result =>{
      console.log("from getDestinationAddress",result);
    })
  }


// DestinationAddress

//   receiver_Country: string 
//   receiver_PersonName: string
//   receiver_Contact: string
//   receiver_EmailId: string
//   receiver_Address: string
//   receiver_State: string
//   receiver_City: string
//   receiver_ZipCode: string
//   receiver_Landmark: string


// ShipmentDetails

//   deliveryMode_Id: number --radio buttnon
//   shipmentType_Id: number --radio buttnon
//   isSelf_Drop: boolean --check box
//   selectBoxSize: number --dropdown 
//   bookingImage: string --select image
//   additional_Weight: number 
//   load_weight: number
//   total_Weight: number (additional_Weight+load_weight)
  

// PaymentDetails

//   total_Wt_GrossAmt: number
//   package_Discount: number
//   packing_Charges: number
//   gsT_Rate: number
//   gsT_Amt: number
//   total_Wt_GrsDisAmt: number
//   total_Wt_NetAmt: number
  

//   SenderAddress

//   sender_Country: string
//   sender_PersonName: string
//   sender_Contact: string
//   sender_EmailId: string
//   sender_Address: string
//   sender_State: string
//   sender_City: string
//   sender_ZipCode: string
//   sender_Landmark: string


// //handle in ts
//   ratePer: number
//   dropOff_Hub: number
//   selectPackage: string
//   isDestinationAdrs_Split_ByPack: boolean

}


export class LogisticConsignmentOrder {
  booking_Id: string
  gen_Id: number
  orderDate: string
  branchId: number
  locationId: number
  registredUser: string
  account_Post: number
  taxType: number
  exchange_rate: string
  bookingPin_Status: boolean
  bookingImage: string
  fromCountry: string
  receiver_Country: string
  receiver_PersonName: string
  receiver_Contact: string
  receiver_EmailId: string
  receiver_Address: string
  receiver_State: string
  receiver_City: string
  receiver_ZipCode: string
  receiver_Latitude: string
  receiver_Longitude: string
  receiver_Landmark: string
  sender_Country: string
  sender_PersonName: string
  sender_Contact: string
  sender_EmailId: string
  sender_Address: string
  sender_State: string
  sender_City: string
  sender_ZipCode: string
  sender_Latitude: string
  sender_Longitude: string
  sender_Landmark: string
  shipmentType_Id: number
  hsN_Code: number
  deliveryMode_Id: number
  loadBag_UomId: number
  isSlab: boolean
  selectPackage: string
  load_weight: number
  additional_Weight: number
  total_Weight: number
  selectBoxSize: number
  ratePer: number
  total_Wt_GrossAmt: number
  package_Discount: number
  packing_Charges: number
  gsT_Rate: number
  gsT_Amt: number
  total_Wt_GrsDisAmt: number
  total_Wt_NetAmt: number
  bookingStatus: string
  isSelf_Drop: boolean
  dropOff_Hub: number
  isDestinationAdrs_Split_ByPack: boolean
  payment_Id: string
  payment_Type: string
  paymentTransactionId: string
  mid: string
  cid: number
  branchName: string
  locationName: string
  insertedDate: string
  modifiedDate: string
}






<!-- Hero Start -->
<section class="bg-half-170 d-table w-100" style="background: url('assets/images/modern01.jpg') center center;"
  id="home">
  <div class="bg-overlay"></div>
  <div class="container">
    <div class="row align-items-center mt-md-5">
      <div class="col-lg-5 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0 order-2 order-md-1">
        <div class="card shadow rounded border-0 me-lg-3">
          <div class="card-body">
            <h5 class="card-title">Register Now</h5>

            <form class="login-form">
              <div class="row">
                <div class="col-md-12">
                  <div class="mb-3">
                    <label class="form-label">First name <span class="text-danger">*</span></label>
                    <div class="form-icon position-relative">
                      <i-feather name="user" class="fea icon-sm icons"></i-feather>
                      <input type="text" class="form-control ps-5" placeholder="First Name" name="s" required="">
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="mb-3">
                    <label class="form-label">Your Email <span class="text-danger">*</span></label>
                    <div class="form-icon position-relative">
                      <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                      <input type="email" class="form-control ps-5" placeholder="Email" name="email" required="">
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="mb-3">
                    <label class="form-label">Password <span class="text-danger">*</span></label>
                    <div class="form-icon position-relative">
                      <i-feather name="key" class="fea icon-sm icons"></i-feather>
                      <input type="password" class="form-control ps-5" placeholder="Password" required="">
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-check mb-3">
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                    <label class="form-check-label" for="flexCheckDefault">
                      I Accept <a href="javascript:void(0)" class="text-primary">Terms And Condition</a>
                    </label>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="d-grid">
                    <button class="btn btn-success">Register</button>
                  </div>
                </div>
                <div class="col-lg-12 mt-4 text-center">
                  <h6>Or Signup With</h6>
                  <div class="row">
                    <div class="col-lg-6 mt-3">
                      <div class="d-grid">
                        <a href="javascript:void(0)" class="btn btn-light"><i class="uil uil-facebook text-primary"></i>
                          Facebook</a>
                      </div>
                    </div>
                    <!--end col-->

                    <div class="col-lg-6 mt-3">
                      <div class="d-grid">
                        <a href="javascript:void(0)" class="btn btn-light"><i class="uil uil-google text-danger"></i>
                          Google</a>
                      </div>
                    </div>
                    <!--end col-->
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-7 col-md-6 order-1 order-md-2">
        <div class="title-heading mt-4 ms-lg-4">
          <h1 class="heading title-dark text-white mb-3">Powerful Solution For Your Startup Business </h1>
          <p class="para-desc para-dark text-white-50">Launch your campaign and benefit from our expertise on designing and
            managing conversion centered bootstrap v5 html page.</p>
          <div class="mt-4 pt-2">
            <a href="javascript:void(0)" class="btn btn-primary m-1">Get Started <i
                class="uil uil-angle-right-b"></i></a>
            <a (click)="openWindowCustomClass(content)" href="javascript:void(0);" data-type="youtube"
              data-id="yba7hPeTSjk" class="btn btn-icon btn-pills btn-primary m-1">
              <i-feather name="video" class="icons"></i-feather>
            </a><small class="fw-bold text-uppercase text-white-50 align-middle ms-1">Watch Now</small>
            <ng-template #content let-modal>
              <div class="modal-header">
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <iframe src="https://player.vimeo.com/video/99025203" height="450" width="780" frameborder="0"
                  allowfullscreen allow="autoplay; encrypted-media"></iframe>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-color-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->

<!-- Partners start -->
<section class="py-4 border-bottom">
  <div class="container">
    <app-clients-logo brand_class="col-lg-2 col-md-2 col-6 text-center py-4"></app-clients-logo>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Partners End -->

<!-- Feature Start -->
<section class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Beautiful & Creative Interfaces</h4>
          <p class="text-muted para-desc mb-0 mx-auto">Start working with <span
              class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness,
            drive traffic, connect.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <app-services [servicesData]="servicesData" service_class="features feature-primary text-center"></app-services>
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-5 col-md-5">
        <div class="position-relative">
          <img src="assets/images/company/about.jpg" class="rounded img-fluid mx-auto d-block" alt="">
          <div class="play-icon">
            <a (click)="openModal(contentmodal)" href="javascript:void(0);" class="play-btn video-play-icon border-0">
              <i class="mdi mdi-play text-primary rounded-circle shadow"></i>
            </a>
            <ng-template #contentmodal let-modal>
              <div class="modal-header">
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <iframe src="https://player.vimeo.com/video/287684225" height="450" width="780" frameborder="0"
                  allowfullscreen allow="autoplay; encrypted-media"></iframe>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-7 col-md-7 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <div class="section-title ms-lg-4">
          <h4 class="title mb-4">Startup Business Solution</h4>
          <p class="text-muted">Start working with <span class="text-primary fw-bold">Landrick</span> that can provide
            everything you need to generate awareness, drive traffic, connect.</p>
          <ul class="list-unstyled text-muted">
            <li class="mb-0"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Digital Marketing Solutions for Tomorrow</li>
            <li class="mb-0"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Our Talented & Experienced Marketing Agency</li>
            <li class="mb-0"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Create your own skin to match your brand</li>
          </ul>
          <a href="javascript:void(0)" class="btn btn-primary mt-3">Buy Now <i class="uil uil-angle-right-b"></i></a>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <app-features></app-features>
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">See everything about your <span class="text-primary">Landrick</span></h4>
          <p class="text-muted para-desc mx-auto mb-0">Start working with <span
              class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness,
            drive traffic, connect.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row" id="counter">
      <div class="col-md-3 col-6 mt-4 pt-2">
        <div class="counter-box text-center">
          <img src="assets/images/illustrator/Asset190.svg" class="avatar avatar-small" alt="">
          <h2 class="mb-0 mt-4"><span class="counter-value" [countUp]="45000" [options]="option"></span>$
          </h2>
          <h6 class="counter-head text-muted">Investment</h6>
        </div>
        <!--end counter box-->
      </div>

      <div class="col-md-3 col-6 mt-4 pt-2">
        <div class="counter-box text-center">
          <img src="assets/images/illustrator/Asset189.svg" class="avatar avatar-small" alt="">
          <h2 class="mb-0 mt-4"><span class="counter-value" [countUp]="9" [options]="option"></span>+</h2>
          <h6 class="counter-head text-muted">Awards</h6>
        </div>
        <!--end counter box-->
      </div>

      <div class="col-md-3 col-6 mt-4 pt-2">
        <div class="counter-box text-center">
          <img src="assets/images/illustrator/Asset186.svg" class="avatar avatar-small" alt="">
          <h2 class="mb-0 mt-4"><span class="counter-value" [countUp]="48002" [options]="option"></span>$
          </h2>
          <h6 class="counter-head text-muted">Profitability</h6>
        </div>
        <!--end counter box-->
      </div>

      <div class="col-md-3 col-6 mt-4 pt-2">
        <div class="counter-box text-center">
          <img src="assets/images/illustrator/Asset187.svg" class="avatar avatar-small" alt="">
          <h2 class="mb-0 mt-4"><span class="counter-value" [countUp]="11" [options]="option"></span>%</h2>
          <h6 class="counter-head text-muted">Growth</h6>
        </div>
        <!--end counter box-->
      </div>
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- About End -->

<!-- CTA Start -->
<section class="section bg-cta" style="background: url('assets/images/2.jpg') center center;" id="cta">
  <div class="bg-overlay"></div>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title">
          <h4 class="title title-dark text-white mb-4">We Are Creative Dreamers and Innovators</h4>
          <p class="text-white-50 para-dark para-desc mx-auto">Start working with Landrick that can provide everything you
            need to generate awareness, drive traffic, connect.</p>
          <a (click)="opencreative(contentCreactive)" href="javascript:void(0);"
            class="play-btn mt-4 video-play-icon">
            <i-feather name="play" class="fea icon-ex-md text-white title-dark"></i-feather>
          </a>
          <ng-template #contentCreactive let-modal>
            <div class="modal-header">
              <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <iframe src="https://player.vimeo.com/video/287684225" height="450" width="780" frameborder="0"
                allowfullscreen allow="autoplay; encrypted-media"></iframe>
            </div>
          </ng-template>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- CTA End -->

<!-- Price Start -->
<section class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Charge your creative inspiration</h4>
          <p class="text-muted para-desc mb-0 mx-auto">Start working with <span
              class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness,
            drive traffic, connect.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
    <app-pricing [pricingData]="pricingData"></app-pricing>
  </div>
  <!--end container-->
  <!-- Price End -->

  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-5 col-md-6">
        <img src="assets/images/illustrator/Startup_SVG.svg" class="img-fluid" alt="">
      </div>
      <!--end col-->

      <div class="col-lg-7 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="section-title ms-lg-5">
          <h4 class="title mb-4">A better compose with landrick marketing</h4>
          <p class="text-muted">You can combine all the Landrick templates into a single one, you can take a component
            from the Application theme and use it in the Website.</p>
          <ul class="list-unstyled mb-0 text-muted">
            <li class="mb-0"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Digital Marketing Solutions for Tomorrow</li>
            <li class="mb-0"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Our Talented & Experienced Marketing Agency</li>
            <li class="mb-0"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Create your own skin to match your brand</li>
          </ul>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Important Clients Review</h4>
          <p class="text-muted para-desc mb-0 mx-auto">Start working with <span
              class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness,
            drive traffic, connect.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row justify-content-center">
      <div class="col-lg-9 mt-4 pt-2 text-center">
        <app-review-testmonial [reviewData]="reviewData" [firstimage]="1"></app-review-testmonial>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!--end Price-->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-light">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->


<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->
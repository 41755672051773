import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-page-history',
  templateUrl: './page-history.component.html',
  styleUrls: ['./page-history.component.css']
})

/**
 * Page History Component
 */
export class PageHistoryComponent implements OnInit {

  track_no : any
  orderDetails : any
  schedule_date : any
  constructor(private appService:AppService,public router: Router,private route: ActivatedRoute) { }

  getDetails(){
    
    if(this.track_no){
      this.orderDetails = undefined
      this.schedule_date = undefined
      this.appService.get_data('/get_Orderdetails_based_order_id/'+this.track_no)
      .subscribe(data=>{
        let msg
        msg = data as JSON
        if(typeof(msg)=='object'){
          this.orderDetails = msg
          if(msg.pack_box_details){
            if(msg.Scheduled_date){
              let s_scheduled_date = new Date(msg.Scheduled_date)
              this.schedule_date = s_scheduled_date.getDate()+'-'+ (s_scheduled_date.getMonth() + 1) + '-' + s_scheduled_date.getFullYear();
      
            }
          }

        }
        
      })
    }
    //
  }

  ngOnInit(): void {
    let name = this.route.snapshot.paramMap.get('id');
    this.track_no = name
  }


}

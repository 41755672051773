<!-- Hero Start -->
<section class="vh-100 d-flex align-items-center"
  style="background: url('assets/images/contact-detail.jpg') center center;" id="home">
  <div class="bg-overlay bg-overlay-white"></div>
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 offset-lg-6 col-md-7 offset-md-5">
        <div class="title-heading mt-5 pt-4">
          <h1 class="heading">Let's talk about your portfolio</h1>
          <p class="text-dark">Start working with <span class="text-primary fw-bold">Landrick</span> that can provide
            everything you need to generate awareness, drive traffic, connect.</p>

          <div class="d-flex contact-detail align-items-center mt-3">
            <div class="icon">
              <i-feather name="mail" class="fea icon-m-md text-dark me-3"></i-feather>
            </div>
            <div class="flex-1 content">
              <h5 class="title fw-bold mb-0">Email</h5>
              <a href="mailto:contact@example.com" class="text-primary">contact&#64;example.com</a>
            </div>
          </div>

          <div class="d-flex contact-detail align-items-center mt-3">
            <div class="icon">
              <i-feather name="phone" class="fea icon-m-md text-dark me-3"></i-feather>
            </div>
            <div class="flex-1 content">
              <h5 class="title fw-bold mb-0">Phone</h5>
              <a href="tel:+152534-468-854" class="text-primary">+152 534-468-854</a>
            </div>
          </div>

          <div class="d-flex contact-detail align-items-center mt-3">
            <div class="icon">
              <i-feather name="map-pin" class="fea icon-m-md text-dark me-3"></i-feather>
            </div>
            <div class="flex-1 content">
              <h5 class="title fw-bold mb-0">Location</h5>
              <a (click)="mapView(content)" data-type="iframe" class="video-play-icon text-primary">View on
                Google map</a>
              <ng-template #content let-modal>
                <div class="modal-header">
                  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39206.002432144705!2d-95.4973981212445!3d29.709510002925988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c16de81f3ca5%3A0xf43e0b60ae539ac9!2sGerald+D.+Hines+Waterwall+Park!5e0!3m2!1sen!2sin!4v1566305861440!5m2!1sen!2sin"
                    height="450" width="750">
                  </iframe>
                </div>
              </ng-template>
            </div>
          </div>
          <ul class="list-unstyled social-icon mb-0 mt-4">
            <li class="list-inline-item"><a href="javascript:void(0)" class="rounded">
                <i-feather name="facebook" class="fea icon-sm fea-social"></i-feather>
              </a></li>
            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                <i-feather name="instagram" class="fea icon-sm fea-social"></i-feather>
              </a></li>
            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                <i-feather name="twitter" class="fea icon-sm fea-social"></i-feather>
              </a></li>
            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                <i-feather name="linkedin" class="fea icon-sm fea-social"></i-feather>
              </a></li>
            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                <i-feather name="github" class="fea icon-sm fea-social"></i-feather>
              </a></li>
            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                <i-feather name="youtube" class="fea icon-sm fea-social"></i-feather>
              </a></li>
            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                <i-feather name="gitlab" class="fea icon-sm fea-social"></i-feather>
              </a></li>
          </ul>
          <!--end icon-->
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->
<!-- Hero Start -->
<section class="bg-half-170 bg-light d-table w-100" id="home">
  <div class="container">
    <div class="row mt-5 justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="pages-heading">
          <h2> Design your apps in your own way </h2>
          <ul class="list-unstyled mt-4 mb-0">
            <li class="list-inline-item h6 user text-muted me-2"><i class="mdi mdi-account"></i> Calvin Carlo</li>
            <li class="list-inline-item h6 date text-muted"><i class="mdi mdi-calendar-check"></i> 25th June 2021</li>
          </ul>
        </div>
      </div><!--end col-->
    </div><!--end row-->

    <div class="position-breadcrumb">
      <nav aria-label="breadcrumb" class="d-inline-block">
        <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
          <li class="breadcrumb-item"><a routerLink="/index">Landrick</a></li>
          <li class="breadcrumb-item"><a href="javascript:void(0)">Page</a></li>
          <li class="breadcrumb-item"><a href="javascript:void(0)">Blog</a></li>
          <li class="breadcrumb-item active" aria-current="page">Blog Detail</li>
        </ul>
      </nav>
    </div>
  </div> <!--end container-->
</section><!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-color-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->

<!-- Blog STart -->
<section class="section">
  <div class="container">
    <div class="row">
      <!-- BLog Start -->
      <div class="col-lg-8 col-md-6">
        <div class="card blog blog-detail border-0 shadow rounded">
          <img src="assets/images/blog/01.jpg" class="img-fluid rounded-top" alt="">
          <div class="card-body content">
            <h6><i class="mdi mdi-tag text-primary me-1"></i><a href="javscript:void(0)"
                class="text-primary">Software</a>, <a href="javscript:void(0)" class="text-primary">Application</a></h6>
            <p class="text-muted mt-3">The most well-known dummy text is the 'Lorem Ipsum', which is said to have
              originated in the 16th century. Lorem Ipsum is composed in a pseudo-Latin language which more or less
              corresponds to 'proper' Latin. It contains a series of real Latin words. This ancient dummy text is also
              incomprehensible, but it imitates the rhythm of most European languages in Latin script. </p>
            <blockquote class="blockquote mt-3 p-3">
              <p class="text-muted mb-0 fst-italic">" There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration in some form, by injected humour, or randomised words which
                don't look even slightly believable. "</p>
            </blockquote>
            <p class="text-muted">The advantage of its Latin origin and the relative meaninglessness of Lorum Ipsum is
              that the text does not attract attention to itself or distract the viewer's attention from the layout.</p>
            <div class="post-meta mt-3">
              <ul class="list-unstyled mb-0">
                <li class="list-inline-item me-2 mb-0"><a href="javascript:void(0)" class="text-muted like"><i
                      class="uil uil-heart me-1"></i>33</a></li>
                <li class="list-inline-item"><a href="javascript:void(0)" class="text-muted comments"><i
                      class="uil uil-comment me-1"></i>08</a></li>
              </ul>
            </div>
          </div>
        </div>

        <div class="card shadow rounded border-0 mt-4">
          <div class="card-body">
            <h5 class="card-title mb-0">Comments :</h5>

            <ul class="media-list list-unstyled mb-0">
              <li class="mt-4">
                <div class="d-flex justify-content-between">
                  <div class="d-flex align-items-center">
                    <a class="pe-3" href="javascript:void(0)">
                      <img src="assets/images/client/01.jpg" class="img-fluid avatar avatar-md-sm rounded-circle shadow"
                        alt="img">
                    </a>
                    <div class="commentor-detail">
                      <h6 class="mb-0"><a href="javascript:void(0)" class="text-dark media-heading">Lorenzo Peterson</a>
                      </h6>
                      <small class="text-muted">15th August, 2019 at 01:25 pm</small>
                    </div>
                  </div>
                  <a [ngxScrollTo]="'#home'" class="text-muted"><i class="mdi mdi-reply"></i> Reply</a>
                </div>
                <div class="mt-3">
                  <p class="text-muted fst-italic p-3 bg-light rounded">" There are many variations of passages of Lorem
                    Ipsum available, but the majority have suffered alteration in some form, by injected humour "</p>
                </div>
              </li>

              <li class="mt-4">
                <div class="d-flex justify-content-between">
                  <div class="d-flex align-items-center">
                    <a class="pe-3" href="javascript:void(0)">
                      <img src="assets/images/client/02.jpg" class="img-fluid avatar avatar-md-sm rounded-circle shadow"
                        alt="img">
                    </a>
                    <div class="commentor-detail">
                      <h6 class="mb-0"><a href="javascript:void(0)" class="media-heading text-dark">Tammy Camacho</a>
                      </h6>
                      <small class="text-muted">15th August, 2019 at 05:44 pm</small>
                    </div>
                  </div>
                  <a [ngxScrollTo]="'#home'" class="text-muted"><i class="mdi mdi-reply"></i> Reply</a>
                </div>
                <div class="mt-3">
                  <p class="text-muted fst-italic p-3 bg-light rounded">" There are many variations of passages of Lorem
                    Ipsum available, but the majority have suffered alteration in some form, by injected humour "</p>
                </div>
              </li>

              <li class="mt-4">
                <div class="d-flex justify-content-between">
                  <div class="d-flex align-items-center">
                    <a class="pe-3" href="javascript:void(0)">
                      <img src="assets/images/client/03.jpg" class="img-fluid avatar avatar-md-sm rounded-circle shadow"
                        alt="img">
                    </a>
                    <div class="commentor-detail">
                      <h6 class="mb-0"><a href="javascript:void(0)" class="media-heading text-dark">Tammy Camacho</a>
                      </h6>
                      <small class="text-muted">16th August, 2019 at 03:44 pm</small>
                    </div>
                  </div>
                  <a [ngxScrollTo]="'#home'" class="text-muted"><i class="mdi mdi-reply"></i> Reply</a>
                </div>
                <div class="mt-3">
                  <p class="text-muted fst-italic p-3 bg-light rounded">" There are many variations of passages of Lorem
                    Ipsum available, but the majority have suffered alteration in some form, by injected humour "</p>
                </div>

                <ul class="list-unstyled ps-4 ps-md-5 sub-comment">
                  <li class="mt-4">
                    <div class="d-flex justify-content-between">
                      <div class="d-flex align-items-center">
                        <a class="pe-3" href="javascript:void(0)">
                          <img src="assets/images/client/01.jpg"
                            class="img-fluid avatar avatar-md-sm rounded-circle shadow" alt="img">
                        </a>
                        <div class="commentor-detail">
                          <h6 class="mb-0"><a href="javascript:void(0)" class="text-dark media-heading">Lorenzo
                              Peterson</a></h6>
                          <small class="text-muted">17th August, 2019 at 01:25 pm</small>
                        </div>
                      </div>
                      <a [ngxScrollTo]="'#home'" class="text-muted"><i class="mdi mdi-reply"></i> Reply</a>
                    </div>
                    <div class="mt-3">
                      <p class="text-muted fst-italic p-3 bg-light rounded">" There are many variations of passages of
                        Lorem Ipsum available, but the majority have suffered alteration in some form, by injected
                        humour "</p>
                    </div>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>

        <div class="card shadow rounded border-0 mt-4">
          <div class="card-body">
            <h5 class="card-title mb-0">Leave A Comment :</h5>

            <form class="mt-3">
              <div class="row">
                <div class="col-md-12">
                  <div class="mb-3">
                    <label class="form-label">Your Comment</label>
                    <div class="form-icon position-relative">
                      <i-feather name="message-circle" class="fea icon-sm icons"></i-feather>
                      <textarea id="message" placeholder="Your Comment" rows="5" name="message"
                        class="form-control ps-5" required=""></textarea>
                    </div>
                  </div>
                </div>
                <!--end col-->

                <div class="col-lg-6">
                  <div class="mb-3">
                    <label class="form-label">Name <span class="text-danger">*</span></label>
                    <div class="form-icon position-relative">
                      <i-feather name="user" class="fea icon-sm icons"></i-feather>
                      <input id="name" name="name" type="text" placeholder="Name" class="form-control ps-5" required="">
                    </div>
                  </div>
                </div>
                <!--end col-->

                <div class="col-lg-6">
                  <div class="mb-3">
                    <label class="form-label">Your Email <span class="text-danger">*</span></label>
                    <div class="form-icon position-relative">
                      <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                      <input id="email" type="email" placeholder="Email" name="email" class="form-control ps-5"
                        required="">
                    </div>
                  </div>
                </div>
                <!--end col-->

                <div class="col-md-12">
                  <div class="send d-grid">
                    <button type="submit" class="btn btn-primary">Send Message</button>
                  </div>
                </div>
                <!--end col-->
              </div>
              <!--end row-->
            </form>
            <!--end form-->
          </div>
        </div>

        <div class="card shadow rounded border-0 mt-4">
          <div class="card-body">
            <h5 class="card-title mb-0">Related Posts :</h5>

            <div class="row">
              <div class="col-lg-6 mt-4 pt-2">
                <div class="card blog rounded border-0 shadow">
                  <div class="position-relative">
                    <img src="assets/images/blog/01.jpg" class="card-img-top rounded-top" alt="...">
                    <div class="overlay rounded-top bg-dark"></div>
                  </div>
                  <div class="card-body content">
                    <h5><a href="javascript:void(0)" class="card-title title text-dark">Design your apps in your own
                        way</a></h5>
                    <div class="post-meta d-flex justify-content-between mt-3">
                      <ul class="list-unstyled mb-0">
                        <li class="list-inline-item me-2 mb-0"><a href="javascript:void(0)" class="text-muted like"><i
                              class="uil uil-heart me-1"></i>33</a></li>
                        <li class="list-inline-item"><a href="javascript:void(0)" class="text-muted comments"><i
                              class="uil uil-comment me-1"></i>08</a></li>
                      </ul>
                      <a routerLink="/page-blog-detail" class="text-muted readmore">Read More <i
                          class="uil uil-angle-right-b align-middle"></i></a>
                    </div>
                  </div>
                  <div class="author">
                    <small class="text-light user d-block"><i class="uil uil-user"></i> Calvin Carlo</small>
                    <small class="text-light date"><i class="uil uil-calendar-alt"></i> 13th August, 2019</small>
                  </div>
                </div>
              </div>
              <!--end col-->

              <div class="col-lg-6 mt-4 pt-2">
                <div class="card blog rounded border-0 shadow">
                  <div class="position-relative">
                    <img src="assets/images/blog/02.jpg" class="card-img-top rounded-top" alt="...">
                    <div class="overlay rounded-top bg-dark"></div>
                  </div>
                  <div class="card-body content">
                    <h5><a href="javascript:void(0)" class="card-title title text-dark">How apps is changing the IT
                        world</a></h5>
                    <div class="post-meta d-flex justify-content-between mt-3">
                      <ul class="list-unstyled mb-0">
                        <li class="list-inline-item me-2 mb-0"><a href="javascript:void(0)" class="text-muted like"><i
                              class="uil uil-heart me-1"></i>33</a></li>
                        <li class="list-inline-item"><a href="javascript:void(0)" class="text-muted comments"><i
                              class="uil uil-comment me-1"></i>08</a></li>
                      </ul>
                      <a routerLink="/page-blog-detail" class="text-muted readmore">Read More <i
                          class="uil uil-angle-right-b align-middle"></i></a>
                    </div>
                  </div>
                  <div class="author">
                    <small class="text-light user d-block"><i class="uil uil-user"></i> Calvin Carlo</small>
                    <small class="text-light date"><i class="uil uil-calendar-alt"></i> 13th August, 2019</small>
                  </div>
                </div>
              </div>
              <!--end col-->
            </div>
            <!--end row-->
          </div>
        </div>
      </div>
      <!-- BLog End -->

      <!-- START SIDEBAR -->
      <div class="col-lg-4 col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="card border-0 sidebar sticky-bar ms-lg-4">
            <div class="card-body p-0">
                <!-- Author -->
                <div class="text-center">
                    <span class="bg-light d-block py-2 rounded shadow text-center h6 mb-0">
                        Author
                    </span>

                    <div class="mt-4">
                        <img src="assets/images/client/03.jpg" class="img-fluid avatar avatar-medium rounded-pill shadow-md d-block mx-auto" alt="">

                        <a routerLink="/blog-about" class="text-primary h5 mt-4 mb-0 d-block">Cristina Jota</a>
                        <small class="text-muted d-block">Photographer & Blogger</small>
                    </div>
                </div>
                <!-- Author -->

                <!-- RECENT POST -->
                <div class="widget mt-4">
                    <span class="bg-light d-block py-2 rounded shadow text-center h6 mb-0">
                        Recent Post
                    </span>

                    <div class="mt-4">
                        <div class="d-flex align-items-center">
                            <img src="assets/images/blog/01.jpg" class="avatar avatar-small rounded" style="width: auto;" alt="">
                            <div class="flex-1 ms-3">
                                <a href="javascript:void(0)" class="d-block title text-dark">Consultant Business</a>
                                <span class="text-muted">15th April 2021</span>
                            </div>
                        </div>

                        <div class="d-flex align-items-center mt-3">
                            <img src="assets/images/blog/02.jpg" class="avatar avatar-small rounded" style="width: auto;" alt="">
                            <div class="flex-1 ms-3">
                                <a href="javascript:void(0)" class="d-block title text-dark">Grow Your Business</a>
                                <span class="text-muted">15th April 2021</span>
                            </div>
                        </div>
                        
                        <div class="d-flex align-items-center mt-3">
                            <img src="assets/images/blog/03.jpg" class="avatar avatar-small rounded" style="width: auto;" alt="">
                            <div class="flex-1 ms-3">
                                <a href="javascript:void(0)" class="d-block title text-dark">Look On The Glorious Balance</a>
                                <span class="text-muted">15th April 2021</span>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- RECENT POST -->

                <!-- TAG CLOUDS -->
                <div class="widget mt-4">
                    <span class="bg-light d-block py-2 rounded shadow text-center h6 mb-0">
                        Tagclouds
                    </span>
                    
                    <div class="tagcloud text-center mt-4">
                        <a href="jvascript:void(0)" class="rounded">Business</a>
                        <a href="jvascript:void(0)" class="rounded">Finance</a>
                        <a href="jvascript:void(0)" class="rounded">Marketing</a>
                        <a href="jvascript:void(0)" class="rounded">Fashion</a>
                        <a href="jvascript:void(0)" class="rounded">Bride</a>
                        <a href="jvascript:void(0)" class="rounded">Lifestyle</a>
                        <a href="jvascript:void(0)" class="rounded">Travel</a>
                        <a href="jvascript:void(0)" class="rounded">Beauty</a>
                        <a href="jvascript:void(0)" class="rounded">Video</a>
                        <a href="jvascript:void(0)" class="rounded">Audio</a>
                    </div>
                </div>
                <!-- TAG CLOUDS -->
                
                <!-- SOCIAL -->
                <div class="widget mt-4">
                    <span class="bg-light d-block py-2 rounded shadow text-center h6 mb-0">
                        Social Media
                    </span>

                    <ul class="list-unstyled social-icon social text-center mb-0 mt-4">
                        <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded"><i-feather name="facebook" class="fea icon-sm fea-social"></i-feather></a></li>
                        <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded"><i-feather name="instagram" class="fea icon-sm fea-social"></i-feather></a></li>
                        <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded"><i-feather name="twitter" class="fea icon-sm fea-social"></i-feather></a></li>
                        <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded"><i-feather name="linkedin" class="fea icon-sm fea-social"></i-feather></a></li>
                        <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded"><i-feather name="github" class="fea icon-sm fea-social"></i-feather></a></li>
                        <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded"><i-feather name="youtube" class="fea icon-sm fea-social"></i-feather></a></li>
                        <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded"><i-feather name="gitlab" class="fea icon-sm fea-social"></i-feather></a></li>
                    </ul><!--end icon-->
                </div>
                <!-- SOCIAL -->
            </div>
        </div>
    </div><!--end col-->
      <!-- END SIDEBAR -->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Blog End -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->
<!-- Hero Start -->
<section class="bg-half1 bg-light d-table w-100" id="home">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="page-next-level">
          <!--<h4 class="title"> About us </h4> -->
          <img src="assets/images/XS5.png" alt="">
          <div class="page-next">
            <nav aria-label="breadcrumb" class="d-inline-block">
              <ul class="breadcrumb bg-white rounded shadow mb-0">
                <li class="breadcrumb-item"><a routerLink="/">xoomship</a></li>
                <!-- <li class="breadcrumb-item"><a href="#">Page</a></li> -->
                <li class="breadcrumb-item active" aria-current="page">About Us</li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->

<!-- About Start -->
<section class="section">
  <!-- About Start -->
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-6">
        <div class="row align-items-center">
          <div class="col-lg-6 col-6 mt-4 mt-lg-0 pt-2 pt-lg-0">
            <div class="card work-container work-modern overflow-hidden rounded border-0 shadow-lg">
              <div class="card-body p-0">
                <img src="assets/images/course/online/1 (1).png" class="img-fluid" alt="work-image">
                <div class="overlay-work bg-dark"></div>
                <div class="content">
                  <a href="javascript:void(0)" class="title text-white d-block font-weight-bold">Web Development</a>
                  <small class="text-light">IT & Software</small>
                </div>
              </div>
            </div>

            <!-- <div class="mt-4 pt-2 text-right d-none d-md-block">
              <a href="javascript:void(0)" class="btn btn-primary">See More
                <i-feather name="chevron-right" class="fea icon-sm"></i-feather>
              </a>
            </div> -->
          </div>
          <!--end col-->

          <div class="col-lg-6 col-6">
            <div class="row">
              <div class="col-lg-12 col-md-12 mt-4 mt-lg-0 pt-2 pt-lg-0">
                <div class="card work-container work-modern overflow-hidden rounded border-0 shadow-lg">
                  <div class="card-body p-0">
                    <img src="assets/images/course/online/2 (1).png" class="img-fluid" alt="work-image">
                    <div class="overlay-work bg-dark"></div>
                    <div class="content">
                      <a href="javascript:void(0)" class="title text-white d-block font-weight-bold">Michanical
                        Engineer</a>
                      <small class="text-light">Engineering</small>
                    </div>
                  </div>
                </div>
              </div>
              <!--end col-->

              <div class="col-lg-12 col-md-12 mt-4 pt-2">
                <div class="card work-container work-modern overflow-hidden rounded border-0 shadow-lg">
                  <div class="card-body p-0">
                    <img src="assets/images/course/online/3 (1).png" class="img-fluid" alt="work-image">
                    <div class="overlay-work bg-dark"></div>
                    <div class="content">
                      <a href="javascript:void(0)" class="title text-white d-block font-weight-bold">Chartered
                        Accountant</a>
                      <small class="text-light">C.A.</small>
                    </div>
                  </div>
                </div>
              </div>
              <!--end col-->
            </div>
            <!--end row-->
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end col-->

      <div class="col-lg-6 col-md-6 mt-4 mt-lg-0 pt- pt-lg-0">
        <div class="ml-lg-4">
          <div class="section-title mb-4 pb-2">
            <h4 class="title mb-4">About Us</h4>
            <p class="text-muted para-desc"><span
                class="text-primary font-weight-bold"> Xoomship </span> mission is to be the poineers in delivering packages from India to anywhere in the world, we serve its customers with shipping thier items with highest quality and faster deliveries.
                As we know those days when we didn't have internet or telephones as a means to communicate and postal service was the only
                option for communication.</p>
            <p class="text-muted para-desc mb-0">
               The feeling of getting a package from your loved ones cant be explained. We take it a pride to make every customer 
              happy by providing them the best of the best service in delivering the packages. Be it documents or anything else you can be assured to send them to your loved ones. Xoomship offers various 
              solutions in which customers can avail and process their needs.</p>
          </div>

          <h5>Our Branches :</h5>

          <div class="row">
            <!-- <div class="col-md-6 col-12 mt-4">
              <div class="d-flex align-items-center">
                <i-feather name="map-pin" class="fea icon-md text-muted"></i-feather>
                <div class="content ml-2">
                  <h5 class="mb-0"><a
                      href="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39206.002432144705!2d-95.4973981212445!3d29.709510002925988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c16de81f3ca5%3A0xf43e0b60ae539ac9!2sGerald+D.+Hines+Waterwall+Park!5e0!3m2!1sen!2sin!4v1566305861440!5m2!1sen!2sin"
                      class="video-play-icon text-primary">U.S.A.</a></h5>
                  <p class="text-muted mb-0">{{service_zones_ist}}</p>
                </div>
              </div>
            </div> -->

            <!--<div class="col-md-6 col-12 mt-4">
              <div class="d-flex align-items-center">
                <i-feather name="map-pin" class="fea icon-md text-muted"></i-feather>
                <div class="content ml-2">
                  <h5 class="mb-0"><a
                      href="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39206.002432144705!2d-95.4973981212445!3d29.709510002925988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c16de81f3ca5%3A0xf43e0b60ae539ac9!2sGerald+D.+Hines+Waterwall+Park!5e0!3m2!1sen!2sin!4v1566305861440!5m2!1sen!2sin"
                      class="video-play-icon text-primary"></a></h5>
                  <p class="text-muted mb-0"></p>
                </div>
              </div>
            </div> -->

            <div class="col-md-6 col-12 mt-4">
              <div class="d-flex align-items-center">
                <i-feather name="map-pin" class="fea icon-md text-muted"></i-feather>
                <div class="content ml-2">
                  <h5 class="mb-0">India
                    <!-- <a
                      href="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39206.002432144705!2d-95.4973981212445!3d29.709510002925988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c16de81f3ca5%3A0xf43e0b60ae539ac9!2sGerald+D.+Hines+Waterwall+Park!5e0!3m2!1sen!2sin!4v1566305861440!5m2!1sen!2sin"
                      class="video-play-icon text-primary">
                      
                    </a> -->
                    </h5>
                  <p class="text-muted mb-0">{{service_zones_ist}}</p>
                </div>
              </div>
            </div>
          </div>

          <div class="watch-video mt-4 pt-2">
           
           
            <ng-template #contentmodal let-modal>
              <div class="modal-header">
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <youtube-player [videoId]="'jNTZpfXYJa4'" height="450" width="780">
                </youtube-player>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <!--end container-->
  <!-- About End -->

  
  

  
  

 
</section>
<!--end section-->
<!-- About End -->

<!-- Team Start -->

<!--end section-->
<!-- Team End -->


<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-soft-primary back-to-top" id="back-to-top"
  [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->

<!-- Hero Start -->
<section class="bg-half-260 d-table w-100" id="home"
  style="background: url('assets/images/work/bg-portfolio.jpg') center center;">
  <div class="bg-overlay bg-overlay-white"></div>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12">
        <div class="title-heading text-center mt-5 pt-4">
          <h1 class="display-1 fw-bold mb-3">Minimal Portfolio</h1>
          <p class="para-desc mx-auto h6">Launch your campaign and benefit from our expertise on designing and managing
            conversion centered bootstrap v5 html page.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-color-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->

<!-- Work Start -->
<section class="section">
  <div class="container">
    <div class="row">
      <ul class="col container-filter list-unstyled categories-filter text-center" id="filter">
        <li class="list-inline-item categories-name border text-dark rounded me-2" (click)="activeCategory('all')"
          [ngClass]="{'active': galleryFilter ==='all'}">All</li>
        <li class="list-inline-item categories-name border text-dark rounded me-2" (click)="activeCategory('branding')"
          [ngClass]="{'active': galleryFilter ==='branding'}">Branding</li>
        <li class="list-inline-item categories-name border text-dark rounded me-2" (click)="activeCategory('designing')"
          [ngClass]="{'active': galleryFilter ==='designing'}">Designing</li>
        <li class="list-inline-item categories-name border text-dark rounded me-2"
          (click)="activeCategory('photography')" [ngClass]="{'active': galleryFilter ==='photography'}">Photography
        </li>
        <li class="list-inline-item categories-name border text-dark rounded me-2"
          (click)="activeCategory('development')" [ngClass]="{'active': galleryFilter ==='development'}">Development
        </li>
      </ul>
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container-fluid">
    <div class="row container-grid projects-wrapper">
      @for(item of filterredImages;track $index){
      <div class="col-lg-3 col-md-6 col-12 spacing mt-3 branding">
        <div class="card border-0 work-container work-grid position-relative d-block overflow-hidden rounded">
          <div class="card-body p-0">
            <a class="mfp-image d-inline-block" href="{{item.image}}" title="">
              <img src="{{item.image}}" class="img-fluid" alt="work-image">
            </a>
            <div class="content bg-white p-3">
              <h5 class="mb-0"><a routerLink="/portfolio-detail-one" class="text-dark title">{{item.title}}</a></h5>
              <h6 class="text-muted tag mb-0">{{item.category}}</h6>
            </div>
          </div>
        </div>
      </div>
    }
      <!--end col-->

    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title">
          <h4 class="title mb-4">Let's talk about your portfolio</h4>
          <p class="text-muted para-desc mx-auto">Start working with <span class="text-primary fw-bold">Landrick</span>
            that can provide everything you need to generate
            awareness, drive traffic, connect.</p>

          <div class="mt-4 pt-2">
            <a href="javascript:void(0)" class="btn btn-primary">Get Started Now</a>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
</section>
<!--end section-->
<!-- Work End -->


<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->
import { environment } from "../environments/environment";

export class Global{
    public static GMapsKey = environment.GMapsKey;
    public static CompanyKey = environment.CompanyKey;
    public static apiLink = environment.apiLink;
    public static apiUrl = environment.apiUrl;
    public static Mail_EndPoint = environment.apiLink+`api/Mail/`;
    public static WebUser_EndPoint = environment.apiLink+`api/WebUser/`;
    public static LogisticCommon_EndPoint = environment.apiLink + `api/LogisticCommon/`;
    public static WebLinkedLookup_EndPoint = environment.apiLink + `api/WebLinkedLookup/`;
    public static CompanyWebPolicy_EndPoint = environment.apiLink + `api/CompanyWebPolicy/`;
    public static LogisticRatecardInternational_EndPoint = environment.apiLink + `api/LogisticRatecardInternational/`;
    public static LogisticConsignmentOrder_EndPoint = environment.apiLink + `api/LogisticConsignmentOrder/`;
    
    
}
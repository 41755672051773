<!-- Hero Start -->
<section class="bg-half1 bg-light d-table w-100" id="home">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="page-next-level">
           <!-- <h4 class="title"> Pricing </h4> -->
          <img src="assets/images/XS2.png" alt="">
          <div class="page-next">
            <nav aria-label="breadcrumb" class="d-inline-block">
              <ul class="breadcrumb bg-white rounded shadow mb-0">
                <li class="breadcrumb-item"><a routerLink="/index">xoomship</a></li>
               <!-- <li class="breadcrumb-item"><a href="#">Page</a></li> -->
                <li class="breadcrumb-item active" aria-current="page">Package Deals</li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->

<!-- Price Start -->
<section class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Package Deals</h4>
          <p class="text-muted para-desc mb-0 mx-auto">Customer <span
              class="text-primary font-weight-bold">favorite destinations ! </span> Below are our specials for this season .
              Avail the offers and take advantages of the pricing.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row align-items-center">
      <div class="col-md-4 col-12 mt-4 pt-2" *ngFor='let option of packagesList'>
        <div class="card pricing-rates business-rate shadow bg-light rounded text-center border-0">
          <div class="card-body py-5">
            <h2 class="title text-uppercase mb-4">{{option.packDetails.pack_title}}</h2>
            <div class="d-flex justify-content-center mb-4">
              <!-- <span class="h4 mb-0 mt-2">$</span> -->
              <span class="price h1 mb-0" *ngIf='option.RateCard_details.length>0'>{{option.RateCard_details[0].discount_freight_amt| currency:'INR':true}}</span>
              <!-- <span class="h4 align-self-end mb-1">/mo</span> -->
            </div>
            <span>Package Weight is {{option.packDetails.package_loader}} Kgs</span>
            <ul class="list-unstyled mb-0 pl-0">
              <li class="h6 text-muted mb-0" *ngFor='let option1 of option.packDetails.descDetails'>
                <span class="text-primary h5 mr-2">
                  <i class="uim uim-check-circle"></i>
                </span>{{option1}}
              </li>
              
            </ul>
            <a href="javascript:void(0)" class="btn btn-primary mt-4">Buy Now</a>
          </div>
        </div>
      </div>
      <!--end col-->

      <!-- <div class="col-md-4 col-12 mt-4 pt-2">
        <div class="card pricing-rates business-rate shadow bg-light rounded text-center border-0">
          <div class="card-body py-5">
            <h2 class="title text-uppercase text-primary mb-4">Starter</h2>
            <div class="d-flex justify-content-center mb-4">
              <span class="h4 mb-0 mt-2">$</span>
              <span class="price h1 mb-0">39</span>
              <span class="h4 align-self-end mb-1">/mo</span>
            </div>

            <ul class="list-unstyled mb-0 pl-0">
              <li class="h6 text-muted mb-0"><span class="text-primary h5 mr-2"><i
                    class="uim uim-check-circle"></i></span>Full Access</li>
              <li class="h6 text-muted mb-0"><span class="text-primary h5 mr-2"><i
                    class="uim uim-check-circle"></i></span>Source Files</li>
              <li class="h6 text-muted mb-0"><span class="text-primary h5 mr-2"><i
                    class="uim uim-check-circle"></i></span>Free Appointments</li>
              <li class="h6 text-muted mb-0"><span class="text-primary h5 mr-2"><i
                    class="uim uim-check-circle"></i></span>Free Installment</li>
              <li class="h6 text-muted mb-0"><span class="text-primary h5 mr-2"><i
                    class="uim uim-check-circle"></i></span>Enhanced Security</li>
            </ul>
            <a href="javascript:void(0)" class="btn btn-primary mt-4">Get Started</a>
          </div>
        </div>
      </div> -->
      <!--end col-->

      <!-- <div class="col-md-4 col-12 mt-4 pt-2">
        <div class="card pricing-rates business-rate shadow bg-light rounded text-center border-0">
          <div class="card-body py-5">
            <h2 class="title text-uppercase mb-4">Professional</h2>
            <div class="d-flex justify-content-center mb-4">
              <span class="h4 mb-0 mt-2">$</span>
              <span class="price h1 mb-0">59</span>
              <span class="h4 align-self-end mb-1">/mo</span>
            </div>

            <ul class="list-unstyled mb-0 pl-0">
              <li class="h6 text-muted mb-0"><span class="text-primary h5 mr-2"><i
                    class="uim uim-check-circle"></i></span>Full Access</li>
              <li class="h6 text-muted mb-0"><span class="text-primary h5 mr-2"><i
                    class="uim uim-check-circle"></i></span>Enhanced Security</li>
              <li class="h6 text-muted mb-0"><span class="text-primary h5 mr-2"><i
                    class="uim uim-check-circle"></i></span>Source Files</li>
              <li class="h6 text-muted mb-0"><span class="text-primary h5 mr-2"><i
                    class="uim uim-check-circle"></i></span>1 Domain Free</li>
            </ul>
            <a href="javascript:void(0)" class="btn btn-primary mt-4">Try It Now</a>
          </div>
        </div>
      </div> -->
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  
  <!--end container-->
  <!-- Price End -->

  <!-- Testi Start -->
  
  <!--end container-->
</section>
<!--end section-->
<!-- Testi End -->

<!-- FAQ n Contact Start -->

<!--end section-->
<!-- FAQ n Contact End -->


<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-soft-primary back-to-top" id="back-to-top"
  [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->

<!-- Navbar STart -->
<header id="topnav" class="defaultscroll sticky" (window:scroll)="windowScroll()" [class]="navClass">
  <div class="container">
    <!-- Logo container-->
    <div>
      <a class="logo" routerLink="/" *ngIf="navClass !== 'nav-light'">
        <img src="assets/images/logo_small.png" class="l-dark" height="40" alt="">
        <img src="assets/images/Xoomship Logo.png" class="l-light" height="40" alt="">
      </a>
      <a class="logo" routerLink="/" *ngIf="navClass === 'nav-light'">
        <img src="assets/images/logo_small.png" class="l-dark" height="40" alt="">
        <img src="assets/images/Xoomship Logo.png" class="l-light" height="40" alt="">
      </a>
    </div>
    <div class="buy-button">
      <ng-template [ngIf]="navClass === 'nav-light'">
        <a routerLink='order/add' class="btn btn-primary login-btn-primary">Book Now</a>
          <a routerLink='order/add' class="btn btn-light login-btn-light">Book Now </a>
      </ng-template>
      <ng-template [ngIf]="navClass !== 'nav-light'">
          <a routerLink='order/add' class="btn btn-primary" >Book Now</a>
      </ng-template>

    </div>

    <!--end login button-->
    <!-- End Logo container-->
    <div class="menu-extras">
      <div class="menu-item">
        <!-- Mobile menu toggle-->
        <a class="navbar-toggle" (click)="toggleMenu()" [ngClass]="{'open': isCondensed === true}">
          <div class="lines">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </a>
        <!-- End mobile menu toggle-->
      </div>
    </div>

    <div id="navigation">
      <!-- Navigation Menu-->
      <ul class="navigation-menu" [class]="navClass">
        <!-- <li class="active"><a routerLink="/" href="/" class="nav-link-ref active">Home</a></li> -->
        <li class="active"><a routerLink="/"  class="nav-link-ref active">Home</a></li>
        <li><a routerLink="/page-pricing" class="nav-link-ref">Package Deals</a></li>
        <li class="has-submenu">
          <a href="javascript:void(0)" (click)="onMenuClick($event)">Services</a><span class="menu-arrow"></span>
          <ul class="submenu">
            <li><a class="nav-link-ref" routerLink="/order/add">Book Your Shipment</a></li>
          </ul>
        </li>
        <!-- <li><a routerLink="/page-services" class="nav-link-ref">Services</a></li>
        <li><a routerLink="/order" class="nav-link-ref">Order</a></li> -->
        <li><a href="https://shop.xoomship.com" target="_blank" class="nav-link-ref">shop</a></li>
        <li><a routerLink="/page-aboutus" class="nav-link-ref">About Us</a></li>
        <li><a routerLink="/index-customer" class="nav-link-ref">Support</a></li>
        <!-- <li class="active"><a (click)="logout()" class="nav-link-ref active">Log</a></li> -->
       
        
       
       
      </ul>
      <!--end navigation menu-->
      <!-- <div class="buy-menu-btn d-none">
        <a routerLink='/order/add' class="btn btn-primary">Book Now</a>
        
      </div> -->
     
      <!--end login button-->
      <!--end login button-->
    </div>
    <!--end navigation-->
  </div>
  <!--end container-->
</header>
<!--end header-->
<!-- Navbar End -->
<!-- Hero Start -->
<section class="bg-half-170 bg-light d-table w-100" id="home">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <div class="title-heading text-center mt-5 pt-3">
          <div class="alert alert-light alert-pills" role="alert" data-aos="fade-up" data-aos-duration="1000">
            <span class="badge badge-pill badge-success mr-1">Contact</span>
            <span class="content"> Customer Support 24/7</span>
          </div>
          <h1 class="heading mb-3" data-aos="fade-up" data-aos-duration="1400">Track Your Shipment </h1>
          <p class="para-desc mx-auto text-muted" data-aos="fade-up" data-aos-duration="1800">Launch your campaign and
            benefit from our expertise on designing and managing conversion centered bootstrap4 html page.</p>
          <div class="subcribe-form mt-4 pt-2" data-aos="fade-up" data-aos-duration="2200">
            <form>
              <div class="form-group mb-0">
                <input type="text" id="help" name="name" class="border bg-white rounded-pill shadow" required=""
                  placeholder="Enter Your Shipment Number" [(ngModel)]='track_no'>
                <button type="submit" class="btn btn-pills btn-primary" (click)='getDetails()'>Trace Now</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<section class="bg-half bg-light d-table w-100" id="home">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="page-next-level">
           <!--<h4 class="title">  Track Your Shipment </h4>
          <img src="assets/images/XS3.png" alt=""> -->
          <div class="page-next"> 
            <nav aria-label="breadcrumb" class="d-inline-block">
              <ul class="breadcrumb bg-white rounded shadow mb-0">
                <li class="breadcrumb-item"><a routerLink="/">xoomship</a></li>
                <!--<li class="breadcrumb-item"><a href="#">Company</a></li> -->
                <li class="breadcrumb-item active" aria-current="page">Order Tracking</li>
              </ul>
            </nav>
          </div>
          
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<div class="position-relative">
  <div class="shape overflow-hidden text-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!-- Hero End -->

<section class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-9" *ngIf='orderDetails'>
        <div class="main-icon rounded-pill text-primary text-center mt-4 pt-2">
          <i-feather name="star" class="fea icon-md-sm"></i-feather>
        </div>
        <div class="timeline-page pt-2 position-relative">
          <div class="timeline-item mt-4">
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="duration date-label-left border rounded p-2 px-4 position-relative shadow">{{orderDetails.order_date}}</div>
              </div>
              <!--end col-->
              <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="card event event-description-right rounded shadow border-0 overflow-hidden float-left">
                  <img src="assets/images/blog/0.jpg" class="img-fluid" alt="">
                  <div class="card-body">
                    <h5 class="mb-0 text-capitalize">Ordered</h5>
                    <p class="mt-3 mb-0 text-muted">Your Order Placed on</p>
                    <p>{{orderDetails.order_date_time}}</p>
                  </div>
                </div>
              </div>
              <!--end col-->
            </div>
            <!--end row-->
          </div>
          <!--end timeline item-->

          <div class="timeline-item mt-4" *ngIf='orderDetails.Allotted_picker_id'>
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-6 order-sm-1 order-2">
                <div class="card event  rounded shadow border-0 overflow-hidden float-left">
                  <!-- <img src="assets/images/blog/02.jpg" class="img-fluid" alt=""> -->
                  <img src="assets/images/blog/0.jpg" class="img-fluid" alt="">
                  <div class="card-body">
                    <h5 class="mb-0 text-capitalize">Alloted </h5>
                    <p class="mt-3 mb-0 text-muted">Alloted Picker on</p>
                    <p>{{orderDetails.alloted_date_time}}</p>
                    <p *ngIf='schedule_date' class="mt-3 mb-0 text-muted">Your Item has been Picked by Courier Boy {{orderDetails.Allotted_picker}} on</p>
                    <p *ngIf='schedule_date'>{{schedule_date}}</p>
                  </div>
                </div>
              </div>
              <!--end col-->
              <div class="col-lg-6 col-md-6 col-sm-6 order-sm-2 order-1">
                <div class="duration duration-right rounded border p-2 px-4 position-relative shadow text-left">{{orderDetails.alloted_date}}
                </div>
              </div>
              <!--end col-->
            </div>
            <!--end row-->
          </div>
          <!--end timeline item-->

          <div class="timeline-item mt-4" *ngIf='orderDetails.pack_box_details'>
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="duration date-label-left border rounded p-2 px-4 position-relative shadow">{{orderDetails.pack_box_details.pbv_date}}</div>
              </div>
              <!--end col-->
              <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="card event event-description-right rounded shadow border-0 overflow-hidden float-left">
                  <!-- <img src="assets/images/blog/03.jpg" class="img-fluid" alt=""> -->
                  <div class="card-body">
                    <h5 class="mb-0 text-capitalize">Order Verified</h5>
                    <p class="mt-3 mb-0 text-muted">Your Order has been Verified on</p>
                    <p>{{orderDetails.pack_box_details.pbv_date_time}}</p>
                  </div>
                </div>
              </div>
              <!--end col-->
            </div>
            <!--end row-->
          </div>
          <!--end timeline item-->

          <div class="timeline-item mt-4" *ngIf='orderDetails.lpt_details'>
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-6 order-sm-1 order-2">
                <div class="card event  rounded shadow border-0 overflow-hidden float-left">
                  <!-- <img src="assets/images/blog/04.jpg" class="img-fluid" alt=""> -->
                  <div class="card-body">
                    <h5 class="mb-0 text-capitalize">Shipped</h5>
                    <p class="mt-3 mb-0 text-muted">Your Order has been shipped on </p>
                    <p>{{orderDetails.lpt_details.lpt_date_time}}</p>
                    <p class="mt-3 mb-0 text-muted">Shipped by {{orderDetails.lpt_details.Shipped_to}}</p>
                  </div>
                </div>
              </div>
              <!--end col-->
              <div class="col-lg-6 col-md-6 col-sm-6 order-sm-2 order-1">
                <div class="duration duration-right rounded border p-2 px-4 position-relative shadow text-left">{{orderDetails.lpt_details.lpt_date}}
                </div>
              </div>
              <!--end col-->
            </div>
            <!--end row-->
          </div>
          <!--end timeline item-->

          

          
          <!--end timeline item-->
        </div>
        <!--end timeline page-->
        <!-- TIMELINE END -->
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
</section>


<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-soft-primary back-to-top" id="back-to-top"
  [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->

import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormControlName, FormGroup, NgForm, Validators } from '@angular/forms';
import { NgbModal, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { map, Observable, of, takeWhile, timer } from 'rxjs';
import { Global } from 'src/app/global';
import { AppService } from 'src/app/services/app.service';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { NgxGpAutocompleteDirective, NgxGpAutocompleteOptions } from '@angular-magic/ngx-gp-autocomplete';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { formatDate } from '@angular/common';
import axios from 'axios';
import { SHA256, enc } from 'crypto-js';
import { Buffer } from 'buffer';
import { OnlyCountriesService } from 'src/app/services/only-countries.service';
//import {https} from 'https'

const CHARGE_ENDPOINT = "/v3/charge";
const QRINIT_ENDPOINT = "/v3/qr/init";
const TRANSACTION_ENDPOINT = "/v3/transaction";
const REFUND_ENDPOINT = "/v3/credit/backToSource";

declare var Razorpay: any;
//declare var axios: any;

declare var test_payload: any;
declare var crypto: any;
declare var https : any;



export class ContactRegistraion {
  isDefaultBranchLocation: boolean
  companyId: string
  roleId: number
  accountTypeId: string
  isEnableProductUpload: boolean
  contactType: string
  company: string
  loginName: string
  name: string
  password: string
  emailId: string
  mobile: string
  identityType: string
  identityNumber: string
  uploadIdentity: string
  professionalSkills: string
  subscriptionId: number
  isPrimaryAddress: boolean
  address: string
  city: string
  state: string
  zipCode: string
  country: string
  contactCompany: string
  mid: string
}

export class LogisticInternationalOrderModel {
  booking_Id: string
  gen_Id: number
  orderDate: string
  branchId: number
  locationId: number
  registredUser: string
  account_Post: number
  taxType: number
  exchange_rate: string
  bookingPin_Status: boolean
  bookingImage: string
  fromCountry: string
  receiver_Country: string
  receiver_PersonName: string
  receiver_Contact: string
  receiver_EmailId: string
  receiver_Address: string
  receiver_State: any
  receiver_City: any
  receiver_ZipCode: any
  receiver_Latitude: any
  receiver_Longitude: any
  receiver_Landmark: any
  sender_Country: string
  sender_PersonName: string
  sender_Contact: string
  sender_EmailId: string
  sender_Address: string
  sender_State: any
  sender_City: any
  sender_ZipCode: any
  sender_Latitude: any
  sender_Longitude: any
  sender_Landmark: any
  shipmentType_Id: number
  hsN_Code: number
  deliveryMode_Id: number
  loadBag_UomId: number
  isSlab: boolean
  selectPackage: string
  load_weight: number
  additional_Weight: number
  total_Weight: number
  selectBoxSize: number
  ratePer: number
  total_Wt_GrossAmt: number
  package_Discount: number
  packing_Charges: number
  gsT_Rate: number
  gsT_Amt: number
  total_Wt_GrsDisAmt: number
  total_Wt_NetAmt: number
  bookingStatus: string
  isSelf_Drop: boolean
  dropOff_Hub: number
  isDestinationAdrs_Split_ByPack: boolean
  payment_Id: string
  payment_Type: string
  paymentTransactionId: string
  mid: string
  cid: number
  branchName: any
  locationName: any
  insertedDate: string
  modifiedDate: string
  bookingAmount: string
  bookingTaxAmount: string
  totalBookingAmount:string
  totalOutstanding:number
  pickup_charges:number
}




@Component({
  selector: 'app-order-test',
  templateUrl: './order-test.component.html',
  styleUrl: './order-test.component.css'
})
export class OrderTestComponent implements OnInit {

  @ViewChild("nav") nav;
  Image:any;
  razorpay = new Razorpay({
    key: 'rzp_test_VPS1IXsq048IZp',
    secret: 'j5GemmcRzMMhhsSwf8qnJhZP'
   
  });
  hideFooter = false;
  bookingPin:string;
  color:boolean=false;
  orderId:any;
  isSelect:boolean=false;
  isAvailable:boolean = false;
  showButton:boolean=false;
  minutes:any;
  isSelf_Drop1:boolean=false;
  isShowImageDetails:boolean=false;
  isShowSummaryDetails:boolean=true;
  value1:any
  deliverModeId:any;
  public selectedValue: any;
  public searchValue: any;
  public filteredList: any = [];
  shipmentId:any;
  DocNonDoc:boolean=false;
  boxWeightDocument:any;
  countryCode1:any;
  countryCode:any;
  bookingTaxAmount:any;
  perKgRate:any;
  boxSelected:boolean=true;
  autocomplete!:any;
  @ViewChild("id") private contentRef: TemplateRef<Object>;

  @ViewChild('ngxPlaces') placesRef: NgxGpAutocompleteDirective;
 
  

  senderNgxOptions: NgxGpAutocompleteOptions = {
    componentRestrictions: { country: ['IN'] },
    types: ['geocode']
  };
//  receiverNgxOptions: NgxGpAutocompleteOptions = {
//     componentRestrictions: { country: ['US'] },
//     types: ['geocode']
//   };


  isAllFill:boolean=false;
  href:string='';
  isPickup:boolean=false;
  yes:string='';
  no:string = '';
  userName:string = '';
  branchName:any;
  isShow:boolean=true;
  value:any;
  vendorName:any;
  cityData:any;


  receiverNgxOptions: NgxGpAutocompleteOptions;
  logisticInternationalOrderModel: LogisticInternationalOrderModel = new LogisticInternationalOrderModel();
  internationalData:any;
  serviceData:boolean=true;
  serviceData1:boolean=true;
  shipmentInfo:string='';
  totalPackingCharges:number =0;
  increamentNo5:number=0;
  increamentNo10:number=0;
  increamentNo15:number=0;
  increamentNo25:number=0;
  zoneAddress:string;
  senderLatitude:any;
  senderLongitude:any;
  consignmentCharges:number=0;

  zoneLatitude:any;
  zoneLongitude:any;
  pickupChargesData:any=[];
  zoneList:any =[];
  pickCharges:number=0;
  totalCharges:number=0;
  distanceInKM:any;
  origins:any;
  destination:any;
  boxId:string[] = [];
  boxId1:any;
  totalGross:number=0;
  totalTax:number=0;
  totalDiscount:number=0;
  packingGross:number = 0;
  packingTax:number = 0;
  packingTotal:number = 0;
 

  isChecked: boolean = false;
  isChecked2:boolean=true;
  isChecked1: boolean = true;
  readonly:boolean=false;

  origins1:google.maps.LatLng;
  destination1:google.maps.LatLng;
  shipTypeId:any;
  branchId:number;
  pickupRequest:boolean=false;
  bookingAmount:number = 0;
  packingDetails5:string='';
  packingDetails10:string='';
  packingDetails15:string='';
  packingDetails25:string='';
  noOBoxes:number = 0;
  countryLabel:any;
  


  cid: number = 1046;
  status:string='Booked Order';
  font_style = 'font-weight: 800;font-size: 16PX;';
  font_style1 = 'color:blue';
  _contactRegistraion: ContactRegistraion = new ContactRegistraion();
  orderForm: FormGroup;
  destinationAddressFG: FormGroup;
  senderAddressFG: FormGroup;
  shipmentDetailsFG: FormGroup;
  deliveryModeFG: FormGroup;
  ctrl = new FormControl({ value: null, disabled: false });

  currentSection = 0; // Track the current section
  delivery_mode_list: any;
  shipment_info_list: any;
  logistic_boxby_weight_list: any;
  service_zones_ist: any;
  selectedBoxIndices: number[] = [];
  countryList: any;
  itemsList1 = []
  packDetails = []
  envelopeTypeList = ['Small', 'Medium', 'Large']
  load_weightTooltipMessage: string = undefined;
  addressPredictions: google.maps.places.AutocompletePrediction[] = [];

  enable_Otp: boolean = false; //for hide unhide container
  enable_SendOtpBtn: boolean = false; //
  sendOtp_SenderMail: boolean = false; //to call the send otp function
  sendOtp_SenderMailLoading: boolean = false; //for loading
  enter_SendOtp: boolean = false;
  sendOtp_Countdown: boolean = false; //for mail status
  countDown: Observable<string>;
  otpVerified: boolean = false;

  /////////////////
  seleted_del_mode: any
  delivery_mode_id: any
  seleted_del_mode1: any
  shipment_id: any
  selected_country: any
  pack_loader: any
  freight_rate: any
  gst_price: any
  total_tax_per: any
  total_selling_price: any
  price_per_kg: any
  international_ratecard_list: any
  showCalculation = false
  calculator: string = '';
  isNonDoc: boolean = true;
  isDoc: boolean = false;
  autocomplete1!:any;
  isValid:boolean = false;
  apiKeys: any;
  countryList1:any;
  code:any
  /////////////////

  constructor(private fb: FormBuilder,private onlyCountriesService: OnlyCountriesService, private appService: AppService,private router: Router,
    private modalService: NgbModal) { 
   
      this.countryList1 = this.onlyCountriesService.countriesList;
      console.log('country',this.countryList1)
  }

  ngOnInit(): void {
    this.yes = 'Yes';
    this.no = 'No';
    
    this.destinationAddressFG = this.fb.group({
      // Destination Address
      receiver_PersonName: ['', Validators.required],
      receiver_Contact: ['', [Validators.required, Validators.pattern(/^\d+$/),Validators.maxLength(10)]],
      receiver_EmailId: ['', [Validators.required, Validators.email]],
      receiver_Country: ['', Validators.required],
      receiver_Address: ['', Validators.required],
      receiver_State: ['', Validators.required],
      receiver_City: ['', Validators.required],
      receiver_Landmark: [''],
      receiver_ZipCode: ['', [Validators.required, Validators.pattern(/^\d+$/)]],
    });

    this.senderAddressFG = this.fb.group({
      // Sender Address
      sender_PersonName: [{ value: '', disabled: false }, [Validators.required, Validators.min(3), Validators.maxLength(100)]],
      sender_Contact: [{ value: '', disabled: false }, [Validators.required, Validators.minLength(6), Validators.maxLength(10),  Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      sender_EmailId: [{ value: '', disabled: false }, [Validators.required, Validators.email]],
      sender_MailOtp: [{ value: '', disabled: false }],
      sender_Country: [{ value: '', disabled: false }, Validators.required],
      sender_Address: [{ value: '', disabled: false }, Validators.required],
      sender_State: [{ value: '', disabled: false }, Validators.required],
      sender_City: [{ value: '', disabled: false }, Validators.required],
      sender_Landmark: [{ value: '', disabled: false }, Validators.required],
      sender_ZipCode: [{ value: '', disabled: false }, [Validators.required, Validators.pattern(/^\d+$/)]],
    });

    this.shipmentDetailsFG = this.fb.group({
      // Shipment Details
      sender_Country: ['India'],
      service_zone: ['', Validators.required],
      branchId: [0],
      locationId: [0],
      receiver_Country: ['', Validators.required],
      deliveryMode_Id: [''],
      shipmentType_Id: ['', Validators.required],
      shipmentType_Document: [false],
      shipmentType_NonDocument: [true],
      isSelf_Drop: [false],
      selectBoxSize: ['', Validators.required], //dropdown multiple selection
      bookingImage: [''],
      //additional_Weight: [0, [Validators.required, Validators.min(0)]],
      load_weight: ['', [Validators.required, Validators.min(0)]],
      //update the value while submit based on shipmentType_NonDocument as kgs, shipmentType_Document as grams shipmentType_Document/1000
      total_Weight: [{ value: 0, disabled: true }],
      pickupRequest:[false]
    });

    this.deliveryModeFG = this.fb.group({
      deliveryMode_Id_Vendor_List: [''],
    });

    this.orderForm = this.fb.group({
      // Payment Details
      total_Wt_GrossAmt: [0, Validators.required],
      package_Discount: [0, Validators.required],
      packing_Charges: [0, Validators.required],
      pickup_Charges: [0],
      gsT_Rate: [0, Validators.required],
      gsT_Amt: [{ value: 0, disabled: true }],
      total_Wt_GrsDisAmt: [{ value: 0, disabled: true }],
      total_Wt_NetAmt: [{ value: 0, disabled: true }],
    });

    this.sdControls.shipmentType_Id.valueChanges.subscribe((c) => {
      debugger
      for (let sd of this.shipment_info_list) {
        if (sd.value === c && sd.label === 'Document') {
          this.sdControls.shipmentType_Document.setValue(true);
          this.sdControls.shipmentType_NonDocument.setValue(false);
           this.sdControls.load_weight.setValue('');
          this.onClickResetOFDocument();
        }
        else if (sd.value === c && sd.label === 'Non Document') {
          this.sdControls.shipmentType_NonDocument.setValue(true);
          this.sdControls.shipmentType_Document.setValue(false);
          // this.sdControls.load_weight.setValue('');
          this.onClickResetOFDocument();
        }
      }
    });

    // this.dmControls.deliveryMode_Id_Vendor_List.valueChanges.subscribe((c) => {
    //   debugger
    //   this.showButton = true;
    //      this.calculateInternationalRate();
    //     // if(this.serviceData == false)
    //     // {
    //     //   Swal.fire({ 
    //     //     title: " <span style='font-size:20px'>We apologize for the inconvenience. We are not currently servicing the  "+this.branchName+".</span> <span style='font-size:20px'> We will notify you when the service is available. <span>",
    //     //     icon: "warning",
    //     //     confirmButtonColor: "#3085d6",
    //     //     confirmButtonText: "OK!",
    //     //     customClass: 'swal-wide',
    //     //   })
    //     //   .then((result) => {
    //     //     if (result.isConfirmed) {
    //     //       this.readonly = true;
    //     //     }
    //     //  })
    //     // }
    //     // this.callme()
    //  });

    

    this.sdControls.shipmentType_Document.valueChanges.subscribe((c) => {
      if (c === true) {
        this.sdControls.load_weight.setValidators([Validators.required, Validators.min(0), Validators.max(999)]);
        this.sdControls.load_weight.updateValueAndValidity();
      }
      else {
        this.sdControls.load_weight.setValidators([Validators.required, Validators.min(0), Validators.max(500)]);
        this.sdControls.load_weight.updateValueAndValidity();
      }
    });

   
    // this.shipmentDetailsFG.valueChanges.subscribe(() => {
    //   this.showCalculation = false;
    // });

    if (this.sdControls.receiver_Country.valueChanges  && this.sdControls.load_weight.valueChanges) {
      this.showCalculation = false;
    }

    //shipment details controlls
    this.sdControls.load_weight.valueChanges.subscribe((c) => {
      debugger
      if (typeof c === 'number') {
        if (c > 0) {
          if (!this.sdControls.receiver_Country.invalid && this.sdControls.shipmentType_NonDocument.value === true) {
            this.calculateRate();
            this.getBoxByWeight();
          }
          else if (!this.sdControls.receiver_Country.invalid && this.sdControls.shipmentType_Document.value === true) {
            this.calculateRate();
            this.getBoxByWeight();
          }
        };
      }
    });

    this.saControls.sender_EmailId.valueChanges.subscribe(() => {
      if (this.saControls.sender_EmailId.valid) {
        this.validateCredentials();
        this.countDownTimer(5);
      }
    });

    this.sdControls.service_zone.valueChanges.subscribe((c) => {
      const s = c;
      debugger
      if (s?.branchId>0) {
        this.sdControls.branchId.setValue(s.branchId);
      }
      if (s?.locationId>0) {
        this.sdControls.locationId.setValue(s.locationId);
      }
    });
    debugger
   
   

    // this.orderForm.get('additional_Weight').valueChanges.subscribe(() => this.updateTotalWeight());
    // this.orderForm.get('load_weight').valueChanges.subscribe(() => this.updateTotalWeight());
    this.callme();
    this.getSipmentInfo();
    this.getDeliveryMode()
    this.getCountry();
    this.getServiceZonesLookup();
   

    
  }

 

  get sdControls() {
    return this.shipmentDetailsFG.controls;
  }

  get saControls() {
    return this.senderAddressFG.controls;
  }
  get daControls() {
    return this.destinationAddressFG.controls;
  }
  get dmControls() {
    return this.deliveryModeFG.controls;
  }
  get ofControls() {
    return this.orderForm.controls;
  }
 

  callme()
  { 
    debugger
    if(this.dmControls.deliveryMode_Id_Vendor_List.value == '' && this.sdControls.shipmentType_Id.value == '' && 
      this.sdControls.service_zone.value == '' && this.sdControls.receiver_Country.value == '' && this.sdControls.shipmentType_Id.value == ''
      && this.sdControls.load_weight.value == '')
      {
       debugger
       this.isValid = true;
      
      }
      else{
        this.isValid = false;
      }
  }


  updateTotalWeight(): void {
    const additionalWeight = this.orderForm.get('additional_Weight').value;
    const loadWeight = this.orderForm.get('load_weight').value;
    const totalWeight = additionalWeight + loadWeight;
    this.orderForm.get('total_Weight').setValue(totalWeight);
  }

  isSectionValid(section: number): boolean {
    switch (section) {
      case 0:
        return this.orderForm.get('receiver_Country').valid &&
          this.orderForm.get('receiver_PersonName').valid &&
          this.orderForm.get('receiver_Contact').valid &&
          this.orderForm.get('receiver_EmailId').valid &&
          this.orderForm.get('receiver_Address').valid &&
          this.orderForm.get('receiver_State').valid &&
          this.orderForm.get('receiver_City').valid &&
          this.orderForm.get('receiver_ZipCode').valid;
      case 1:
        return this.orderForm.get('deliveryMode_Id').valid &&
          this.orderForm.get('shipmentType_Id').valid &&
          this.orderForm.get('selectBoxSize').valid &&
          this.orderForm.get('additional_Weight').valid &&
          this.orderForm.get('load_weight').valid;
      case 2:
        return this.orderForm.get('total_Wt_GrossAmt').valid &&
          this.orderForm.get('package_Discount').valid &&
          this.orderForm.get('packing_Charges').valid &&
          this.orderForm.get('gsT_Rate').valid;
      default:
        return false;
    }
  }

  goToNextSection(): void {
    if (this.isSectionValid(this.currentSection)) {
      this.currentSection++;
    }
  }

  goToPreviousSection(): void {
    if (this.currentSection > 0) {
      this.currentSection--;
    }
  }

  onSubmit() { }


  getDeliveryMode() {
    let obj = {
      "mid": "MIN#05",
      "cid": 1046
    };
    this.appService.postJson(Global.LogisticCommon_EndPoint + 'GetDeliveryModeByMid', obj)
      .subscribe(data => {
        this.delivery_mode_list = data;
      })
  }

  getSipmentInfo() {
    let obj = {
      "mid": "MIN#05",
      "cid": 1046
    };
    this.appService.postJson(Global.LogisticCommon_EndPoint + 'GetShipmentInfoByMid', obj)
      .subscribe(data => {
        this.shipment_info_list = data;
      })
  }

  getCountry() {
    let obj = {
      cid: 1046, from: "International",
      mid: "MIN#05",
      type: "ToCountry"
    }
    debugger
    this.appService.postJson(Global.LogisticCommon_EndPoint + 'GetLogisticValues', obj)
      .subscribe(data => {
        this.countryList = data;
        
      })
  }

  onChangePackageType() {
    this.sdControls.shipmentType_NonDocument.setValue(true);
    this.sdControls.shipmentType_Document.setValue(false);
    if ((this.sdControls.shipmentType_NonDocument.value === false) && (this.sdControls.shipmentType_Document.value == true)) {
      //this.pack_loader = 0.5
      //this.onChangeCustomPackage()
    }
  }



  load_weightValidateTooltip(): Observable<boolean> {
    if (this.sdControls.shipmentType_Document.value === true || this.sdControls.shipmentType_NonDocument.value === true) {
      if (this.sdControls.load_weight.invalid && this.sdControls.load_weight.errors.max && this.sdControls.shipmentType_Document.value === true) {
        //max grams 999
        this.load_weightTooltipMessage = 'max grams 999.';
        return of(true);
      }
      if (this.sdControls.load_weight.invalid && this.sdControls.load_weight.errors.max && this.sdControls.shipmentType_NonDocument.value === true) {
        //max kgs 500
        this.load_weightTooltipMessage = 'max kgs 500.';
        return of(true);
      }
      else if (this.sdControls.load_weight.invalid) {
        this.load_weightTooltipMessage = 'required';
        return of(true);
      }
      else {
        this.load_weightTooltipMessage = '';
        return of(false);
      }
    }
    else {
      this.load_weightTooltipMessage = '';
      return of(false);
    }
  }


  onClickDelmode(option) {
    this.seleted_del_mode = option.label
    this.delivery_mode_id = option.value
  }

  calculateRate() {
    debugger
    if (this.sdControls.receiver_Country.invalid) {
      this.appService.openModel('Please Select Delivery Country', 'warning')
      return
    }

    if (this.sdControls.load_weight.invalid) {
      this.appService.openModel('Please Enter Package Weight', 'warning')
      return
    }
    let ratesList = []
    if(this.sdControls.shipmentType_Document.value == true)
    {
      this.boxWeightDocument = (this.sdControls.load_weight.value)/1000
    }
    else
    {
      this.boxWeightDocument = this.sdControls.load_weight.value
    }

    let obj = {
      cid: 1046,
      deliveryModeId: Number(this.sdControls.deliveryMode_Id.value) ?? null,
      fromCountry: "India",
      mid: "MIN#05",
      //shipmentTypeId:this.shipmentId,
      shipmentTypeId:Number(this.sdControls.shipmentType_Id.value) ?? 0,
      toCountry: this.sdControls.receiver_Country.value ?? '',
      weight: this.boxWeightDocument ?? 0
      // weight: this.sdControls.load_weight.value ?? 0
    }
    debugger
    this.appService.postJson(Global.LogisticCommon_EndPoint + 'GetByRatecardCalInt', obj)
      .subscribe(data => {
        debugger
        this.international_ratecard_list = data;
        if(this.international_ratecard_list.length <=0)
        {
          Swal.fire({ 
            title: "<span style='font-size:20px'>Rates for the specified weight are unavailable. <br/>Contact our Help Team for further details<span>",
            icon: "warning",
            confirmButtonColor: "#3085d6",
            confirmButtonText: "OK!",
            customClass: 'swal-wide',
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.sdControls.load_weight.setValue('');
            }
         })
        }
        const list = this.international_ratecard_list;
        if (list !== undefined && list !== null && list !== '' && list.length > 0) {
          this.freight_rate = list[0]?.grossAmount
          this.total_tax_per = list[0]?.taxPercentage
          this.gst_price = list[0]?.totalGst
          this.total_selling_price = list[0]?.totalPrice
          this.price_per_kg = list[0]?.perKgRate
          // if(this.DocNonDoc ==true)
          // {
          //   this.showCalculation = false
          // }
          // else
          // {
            this.showCalculation = true
          //}
         
        } else {
          this.showCalculation = false
        }
      });
  }

  onClickReset() {
    debugger
    this.shipmentDetailsFG.reset();
    this.sdControls.shipmentType_NonDocument.setValue(true);
    this.sdControls.load_weight.setValue('');
    this.shipmentDetailsFG.updateValueAndValidity();

    this.senderAddressFG.reset();
    this.destinationAddressFG.reset();
    this.deliveryModeFG.reset();
    this.isAllFill = false;
    // this.nav.select("ngb-nav-0");
    this.showCalculation = false;
    this.isShow = true;
    this.isShowSummaryDetails = false;
    this.value = ''
    this.value1 = ''
    this.color = false;
    this.vendorName ='';
    this.consignmentCharges = 0
    this.perKgRate = 0
this.totalGross = 0
this.consignmentCharges = 0
this.noOBoxes = 0
this.packingGross = 0
this.packingTax = 0
this.packingTotal= 0
this.pickCharges= 0
this.totalGross= 0
this.totalTax= 0
this.totalDiscount= 0
this.consignmentCharges= 0;
this.totalTax = 0;
this.bookingAmount = 0;
this.isPickup = false;
this.isChecked1 = true;
this.isChecked2 = true;
this.countryCode = '';
this.countryCode1 = '';
this.boxSelected = true
  }
  onClickResetOFDocument() {
    this.DocNonDoc =true;

    this.isShowSummaryDetails = true;
   
    this.shipmentDetailsFG.updateValueAndValidity();
    this.senderAddressFG.reset();
    this.destinationAddressFG.reset();
    this.deliveryModeFG.reset();
    this.isAllFill = false;
   
    this.isShow = true;
    // this.isShowSummaryDetails = false;
    this.vendorName ='';
    this.consignmentCharges = 0
    this.perKgRate = 0
this.totalGross = 0
this.consignmentCharges = 0
this.noOBoxes = 0
this.packingGross = 0
this.packingTax = 0
this.packingTotal= 0
this.pickCharges= 0
this.totalGross= 0
this.totalTax= 0
this.totalDiscount= 0
this.consignmentCharges= 0;
this.totalTax = 0;
this.bookingAmount = 0;
this.isPickup = false;
this.isChecked1 = true;
this.isChecked2 = true;
this.countryCode = '';
this.countryCode1 = '';
this.showCalculation = false;
this.boxSelected = true
  }


  onSelectSlide(index: number) {
    debugger
    this.boxSelected = false
    const selectedIndex = this.selectedBoxIndices.indexOf(index);
    if (selectedIndex === -1) {
      this.selectedBoxIndices.push(index);
      this.logistic_boxby_weight_list[index].title = '✔️';
      this.boxId.push((this.logistic_boxby_weight_list[index].boxId).toString());
      let a = this.boxId.map(e => e.replace(/\s/g, "")).join(",")
       this.boxId1 = a;
    } else {
      this.selectedBoxIndices.splice(selectedIndex, 1);
      this.logistic_boxby_weight_list[index].title = '';
    }
   let a =  this.selectedBoxIndices.map(x=>x).join(",")
   debugger
    this.sdControls.selectBoxSize.setValue(a);
    console.log(this.logistic_boxby_weight_list[index]);
    console.log("from this.sdControls.selectBoxSize.", this.sdControls.selectBoxSize.value);

  }


  isImageSelected(index: number): boolean {
    return this.selectedBoxIndices.includes(index);
  }


  getBoxByWeight() {
    debugger
    if(this.sdControls.shipmentType_Document.value == true)
    {
      this.boxWeightDocument = (this.sdControls.load_weight.value)/1000
    }
    else
    {
      this.boxWeightDocument = this.sdControls.load_weight.value
    }

    let obj = {
      "cid": 1046,
      "mid": "MIN#05",
      "boxWeight": this.boxWeightDocument ?? 0
      // "boxWeight": this.sdControls.load_weight.value ?? 0
    };

    this.appService.postJson(Global.LogisticCommon_EndPoint + 'GetLogisticBoxByWeight', obj)
      .subscribe(data => {
        debugger
        this.logistic_boxby_weight_list = data.map((c: any) => {
          return {
            boxId: c?.boxId,
            boxWeight: c?.boxWeight,
            loadBoxIn: c?.loadBoxIn,
            boxTitle: c?.boxTitle,
            thumbImage: this.isValidImage(c?.boxImage),
            title: '',
            packing_Charges: c?.packing_Charges,
            uomName: c?.uomName,
            hsnId: c?.hsnId,
            hsnCode: c?.hsnCode,
            taxInclusive: c?.taxInclusive,
            igst: c?.igst,
            igstValue: c?.igstValue,
            cgst: c?.cgst,
            cgstValue: c?.cgstValue,
            sgst: c?.sgst,
            sgstValue: c?.sgstValue,
            taxGstRate: c?.taxGstRate,
            taxAmount: c?.taxAmount,
            totalPackingCharges: c?.totalPackingCharges
          }
        });
        this.packingGross = 0;
        this.packingTax = 0;
        this.packingTotal = 0;
        for(let i = 0;i<data.length;i++)
        {
          this.packingGross = this.packingGross + data[i].packing_Charges;
          this.packingTax = this.packingTax + data[i].taxAmount;
          this.packingTotal = this.packingTotal + data[i].totalPackingCharges;
        }

        this.increamentNo5 = 0;
        this.increamentNo10 = 0;
        this.increamentNo15 = 0;
        this.increamentNo25 = 0;
        this.noOBoxes = 0
        for(let i=0;i < this.logistic_boxby_weight_list.length;i++)
        {
          this.noOBoxes++;
          debugger
          this.totalPackingCharges = this.totalPackingCharges + this.logistic_boxby_weight_list[i].totalPackingCharges;
          if(this.logistic_boxby_weight_list[i].boxTitle == '25 kg Loaded')
          {
            this.increamentNo25++;
            this.packingDetails25 = "25 kg"+ "*" +this.increamentNo25;
          }
         else if(this.logistic_boxby_weight_list[i].boxTitle == '15 Kg')
          {
            this.increamentNo15++;
            this.packingDetails15 = "15 kg"+ "*" +this.increamentNo15;
          }
          else if(this.logistic_boxby_weight_list[i].boxTitle == '10 kg')
          {
            this.increamentNo10++;
            this.packingDetails10 = "10 Kg"+ "*" +this.increamentNo10;
          }
          else if(this.logistic_boxby_weight_list[i].boxTitle == '5 kg')
          {
            this.increamentNo5++;
            this.packingDetails5 = "5 Kg"+ "*" +this.increamentNo5;
          }
        }
        

      });
  }

  isValidImage(image: string): string {
    if (!image.includes('NotFound')) {
      return Global.apiLink + image;
    }
    return '';
  }

  private _markFormPristine(form: FormGroup | NgForm): void {
    //for validation purpose onSubmit
    Object.keys(form.controls).forEach(control => {
      form.controls[control].markAllAsTouched();
    });
  }

  sendOtpSenderMail() {
    if (this.senderAddressFG.invalid) {
      this._markFormPristine(this.senderAddressFG);
      return;
    }

    if (this.saControls.sender_EmailId.valid && this.saControls.sender_PersonName.valid) {
      this.sendOtp_SenderMail = true;
      this.validateCredentials();

    }
  }

  validateCredentials(): void {
debugger
    if (this.saControls.sender_EmailId.valid && this.saControls.sender_PersonName.valid) {
      let obj = {
        "companyId": Global.CompanyKey,
        "email": this.saControls.sender_EmailId.value,
      };
      this.appService.postJson(Global.WebUser_EndPoint + 'ValidateCredentials', obj).subscribe(c => {
        if (c.status === true) {
          this.enable_Otp = true;
          this.enable_SendOtpBtn = true;
          debugger
         
          if (c.data[0].result === true && c.data[0].message === 'avaliable') {
            debugger
            this.isAvailable = false;
            //new user registration otp 
            if (this.sendOtp_SenderMail) {
              this.sendOtpRegistration();
            }
          }
          else if (c.data[0].message.includes('Duplicate')) {
            this.isAvailable = true;
            //existed user otp
            if (this.sendOtp_SenderMail) {
              this.sendOtpEmail();
            }
          }
        }
        else {
          this.enable_Otp = false;
          //for server issue
          this.appService.openModel('Something went wrong please try after some time.', 'error')
          return
        }
      });
    }
  }

  sendOtpEmail(): void {
    try {
      this.enable_SendOtpBtn = false;
      this.sendOtp_SenderMailLoading = true;
      this.appService.sendMailOtp(this.saControls.sender_EmailId.value).subscribe(result => {
        if (result?.status === true) {
          this.sendOtp_SenderMailLoading = false;
          this.sendOtp_Countdown = true;
          this.enter_SendOtp = true;
          this.countDownTimer(5);
        } else {
          this.enable_Otp = false
          this.sendOtp_Countdown = false;
          this.sendOtp_SenderMailLoading = false;

          this.appService.openModel('Something went wrong, please try after some time', 'error')
          return
        }
      });
    }
    catch (error) {
      this.appService.openModel('Something went wrong, please try after some time', 'error');
      return;
    }
  }

  sendOtpRegistration(): void {
    try {
      this.enable_SendOtpBtn = false;
      this.sendOtp_SenderMailLoading = true;
      this.appService.sendMailOtpRegistration(this.saControls.sender_EmailId.value, this.saControls.sender_PersonName.value).subscribe(result => {
        if (result?.status === true) {
          this.sendOtp_Countdown = true;
          this.enter_SendOtp = true;
          this.sendOtp_SenderMailLoading = false;
          this.countDownTimer(5);
        } else {
          this.enable_Otp = false
          this.sendOtp_Countdown = false;
          this.sendOtp_SenderMailLoading = false;

          this.appService.openModel('Something went wrong, please try after some time', 'error')
          return
        }

      });
    }
    catch (error) {
      this.appService.openModel('Something went wrong, please try after some time', 'error');
      return;
    }
  }

  verifyOtp(): void {
    try {
      this.saControls.sender_MailOtp?.setValidators([Validators.required, Validators.minLength(6), Validators.maxLength(6)]);
      this.saControls.sender_MailOtp.updateValueAndValidity();

      if (this.saControls.sender_EmailId.valid && this.saControls.sender_MailOtp.valid) {

        this.sendOtp_Countdown = false;
        this.appService.verifyMailOtp(this.saControls.sender_EmailId.value, this.saControls.sender_MailOtp.value).subscribe(result => {
         debugger
          if (result.status === true) {
            this.otpVerified = true;
            this.senderAddressFG.disable();
          }
          else if (result.status === false) {
            this.appService.openModel('Invalid OTP', 'warning')
            return
          }
        });

      } else {
        this.appService.openModel('Please input a valid OTP consisting of 6 digits.', 'warning')
        return
      }
    }
    catch (error) {
      this.appService.openModel('Something went wrong, please try after some time', 'error')
      return;
    }
  }

  countDownTimer(minute: any) {
    const duration = minute * 60;
    this.countDown = timer(0, 1000).pipe(map((i) => duration - i),
      takeWhile((time) => time >= 0),
      map((time) => {
        if (time === 0) {
          this.sendOtp_Countdown = false;
        }
        return this.formatTime(time)
      }));
  }

  formatTime(time: number): string {
    const minute = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minute}: ${seconds < 10 ? '0' : ''}${seconds}`;
  }


  onSenderAddressSelected(place: google.maps.places.PlaceResult): void {
    debugger
    const addressComponents = place.address_components;
    this.senderAddressFG.patchValue({ sender_Address: place.formatted_address });
    const getComponent = (type: string) => {
      return addressComponents?.find(component => component.types.includes(type)) || { long_name: '' };
    };

    this.senderAddressFG.patchValue({
      sender_ZipCode: getComponent('postal_code').long_name,
      sender_Country: getComponent('country').long_name,
      sender_State: getComponent('administrative_area_level_1').long_name,
      sender_City: getComponent('administrative_area_level_3').long_name || getComponent('administrative_area_level_2').long_name,
      sender_Landmark: getComponent('landmark').long_name
    });

    this.countryCode = '+91'
    let a:any = place.formatted_address
    let b = a.international_phone_number;
   let c= a?.split(' ',1);

       this.senderLatitude = place.geometry.location.lat();
    this.senderLongitude= place.geometry.location.lng();

    this.origins1 = new google.maps.LatLng(this.zoneLatitude, this.zoneLongitude)
  this.destination1 = new google.maps.LatLng(this.senderLatitude, this.senderLongitude)
    this.getDistancia();

  }


  onDestinationAddressSelected(place: google.maps.places.PlaceResult): void {
    debugger
    const addressComponents = place.address_components;
    this.destinationAddressFG.patchValue({ receiver_Address: place.formatted_address });
    const getComponent = (type: string) => {
      return addressComponents?.find(component => component.types.includes(type)) || { long_name: '' } || { short_name: '' };
    };
    this.daControls.receiver_Country.patchValue(getComponent('country').short_name == 'US' ? 'United States of America ' :getComponent('country').long_name)
    this.destinationAddressFG.patchValue({
      receiver_ZipCode: getComponent('postal_code').long_name,
      receiver_State: getComponent('administrative_area_level_1').long_name,
      receiver_City: getComponent('administrative_area_level_3').long_name || getComponent('administrative_area_level_2').long_name,
      receiver_Landmark: getComponent('landmark').long_name
    });

    let a = place.formatted_phone_number;
    this.countryCode1= a?.split(' ',1);
    let that =this
    // getComponent('country').long_name
    if(that.daControls.receiver_Country.value != that.sdControls.receiver_Country.value)
    {
      Swal.fire({
        title: "Destination country not match",
       confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
        cancelButtonText:"Cancel",
        cancelButtonColor:"#3085d6",
        showCancelButton:true
        // html: '<button class="btn-yes btn btn-primary" style="width: 80px;" (click)="togglebutton(sdControls.pickupRequest.value,this.yes)">Yes</button><button class="btn-no btn btn-primary" style="width: 80px;margin-left:5px" (click)="togglebutton(sdControls.pickupRequest.value,this.no)">No</button>',
     
      }).then(function(res) {
        debugger
        if (res.isConfirmed) {
        
          that.destinationAddressFG.patchValue({
            receiver_Address: '',
            receiver_ZipCode: '',
            receiver_Country: '',
            receiver_State: '',
            receiver_City:'',
            receiver_Landmark: ''
          });
        }
        if (res.isDismissed) {
        
      }
   });
    }
    else 
    {
      Swal.fire({
        title: "Do you want to request a pickup?",
       confirmButtonColor: "#3085d6",
        confirmButtonText: "Yes",
        cancelButtonText:"No",
        cancelButtonColor:"#3085d6",
        showCancelButton:true
        // html: '<button class="btn-yes btn btn-primary" style="width: 80px;" (click)="togglebutton(sdControls.pickupRequest.value,this.yes)">Yes</button><button class="btn-no btn btn-primary" style="width: 80px;margin-left:5px" (click)="togglebutton(sdControls.pickupRequest.value,this.no)">No</button>',
     
      }).then(function(res) {
        debugger
        if (res.isConfirmed) {
         that.togglebutton(that.yes)
        }
        if (res.isDismissed) {
          that.togglebutton(that.no)
      }
   });
    }

    // this.destinationAddressFG.markAllAsTouched()
    // debugger
    // /////////////////
    // if(this.destinationAddressFG.valid)
    // {
     
     // this.modalService.open(this.contentRef, { windowClass: 'mysize', centered: true })
      
    //}
   
      
  }

  numberOnlyMob(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  getServiceZonesLookup(): void {
    this.appService.getJson(Global.WebLinkedLookup_EndPoint + 'GetBranchLocationLookup/' + this.cid).subscribe(result => {
      if (result) {
        this.service_zones_ist = result;
      }
    });
  }

  public contactRegistration(): void {
    this._contactRegistraion = new ContactRegistraion();
    this._contactRegistraion.isDefaultBranchLocation = false;
    this._contactRegistraion.companyId = Global.CompanyKey;
    this._contactRegistraion.roleId = 32; //online customer
    this._contactRegistraion.accountTypeId = "79"; //contact account typeid 
    this._contactRegistraion.isEnableProductUpload = false;
    this._contactRegistraion.contactType = 'Account holder';
    this._contactRegistraion.company = 'Xoomship';
    this._contactRegistraion.loginName = this.saControls.sender_EmailId.value;
    this._contactRegistraion.name = this.saControls.sender_PersonName.value;
    this._contactRegistraion.password = '12345678';
    this._contactRegistraion.emailId = this.saControls.sender_EmailId.value;
    this._contactRegistraion.mobile = this.saControls.sender_Contact.value;
    this._contactRegistraion.identityType //AADHAR, PAN
    this._contactRegistraion.identityNumber //based on identityType validate it
    this._contactRegistraion.uploadIdentity //required.
    this._contactRegistraion.professionalSkills = '';
    this._contactRegistraion.subscriptionId = 1;
    this._contactRegistraion.isPrimaryAddress = true;
    this._contactRegistraion.address = this.saControls.sender_Address.value;
    this._contactRegistraion.city = this.saControls.sender_City.value;
    this._contactRegistraion.state = this.saControls.sender_State.value;
    this._contactRegistraion.zipCode = this.saControls.sender_ZipCode.value;
    this._contactRegistraion.country = this.saControls.sender_Country.value;
    this._contactRegistraion.contactCompany = 'Xoomship';
    this._contactRegistraion.mid = 'MIN#00';
    this.appService.postJson(Global.WebUser_EndPoint + 'AddContactRegistration', this._contactRegistraion)
    .subscribe(c => {
      debugger
      if (c) {
        debugger
        // this.userName = c.emailId
        this.saControls.sender_EmailId.setValue(c.emailId)
      }
    })
  }
  checkValue() {
    this.isChecked = true;
  }

  checkValue1() {
    this.isChecked1 = true;
    this.isSelect = false;
  }

  checkValue2() {
    this.isChecked2 = true;
    
  }
  getDistancia() {
   debugger
    return new google.maps.DistanceMatrixService().getDistanceMatrix({ 'origins': [this.origins1],
      'destinations':  [this.destination1],'travelMode': google.maps.TravelMode.DRIVING}, (results: any) => {
       debugger
        let a = results.rows[0].elements[0].distance.value
        this.distanceInKM =  Number((a/1000).toFixed(2));
        let b = results.rows[0].elements[0].duration.text;
        let hours = b.split('hours');
        let hours1 = hours[1].split('mins');
        let hours2 = hours1[0]//+
       this.minutes = Number(hours[0]*60) + Number(hours2) 
        
       
        
        // let b = results.rows[0].elements[0].distance.text;
        // console.log('jjjjjjjjjjjjjjjj',b)
   
        });
       
}



onChange(e:any)
{
  debugger
  let a = e.label.split('-');
  let b =a[0];
  this.branchName = b;
  this.branchId = e.branchId;
 this.zoneLatitude = e.locationLatitude;
  this.zoneLongitude = e.locationLongitude
}

calculateInternationalRate() {
   debugger
    let internationalratesList = [];
    if(this.sdControls.shipmentType_Document.value == true)
    {
      this.boxWeightDocument = (this.sdControls.load_weight.value)/1000
    }
    else
    {
      this.boxWeightDocument = this.sdControls.load_weight.value
    }
    this.pickCharges = 0;
    let obj = {
      "cid":this.cid,
      "mid": "MIN#05",
      "branchId":this.branchId,
      "destinationCountry":this.sdControls.receiver_Country.value,
      //  "United States of America (the)",
      "slabWeightInKg":this.boxWeightDocument? this.boxWeightDocument:0,
      "additionalWeight": 0,
     
       "pickupRequest": this.sdControls.pickupRequest.value? this.sdControls.pickupRequest.value:false,
      "discountCoupon": null,
      "discountPercentage": 0,
      "distanceInKm": this.distanceInKM? this.distanceInKM:0,
      //"deliveryModeId":this.deliverModeId,
       "deliveryModeId": Number(this.dmControls.deliveryMode_Id_Vendor_List.value),
      "timeFareMinutes": this.minutes ? this.minutes : 2,
      "shipmentTypeId":8,
      "logisticBox": "1,2,3",
      "loadBoxId":this.boxId1
      
    }
    debugger
    this.appService.postJson(Global.LogisticCommon_EndPoint + 'GetLogisticInternationalRateCard', obj)
      .subscribe({next:(data:any) => {
        debugger
        
        this.internationalData = data;

        
        if(this.internationalData.length < 0)
        {
          this.serviceData = false;
          if(this.serviceData == false)
          {
            Swal.fire({ 
              title: " <span style='font-size:20px'>We apologize for the inconvenience. We are not currently servicing the  "+this.branchName+".</span> <span style='font-size:20px'> We will notify you when the service is available. <span>",
              icon: "warning",
              confirmButtonColor: "#3085d6",
              confirmButtonText: "OK!",
              customClass: 'swal-wide',
            })
            .then((result) => {
              if (result.isConfirmed) {
                this.readonly = true;
              }
           })
          }
          this.callme()
        }
        



        this.pickupRequest=data.pickupRequest;
        this.consignmentCharges = this.internationalData?.intlTotalAmt
        
        this.pickupChargesData= data.cityRatecard?.filter((a:any) => a.vehicleTypeName == '3 Wheeler')
        this.pickCharges =  this.pickupChargesData[0]?.totalTripFare ? this.pickupChargesData[0]?.totalTripFare : 0;

        //let a = (this.internationalData.bookingAmount + this.internationalData.bookingTaxAmount) 
        this.bookingAmount = this.internationalData?.totalBookingAmount;
      
        this.totalCharges =  (((Number(this.consignmentCharges)+Number(this.totalPackingCharges ? this.totalPackingCharges : 0 )+Number(this.pickCharges ? this.pickCharges : 0)) - (this.bookingAmount ? this.bookingAmount : 0)))
       
        this.totalGross =  this.internationalData.intlGrossAmt;
        this.totalTax =  this.internationalData.taxAmount;
        this.totalDiscount =  this.internationalData.intlGrossAmt;
        this.bookingTaxAmount = this.internationalData?.bookingTaxAmount;
        this.perKgRate = this.internationalData?.perKgRate;
        
      },
      error: (error: any) => {
        this.serviceData = false;
        if(this.serviceData == false)
        {
          Swal.fire({ 
            title: " <span style='font-size:20px'>We apologize for the inconvenience. We are not currently servicing the  "+this.branchName+".</span> <span style='font-size:20px'> We will notify you when the service is available. <span>",
            icon: "warning",
            confirmButtonColor: "#3085d6",
            confirmButtonText: "OK!",
            customClass: 'swal-wide',
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.readonly = true;
            }
         })
        }
        this.callme()
      }
    });
  }

  togglebutton(text:string)
  {
    debugger
    //this.isShowSummaryDetails = true;
    if(text == 'Yes')
    {
      this.sdControls.pickupRequest.setValue(true);
      this.sdControls.isSelf_Drop.setValue(false);
      this.isSelf_Drop1 = false;
      this.isShowImageDetails = true;
      this.isPickup = true
      this.isShow = false;
    }
    if(text == 'No'){
      this.sdControls.pickupRequest.setValue(false);
      this.sdControls.isSelf_Drop.setValue(true);
      this.isSelf_Drop1 = true;
      this.isShowImageDetails = false;
      this.isPickup = false
      this.isShow = false;
    }
   
   if(this.sdControls.pickupRequest.value == true || this.sdControls.pickupRequest.value == false)
   {
      this.isAllFill = true;
    
   }
   this.calculateInternationalRate();
   
  }

  onSaveclick()
  {
   debugger
   
    let userName = this.appService.currentUserValue?.userName ?? '';
   
        this.logisticInternationalOrderModel.receiver_PersonName = this.daControls.receiver_PersonName.value ? this.daControls.receiver_PersonName.value : '';
        this.logisticInternationalOrderModel.receiver_Contact = this.daControls.receiver_Contact.value ? this.daControls.receiver_Contact.value : '';
        this.logisticInternationalOrderModel.receiver_EmailId= this.daControls.receiver_EmailId.value ? this.daControls.receiver_EmailId.value : '';
        this.logisticInternationalOrderModel.receiver_Country= this.daControls.receiver_Country.value ? this.daControls.receiver_Country.value : '';
        this.logisticInternationalOrderModel.receiver_Address= this.daControls.receiver_Address.value ? this.daControls.receiver_Address.value : '';
        this.logisticInternationalOrderModel.receiver_State= this.daControls.receiver_State.value ? this.daControls.receiver_State.value : '';
        this.logisticInternationalOrderModel.receiver_City= this.daControls.receiver_City.value ? this.daControls.receiver_City.value : '';
       // this.logisticInternationalOrderModel.receiver_house= this.daControls.sender_PersonName.value ? this.daControls.sender_PersonName.value : '';
        this.logisticInternationalOrderModel.receiver_Landmark= this.daControls.receiver_Landmark.value ? this.daControls.receiver_Landmark.value : '';
        this.logisticInternationalOrderModel.receiver_ZipCode= this.daControls.receiver_ZipCode.value ? this.daControls.receiver_ZipCode.value : '';

        this.logisticInternationalOrderModel.sender_PersonName = this.saControls.sender_PersonName.value ? this.saControls.sender_PersonName.value : '';
        this.logisticInternationalOrderModel.sender_Contact = this.saControls.sender_Contact.value ? this.saControls.sender_Contact.value : '';
        this.logisticInternationalOrderModel.sender_EmailId= this.saControls.sender_EmailId.value ? this.saControls.sender_EmailId.value : '';
        this.logisticInternationalOrderModel.sender_Country= this.saControls.sender_Country.value ? this.saControls.sender_Country.value : '';
        this.logisticInternationalOrderModel.sender_Address= this.saControls.sender_Address.value ? this.saControls.sender_Address.value : '';
        this.logisticInternationalOrderModel.sender_State= this.saControls.sender_State.value ? this.saControls.sender_State.value : '';
        this.logisticInternationalOrderModel.sender_City= this.saControls.sender_City.value ? this.saControls.sender_City.value : '';
       //this.logisticInternationalOrderModel.receiver_house= this.daControls.sender_PersonName.value ? this.daControls.sender_PersonName.value : '';
        this.logisticInternationalOrderModel.sender_Landmark= this.saControls.sender_Landmark.value ? this.saControls.sender_Landmark.value : '';
        this.logisticInternationalOrderModel.sender_ZipCode= this.saControls.sender_ZipCode.value ? this.saControls.sender_ZipCode.value : '';

        this.logisticInternationalOrderModel.orderDate = formatDate(new Date(), 'yyyy-MM-dd', 'en-US'),
        
        this.logisticInternationalOrderModel.branchId= this.branchId ?  this.branchId :  this.logisticInternationalOrderModel.branchId;
        this.logisticInternationalOrderModel.locationId= this.sdControls.locationId.value ? this.sdControls.locationId.value : '';
        this.logisticInternationalOrderModel.deliveryMode_Id= Number(this.deliverModeId) ? Number(this.deliverModeId) :this.logisticInternationalOrderModel.deliveryMode_Id;
        // this.logisticInternationalOrderModel.shipmentType_Id = this.shipmentId ? this.shipmentId : this.logisticInternationalOrderModel.shipmentType_Id;
         this.logisticInternationalOrderModel.shipmentType_Id= this.sdControls.shipmentType_Id.value ? this.sdControls.shipmentType_Id.value : '';
       this.logisticInternationalOrderModel.isSelf_Drop = this.isSelf_Drop1;
       if(this.isSelf_Drop1 == false)
       {
        this.logisticInternationalOrderModel.pickup_charges = this.pickCharges? this.pickCharges: 0;
        this.logisticInternationalOrderModel.bookingPin_Status = true;
     
       }
       
        this.logisticInternationalOrderModel.selectBoxSize= this.sdControls.selectBoxSize.value ? this.sdControls.selectBoxSize.value : '';
        this.logisticInternationalOrderModel.bookingImage= this.sdControls.bookingImage.value ? this.sdControls.bookingImage.value : this.logisticInternationalOrderModel.bookingImage;
        
        // this.logisticInternationalOrderModel.registredUser= this.userName;
        this.logisticInternationalOrderModel.registredUser= this.saControls.sender_EmailId.value? this.saControls.sender_EmailId.value : '';
        
        this.logisticInternationalOrderModel.exchange_rate= "139";
         this.logisticInternationalOrderModel.fromCountry= "India";
         this.logisticInternationalOrderModel.hsN_Code =  this.pickupChargesData[0]?.hsnCode ? this.pickupChargesData[0]?.hsnCode : this.logisticInternationalOrderModel.hsN_Code;
         this.logisticInternationalOrderModel.load_weight= this.sdControls.load_weight.value ? this.sdControls.load_weight.value : '';
         this.logisticInternationalOrderModel.additional_Weight= 0;
       this.logisticInternationalOrderModel.total_Weight= this.internationalData.totalWeight ? this.internationalData.totalWeight : '';
        this.logisticInternationalOrderModel.ratePer= this.internationalData.perKgRate ? this.perKgRate : '';
       // this.logisticInternationalOrderModel.package_Discount= this.saControls.bookingImage.value ? this.saControls.bookingImage.value : this.logisticInternationalOrderModel.ratePer;
       
       this.logisticInternationalOrderModel.packing_Charges= this.packingTotal? this.packingTotal :  this.logisticInternationalOrderModel.packing_Charges;
       this.logisticInternationalOrderModel.gsT_Rate= this.internationalData?.taxAmount ? this.totalTax :this.logisticInternationalOrderModel.gsT_Rate ;
       this.logisticInternationalOrderModel.gsT_Amt =  this.logisticInternationalOrderModel.gsT_Amt;
      this.logisticInternationalOrderModel.ratePer= this.internationalData.perKgRate ? this.perKgRate : '';
     
      let a:any =((this.totalGross && this.isShowSummaryDetails  ? this.totalGross : 0 ) + (this.packingGross && this.isShowSummaryDetails  ? this.packingGross : 0))
      this.logisticInternationalOrderModel.total_Wt_GrossAmt= a ? a:0;
      //  let b:any = ((this.totalTax && this.isShowSummaryDetails ? this.totalTax : 0 ) + (this.packingTax && this.isShowSummaryDetails ? this.packingTax : 0))
      //  this.logisticInternationalOrderModel.total_Wt_ta= 0;
       this.logisticInternationalOrderModel.total_Wt_GrsDisAmt= 0;
      let c:any = (((this.internationalData?.intlTotalAmt && this.isShowSummaryDetails ) ? this.internationalData?.intlTotalAmt : 0 ) + (this.packingTotal && this.consignmentCharges && this.isShowSummaryDetails  ? this.packingTotal : 0 ))
      this.logisticInternationalOrderModel.total_Wt_NetAmt= c ? c:0,

     // this.logisticInternationalOrderModel.pickupRe

      this.logisticInternationalOrderModel.dropOff_Hub= 1,
      this.logisticInternationalOrderModel.isDestinationAdrs_Split_ByPack= false,
      
      this.logisticInternationalOrderModel.payment_Type="Upi",
      this.logisticInternationalOrderModel.paymentTransactionId="44ja",
      this.logisticInternationalOrderModel.bookingImage="booking image",
      this.logisticInternationalOrderModel.bookingAmount= this.bookingAmount ? (this.bookingAmount).toString() :  this.logisticInternationalOrderModel.bookingAmount,
      this.logisticInternationalOrderModel.bookingTaxAmount=this.bookingTaxAmount? (this.bookingTaxAmount).toString(): 0,
      this.logisticInternationalOrderModel.totalBookingAmount= this.bookingAmount ? (this.bookingAmount).toString() :  this.logisticInternationalOrderModel.bookingAmount,
      this.logisticInternationalOrderModel.totalOutstanding= 123,
      this.logisticInternationalOrderModel.mid="MIN#05",
      this.logisticInternationalOrderModel.cid= 1046,
      this.logisticInternationalOrderModel.sender_Latitude = this.senderLatitude.toString();
      this.logisticInternationalOrderModel.sender_Longitude = this.senderLongitude.toString();
     

    this.addDetails();
        // //additional_Weight: [0, [Validators.required, Validators.min(0)]],
        // load_weight: ['', [Validators.required, Validators.min(0)]],
        // //update the value while submit based on shipmentType_NonDocument as kgs, shipmentType_Document as grams shipmentType_Document/1000
        // total_Weight: [{ value: 0, disabled: true }],
        // pickupRequest:[false],
        // discountCoupon:['']
      //   if (this.id != '' && this.id != undefined && this.id.length > 0) {
      //     that.updateDetails();
      //   } else {
      //     that.addDetails();
      //  }
  }

  addDetails()
  {
    this.appService.Post(Global.LogisticConsignmentOrder_EndPoint + 'Add', this.logisticInternationalOrderModel)
    .subscribe({
      next: (res: any) => {
        debugger
     this.orderId=res[0].data.bookingId;
        this.bookingPin = res[0].data.bookingPin
     Swal.fire({ 
      title: "<span>Order successfully booked </span><br/>"+"<span>"+this.orderId+"</span>"+"<br/><span style='font-size:20px'>Date:"+formatDate(new Date(), 'yyyy-MM-dd', 'en-US')+"</span><br/><span style='font-size:20px'>Sender Name:"+this.saControls.sender_PersonName.value+"</span><br/><span style='font-size:20px'>Sender Contact: "+this.saControls.sender_Contact.value+"</span><br/>"+"<span>BookingPin:"+this.bookingPin+"</span>",
      icon: "warning",
      confirmButtonColor: "#3085d6",
      confirmButtonText: "OK!",
      customClass: 'swal-wide',
    })
    .then((result) => {
      if (result.isConfirmed) {
        this.onClickReset()
        this.readonly = true;
      }
   })

      
    }
  })
  }

  updateDetails()
  {

  }

  onCountryChange(e: any)
  {

    let obj = {
      cid: 1046, from: "International",
      mid: "MIN#05",
      type: "ToCountry"
    }
    debugger
    this.appService.postJson(Global.LogisticCommon_EndPoint + 'GetLogisticValues', obj)
      .subscribe(data => {
        this.countryList = data;
        this.countryLabel = this.countryList.filter((a:any)=>a.label == this.sdControls.receiver_Country.value)
        
        for(let i=0;i<=this.countryList1.length;i++)
        {
          debugger
          if((this.sdControls.receiver_Country.value)?.trim() == (this.countryList1[i]?.name)?.trim())
          {
            this.code= this.countryList1[i].code
          }
          
        }
        
        this.receiverNgxOptions ={
          componentRestrictions: { country: [this.code ] },
          types: ['address'],
          fields: ["address_component", "place_id"]
        } as NgxGpAutocompleteOptions ;
     
        // let label = document.getElementById('receiver_Address') as HTMLInputElement
        // this.autocomplete1 = new google.maps.places.Autocomplete(label, this.receiverNgxOptions);
        console.log( 'label',this.receiverNgxOptions)
      })

      // debugger
      // this.receiverNgxOptions ={
      //   componentRestrictions: { country: [this.sdControls.receiver_Country.value == 'United States of America (the)' ? 'US' : this.sdControls.receiver_Country.value ] },
      //   types: ['geocode']
      // };
   
    // let midCode = "MIN#05";
    // let obj = { "mid": midCode, "cid": this.cid, "userId": "admin@finobooks.com" };
    // this.appService.postJson(Global.LogisticRatecardInternational_EndPoint + 'List', obj)
    //   .subscribe({
    //     next: (res: any) => {
    //     debugger
    //     this.readonly = false;
    //        this.zoneList = res?.data.filter((a: any) => a.branchId == this.branchId);

    //        if(this.zoneList.length == 0)
    //        {
    //         Swal.fire({
    //           title: "Services not available",
    //           icon: "warning",
    //           confirmButtonColor: "#3085d6",
    //           confirmButtonText: "OK!"
    //         })
    //         .then((result) => {
    //           if (result.isConfirmed) {
    //             this.readonly = true;
    //           }
    //        })
          
    //       }
         
    //     },
        
    //   })
  }

payNow(check1: HTMLInputElement,check2: HTMLInputElement) {
  debugger
if(check1.checked && check2.checked)
{
  var newThis=this;
  this.razorpay={
      description: 'Sample Razorpay demo',
      currency: 'INR',
    // amount:"186.89",
      amount: (this.bookingAmount)*100,
      name: this.saControls.sender_PersonName.value,
       key: 'rzp_test_VPS1IXsq048IZp',
       image: '/assets/images/logo_small.png',
       handler: function(response:any){
      
        newThis.logisticInternationalOrderModel.payment_Id = response.razorpay_payment_id;
      
        newThis.onSaveclick();
        
       }.bind(this),
     
      prefill: {
        name: this.saControls.sender_PersonName.value,
        email: this.saControls.sender_EmailId.value,
        phone: this.saControls.sender_Contact.value,
      },
      theme: {
        color: '#e0ebeb'
      },
      modal: {
        ondismiss:  () => {
          console.log('dismissed')
        }
      }
    }

    const successCallback = (paymentid: any) => {
      console.log(paymentid);
    }

    const failureCallback = (e: any) => {
      console.log(e);
    }
    
     Razorpay.open(this.razorpay,successCallback, failureCallback)
     debugger
   
}
else{
  this.isSelect = true;
}
 

  }
 generatedTranscId() {
    return 'T' + Date.now();
}

  async payNow2()
  {
    debugger
    const userid = 'dfsdfsdf';
    const phone = '4567893456';
    const name = 'senderName';
    const by_amount = 2000;

    // Generate unique transaction and order IDs
    const merchantTransactionId = 'MUID' + Math.random().toString(36).substring(7);
    const merchantOrderId = 'Order' + Math.floor(Math.random() * 89999 + 10000);
    const amount = by_amount * 100;

    // PhonePe Payment Gateway configuration
    const config = {
        merchantId: 'PGTESTPAYUAT132',
        merchantSecret: '58f62bdc-2b1f-44a1-9da5-1820a35835f3',
        environment: 1
    };

    const redirectUrl = "https://xoomship.com/phonepesuccess/"+merchantTransactionId;
    const mode = "PRODUCTION";
    const callbackUrl = "https://xoomship.com/phonepesuccess/"+merchantTransactionId;
    const mobileNumber = phone;
    
    // const newPayment = async (req, res) => {
    // try {

    
const data = {
  merchantId: "PGTESTPAYUAT132",
  merchantTransactionId: new Date(),
  merchantUserId: 'MUID' + 'dfsdfdf',
  name: name,
  amount: 3222 * 100,
  ///redirectUrl: `http://localhost:3001/api/v1/orders/status/${new Date()}`,
  redirectMode: "POST",
  mobileNumber: phone,
  redirectUrl,
  callbackUrl,
  paymentInstrument: {
      type: "PAY_PAGE",
  },
};

        // const response = await axios.post('https://api-preprod.phonepe.com/apis/pg-sandbox/pg/v1/pay', {
        //     merchantTransactionId,
        //     merchantOrderId,
        //     amount,
        //     // redirectUrl,
        //     // callbackUrl,
        //     mobileNumber,
        //     mode
        // }, {
        //     headers: {
        //         'Content-Type': 'application/json',
        //         'X-Merchant-Id': config.merchantId,
        //         'X-Merchant-Secret': config.merchantSecret
        //     }
        // });
        
        const payload = JSON.stringify(data);
        const payloadMain = Buffer.from(payload).toString('base64');

        const key = "58f62bdc-2b1f-44a1-9da5-1820a35835f3";
        const keyIndex = 1;
        const string = payloadMain + "/pg/v1/pay" + key;

        const sha256 = SHA256(string).toString();
        const checksum = sha256 + "###" + keyIndex;

        const option={
          method: "post",
          url:'https://api.phonepe.com/apis/hermes/pg/v1/pay',
          headers: {
            accept: 'application/json',
            'Content-Type' : 'application/json', 
            
            // "X-VERIFY": checksum
           },
           data:{
            request:payloadMain
           }

        }

        axios
        .request(option)
          .then(function (response:any) {
            console.log(response.data)
            })
            .catch(function (error) {
                console.error(error);
            });

          //}
        // }
        //   alert(newPayment)
      
  }

  

  
  yesClick(text:string)
  {
    if(text=='Yes')
    {

    }
    else{

    }
  }
  selectTab(event:any) {
    debugger
    
    if(event==0)
    {
      this.isShow = true;
      this.nav.select("ngb-nav-0");
      this.onClickReset()
    }
    else{
      this.isShow = false;
      this.nav.select("ngb-nav-1");
      //this.onClickReset()
    }
   // this.callme();
    
}




// openPopup()
// {
//   let that =this;
//   Swal.fire({
//    confirmButtonColor: "#3085d6",
//     confirmButtonText: "PhonePe",
//     cancelButtonText:"RazorPay",
//     cancelButtonColor:"#3085d6",
//     showCancelButton:true
//     }).then(function(res) {
//     if (res.isConfirmed) {
//       that.payNow2();
//     }
//     if (res.isDismissed) {
//       that.payNow();
//   }
// });
// }

// charge(amount, transactionId, mobile, apiKeyIndex) {
//   const payload = {
//     amount: amount, // Amount in paise
//     expiresIn: 180,
//     instrumentReference: mobile,
//     instrumentType: "MOBILE",
//     merchantId: 'M22FLSALXCF8P',
//     merchantOrderId: transactionId,
//     storeId: 'asdas',
//     terminalId: 'sadcasdas',
//     transactionId: transactionId,
//     message: "Payment for " + transactionId
//   };

//   const base64 = encodeRequest(payload);
//   const sign = base64 + CHARGE_ENDPOINT + this.apiKeys[apiKeyIndex];
//   const X_VERIFY = signRequest(sign) + "###" + apiKeyIndex;

//   return request(
//     {
//       method: "POST",
//       hostname: endpoints[this.env],
//       path: CHARGE_ENDPOINT,
//       headers: {
//         "Content-Type": "application/json",
//         "X-VERIFY": X_VERIFY
//       }
//     },
//     { request: base64 }
//   );
// }

// qrcode(amount, transactionId, apiKeyIndex) {
//   const payload = {
//     amount: amount, // Amount in paise
//     expiresIn: 180,
//     merchantId: 'M22FLSALXCF8P',
//     merchantOrderId: transactionId,
//     storeId: 'sadas',
//     terminalId: 'safafa',
//     transactionId: transactionId,
//     message: "Payment for " + transactionId
//   };

//   const base64 = make_base64(payload);
//   const sign = base64 + QRINIT_ENDPOINT + this.apiKeys[apiKeyIndex];
//   const X_VERIFY = make_hash(sign) + "###" + apiKeyIndex;

//   return request(
//     {
//       method: "POST",
//       hostname: endpoints[this.env],
//       path: QRINIT_ENDPOINT,
//       headers: {
//         "Content-Type": "application/json",
//         "X-VERIFY": X_VERIFY
//       }
//     },
//     { request: base64 }
//   );
// }

// status1(transactionId, apiKeyIndex) {
//   const endpoint =
//   TRANSACTION_ENDPOINT +
//   "/" +
//   this.merchantId +
//   "/" +
//   transactionId +
//   "/status";
// const sign = endpoint + this.apiKeys[apiKeyIndex];
// const X_VERIFY = make_hash(sign) + "###" + apiKeyIndex;

// return request({
//   method: "GET",
//   hostname: endpoints[this.env],
//   path: endpoint,
//   headers: {
//     "Content-Type": "application/json",
//     "X-VERIFY": X_VERIFY
//   }
// });
// }

// cancel(transactionId, apiKeyIndex) {
//   const endpoint =
//   CHARGE_ENDPOINT + "/" + this.merchantId + "/" + transactionId + "/cancel";
// const sign = endpoint + this.apiKeys[apiKeyIndex];
// const X_VERIFY = make_hash(sign) + "###" + apiKeyIndex;

// return request({
//   method: "POST",
//   hostname: endpoints[this.env],
//   path: endpoint,
//   headers: {
//     "Content-Type": "application/json",
//     "X-VERIFY": X_VERIFY
//   }
// });
// }

// refund(transactionId, providerReferenceId, apiKeyIndex) {
//   const payload = {
//     amount: 100,
//     merchantId: 'PGTESTPAYUAT132',
//     providerReferenceId: providerReferenceId,
//     transactionId: transactionId + "_refund",
//     message: "Refund"
//   };

//   const base64 = make_base64(payload);
//   const sign = base64 + REFUND_ENDPOINT + this.apiKeys[apiKeyIndex];
//   const X_VERIFY = make_hash(sign) + "###" + apiKeyIndex;

//   return request(
//     {
//       method: "POST",
//       hostname: endpoints[this.env],
//       path: REFUND_ENDPOINT,
//       headers: {
//         "Content-Type": "application/json",
//         "X-VERIFY": X_VERIFY
//       }
//     },
//     { request: base64_payload }
//   );
// }
//  encodeRequest(payload) {
//   return Buffer.from(JSON.stringify(payload)).toString("base64");
// }

signRequest(payload) {
  return crypto
    .createHash("sha256")
    .update(payload)
    .digest("hex");
}

request(options, payload) {
  return new Promise((resolve, reject) => {
    const req = https.request(options, resp => {
      let data = "";

      resp.on("data", chunk => {
        data += chunk;
      });

      resp.on("end", () => {
        resolve(JSON.parse(data));
      });
    });

    req.on("error", reject);

    if (payload) {
      req.write(JSON.stringify(payload));
    }

    req.end();
  });
}

filterDropdown(e) {
  console.log("e in filterDropdown -------> ", e);
  window.scrollTo(window.scrollX, window.scrollY + 1);
  let searchString = e.toLowerCase();
  if (!searchString) {
    this.filteredList = this.service_zones_ist.slice();
    return;
  } else {
    this.filteredList = this.service_zones_ist.filter(
      user => user.name.toLowerCase().indexOf(searchString) > -1
    );
  }
  window.scrollTo(window.scrollX, window.scrollY - 1);
  console.log("this.filteredList indropdown -------> ", this.filteredList);
}

selectValue(name) {
  this.selectedValue = name;
}
changeColor(value:any)
{
  debugger
  this.value=''
  if(value == '12')
  {
    this.value = '12'
    this.color = true;
  }
  if(value == '13')
  {
    this.value = '13'
    this.color = true;
  }
 
}
change(option:any)
{
  debugger
  for (let sd of this.shipment_info_list) {
    if (sd.value === option.value && sd.label === 'Document') {
      this.sdControls.shipmentType_Document.setValue(true);
      this.sdControls.shipmentType_NonDocument.setValue(false);
      // this.sdControls.load_weight.setValue('');
       this.sdControls.shipmentType_Id.setValue(option.value);
      this.onClickResetOFDocument();
    }
    else if (sd.value === option.value && sd.label === 'Non Document') {
      this.sdControls.shipmentType_NonDocument.setValue(true);
      this.sdControls.shipmentType_Document.setValue(false);
      //this.sdControls.load_weight.setValue('');
      this.sdControls.shipmentType_Id.setValue(option.value);
      debugger
      this.onClickResetOFDocument();
     
      //this.shipmentId = option.value
    }
  }
}
changeColor1(value:any)
{
  if(this.boxSelected == false)
  {
  if(value.deliverModeId == 10 && value.vendorName == 'UPSC')
  {
    this.value1 = 10;
    this.vendorName = 'UPSC'
  }
  if(value.deliverModeId == 9 && value.vendorName == 'FedEx')
  {
    this.value1 = 9
    this.vendorName = 'FedEx'
  }
  if(value.deliverModeId == 9 && value.vendorName == 'ICL')
  {
    this.value1 = 9
    this.vendorName = 'ICL'
  }
  if(value.deliverModeId == 10 && value.vendorName == 'ICL')
  {
    this.value1 = 10
    this.vendorName = 'ICL'
  }
}
 
}
change1(option:any)
{
  if(this.boxSelected == true)
  {
    Swal.fire({ 
      title: " <span style='font-size:20px'>Please select the box.<span>",
      icon: "warning",
      confirmButtonColor: "#3085d6",
      confirmButtonText: "OK!",
      customClass: 'swal-wide',
    })
    .then((result) => {
      if (result.isConfirmed) {
        this.readonly = true;
      }
   })
  }
  else{
    this.deliverModeId = option.deliverModeId
    this.dmControls.deliveryMode_Id_Vendor_List.setValue(option.deliverModeId)
    this.showButton = true;
    
      this.calculateInternationalRate();
  }
  
}


onChangeFile(files: any) {
  let that = this;
  if (files.length > 0) {
    var fileExtension = files[0].name.replace(/^.*\./, '');
    if (fileExtension.toLowerCase() == 'png' || fileExtension.toLowerCase() == 'jpg' || fileExtension.toLowerCase() == 'jpeg') {
      let formData = new FormData();
      formData.append('files', files[0], ('order_' + that.appService.generateUUID() + '.' + fileExtension));
      Swal.fire({
        title: 'Do you want to upload the image?',
        showCancelButton: true,
        confirmButtonText: 'Upload'
      }).then((result) => {
        if (result.isConfirmed) {
          this.appService.UploadTempFile(formData)
            .subscribe({
              next: (res: any) => {
                debugger
                this.logisticInternationalOrderModel.bookingImage = res;
                //this.pr_Hsn.setValue(this.hsnModel.pr_Hsn);
              },
              error: (error) => {
                console.error(error);
              }
            });
        }
      });
    } else {
      // $("#kt_create_account_form_account_type_personal").val("");
      Swal.fire({
        title: 'Information!',
        text: 'Please select .png or .jpg or jpeg',
      });
    }
  } else {
    // $("#kt_create_account_form_account_type_personal").val("");
    Swal.fire({
      title: 'Information!',
      text: 'Please select .png or .jpg or jpeg',
    });
  }
}

deleteImagePath() {
  this.logisticInternationalOrderModel.bookingImage = '';
}
openImage(templateRef: TemplateRef<any>,image:string){
  this.modalService.open(templateRef,{
    windowClass: 'myClass'
    // width: '300px',
    // height: '300px',
    // position:{right:'10px',bottom:'10px'},
    
  });
  this.Image =image
}
}

   
    
  




<section class="bg-half1 d-table w-100" id="home">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-md-7">
        <div class="title-heading mt-4">
          <h1 class="display-4 font-weight-bold mb-3">The best part! <br> we deliver to your door step.</h1>
          <p class="para-desc text-muted">We served in the past and now we are back with what our customers want.
            Xoomship provides you the ability to now Shop from sitting at home.With the convenience of having iphone and
            andriod apps..
            you can now order prepaid shipping, order your favorite items online, Custom order a category of things that
            you need, sit back relax and we take care of your order.</p>

          <div class="subcribe-form mt-4 pt-2">
            <form>
              <div class="form-group">
                <input type="text" id="order"  class="border bg-white rounded-pill" required
                  placeholder="Enter Your Tracking No">
               <button type="submit" class="btn btn-pills btn-primary" (click)="searchDetails($event)">Trace Now</button>
                
                <div *ngIf="isOpenBox" style="border:2px" class="col-md-12">
                  <div  *ngFor='let option of international_data_list1;let i= index;'>
                  <div class=" card-body" style="background-color:#d6e4f1;color:white;margin-top:15px;border-radius:15px">
                    <div class="col-md-12 row">
                      <span
                        ><span style="font-size:25px;font-weight:bold;color:black">{{option.total_Wt_NetAmt}}</span><span style="color:black">+GST</span></span>
                      </div>
                      <div class="col-md-12 row">
                        <div class="col-md-6">
                          <span style="color:Gray">Sender Name</span><br/>
                          <span  style="font-size:17px;font-weight:bold;color:black">{{option.sender_PersonName}}</span>
                        </div>
                        <div class="col-md-6">
                          <span style="color:Gray">Receiver name</span><br/>
                          <span style="font-size:17px;font-weight:bold;color:black">{{option.receiver_PersonName}}</span>
                        </div>
                      </div>

                      <div class="col-md-12 row">
                        <div class="col-md-6">
                          <span style="color:Gray">Shipment Info</span><br/>
                          <span  style="font-size:17px;font-weight:bold;color:black">{{shipment_info_list[0].label}}</span>
                        </div>
                        <div class="col-md-6">
                          <span style="color:Gray">Weight In Kgs.</span><br/>
                          <span style="font-size:25px;font-weight:bold;color:black">{{option.total_Weight}}</span>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <span style="color:Gray">{{this.international_ratecard_list1[0].deliveryModeName}} Mode</span><br/>
                      </div>
                      <!-- <div class="col-md-12">
                        <a (click)="openOrderModal(myModal3)" class="btn btn-primary login-btn-primary">View Details</a>
                      </div> -->
                   </div>
                   </div>
                    </div>
                 
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <div class="col-md-12 row">
                      <!--<input type="checkbox" style="width:15px">
                       <label class="custom-control-label" style="margin-top: -37px;
                      margin-left: 10px;" for="customCheck1">I Accept <a href="#" class="text-primary">Terms And
                          Condition</a></label> -->
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-5">
        <div class="card login_page pricing-rates business-rate bg-light  shadow  rounded border-0 ml-lg-5"
          *ngIf='showSignUp'>
          <div class="card-body">
            <h4 class="card-title text-center">Signup</h4>
            <form class="login-form mt-4">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group position-relative">
                    <label>First name <span class="text-danger">*</span></label>
                    <i-feather name="user" class="fea icon-sm icons"></i-feather>
                    <input type="text" class="form-control pl-5" placeholder="First Name" name="s" required="">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group position-relative">
                    <label>Last name <span class="text-danger">*</span></label>
                    <i-feather name="user-check" class="fea icon-sm icons"></i-feather>
                    <input type="text" class="form-control pl-5" placeholder="Last Name" name="s" required="">
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group position-relative">
                    <label>Your Email <span class="text-danger">*</span></label>
                    <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                    <input type="email" class="form-control pl-5" placeholder="Email" name="email" required="">
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group position-relative">
                    <label>Password <span class="text-danger">*</span></label>
                    <i-feather name="key" class="fea icon-sm icons"></i-feather>
                    <input type="password" class="form-control pl-5" placeholder="Password" required="">
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" class="custom-control-input" id="customCheck1">
                      <label class="custom-control-label" for="customCheck1">I Accept <a href="#"
                          class="text-primary">Terms And Condition</a></label>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <button class="btn btn-primary btn-block" (click)="showSignUp=!showSignUp">Register</button>
                </div>
                <div class="col-lg-12 mt-4 text-center">
                  <h6>Or Signup With</h6>
                  <ul class="list-unstyled social-icon mb-0 mt-3">
                    <li class="list-inline-item"><a href="javascript:void(0)" class="rounded">
                        <i-feather name="facebook" class="fea icon-sm icons"></i-feather>
                      </a></li>
                    <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded">
                        <i-feather name="github" class="fea icon-sm icons"></i-feather>
                      </a></li>
                    <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded">
                        <i-feather name="twitter" class="fea icon-sm icons"></i-feather>
                      </a></li>
                    <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded">
                        <i-feather name="gitlab" class="fea icon-sm icons"></i-feather>
                      </a></li>
                  </ul>
                  <!--end icon-->
                </div>
                <div class="mx-auto">
                  <p class="mb-0 mt-3"><small class="text-dark mr-2">Already have an account ?</small> <a
                      routerLink="/auth-login" class="text-dark font-weight-bold">Sign in</a></p>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div *ngIf='!showSignUp'>
          <div class="card pricing-rates business-rate shadow bg-light rounded border-0 shadow ml-lg-5">
            <ul ngbNav #nav="ngbNav" class="nav-pills nav-justified">
              
              <li ngbNavItem (click)="internationalClick()">
                <a ngbNavLink class=" rounded">
                  <div class="text-center pt-1 pb-1">
                    <h4 class="title font-weight-normal mb-0">International</h4>
                  </div>
                </a>
                <ng-template ngbNavContent>
                  <div class="col-lg-12 ">

                    <div class="form-group position-relative">

                      <div class="form-group position-relative">
                        <label>Delivery Mode : <span class="text-danger">*</span></label>
                        <div>
                          <div class="custom-control custom-radio custom-control-inline"
                            *ngFor='let option of delivery_mode_list;let i= index;'>
                            <div class="form-group mb-0">
                              <input type="radio" id="customRadio{{i}}" name="customRadio" class="custom-control-input"
                                (click)='onClickDelmode(option)'>
                              <label class="custom-control-label" for="customRadio{{i}}">{{option.name}}</label>
                            </div>
                          </div>

                        </div>
                      </div>

                      <div class="form-group position-relative">
                        <label>Shipment Info : <span class="text-danger">*</span></label>
                        <div>
                          <div class="custom-control custom-radio custom-control-inline"
                            *ngFor='let option of delivery_mode_list;let i= index;'>
                            <div class="form-group mb-0">
                              <input type="radio" id="customRadio{{i}}" name="customRadio" class="custom-control-input"
                                (click)='onClickDelmode(option)'>
                              <label class="custom-control-label" for="customRadio{{i}}">{{option.name}}</label>
                            </div>
                          </div>

                        </div>
                      </div>

                    </div>

                    <div class="col-lg-12">
                      <div class="form-group position-relative">
                        <label>Country <span class="text-danger">*</span></label>
                        <select class="form-control custom-select" [(ngModel)]='selected_country'
                          name='selected_country'>
                          <option *ngFor='let option of countryList' [value]='option.label'>{{option.label}}</option>
                        </select>
                      </div>


                      <div class="col-lg-12">
                        <div class="form-group position-relative">
                          <label>Weight in Kgs : <span class="text-danger">*</span></label>

                          <input type="number" class="form-control pl-5" placeholder="Kgs" min="1"
                            [(ngModel)]='pack_loader' name='pack_loader' required="">
                        </div>
                      </div>


                      <div class="col-lg-12 mt-2 mb-0">
                        <button class="btn btn-primary btn-block" style="width:100%"
                          (click)='calculateRate()'>Calculate</button>
                        <!-- <button class="btn btn-primary btn-block" (click)='(showCalculation=!showCalculation);(showSignUp=!showSignUp)' *ngIf='showCalculation'>Pickup Request</button> -->
                      </div>

                    </div>
                  </div>
                </ng-template>
              </li>

              <li ngbNavItem (click)="domesticCklick()">
                <a ngbNavLink class=" rounded">
                  <div class="text-center pt-1 pb-1">
                    <h4 class="title font-weight-normal mb-0">Domestic</h4>
                  </div>
                </a>
                <ng-template ngbNavContent>
                 
                  <div class="col-lg-12 ">

                    <div class="form-group position-relative">

                      <div class="form-group position-relative">
                        <label>Delivery Mode : <span class="text-danger">*</span></label>
                        <div>
                          <div class="custom-control custom-radio custom-control-inline"
                            *ngFor='let option of delivery_mode_list;let i= index;'>
                            <div class="form-group mb-0">
                              <input type="radio" id="customRadio{{i}}" name="customRadio" class="custom-control-input"
                                (click)='onClickDelmode(option)'>
                              <label class="custom-control-label" for="customRadio{{i}}">{{option.name}}</label>
                            </div>
                          </div>

                        </div>
                      </div>

                      <div class="form-group position-relative">
                        <label>Shipment Info : <span class="text-danger">*</span></label>
                        <div>
                          <div class="custom-control custom-radio custom-control-inline"
                            *ngFor='let option of delivery_mode_list;let i= index;'>
                            <div class="form-group mb-0">
                              <input type="radio" id="customRadio{{i}}" name="customRadio" class="custom-control-input"
                                (click)='onClickDelmode(option)'>
                              <label class="custom-control-label" for="customRadio{{i}}">{{option.name}}</label>
                            </div>
                          </div>

                        </div>
                      </div>

                    </div>

                    <div class="col-lg-12">
                      <div class="form-group position-relative">
                        <label>Country <span class="text-danger">*</span></label>
                        <select class="form-control custom-select" [(ngModel)]='selected_country'
                          name='selected_country'>
                          <option *ngFor='let option of countryList' [value]='option.label'>{{option.label}}</option>

                        </select>
                      </div>


                      <div class="col-lg-12">
                        <div class="form-group position-relative">
                          <label>Weight in Kgs : <span class="text-danger">*</span></label>

                          <input type="number" class="form-control pl-5" placeholder="Kgs" min="1"
                            [(ngModel)]='pack_loader' name='pack_loader' required="">
                        </div>
                      </div>


                      <div class="col-lg-12 mt-2 mb-0">
                        <button class="btn btn-primary btn-block" style="width:100%"
                          (click)='calculateRate()'>Calculate</button>
                        
                      </div>

                    </div>
                  </div>
                </ng-template>
              </li>

            </ul>

            <!-- <ul  ngbnav="" class="nav-pills nav-justified nav" >
              <li  class="nav-item"><a (click)="internationalClick()" [ngClass]="{'rounded nav-link active ': isInter ==true}" 
                  id="ngb-nav-9" >
                  <div class="text-center pt-1 pb-1">
                    <h4 class="title font-weight-normal mb-0"> International </h4>
                  </div>
                </a>
               
              </li>
              <li class="nav-item"><a (click)="domesticCklick()"[ngClass]="{'rounded nav-link active ': isDomestic ==true}">
                  <div class="text-center pt-1 pb-1">
                    <h4 class="title font-weight-normal mb-0"> Domestic </h4>
                  </div>
                </a>
              
              </li>
            </ul> -->
            <div class="card-body">

              <img src="assets/images/illustrator/PriceBanner.gif" style='width: 104%;' alt="" 
                *ngIf='!showCalculation'>
              <div class="rounded shadow-lg p-4" *ngIf='showCalculation'>
                <div class="d-flex justify-content-between">
                  <h5>Price Calculator for</h5><span class="text-primary h4 font-weight-bold"
                    style='font-family: "Nunito", sans-serif;'> {{pack_loader}} Kgs </span>
                  <a href="javascript:void(0)" class="btn btn-outline-primary btn-sm mt-2 mr-2" style='padding: 7px 10px;
                  font-size: 13px;' (click)='(showCalculation=!showCalculation);onClickReset()'>Reset</a>
                  <!-- <a href="shop-cart" class="text-muted h6">Show Details</a> -->
                </div>
                <span class="text-primary  font-weight-bold" style='font-family: "Nunito", sans-serif;'>From India to
                  {{selected_country}}</span>
                <div class="table-responsive">
                  <table class="table table-center table-padding mb-0">
                    <tbody>
                      <tr>
                        <td class="h6 border-0">Price/Kg</td>
                        <td class="text-center font-weight-bold border-0">{{price_per_kg| currency:'INR'}}</td>
                      </tr>
                      <tr>
                        <td class="h6 border-0">Gross Amount</td>
                        <td class="text-center font-weight-bold border-0">{{freight_rate| currency:'INR'}}</td>
                      </tr>
                      <tr>
                        <td class="h6 border-0">Total GST({{total_tax_per}}%)</td>
                        <td class="text-center font-weight-bold border-0">{{gst_price| currency:'INR'}}</td>
                      </tr>

                      <tr class="bg-light">
                        <td class="h5 font-weight-bold">Total Price</td>
                        <td class="text-center text-primary h4 font-weight-bold">{{total_selling_price|
                          currency:'INR'}}</td>
                      </tr>
                    </tbody>
                  </table>

                </div>
              </div>
              <div class="content mt-4 pt-2 " >
                <div  *ngIf="isDomestic" class="col-lg-12 " style="font-size:25px;font-weight:bold">Coming Soon....
                  <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/></div>
              
                <form *ngIf="!isDomestic">
                  <div class="row">
                    <div class="col-lg-12 ">
                      <h4>Price Calculator {{calculator}}</h4>
                    </div>
                    <div class="col-lg-12 ">

                      <div class="form-group position-relative">

                        <div class="form-group position-relative">
                          <label>Delivery Mode : <span class="text-danger">*</span></label>
                          <div>
                            <div class="custom-control custom-radio custom-control-inline"
                              *ngFor='let option of delivery_mode_list;let i= index;'>
                              <div class="form-group mb-0">
                                <input type="radio" id="customRadio{{i}}" name="customRadio"
                                  (click)='onClickDelmode(option)'>
                                <label class="custom-control-label" style="margin-left:6px"
                                  for="customRadio{{i}}">{{option.label}}</label>
                              </div>
                            </div>

                          </div>
                        </div>

                        <div class="form-group position-relative">
                          <label>Shipment Info : <span class="text-danger">*</span></label>
                          <div>
                            <div class="custom-control custom-radio custom-control-inline"
                              *ngFor='let option of shipment_info_list;let i= index;'>
                              <div class="form-group mb-0">
                                <input type="radio" id="customRadio1{{i}}" name="customRadio1"
                                  (change)="option.label == 'Document' ? docClick() : nonDocClick()"
                                  class="custom-control-input" (click)='onClickDelmode1(option)'>
                                <label *ngIf="option.label == 'Document'" (click)="docClick()"
                                  class="custom-control-label" style="margin-left:6px"
                                  for="customRadio1{{i}}">{{option.label}}</label>
                                <label *ngIf="option.label == 'Non Document'" (click)="nonDocClick()"
                                  class="custom-control-label" style="margin-left:6px"
                                  for="customRadio1{{i}}">{{option.label}}</label>
                              </div>
                            </div>

                          </div>
                        </div>

                      </div>
<!-- 
                      <div class="col-lg-12" *ngIf="isDomestic">
                        <div class="form-group position-relative"><label>From ZipCode :</label>
                          <i-feather name="book" class="fea icon-sm icons"><svg xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24" class="feather feather-book">
                              <path d="M4 19.5A2.5 2.5 0 0 1 6.5 17H20"></path>
                              <path d="M6.5 2H20v20H6.5A2.5 2.5 0 0 1 4 19.5v-15A2.5 2.5 0 0 1 6.5 2z"></path>
                            </svg></i-feather><input name="from_zipcode_dom" id="from_zipcode_dom" maxlength="6"
                            placeholder="ZipCode :" class="form-control pl-5 ng-pristine ng-valid ng-touched">
                        </div>
                      </div> -->


                      <!-- <div class="col-lg-12" *ngIf="isDomestic">
                        <div class="form-group position-relative"><label>To ZipCode :</label>
                          <i-feather name="book" class="fea icon-sm icons"><svg xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24" class="feather feather-book">
                              <path d="M4 19.5A2.5 2.5 0 0 1 6.5 17H20"></path>
                              <path d="M6.5 2H20v20H6.5A2.5 2.5 0 0 1 4 19.5v-15A2.5 2.5 0 0 1 6.5 2z"></path>
                            </svg></i-feather><input name="to_zipcode_dom" id="to_zipcode_dom" maxlength="6"
                            placeholder="ZipCode :" class="form-control pl-5 ng-pristine ng-valid ng-touched">
                        </div>
                      </div> -->


                      <div class="col-lg-12">
                        <div class="form-group position-relative">
                          <label>Country <span class="text-danger">*</span></label>
                          <select class="form-control custom-select" [(ngModel)]='selected_country'
                            name='selected_country'>
                            <option *ngFor='let option of countryList' [value]='option.label'>{{option.label}}
                            </option>
                          </select>
                        </div>
                        <div class="col-lg-12" *ngIf="isDoc">
                          <div class="form-group position-relative">
                            <label>Weight Grams : <span class="text-danger">*</span></label>
                            <input type="number" class="form-control pl-5" placeholder="100gm,200gm" min="1"
                              [(ngModel)]='pack_loader' name='pack_loader' required="">
                          </div>
                        </div>


                        <div class="col-lg-12" *ngIf="isNonDoc">
                          <div class="form-group position-relative">
                            <label>Weight in Kgs : <span class="text-danger">*</span></label>
                            <input type="number" class="form-control pl-5" placeholder="Kgs" min="1"
                              [(ngModel)]='pack_loader' name='pack_loader' required="">
                          </div>
                        </div>

                        <div class="col-lg-12 mt-2 mb-0">
                          <button class="btn btn-primary btn-block" style="width:100%"
                            (click)='calculateRate()'>Calculate</button>
                          <!-- <button class="btn btn-primary btn-block" (click)='(showCalculation=!showCalculation);(showSignUp=!showSignUp)' *ngIf='showCalculation'>Pickup Request</button> -->
                        </div>

                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <!-- <img src="assets/images/onepage/flyxoom.png" alt="" style='width: 120%;'> -->
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
  <!-- <div class="container">
    <article class="card pricing-rates business-rate shadow bg-light rounded border-0 shadow ml-lg-5">
        <header class="card-header"> My Orders / Tracking </header>
        <div class="card-body">
            <h6>Order ID: OD45345345435</h6>
            
            <div class="track">
                <div class="step active"> <span class="icon"> <i class="fa fa-check"></i> </span> <span class="text">Order confirmed</span> </div>
                <div class="step active"> <span class="icon"> <i class="fa fa-user"></i> </span> <span class="text"> Picked by courier</span> </div>
                <div class="step"> <span class="icon"> <i class="fa fa-truck"></i> </span> <span class="text"> On the way </span> </div>
                <div class="step"> <span class="icon"> <i class="fa fa-box"></i> </span> <span class="text">Ready for pickup</span> </div>
            </div>
            <hr>
            
            <hr>
            <a href="#" class="btn btn-warning" data-abc="true"> <i class="fa fa-chevron-left"></i> Track info</a>
        </div>
    </article>
  </div> -->
</section>



<!--end section-->
<div class="position-relative">
  <div class="shape overflow-hidden text-light">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!-- Hero End -->
<!--AdsnStart-->

<div class="container-fluid mt-5 pt-2">
  <div class="row">
    <div class="col-md-12 row t-4 pt-2">
      <span class="text-primary text-center h4 font-weight-bold" style='font-family: "Nunito", sans-serif;'> Ship all
        over the world - USA, UK, Europe, Australia, New Zealand, UAE, Middle East and 200 other countries. </span>

    </div>
    <div class="col-md-12 mt-4 pt-2">
      <owl-carousel-o id="six-slide" [options]="customAdOptions" class="owl-stage-outer">
        <ng-template carouselSlide>
          <div class="py-5 rounded shadow"
            style="background: url('assets/images/shop/1.png') top center;margin-right: 20px;">
            <div class="p-4">
              <h3> <br> </h3>
              <a href="javascript:void(0)" class="btn btn-sm btn-soft-primary mt-2"></a>
            </div>
          </div>
          <!--end tour post-->
        </ng-template>
        <ng-template carouselSlide>
          <div class="py-5 rounded shadow"
            style="background: url('assets/images/shop/2.png') top center;margin-right: 20px;">
            <div class="p-4">
              <h3><br> </h3>
              <a href="javascript:void(0)" class="btn btn-sm btn-soft-primary mt-2"></a>
            </div>
          </div>
          <!--end tour post-->
        </ng-template>
        <ng-template carouselSlide>
          <div class="py-5 rounded shadow"
            style="background: url('assets/images/shop/3.png') top center;margin-right: 20px;">
            <div class="p-4">
              <h3> <br> </h3>
              <a href="javascript:void(0)" class="btn btn-sm btn-soft-primary mt-2"></a>
            </div>
          </div>
          <!--end tour post-->
        </ng-template>
      <ng-template carouselSlide>
        <div class="py-5 rounded shadow"
        style="background: url('assets/images/shop/4.png') top center;margin-right: 20px;">
        <div class="p-4">
          <h3> <br> </h3>
          <a href="javascript:void(0)" class="btn btn-sm btn-soft-primary mt-2"></a>
        </div>
      </div>
        
        </ng-template> 

        <ng-template carouselSlide>
          <div class="py-5 rounded shadow"
          style="background: url('assets/images/shop/5.png') top center;margin-right: 20px;">
          <div class="p-4">
            <h3> <br> </h3>
            <a href="javascript:void(0)" class="btn btn-sm btn-soft-primary mt-2"></a>
          </div>
        </div>
          
          </ng-template> 


      </owl-carousel-o>
    </div>
    <!-- <div class="col-md-4">
      <div class="py-5 rounded shadow" style="background: url('assets/images/shop/fea1.jpg') top center;">
        <div class="p-4">
          <h3>Medicines <br> </h3>
          <a href="javascript:void(0)" class="btn btn-sm btn-soft-primary mt-2">Place Order</a>
        </div>
      </div>
    </div>
    <div class="col-md-4 mt-4 pt-2 mt-sm-0 pt-sm-0">
      <div class="py-5 rounded shadow" style="background: url('assets/images/shop/fea2.jpg') top center;">
        <div class="p-4">
          <h3>Summer <br> Collection</h3>
          <a href="javascript:void(0)" class="btn btn-sm btn-soft-primary mt-2">Place Order</a>
        </div>
      </div>
    </div>

    <div class="col-md-4 mt-4 pt-2 mt-sm-0 pt-sm-0">
      <div class="py-5 rounded shadow" style="background: url('assets/images/shop/fea3.jpg') top center;">
        <div class="p-4">
          <h3>Summer <br> Collection</h3>
          <a href="javascript:void(0)" class="btn btn-sm btn-soft-primary mt-2">Place Order</a>
        </div>
      </div>
    </div> -->
  </div>
  <!--end row-->
</div>
<!--Ads End-->
<section class="section">

  <!--end container-->

  <div class="container mt-100 mt-60 text-center">
    <div class="row align-items-end mb-4 pb-2">
      <div class="col-md-12">
        <div class="section-title text-center text-md-left">
          <h6 class="text-primary">Find Out Most</h6>
          <h4 class="title mb-4">Popular Destination</h4>
          <span class="text-muted mb-0 para-desc">Start working with <span
              class="text-primary font-weight-bold">xoomship</span> that can provide everything you need to generate
            awareness, drive traffic, connect.</span>
        </div>
      </div>
      <!--end col-->

       <!--<div class="col-md-4 mt-4 mt-sm-0">
        <div class="text-center text-md-right">
          <a href="javascript:void(0)" class="text-primary h6">See More
            <i-feather name="arrow-right" class="fea icon-sm"></i-feather>
          </a>
        </div>
      </div> -->
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 mt-4 pt-2">
        <owl-carousel-o id="six-slide" [options]="customOptions" class="owl-stage-outer">
          <ng-template carouselSlide>
            <div class="popular-tour rounded-md position-relative overflow-hidden mx-3">
              <img src="assets/images/travel/dubai.jpg" class="img-fluid" alt="">
              <div class="overlay-work bg-dark"></div>
              <div class="content">
                <a href="javascript:void(0)" class="title text-white h4 title-dark">Dubai</a>
              </div>
            </div>
            <!--end tour post-->
          </ng-template>
          <ng-template carouselSlide>
            <div class="popular-tour rounded-md position-relative overflow-hidden mx-3">
              <img src="assets/images/travel/thailand.jpg" class="img-fluid" alt="">
              <div class="overlay-work bg-dark"></div>
              <div class="content">
                <a href="javascript:void(0)" class="title text-white h4 title-dark">Thailand</a>
              </div>
            </div>
            <!--end tour post-->
          </ng-template>
          <ng-template carouselSlide>
            <div class="popular-tour rounded-md position-relative overflow-hidden mx-3">
              <img src="assets/images/travel/india.jpg" class="img-fluid" alt="">
              <div class="overlay-work bg-dark"></div>
              <div class="content">
                <a href="javascript:void(0)" class="title text-white h4 title-dark">India</a>
              </div>
            </div>
            <!--end tour post-->
          </ng-template>
          <ng-template carouselSlide>
            <div class="popular-tour rounded-md position-relative overflow-hidden mx-3">
              <img src="assets/images/travel/london.jpg" class="img-fluid" alt="">
              <div class="overlay-work bg-dark"></div>
              <div class="content">
                <a href="javascript:void(0)" class="title text-white h4 title-dark">Londan</a>
              </div>
            </div>
            <!--end tour post-->
          </ng-template>
          <ng-template carouselSlide>
            <div class="popular-tour rounded-md position-relative overflow-hidden mx-3">
              <img src="assets/images/travel/paris.jpg" class="img-fluid" alt="">
              <div class="overlay-work bg-dark"></div>
              <div class="content">
                <a href="javascript:void(0)" class="title text-white h4 title-dark">Paris</a>
              </div>
            </div>
            <!--end tour post-->
          </ng-template>
          <ng-template carouselSlide>
            <div class="popular-tour rounded-md position-relative overflow-hidden mx-3">
              <img src="assets/images/travel/singapore.jpg" class="img-fluid" alt="">
              <div class="overlay-work bg-dark"></div>
              <div class="content">
                <a href="javascript:void(0)" class="title text-white h4 title-dark">Singapore</a>
              </div>
            </div>
            <!--end tour post-->
          </ng-template>
        </owl-carousel-o>
        <!-- </div> -->
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
</section>


<section class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">What We Do ?</h4>
          <p class="text-muted para-desc mx-auto mb-0">
            We ship parcels /packages from india to anywhere in the world .Our services is fast, reliable and
            trustworthy.
            You can track your shipments online in
            <span class="text-primary font-weight-bold">Details</span> and also check status via our apps.
          </p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row">
      <div class="col-md-4 col-12">
        <div class="features mt-5">
          <div class="image position-relative d-inline-block">
            <img src="assets/images/icon/PP2.png" class="avatar avatar-small" alt="" />
          </div>

          <div class="content mt-4">
            <h4 class="title-2">Pickup and Delivery</h4>
            <p class="text-muted mb-0">
              We pickup package from your door step and we deliver to the destination
              Using a range of services. We also do special packaging for items that need
              International standard. With a nominal fee we will make sure your valuable
              Items reach to the destination safely with out any damages.
            </p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-4 col-12 mt-5">
        <div class="features">
          <div class="image position-relative d-inline-block">
            <img src="assets/images/icon/RealTime.png" class="avatar avatar-small" alt="" />
          </div>

          <div class="content mt-4">
            <h4 class="title-2">Online Realtime Tracking</h4>
            <p class="text-muted mb-0">
              We provide you the ability to track each and every stage of your package while in
              Transit to the destination. Realtime occurs when the package is either picked up or
              Reached a certain destination.
            </p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-4 col-12 mt-5">
        <div class="features">
          <div class="image position-relative d-inline-block">
            <img src="assets/images/icon/24hrs.png" class="avatar avatar-small" alt="" />
          </div>

          <div class="content mt-4">
            <h4 class="title-2">We operate 24/7</h4>
            <p class="text-muted mb-0">
              Our daily schedule is published including the timings that we operate. If you need
              Help in our services, you can reach to our customer care hotline and speak to a representative.
            </p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-4 col-12 mt-5">
        <div class="features">
          <div class="image position-relative d-inline-block">
            <img src="assets/images/icon/packaging.png" class="avatar avatar-small" alt="" />
          </div>

          <div class="content mt-4">
            <h4 class="title-2">Special packaging </h4>
            <p class="text-muted mb-0">
              Our packaging material includes boxes, tape, packing paper, bio degradable
              Envelopes to ensure no leakage or spillover happens while transit. We follow
              International standards based on the requirements.
            </p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-4 col-12 mt-5">
        <div class="features">
          <div class="image position-relative d-inline-block">
            <img src="assets/images/icon/customs-officer.png" class="avatar avatar-small" alt="" />
          </div>

          <div class="content mt-4">
            <h4 class="title-2">Custom Services</h4>
            <p class="text-muted mb-0">
              For any occasions or party’s we provide several services for your convenience.
              Fill our Custom service form and send us the details.
              Our representative will get in touch with you generally with in 24hrs.
            </p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-4 col-12 mt-5">
        <div class="features">
          <div class="image position-relative d-inline-block">
            <img src="assets/images/icon/mobile-app.png" class="avatar avatar-small" alt="" />
          </div>

          <div class="content mt-4">
            <h4 class="title-2">Convenience using Mobile Apps</h4>
            <p class="text-muted mb-0">
              Using our mobile apps you can order pre-paid shipping and also do schedule package pickup.
              Best of all payments can be done via your mobile phone.
            </p>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->


<section class="section">
  <!-- About Start -->
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-6">
        <div class="row align-items-center">
          <div class="col-lg-6 col-6 mt-4 mt-lg-0 pt-2 pt-lg-0">
            <div class="card work-container work-modern overflow-hidden rounded border-0 shadow-lg">
              <div class="card-body p-0">
                <img src="assets/images/course/online/1 (1).png" class="img-fluid" alt="work-image">
                <div class="overlay-work bg-dark"></div>
                <div class="content">
                  <a href="javascript:void(0)" class="title text-white d-block font-weight-bold">Web Development</a>
                  <small class="text-light">IT & Software</small>
                </div>
              </div>
            </div>

            <!-- <div class="mt-4 pt-2 text-right d-none d-md-block">
              <a href="javascript:void(0)" class="btn btn-primary">See More
                <i-feather name="chevron-right" class="fea icon-sm"></i-feather>
              </a>
            </div> -->
          </div>
          <!--end col-->

          <div class="col-lg-6 col-6">
            <div class="row">
              <div class="col-lg-12 col-md-12 mt-4 mt-lg-0 pt-2 pt-lg-0">
                <div class="card work-container work-modern overflow-hidden rounded border-0 shadow-lg">
                  <div class="card-body p-0">
                    <img src="assets/images/course/online/2 (1).png" class="img-fluid" alt="work-image">
                    <div class="overlay-work bg-dark"></div>
                    <div class="content">
                      <a href="javascript:void(0)" class="title text-white d-block font-weight-bold">Michanical
                        Engineer</a>
                      <small class="text-light">Engineering</small>
                    </div>
                  </div>
                </div>
              </div>
              <!--end col-->

              <div class="col-lg-12 col-md-12 mt-4 pt-2">
                <div class="card work-container work-modern overflow-hidden rounded border-0 shadow-lg">
                  <div class="card-body p-0">
                    <img src="assets/images/course/online/3 (1).png" class="img-fluid" alt="work-image">
                    <div class="overlay-work bg-dark"></div>
                    <div class="content">
                      <a href="javascript:void(0)" class="title text-white d-block font-weight-bold">Chartered
                        Accountant</a>
                      <small class="text-light">C.A.</small>
                    </div>
                  </div>
                </div>
              </div>
              <!--end col-->
            </div>
            <!--end row-->
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end col-->

      <div class="col-lg-6 col-md-6 mt-4 mt-lg-0 pt- pt-lg-0">
        <div class="ml-lg-4">
          <div class="section-title mb-4 pb-2">
            <h4 class="title mb-4">About Our Story</h4>
            <p class="text-muted para-desc"><span class="text-primary font-weight-bold">Xoomship </span> mission is to
              be the poineers in delivering packages from India to anywhere in the world, we serve its customers with
              shipping thier items with highest quality and faster deliveries.
              As we know those days when we didn't have internet or telephones as a means to communicate and postal
              service was the only
              option for communication.</p>
            <p class="text-muted para-desc mb-0">
              The feeling of getting a package from your loved ones cant be explained. We take it a pride to make every
              customer
              happy by providing them the best of the best service in delivering the packages. Be it documents or
              anything else you can be assured to send them to your loved ones. Xoomship offers various
              solutions in which customers can avail and process their needs.</p>
          </div>

          <h5>Our Branches :</h5>

          <div class="row">
            <!-- <div class="col-md-6 col-12 mt-4">
              <div class="d-flex align-items-center">
                <i-feather name="map-pin" class="fea icon-md text-muted"></i-feather>
                <div class="content ml-2">
                  <h5 class="mb-0"><a
                      href="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39206.002432144705!2d-95.4973981212445!3d29.709510002925988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c16de81f3ca5%3A0xf43e0b60ae539ac9!2sGerald+D.+Hines+Waterwall+Park!5e0!3m2!1sen!2sin!4v1566305861440!5m2!1sen!2sin"
                      class="video-play-icon text-primary">U.S.A.</a></h5>
                  <p class="text-muted mb-0">Long Street, WS, US</p>
                </div>
              </div>
            </div> -->

            <!-- <div class="col-md-6 col-12 mt-4">
              <div class="d-flex align-items-center">
                <i-feather name="map-pin" class="fea icon-md text-muted"></i-feather>
                <div class="content ml-2">
                  <h5 class="mb-0"><a
                      href="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39206.002432144705!2d-95.4973981212445!3d29.709510002925988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c16de81f3ca5%3A0xf43e0b60ae539ac9!2sGerald+D.+Hines+Waterwall+Park!5e0!3m2!1sen!2sin!4v1566305861440!5m2!1sen!2sin"
                      class="video-play-icon text-primary">China</a></h5>
                  <p class="text-muted mb-0">Wuhan, China</p>
                </div>
              </div>
            </div>-->

            <div class="col-md-6 col-12 mt-4">
              <div class="d-flex align-items-center">
                <i-feather name="map-pin" class="fea icon-md text-muted"></i-feather>
                <div class="content ml-2">
                  <h5 class="mb-0">India
                    <!-- <a
                      href="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39206.002432144705!2d-95.4973981212445!3d29.709510002925988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c16de81f3ca5%3A0xf43e0b60ae539ac9!2sGerald+D.+Hines+Waterwall+Park!5e0!3m2!1sen!2sin!4v1566305861440!5m2!1sen!2sin"
                      class="video-play-icon text-primary"></a> -->
                    </h5>
                  <p class="text-muted mb-0">{{service_zones_ist}}</p>
                </div>
              </div>
            </div>
          </div>

          <div class="watch-video mt-4 pt-2">


            <ng-template #contentmodal let-modal>
              <div class="modal-header">
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <youtube-player [videoId]="'jNTZpfXYJa4'" height="450" width="780">
                </youtube-player>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <!--end container-->
  <!-- About End -->

  <ng-template #content let-modal >
    <div class="modal-header">
      <h4 class="card-title text-center">Login</h4>
      <!-- <button type="button"  #closebutton class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">
          &times;
        </span>
      </button> -->
    </div>
    <div class="modal-body">
      <div class="col-lg-11 col-md-12">
        <div class="card login-page shadow rounded border-0">
          <div class="card-body">
            
            <form class="login-form mt-4"  [formGroup]="loginForm"  (ngSubmit)="submit()">
              <div class="row">
                <div class="col-lg-12">
                  <div class="mb-3">
                    <label class="form-label">Your Email <span class="text-danger">*</span></label>
                    <div class="form-icon position-relative">
                      <i-feather name="user" class="fea icon-sm icons"></i-feather>
                      <input type="email" class="form-control ps-5" placeholder="Email" formControlName="email" name="email" required="">
                    </div>
                  </div>
                </div>
                <!--end col-->

                <div class="col-lg-12">
                  <div class="mb-3">
                    <label class="form-label">Password <span class="text-danger">*</span></label>
                    <div class="form-icon position-relative">
                      <i-feather name="key" class="fea icon-sm icons"></i-feather>
                      <input type="password" class="form-control ps-5" formControlName="password" placeholder="Password" required="">
                    </div>
                  </div>
                </div>
                <!--end col-->

                <div class="col-lg-12">
                  <div class="d-flex justify-content-between">
                    <div class="mb-3">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                        <label class="form-check-label" for="flexCheckDefault">Remember me</label>
                      </div>
                    </div>
                    <p class="forgot-pass mb-0"><a routerLink="/auth-re-password" class="text-dark fw-bold">Forgot
                        password ?</a></p>
                  </div>
                </div>
                <!--end col-->

                <div class="col-lg-12 mb-0">
                  <div class="d-grid">
                    <button class="btn btn-primary">Sign in</button>
                  </div>
                </div>
                <!--end col-->

             
                <!--end col-->

            
                <!--end col-->
              </div>
              <!--end row-->
            </form>
          </div>
        </div>
        <!---->
      </div>
    </div>
    <!-- <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Save</button>
    </div> -->
  </ng-template>

  <ng-template #content1 let-modal class="border-0">
    
    <div  style="height:50%;width:100%; text-align:center;margin:0; padding:0;background-color:#7f7f7f;">
      <i class="uil uil-times fs-4 text-dark" (click)="modal.dismiss('Cross click')"></i>
      <ng-image-slider #nav
        [images]="imageObject"
        [infinite]="false"
        [autoSlide]="1"
        [imageSize]="{width: '100%', height: '400px'}"
        slideImage="1" (imageClick)="imageClickHandler($event)">
     </ng-image-slider>
     </div>
   
  </ng-template>






</section>



<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-soft-primary back-to-top" id="back-to-top"
  [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<app-download-app></app-download-app>
<!-- Back to top -->


<div class="container">

  <!-- The Modal -->
  <div class="modal fade" id="myModal3">
    <div class="modal-dialog modal-md" style="max-width:600px">
      <div class="modal-content">
        <div class="col-xl-12 mb-xl-10">
        
          <div class="card card-flush h-lg-100">
         
            <div class="card-header pt-7">
         
              <h3 class="card-title align-items-start flex-column">
                <span class="card-label fw-bold text-gray-800">Shipment History</span>
               
              </h3>
           
              <div class="card-toolbar">
                <a href="#" class="btn btn-sm btn-light" data-bs-toggle='tooltip' data-bs-dismiss='click' data-bs-custom-class="tooltip-inverse" title="Logistics App is coming soon">View All</a>
              </div>
             
            </div>
           
            <div class="card-body">
              
              <ul class="nav nav-pills nav-pills-custom row position-relative mx-0 mb-9">
            
                <li class="nav-item col-4 mx-0 p-0">
         
                  <a class="nav-link active d-flex justify-content-center w-100 border-0 h-100" data-bs-toggle="pill" href="#kt_list_widget_10_tab_1">
                
                    <span class="nav-text text-gray-800 fw-bold fs-6 mb-3">Booked</span>
                    
                    <span class="bullet-custom position-absolute z-index-2 bottom-0 w-100 h-4px bg-primary rounded"></span>
                   
                  </a>
                
                </li>
               
                <li class="nav-item col-4 mx-0 px-0">
                 
                  <a class="nav-link d-flex justify-content-center w-100 border-0 h-100" data-bs-toggle="pill" href="#kt_list_widget_10_tab_2">
                    
                    <span class="nav-text text-gray-800 fw-bold fs-6 mb-3">Pickup</span>
                  
                    <span class="bullet-custom position-absolute z-index-2 bottom-0 w-100 h-4px bg-primary rounded"></span>
                 
                  </a>
                
                </li>
               
                <li class="nav-item col-4 mx-0 px-0">
                  
                  <a class="nav-link d-flex justify-content-center w-100 border-0 h-100" data-bs-toggle="pill" href="#kt_list_widget_10_tab_3">
                   
                    <span class="nav-text text-gray-800 fw-bold fs-6 mb-3">Pick</span>
                  
                    <span class="bullet-custom position-absolute z-index-2 bottom-0 w-100 h-4px bg-primary rounded"></span>
                   
                  </a>
                 
                </li>
                <span class="position-absolute z-index-1 bottom-0 w-100 h-4px bg-light rounded"></span>
                
              </ul>
            
              <div class="tab-content">
               
                <div class="tab-pane fade show active" id="kt_list_widget_10_tab_1">
                 
                  <div class="m-0">
                   
                    <div class="d-flex align-items-sm-center mb-5">
                     
                      <div class="symbol symbol-45px me-4">
                        <span class="symbol-label bg-primary">
                          <i class="ki-duotone ki-ship text-inverse-primary fs-1">
                            <span class="path1"></span>
                            <span class="path2"></span>
                            <span class="path3"></span>
                          </i>
                        </span>
                      </div>
                     
                      <div class="d-flex align-items-center flex-row-fluid flex-wrap">
                        <div class="flex-grow-1 me-2">
                          <a href="#" class="text-gray-500 fs-6 fw-semibold">Ship Freight</a>
                          <span class="text-gray-800 fw-bold d-block fs-4">#5635-342808</span>
                        </div>
                        <span class="badge badge-lg badge-light-success fw-bold my-2 fs-8">Delivered</span>
                      </div>
                     
                    </div>
                 
                    <div class="timeline">
                    
                      <div class="timeline-item align-items-center mb-7">
                    
                        <div class="timeline-line mt-1 mb-n6 mb-sm-n7"></div>
                     
                        <div class="timeline-icon">
                          <i class="ki-duotone ki-cd fs-2 text-danger">
                            <span class="path1"></span>
                            <span class="path2"></span>
                          </i>
                        </div>
                        
                        <div class="timeline-content m-0">
                        
                          <span class="fs-6 text-gray-500 fw-semibold d-block">Messina Harbor</span>
                         
                          <span class="fs-6 fw-bold text-gray-800">Sicily, Italy</span>
                        
                        </div>
                       
                      </div>
                     
                      <div class="timeline-item align-items-center">
                       
                        <div class="timeline-line"></div>
                       
                        <div class="timeline-icon">
                          <i class="ki-duotone ki-geolocation fs-2 text-info">
                            <span class="path1"></span>
                            <span class="path2"></span>
                          </i>
                        </div>
                     
                        <div class="timeline-content m-0">
                          
                          <span class="fs-6 text-gray-500 fw-semibold d-block">Hektor Container Hotel</span>
                          
                          <span class="fs-6 fw-bold text-gray-800">Tallin, EST</span>
                        
                        </div>
                     
                      </div>
                      
                    </div>
                 
                  </div>
                
                  <div class="separator separator-dashed my-6"></div>
                  
                  <div class="m-0">
                    
                    <div class="d-flex align-items-sm-center mb-5">
                    
                      <div class="symbol symbol-45px me-4">
                        <span class="symbol-label bg-primary">
                          <i class="ki-duotone ki-truck text-inverse-primary fs-1">
                            <span class="path1"></span>
                            <span class="path2"></span>
                            <span class="path3"></span>
                            <span class="path4"></span>
                            <span class="path5"></span>
                          </i>
                        </span>
                      </div>
                     
                      <div class="d-flex align-items-center flex-row-fluid flex-wrap">
                        <div class="flex-grow-1 me-2">
                          <a href="#" class="text-gray-500 fs-6 fw-semibold">Truck Freight</a>
                          <span class="text-gray-800 fw-bold d-block fs-4">#0066-954784</span>
                        </div>
                        <span class="badge badge-lg badge-light-primary fw-bold my-2 fs-8">Shipping</span>
                      </div>
                     
                    </div>
                
                    <div class="timeline">
                    
                      <div class="timeline-item align-items-center mb-7">
                     
                        <div class="timeline-line mt-1 mb-n6 mb-sm-n7"></div>
                       
                        <div class="timeline-icon">
                          <i class="ki-duotone ki-cd fs-2 text-danger">
                            <span class="path1"></span>
                            <span class="path2"></span>
                          </i>
                        </div>
                        
                        <div class="timeline-content m-0">
                         
                          <span class="fs-6 text-gray-500 fw-semibold d-block">Haven van Rotterdam</span>
                         
                          <span class="fs-6 fw-bold text-gray-800">Rotterdam, Netherlands</span>
                       
                        </div>
                        
                      </div>
                    
                      <div class="timeline-item align-items-center">
                        
                        <div class="timeline-line"></div>
                       
                        <div class="timeline-icon">
                          <i class="ki-duotone ki-geolocation fs-2 text-info">
                            <span class="path1"></span>
                            <span class="path2"></span>
                          </i>
                        </div>
                        
                        <div class="timeline-content m-0">
                         
                          <span class="fs-6 text-gray-500 fw-semibold d-block">Forest-Midi</span>
                         
                          <span class="fs-6 fw-bold text-gray-800">Brussels, Belgium</span>
                        
                        </div>
                       
                      </div>
                      
                    </div>
                    <!--end::Timeline-->
                  </div>
                  <!--end::Item-->
                  <!--begin::Separator-->
                  <div class="separator separator-dashed my-6"></div>
                  <!--end::Separator-->
                  <!--begin::Item-->
                  <div class="m-0">
                    <!--begin::Wrapper-->
                    <div class="d-flex align-items-sm-center mb-5">
                      <!--begin::Symbol-->
                      <div class="symbol symbol-45px me-4">
                        <span class="symbol-label bg-primary">
                          <i class="ki-duotone ki-delivery text-inverse-primary fs-1">
                            <span class="path1"></span>
                            <span class="path2"></span>
                            <span class="path3"></span>
                            <span class="path4"></span>
                            <span class="path5"></span>
                          </i>
                        </span>
                      </div>
                      <!--end::Symbol-->
                      <!--begin::Section-->
                      <div class="d-flex align-items-center flex-row-fluid flex-wrap">
                        <div class="flex-grow-1 me-2">
                          <a href="#" class="text-gray-500 fs-6 fw-semibold">Delivery Freight</a>
                          <span class="text-gray-800 fw-bold d-block fs-4">#5635-342808</span>
                        </div>
                        <span class="badge badge-lg badge-light-success fw-bold my-2 fs-8">Delivered</span>
                      </div>
                      <!--end::Section-->
                    </div>
                    <!--end::Wrapper-->
                    <!--begin::Timeline-->
                    <div class="timeline">
                      <!--begin::Timeline item-->
                      <div class="timeline-item align-items-center mb-7">
                        <!--begin::Timeline line-->
                        <div class="timeline-line mt-1 mb-n6 mb-sm-n7"></div>
                        <!--end::Timeline line-->
                        <!--begin::Timeline icon-->
                        <div class="timeline-icon">
                          <i class="ki-duotone ki-cd fs-2 text-danger">
                            <span class="path1"></span>
                            <span class="path2"></span>
                          </i>
                        </div>
                        <!--end::Timeline icon-->
                        <!--begin::Timeline content-->
                        <div class="timeline-content m-0">
                          <!--begin::Title-->
                          <span class="fs-6 text-gray-500 fw-semibold d-block">Mina St - Zayed Port</span>
                          <!--end::Title-->
                          <!--begin::Title-->
                          <span class="fs-6 fw-bold text-gray-800">Abu Dhabi, UAE</span>
                          <!--end::Title-->
                        </div>
                        <!--end::Timeline content-->
                      </div>
                      <!--end::Timeline item-->
                      <!--begin::Timeline item-->
                      <div class="timeline-item align-items-center">
                        <!--begin::Timeline line-->
                        <div class="timeline-line"></div>
                        <!--end::Timeline line-->
                        <!--begin::Timeline icon-->
                        <div class="timeline-icon">
                          <i class="ki-duotone ki-geolocation fs-2 text-info">
                            <span class="path1"></span>
                            <span class="path2"></span>
                          </i>
                        </div>
                        <!--end::Timeline icon-->
                        <!--begin::Timeline content-->
                        <div class="timeline-content m-0">
                          <!--begin::Title-->
                          <span class="fs-6 text-gray-500 fw-semibold d-block">27 Drydock Boston</span>
                          <!--end::Title-->
                          <!--begin::Title-->
                          <span class="fs-6 fw-bold text-gray-800">Boston, USA</span>
                          <!--end::Title-->
                        </div>
                        <!--end::Timeline content-->
                      </div>
                      <!--end::Timeline item-->
                    </div>
                    <!--end::Timeline-->
                  </div>
                  <!--end::Item-->
                  <!--begin::Separator-->
                  <div class="separator separator-dashed my-6"></div>
                  <!--end::Separator-->
                  <!--begin::Item-->
                  <div class="m-0">
                    <!--begin::Wrapper-->
                    <div class="d-flex align-items-sm-center mb-5">
                      <!--begin::Symbol-->
                      <div class="symbol symbol-45px me-4">
                        <span class="symbol-label bg-primary">
                          <i class="ki-duotone ki-airplane-square text-inverse-primary fs-1">
                            <span class="path1"></span>
                            <span class="path2"></span>
                          </i>
                        </span>
                      </div>
                      <!--end::Symbol-->
                      <!--begin::Section-->
                      <div class="d-flex align-items-center flex-row-fluid flex-wrap">
                        <div class="flex-grow-1 me-2">
                          <a href="#" class="text-gray-500 fs-6 fw-semibold">Plane Freight</a>
                          <span class="text-gray-800 fw-bold d-block fs-4">#5635-342808</span>
                        </div>
                        <span class="badge badge-lg badge-light-danger fw-bold my-2 fs-8">Delayed</span>
                      </div>
                      <!--end::Section-->
                    </div>
                    <!--end::Wrapper-->
                    <!--begin::Timeline-->
                    <div class="timeline">
                      <!--begin::Timeline item-->
                      <div class="timeline-item align-items-center mb-7">
                        <!--begin::Timeline line-->
                        <div class="timeline-line mt-1 mb-n6 mb-sm-n7"></div>
                        <!--end::Timeline line-->
                        <!--begin::Timeline icon-->
                        <div class="timeline-icon">
                          <i class="ki-duotone ki-cd fs-2 text-danger">
                            <span class="path1"></span>
                            <span class="path2"></span>
                          </i>
                        </div>
                        <!--end::Timeline icon-->
                        <!--begin::Timeline content-->
                        <div class="timeline-content m-0">
                          <!--begin::Title-->
                          <span class="fs-6 text-gray-500 fw-semibold d-block">KLM Cargo</span>
                          <!--end::Title-->
                          <!--begin::Title-->
                          <span class="fs-6 fw-bold text-gray-800">Schipol Airport, Amsterdam</span>
                          <!--end::Title-->
                        </div>
                        <!--end::Timeline content-->
                      </div>
                      <!--end::Timeline item-->
                      <!--begin::Timeline item-->
                      <div class="timeline-item align-items-center">
                        <!--begin::Timeline line-->
                        <div class="timeline-line"></div>
                        <!--end::Timeline line-->
                        <!--begin::Timeline icon-->
                        <div class="timeline-icon">
                          <i class="ki-duotone ki-geolocation fs-2 text-info">
                            <span class="path1"></span>
                            <span class="path2"></span>
                          </i>
                        </div>
                        <!--end::Timeline icon-->
                        <!--begin::Timeline content-->
                        <div class="timeline-content m-0">
                          <!--begin::Title-->
                          <span class="fs-6 text-gray-500 fw-semibold d-block">Singapore Cargo</span>
                          <!--end::Title-->
                          <!--begin::Title-->
                          <span class="fs-6 fw-bold text-gray-800">Changi Airport, Singapore</span>
                          <!--end::Title-->
                        </div>
                        <!--end::Timeline content-->
                      </div>
                      <!--end::Timeline item-->
                    </div>
                    <!--end::Timeline-->
                  </div>
                  <!--end::Item-->
                </div>
              
              </div>
              <!--end::Tab Content-->
            </div>
            <!--end: Card Body-->
          </div>
          <!--end::List widget 10-->
        </div>
        <!-- <div class="modal-body" style="padding: 20px; padding-top:20px">
          <div class="col-md-12 row">
            <span style="font-size:20px;font-weight:bold">Order Details</span><br/><br/>
            <span>Status</span><br/>
            <span style="font-size:17px;font-weight:bold">Booked</span><br/>

            <span>BookingId</span><br/>
            <span style="font-size:17px;font-weight:bold">Xodfsd123444</span><br/>

            <span>Sender Address</span><br/>
            <span style="font-size:17px;font-weight:bold">Xodfsd123444 ddfgdfgdfdf dfbdfbd</span><br/>

            <span>Receiver Address</span><br/>
            <span style="font-size:17px;font-weight:bold">Xodfsd123444 dfdfgdfgdfgvb dfbdfbdfgb</span><br/>
          </div>
        </div> -->


      </div>
    </div>
  </div>

</div>


import { formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Global } from 'src/app/global';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})

/***
 * Footer Component
 */
export class FooterComponent implements OnInit {

  @Input() footerVariant: string;
  @Input() hideFooter: boolean;

  newsForm!: UntypedFormGroup;
  submitted = false;
  service_zones_ist:any;

  //Get Year
  year = new Date().getFullYear();
  list:any=[];
  data1:any;
  href:string='';
  constructor(private formBuilder: UntypedFormBuilder,private appService: AppService,
    private httpClient: HttpClient,private router: Router) {
      this.href = this.router.url
     }

  ngOnInit(): void {
    /**
     * Form Validatyion
     */
     //this. getLogin();
     this.getList();
     this.getServiceZonesLookup()
    this.newsForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
    });

  
  }

  // convenience getter for easy access to form fields
  get form() { return this.newsForm.controls; }

  /**
   * On submit form
   */
  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.newsForm.invalid) {
      return;
    }
  }

  getList(){
    
    let obj = {
      "userId": "admin@finobooks.com",
      "cid": 1046
      
    }
    debugger
    this.appService.getDataById(Global.WebLinkedLookup_EndPoint +'GetCompanyWebPolicyLookup/',1046 +'/'+0 )
    .subscribe({
      next: (res: any) => {
        debugger;
        this.list = res;
     
      },
      error: (error) => {
        console.log(error);
      }
    })

    
  }

//  getLogin()
//  {
//   this.appService.login('admin@finobooks.com','OCTO*54321')
//   .subscribe(data => {
//   debugger
    
//   });
//  }

getServiceZonesLookup(): void {
  this.appService.getJson(Global.WebLinkedLookup_EndPoint + 'GetBranchLocationLookup/' + 1046).subscribe(result => {
    if (result) {
      debugger
      this.service_zones_ist = result[0].address;
    }
  });
}
 
 routerClick(titleId:any,titleName:string)
 {
  
  let nameInRoute: String = titleName.trim().split(' ').join('-');
  if(titleName == 'Terms & Conditions')
  {
    this.router.navigate([nameInRoute+'/'+titleId]);
  }
  if(titleName == 'Privacy Policy')
  {
    this.router.navigate([nameInRoute+'/'+titleId]);
  }
  if(titleName == ' Cancel & Refund policy')
  {
    this.router.navigate([nameInRoute+'/'+titleId]);
  }
  if(titleName == 'Prohibited List')
  {
    this.router.navigate([nameInRoute+'/'+titleId]);
  }
 }

}


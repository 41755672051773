import { Component, Injectable } from '@angular/core';
import { Router, NavigationEnd, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import Swal from 'sweetalert2';
import { AppService, AuthModel } from './services/app.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'landrick-angular';
  

  constructor(private router: Router) {
    /**
     * Unicons icon refreshed on route change.
     */
    this.router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        // window['Unicons']();
      }

      if (!(event instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });

   
  }
}





@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(private authService: AppService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    debugger;
    const currentUser = this.authService.currentUserValue;
    if (currentUser) {
      if (!this.authService.isTokenExpired()) {
        // logged in so return true
        return true;
      } else
        Swal.fire("Information!", "Your session is expired.continue Login");
    }
    // not logged in so redirect to login page with the return url
    this.authService.logout();
    return false;
  }
}





<!-- Hero Start -->
<section class="home-slider position-relative" id="home">
  <div id="carouselExampleInterval" class="carousel slide" data-bs-ride="carousel">
    <div class="carousel-inner">
      <owl-carousel-o id="customer-testi" [options]="customOptions">
        <ng-template carouselSlide>
          <div class="slide-inner slide-bg-image d-flex align-items-center vh-100" style="background: center center;"
          style="background-image:url('assets/images/course/bg01.jpg')">
          <div class="bg-overlay"></div>
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-12 text-center">
                <div class="title-heading text-white mt-4">
                  <h1 class="display-4 title-dark fw-bold mb-3">You Can Learn Anything With Us</h1>
                  <p class="para-desc para-dark mx-auto text-light">Launch your campaign and benefit from our expertise on
                    designing and managing conversion centered bootstrap v5 html page.</p>
                  <div class="mt-4">
                    <a [ngxScrollTo]="'#courses'" class="btn btn-primary mt-2"><i class="uil uil-book-open"></i> Find
                      Courses</a>
                  </div>
                </div>
              </div>
              <!--end col-->
            </div>
            <!--end row-->
          </div>
          <!--end container-->
        </div><!-- end slide-inner -->
          <!--end slide-->
        </ng-template>
        <ng-template carouselSlide>
          <div class="slide-inner slide-bg-image d-flex align-items-center vh-100" style="background: center center;"
        style="background-image:url('assets/images/course/bg02.jpg')">
        <div class="bg-overlay"></div>
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-12 text-center">
              <div class="title-heading text-white mt-4">
                <h1 class="display-4 title-dark fw-bold mb-3">Better Education For A Better Future </h1>
                <p class="para-desc text-white-50 mx-auto">Launch your campaign and benefit from our expertise on
                  designing and managing conversion centered bootstrap v5 html page.</p>
                <div class="mt-4">
                  <a [ngxScrollTo]="'#admission'" class="btn btn-primary mt-2"><i class="uil uil-graduation-cap"></i>
                    Admission Now</a>
                </div>
              </div>
            </div>
            <!--end col-->
          </div>
          <!--end row-->
        </div>
        <!--end container-->
      </div><!-- end slide-inner -->
          <!--end slide-->
        </ng-template>
        <ng-template carouselSlide>
          <div class="slide-inner slide-bg-image d-flex align-items-center vh-100" style="background: center center;"
        style="background-image:url('assets/images/course/bg03.jpg')">
        <div class="bg-overlay"></div>
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-12 text-center">
              <div class="title-heading text-white mt-4">
                <h1 class="display-4 title-dark fw-bold mb-3">Education Is The Key of Success</h1>
                <p class="para-desc para-dark mx-auto text-light">Launch your campaign and benefit from our expertise on
                  designing and managing conversion centered bootstrap v5 html page.</p>
                <div class="mt-4">
                  <a [ngxScrollTo]="'#instructors'" class="btn btn-primary mt-2"><i class="uil uil-user"></i>
                    Instructors</a>
                </div>
              </div>
            </div>
            <!--end col-->
          </div>
          <!--end row-->
        </div>
        <!--end container-->
      </div><!-- end slide-inner -->
          <!--end slide-->
        </ng-template>
      </owl-carousel-o>
    </div>
  </div>
</section>
<!-- Hero End -->

<!-- FEATURES START -->
<section class="section">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="features-absolute">
          <div class="row">
            <div class="col-md-4">
              <div class="card border-0 text-center features feature-clean feature-primary course-feature p-4 overflow-hidden shadow">
                <div class="icons text-primary text-center mx-auto">
                  <i class="uil uil-file d-block rounded h3 mb-0"></i>
                </div>
                <div class="card-body p-0 mt-4">
                  <a href="javascript:void(0)" class="title h5 text-dark"> Unlimited Access</a>
                  <p class="text-muted mt-2">It is a long established fact that a reader will be of a page reader will
                    be of a page when looking at its layout. </p>
                  <a href="javascript:void(0)" class="text-primary read-more">Read More <i
                      class="uil uil-angle-right-b align-middle"></i></a>
                  <i class="uil uil-file text-primary full-img"></i>
                </div>
              </div>
            </div>
            <!--end col-->

            <div class="col-md-4 mt-4 pt-2 mt-sm-0 pt-sm-0">
              <div class="card border-0 text-center features feature-clean feature-primary course-feature p-4 overflow-hidden shadow">
                <div class="icons text-primary text-center mx-auto">
                  <i class="uil uil-graduation-cap d-block rounded h3 mb-0"></i>
                </div>
                <div class="card-body p-0 mt-4">
                  <a href="javascript:void(0)" class="title h5 text-dark"> Our Courses</a>
                  <p class="text-muted mt-2">It is a long established fact that a reader will be of a page when reader
                    will be of a page looking at its layout. </p>
                  <a href="javascript:void(0)" class="text-primary read-more">Read More <i
                      class="uil uil-angle-right-b align-middle"></i></a>
                  <i class="uil uil-graduation-cap text-primary full-img"></i>
                </div>
              </div>
            </div>
            <!--end col-->

            <div class="col-md-4 mt-4 pt-2 mt-sm-0 pt-sm-0">
              <div class="card border-0 text-center features feature-clean feature-primary course-feature p-4 overflow-hidden shadow">
                <div class="icons text-primary text-center mx-auto">
                  <i class="uil uil-book-reader d-block rounded h3 mb-0"></i>
                </div>
                <div class="card-body p-0 mt-4">
                  <a href="javascript:void(0)" class="title h5 text-dark"> Expert Teachers</a>
                  <p class="text-muted mt-2">It is a long established fact that a reader will be of a page when reader
                    will be of a page looking at its layout. </p>
                  <a href="javascript:void(0)" class="text-primary read-more">Read More <i
                      class="uil uil-angle-right-b align-middle"></i></a>
                  <i class="uil uil-book-reader text-primary full-img"></i>
                </div>
              </div>
            </div>
            <!--end col-->
          </div>
          <!--end row-->
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- FEATURES END -->

<!-- About Start -->
<section class="section pt-0">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-5 col-md-6 col-12">
        <img src="assets/images/course/about.jpg" class="img-fluid shadow rounded" alt="">
      </div>
      <!--end col-->

      <div class="col-lg-7 col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="section-title ms-lg-4">
          <h4 class="title mb-4">Our Story : <span class="text-primary">Landrick Education</span></h4>
          <p class="text-muted">Start Course with <span class="text-primary fw-bold">Landrick Education</span> that can
            provide everything you need to generate awareness, drive traffic, connect. Dummy text is text that is used
            in the publishing industry or by web designers to occupy the space which will later be filled with 'real'
            content. This is required when, for example, the final text is not yet available. Dummy texts have been in
            use by typesetters since the 16th century.</p>
          <ul class="list-unstyled mb-0 text-muted">
            <li><i class="uil uil-arrow-circle-right text-primary h5 mb-0 align-middle me-2"></i>Digital Marketing
              Solutions for Tomorrow</li>
            <li><i class="uil uil-arrow-circle-right text-primary h5 mb-0 align-middle me-2"></i>Our Talented &
              Experienced Marketing Agency</li>
            <li><i class="uil uil-arrow-circle-right text-primary h5 mb-0 align-middle me-2"></i>Create your own skin to
              match your brand</li>
          </ul>
          <a href="javascript:void(0)" class="btn btn-outline-primary mt-3">Admission Now</a>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--enc container-->
</section>
<!--end section-->
<!-- About End -->

<!-- Cta Start -->
<section class="section bg-cta" style="background: url('assets/images/course/bg02.jpg') center center;" id="cta">
  <div class="bg-overlay"></div>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title">
          <h4 class="title title-dark text-white mb-4">We Bring New Online Courses</h4>
          <p class="text-white-50 para-desc mx-auto">Start working with Landrick that can provide everything you
            need to generate awareness, drive traffic, connect.</p>
          <a (click)="openWindowCustomClass(content)" href="javascript:void(0);"
            class="play-btn mt-4 video-play-icon">
            <i-feather name="play" class="fea icon-ex-md text-white title-dark"></i-feather>
          </a>
          <ng-template #content let-modal>
            <div class="modal-header">
              <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <iframe src="https://player.vimeo.com/video/12022651" height="450" width="780" frameborder="0"
                allowfullscreen allow="autoplay; encrypted-media"></iframe>
            </div>
          </ng-template>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Cta End -->

<!-- Courses Start -->
<section class="section" id="courses">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Explore Popular Courses</h4>
          <p class="text-muted para-desc mx-auto mb-0">Start working with <span
              class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness,
            drive traffic, connect.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row">
      <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
        <div class="card blog blog-primary overflow-hidden rounded shadow border-0">
          <div class="position-relative d-block overflow-hidden">
            <img src="assets/images/course/1.jpg" class="img-fluid rounded-top mx-auto" alt="">
            <div class="overlay-work bg-dark"></div>
            <a href="javascript:void(0)" class="h6 preview bottom-0 end-0 me-4 mb-3">Preview Now <i
                class="uil uil-angle-right-b align-middle"></i></a>
          </div>

          <div class="card-body">
            <h5><a href="javascript:void(0)" class="title text-dark">Program for Missionaries</a></h5>
            <div class="rating">
              <ul class="list-unstyled mb-0">
                <li class="list-inline-item"><i class="mdi mdi-star h5 mb-0 text-warning"></i></li>
                <li class="list-inline-item"><i class="mdi mdi-star h5 mb-0 text-warning"></i></li>
                <li class="list-inline-item"><i class="mdi mdi-star h5 mb-0 text-warning"></i></li>
                <li class="list-inline-item"><i class="mdi mdi-star h5 mb-0 text-warning"></i></li>
                <li class="list-inline-item"><i class="mdi mdi-star h5 mb-0 text-warning"></i></li>
                <li class="list-inline-item">5 Star (3 <i class="uil uil-user text-muted small"></i>)</li>
              </ul>
            </div>
            <div class="fees d-flex justify-content-between">
              <ul class="list-unstyled mb-0 numbers">
                <li><i class="uil uil-graduation-cap text-muted"></i> 30 Students</li>
                <li><i class="uil uil-notebooks text-muted"></i> 5 Lession</li>
              </ul>
              <h4><span class="h6">$</span>75</h4>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
        <div class="card blog blog-primary overflow-hidden rounded shadow border-0">
          <div class="position-relative d-block overflow-hidden">
            <img src="assets/images/course/2.jpg" class="img-fluid rounded-top mx-auto" alt="">
            <div class="overlay-work bg-dark"></div>
            <a href="javascript:void(0)" class="h6 preview bottom-0 end-0 me-4 mb-3">Preview Now <i
                class="uil uil-angle-right-b align-middle"></i></a>
          </div>

          <div class="card-body">
            <h5><a href="javascript:void(0)" class="title text-dark">Access to Higher Education</a></h5>
            <div class="rating">
              <ul class="list-unstyled mb-0">
                <li class="list-inline-item"><i class="mdi mdi-star h5 mb-0 text-warning"></i></li>
                <li class="list-inline-item"><i class="mdi mdi-star h5 mb-0 text-warning"></i></li>
                <li class="list-inline-item"><i class="mdi mdi-star h5 mb-0 text-warning"></i></li>
                <li class="list-inline-item"><i class="mdi mdi-star h5 mb-0 text-warning"></i></li>
                <li class="list-inline-item"><i class="mdi mdi-star-outline h5 mb-0 text-warning"></i></li>
                <li class="list-inline-item">3.99 Star (11 <i class="uil uil-user text-muted small"></i>)</li>
              </ul>
            </div>
            <div class="fees d-flex justify-content-between">
              <ul class="list-unstyled mb-0 numbers">
                <li><i class="uil uil-graduation-cap text-muted"></i> 30 Students</li>
                <li><i class="uil uil-notebooks text-muted"></i> 5 Lession</li>
              </ul>
              <h4><span class="h6">$</span>150</h4>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
        <div class="card blog blog-primary overflow-hidden rounded shadow border-0">
          <div class="position-relative d-block overflow-hidden">
            <img src="assets/images/course/3.jpg" class="img-fluid rounded-top mx-auto" alt="">
            <div class="overlay-work bg-dark"></div>
            <a href="javascript:void(0)" class="h6 preview bottom-0 end-0 me-4 mb-3">Preview Now <i
                class="uil uil-angle-right-b align-middle"></i></a>
          </div>

          <div class="card-body">
            <h5><a href="javascript:void(0)" class="title text-dark">Course in TEFL Teacher Training</a></h5>
            <div class="rating">
              <ul class="list-unstyled mb-0">
                <li class="list-inline-item"><i class="mdi mdi-star h5 mb-0 text-warning"></i></li>
                <li class="list-inline-item"><i class="mdi mdi-star h5 mb-0 text-warning"></i></li>
                <li class="list-inline-item"><i class="mdi mdi-star h5 mb-0 text-warning"></i></li>
                <li class="list-inline-item"><i class="mdi mdi-star h5 mb-0 text-warning"></i></li>
                <li class="list-inline-item"><i class="mdi mdi-star-half h5 mb-0 text-warning"></i></li>
                <li class="list-inline-item">4.7 Star (9 <i class="uil uil-user text-muted small"></i>)</li>
              </ul>
            </div>
            <div class="fees d-flex justify-content-between">
              <ul class="list-unstyled mb-0 numbers">
                <li><i class="uil uil-graduation-cap text-muted"></i> 30 Students</li>
                <li><i class="uil uil-notebooks text-muted"></i> 5 Lession</li>
              </ul>
              <h4><span class="h6">$</span>175</h4>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
        <div class="card blog blog-primary overflow-hidden rounded shadow border-0">
          <div class="position-relative d-block overflow-hidden">
            <img src="assets/images/course/4.jpg" class="img-fluid rounded-top mx-auto" alt="">
            <div class="overlay-work bg-dark"></div>
            <a href="javascript:void(0)" class="h6 preview bottom-0 end-0 me-4 mb-3">Preview Now <i
                class="uil uil-angle-right-b align-middle"></i></a>
          </div>

          <div class="card-body">
            <h5><a href="javascript:void(0)" class="title text-dark">Educational Communication</a></h5>
            <div class="rating">
              <ul class="list-unstyled mb-0">
                <li class="list-inline-item"><i class="mdi mdi-star h5 mb-0 text-warning"></i></li>
                <li class="list-inline-item"><i class="mdi mdi-star h5 mb-0 text-warning"></i></li>
                <li class="list-inline-item"><i class="mdi mdi-star h5 mb-0 text-warning"></i></li>
                <li class="list-inline-item"><i class="mdi mdi-star h5 mb-0 text-warning"></i></li>
                <li class="list-inline-item"><i class="mdi mdi-star h5 mb-0 text-warning"></i></li>
                <li class="list-inline-item">5 Star (3 <i class="uil uil-user text-muted small"></i>)</li>
              </ul>
            </div>
            <div class="fees d-flex justify-content-between">
              <ul class="list-unstyled mb-0 numbers">
                <li><i class="uil uil-graduation-cap text-muted"></i> 30 Students</li>
                <li><i class="uil uil-notebooks text-muted"></i> 5 Lession</li>
              </ul>
              <h4><span class="h6">$</span>22</h4>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
        <div class="card blog blog-primary overflow-hidden rounded shadow border-0">
          <div class="position-relative d-block overflow-hidden">
            <img src="assets/images/course/5.jpg" class="img-fluid rounded-top mx-auto" alt="">
            <div class="overlay-work bg-dark"></div>
            <a href="javascript:void(0)" class="h6 preview bottom-0 end-0 me-4 mb-3">Preview Now <i
                class="uil uil-angle-right-b align-middle"></i></a>
          </div>

          <div class="card-body">
            <h5><a href="javascript:void(0)" class="title text-dark">Introduction to Epidemiology</a></h5>
            <div class="rating">
              <ul class="list-unstyled mb-0">
                <li class="list-inline-item"><i class="mdi mdi-star h5 mb-0 text-warning"></i></li>
                <li class="list-inline-item"><i class="mdi mdi-star h5 mb-0 text-warning"></i></li>
                <li class="list-inline-item"><i class="mdi mdi-star h5 mb-0 text-warning"></i></li>
                <li class="list-inline-item"><i class="mdi mdi-star h5 mb-0 text-warning"></i></li>
                <li class="list-inline-item"><i class="mdi mdi-star h5 mb-0 text-warning"></i></li>
                <li class="list-inline-item">5 Star (3 <i class="uil uil-user text-muted small"></i>)</li>
              </ul>
            </div>
            <div class="fees d-flex justify-content-between">
              <ul class="list-unstyled mb-0 numbers">
                <li><i class="uil uil-graduation-cap text-muted"></i> 30 Students</li>
                <li><i class="uil uil-notebooks text-muted"></i> 5 Lession</li>
              </ul>
              <h4><span class="h6">$</span>100</h4>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
        <div class="card blog blog-primary overflow-hidden rounded shadow border-0">
          <div class="position-relative d-block overflow-hidden">
            <img src="assets/images/course/6.jpg" class="img-fluid rounded-top mx-auto" alt="">
            <div class="overlay-work bg-dark"></div>
            <a href="javascript:void(0)" class="h6 preview bottom-0 end-0 me-4 mb-3">Preview Now <i
                class="uil uil-angle-right-b align-middle"></i></a>
          </div>

          <div class="card-body">
            <h5><a href="javascript:void(0)" class="title text-dark">Good Clinical Practice</a></h5>
            <div class="rating">
              <ul class="list-unstyled mb-0">
                <li class="list-inline-item"><i class="mdi mdi-star h5 mb-0 text-warning"></i></li>
                <li class="list-inline-item"><i class="mdi mdi-star h5 mb-0 text-warning"></i></li>
                <li class="list-inline-item"><i class="mdi mdi-star h5 mb-0 text-warning"></i></li>
                <li class="list-inline-item"><i class="mdi mdi-star h5 mb-0 text-warning"></i></li>
                <li class="list-inline-item"><i class="mdi mdi-star-outline h5 mb-0 text-warning"></i></li>
                <li class="list-inline-item">4 Star (2 <i class="uil uil-user text-muted small"></i>)</li>
              </ul>
            </div>
            <div class="fees d-flex justify-content-between">
              <ul class="list-unstyled mb-0 numbers">
                <li><i class="uil uil-graduation-cap text-muted"></i> 30 Students</li>
                <li><i class="uil uil-notebooks text-muted"></i> 5 Lession</li>
              </ul>
              <h4><span class="h6">$</span>110</h4>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
        <div class="card blog blog-primary overflow-hidden rounded shadow border-0">
          <div class="position-relative d-block overflow-hidden">
            <img src="assets/images/course/7.jpg" class="img-fluid rounded-top mx-auto" alt="">
            <div class="overlay-work bg-dark"></div>
            <a href="javascript:void(0)" class="h6 preview bottom-0 end-0 me-4 mb-3">Preview Now <i
                class="uil uil-angle-right-b align-middle"></i></a>
          </div>

          <div class="card-body">
            <h5><a href="javascript:void(0)" class="title text-dark">Advanced Diagnostic Research</a></h5>
            <div class="rating">
              <ul class="list-unstyled mb-0">
                <li class="list-inline-item"><i class="mdi mdi-star h5 mb-0 text-warning"></i></li>
                <li class="list-inline-item"><i class="mdi mdi-star h5 mb-0 text-warning"></i></li>
                <li class="list-inline-item"><i class="mdi mdi-star h5 mb-0 text-warning"></i></li>
                <li class="list-inline-item"><i class="mdi mdi-star h5 mb-0 text-warning"></i></li>
                <li class="list-inline-item"><i class="mdi mdi-star-half h5 mb-0 text-warning"></i></li>
                <li class="list-inline-item">4.5 Star (8 <i class="uil uil-user text-muted small"></i>)</li>
              </ul>
            </div>
            <div class="fees d-flex justify-content-between">
              <ul class="list-unstyled mb-0 numbers">
                <li><i class="uil uil-graduation-cap text-muted"></i> 30 Students</li>
                <li><i class="uil uil-notebooks text-muted"></i> 5 Lession</li>
              </ul>
              <h4><span class="h6">$</span>149</h4>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
        <div class="card blog blog-primary overflow-hidden rounded shadow border-0">
          <div class="position-relative d-block overflow-hidden">
            <img src="assets/images/course/8.jpg" class="img-fluid rounded-top mx-auto" alt="">
            <div class="overlay-work bg-dark"></div>
            <a href="javascript:void(0)" class="h6 preview bottom-0 end-0 me-4 mb-3">Preview Now <i
                class="uil uil-angle-right-b align-middle"></i></a>
          </div>

          <div class="card-body">
            <h5><a href="javascript:void(0)" class="title text-dark">Skills Development Facilitation</a></h5>
            <div class="rating">
              <ul class="list-unstyled mb-0">
                <li class="list-inline-item"><i class="mdi mdi-star h5 mb-0 text-warning"></i></li>
                <li class="list-inline-item"><i class="mdi mdi-star h5 mb-0 text-warning"></i></li>
                <li class="list-inline-item"><i class="mdi mdi-star h5 mb-0 text-warning"></i></li>
                <li class="list-inline-item"><i class="mdi mdi-star h5 mb-0 text-warning"></i></li>
                <li class="list-inline-item"><i class="mdi mdi-star h5 mb-0 text-warning"></i></li>
                <li class="list-inline-item">5 Star (3 <i class="uil uil-user text-muted small"></i>)</li>
              </ul>
            </div>
            <div class="fees d-flex justify-content-between">
              <ul class="list-unstyled mb-0 numbers">
                <li><i class="uil uil-graduation-cap text-muted"></i> 30 Students</li>
                <li><i class="uil uil-notebooks text-muted"></i> 5 Lession</li>
              </ul>
              <h4><span class="h6">$</span>135</h4>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
        <div class="card blog blog-primary overflow-hidden rounded shadow border-0">
          <div class="position-relative d-block overflow-hidden">
            <img src="assets/images/course/9.jpg" class="img-fluid rounded-top mx-auto" alt="">
            <div class="overlay-work bg-dark"></div>
            <a href="javascript:void(0)" class="h6 preview bottom-0 end-0 me-4 mb-3">Preview Now <i
                class="uil uil-angle-right-b align-middle"></i></a>
          </div>

          <div class="card-body">
            <h5><a href="javascript:void(0)" class="title text-dark">PGCE Primary</a></h5>
            <div class="rating">
              <ul class="list-unstyled mb-0">
                <li class="list-inline-item"><i class="mdi mdi-star h5 mb-0 text-warning"></i></li>
                <li class="list-inline-item"><i class="mdi mdi-star h5 mb-0 text-warning"></i></li>
                <li class="list-inline-item"><i class="mdi mdi-star h5 mb-0 text-warning"></i></li>
                <li class="list-inline-item"><i class="mdi mdi-star h5 mb-0 text-warning"></i></li>
                <li class="list-inline-item"><i class="mdi mdi-star h5 mb-0 text-warning"></i></li>
                <li class="list-inline-item">5 Star (3 <i class="uil uil-user text-muted small"></i>)</li>
              </ul>
            </div>
            <div class="fees d-flex justify-content-between">
              <ul class="list-unstyled mb-0 numbers">
                <li><i class="uil uil-graduation-cap text-muted"></i> 30 Students</li>
                <li><i class="uil uil-notebooks text-muted"></i> 5 Lession</li>
              </ul>
              <h4><span class="h6">$</span>55</h4>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-12 mt-4 pt-2 text-center">
        <a href="javascript:void(0)" class="btn btn-primary">See More Courses <i
            class="uil uil-angle-right-b align-middle"></i></a>
      </div>
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60" id="instructors">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Instructors</h4>
          <p class="text-muted para-desc mx-auto mb-0">Start working with <span
              class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness,
            drive traffic, connect.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <app-member [memberData]="memberData"></app-member>
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Courses End -->

<!-- Cta Start -->
<section class="section bg-cta" style="background: url('assets/images/course/bg03.jpg') center center;" id="admission">
  <div class="bg-overlay"></div>
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-8 col-md-7 col-12">
        <div class="section-title">
          <h4 class="title title-dark text-white mb-4">Admission Now</h4>
          <p class="para-desc para-dark mb-0 text-white-50">Launch your campaign and benefit from our expertise on
            designing and managing conversion centered bootstrap4 html page.</p>
        </div>
        <div class="row" id="counter">
          <div class="col-md-3 col-6 mt-4 pt-2">
            <div class="counter-box">
              <i class="uil uil-graduation-cap text-white-50 h2"></i>
              <h2 class="mb-0 text-white title-dark mt-2"><span class="counter-value" [countUp]="25" [options]="option"></span>K
              </h2>
              <h6 class="counter-head text-white-50">Students</h6>
            </div>
            <!--end counter box-->
          </div>
          <!--end col-->

          <div class="col-md-3 col-6 mt-4 pt-2">
            <div class="counter-box">
              <i class="uil uil-book-open text-white-50 h2"></i>
              <h2 class="mb-0 text-white title-dark mt-2"><span class="counter-value" [countUp]="70" [options]="option">1</span>+</h2>
              <h6 class="counter-head text-white-50">Courses</h6>
            </div>
            <!--end counter box-->
          </div>
          <!--end col-->

          <div class="col-md-3 col-6 mt-4 pt-2">
            <div class="counter-box">
              <i class="uil uil-user text-white-50 h2"></i>
              <h2 class="mb-0 text-white title-dark mt-2"><span class="counter-value" [countUp]="55" [options]="option"></span>+</h2>
              <h6 class="counter-head text-white-50">Instructors</h6>
            </div>
            <!--end counter box-->
          </div>
          <!--end col-->

          <div class="col-md-3 col-6 mt-4 pt-2">
            <div class="counter-box">
              <i class="uil uil-english-to-chinese text-white-50 h2"></i>
              <h2 class="mb-0 text-white title-dark mt-2"><span class="counter-value" [countUp]="25" [options]="option"></span>+</h2>
              <h6 class="counter-head text-white-50">Languages</h6>
            </div>
            <!--end counter box-->
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-5 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="card border-0 rounded">
          <div class="card-body">
            <form>
              <h4>7 Days Free Trial</h4>
              <div class="row">
                <div class="col-lg-12">
                  <div class="mb-3">
                    <label class="form-label">Name : <span class="text-danger">*</span></label>
                    <div class="form-icon position-relative">
                      <i-feather name="user" class="fea icon-sm icons"></i-feather>
                      <input type="text" class="form-control ps-5" placeholder="Name" name="name" required="">
                    </div>
                  </div>
                </div>
                <!--end col-->

                <div class="col-lg-12">
                  <div class="mb-3">
                    <label class="form-label">Email : <span class="text-danger">*</span></label>
                    <div class="form-icon position-relative">
                      <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                      <input type="email" class="form-control ps-5" placeholder="Email" name="email" required="">
                    </div>
                  </div>
                </div>
                <!--end col-->

                <div class="col-lg-12">
                  <div class="mb-3">
                    <label class="form-label">Phone : <span class="text-danger">*</span></label>
                    <div class="form-icon position-relative">
                      <i-feather name="phone" class="fea icon-sm icons"></i-feather>
                      <input type="number" id="number" class="form-control ps-5" placeholder="phone" required="">
                    </div>
                  </div>
                </div>
                <!--end col-->

                <div class="col-lg-12 mt-2 mb-0">
                  <div class="d-grid">
                    <button class="btn btn-primary">Register Now</button>
                  </div>
                </div>
                <!--end col-->
              </div>
            </form>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Cta End -->

<!-- Testi Subscribe Start -->
<section class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">What Students Say ?</h4>
          <p class="text-muted para-desc mx-auto mb-0">Start working with <span
              class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness,
            drive traffic, connect.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row justify-content-center">
      <div class="col-lg-12 mt-4">
        <app-testimonial [testimonialData]="testimonialData"></app-testimonial>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Sign up for our Newsletter</h4>
          <p class="text-muted para-desc mx-auto mb-0">Start working with <span
              class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness,
            drive traffic, connect.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row justify-content-center mt-4 pt-2">
      <div class="col-lg-7 col-md-10">
        <div class="text-center subcribe-form">
          <form>
            <input name="email" id="email" type="email" class="form-control rounded-pill shadow"
              placeholder="Your email :" required aria-describedby="newssubscribebtn">
            <button type="submit" class="btn btn-pills btn-primary" id="newssubscribebtn">Subscribe</button>
          </form>
          <!--end form-->
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Testi Subscribe End -->

<!-- Partners Start -->
<section class="py-4 bg-light">
  <div class="container">
    <app-clients-logo brand_class="col-lg-2 col-md-2 col-6 text-center py-4"></app-clients-logo>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Partners End -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->
<!-- Footer Start -->
<footer class="footer" >
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
          <a href="#" class="logo-footer">
            <img src="assets/images/Xoomship Logo.png" height="50" alt="">
          </a>
          <p class="mt-4">Customer Service is our first priority and we depend on them . Our SAS model allows us to achieve the quality of service that makes us at per with others.
            </p>
          <ul class="list-unstyled social-icon social mb-0 mt-4">
            <li class="list-inline-item"><a href="javascript:void(0)" class="rounded">
                <i-feather name="facebook" class="fea icon-sm fea-social"></i-feather>
              </a></li>
            <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded">
                <i-feather name="instagram" class="fea icon-sm fea-social"></i-feather>
              </a></li>
            <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded">
                <i-feather name="twitter" class="fea icon-sm fea-social"></i-feather>
              </a></li>
            <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded">
                <i-feather name="linkedin" class="fea icon-sm fea-social"></i-feather>
              </a></li>
          </ul>
          <!--end icon-->
        </div>
        <!--end col-->
 
        <div class="col-lg-2 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
          <h4 class="text-light footer-head">Company</h4>
          <ul class="list-unstyled footer-list mt-4">
            <li><a routerLink="/" class="text-foot"><i class="mdi mdi-chevron-right mr-1"></i> Home</a>
            </li>
            <li><a routerLink="/page-aboutus" class="text-foot"><i class="mdi mdi-chevron-right mr-1"></i> About us</a>
            </li>
            <li><a routerLink="/index-customer" class="text-foot"><i class="mdi mdi-chevron-right mr-1"></i> Support</a>
            </li>
            <li><a routerLink="/page-pricing" class="text-foot"><i class="mdi mdi-chevron-right mr-1"></i> Package Deals</a>
            </li>
           
            <!-- <li><a routerLink="/page-job-apply" class="text-foot"><i class="mdi mdi-chevron-right mr-1"></i> Careers</a>
            </li> -->
            <!-- <li><a href="https://shop.xoomship.com" target="_blank" class="text-foot"><i class="mdi mdi-chevron-right mr-1"></i> Shop</a></li> -->
             
            <!-- <li><a routerLink="/page-blog-grid" class="text-foot"><i class="mdi mdi-chevron-right mr-1"></i> Blog</a> -->
            
            <!-- <li><a routerLink="/auth-login" class="text-foot"><i class="mdi mdi-chevron-right mr-1"></i> Login</a>
            </li> -->
          </ul>
        </div>
        <!--end col-->
        <div class="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
          <h4 class="text-light footer-head">Usefull Links</h4>
          <ul class="list-unstyled footer-list mt-4"  >
            <!-- <li *ngFor="let data of list"><a (click)="routerClick(data.titleId,data.titleName)" class="text-foot"><i
                  class="mdi mdi-chevron-right mr-1"></i> {{data.titleName}}</a>
            </li> -->

            <li *ngFor="let data of list"><a [routerLink]="[data.titleName.trim().split(' ').join('-')+'/'+data.titleId]" class="text-foot"><i
              class="mdi mdi-chevron-right mr-1"></i> {{data.titleName}}</a>
        </li>
          
          </ul>
        </div>

 
        <!-- <div class="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
          <h4 class="text-light footer-head">Usefull Links</h4>
          <ul class="list-unstyled footer-list mt-4" >
            <li><a routerLink="/page-terms" class="text-foot"><i class="mdi mdi-chevron-right mr-1"></i> Terms of
                Services</a>
            </li>
            <li><a routerLink="/page-privacy" class="text-foot"><i class="mdi mdi-chevron-right mr-1"></i> Privacy
                Policy</a>
            </li>
            <li><a routerLink="/helpcenter-faqs" class="text-foot"><i class="mdi mdi-chevron-right mr-1"></i> FAQs </a>
            </li>
            <li><a routerLink="/page-contact-one" class="text-foot"><i class="mdi mdi-chevron-right mr-1"></i> Contact Us</a>
            </li>
            <li><a routerLink="/page-history" class="text-foot"><i class="mdi mdi-chevron-right mr-1"></i> Track Your Shipment</a>
            </li>
          </ul>
        </div> -->
        <!--end col-->
 
        <div class="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
          <h4 class="text-light footer-head">HeadOffice</h4>
          <div class="content ml-2">
            <h5 class="mb-0">India</h5>
            <p class="text-muted mb-0">{{service_zones_ist}}</p>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
    <!--end container-->
  </footer>
  <!--end footer-->
  <footer class="footer footer-bar" >
    <div class="container text-center">
      <div class="row align-items-center">
        <div class="col-sm-6">
          <div class="text-sm-left">
            <p class="mb-0">© 2020 xoomship. Design with <i class="mdi mdi-heart text-danger"></i> by <a
                href="#" target="_blank" class="text-reset">octohub </a>.</p>
          </div>
        </div>
        <!--end col-->
 
        <div class="col-sm-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
          <ul class="list-unstyled text-sm-right mb-0">
            <li class="list-inline-item"><a href="javascript:void(0)"><img src="assets/images/payments/american-ex.png"
                  class="avatar avatar-ex-sm" title="American Express" alt=""></a></li>
            <li class="list-inline-item ml-1"><a href="javascript:void(0)"><img src="assets/images/payments/discover.png"
                  class="avatar avatar-ex-sm" title="Discover" alt=""></a></li>
            <li class="list-inline-item ml-1"><a href="javascript:void(0)"><img
                  src="assets/images/payments/master-card.png" class="avatar avatar-ex-sm" title="Master Card"
                  alt=""></a></li>
            <li class="list-inline-item ml-1"><a href="javascript:void(0)"><img src="assets/images/payments/paypal.png"
                  class="avatar avatar-ex-sm" title="Paypal" alt=""></a></li>
            <li class="list-inline-item ml-1"><a href="javascript:void(0)"><img src="assets/images/payments/visa.png"
                  class="avatar avatar-ex-sm" title="Visa" alt=""></a></li>
          </ul>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
    <!--end container-->
  </footer>
  <!--end footer-->
  <!-- Footer End -->
 
<!-- Hero Start -->
<section class="bg-half bg-light d-table w-100" id="home">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="page-next-level">
          <h4 class="title"> Frequently Asked Questions </h4>

          <div class="page-next">
            <nav aria-label="breadcrumb" class="d-inline-block">
              <ul class="breadcrumb bg-white rounded shadow mb-0">
                <li class="breadcrumb-item"><a routerLink="/">xoomship</a></li>
                <li class="breadcrumb-item"><a routerLink="/index-customer">Support Center</a></li>
               <!-- <li class="breadcrumb-item active" aria-current="page">FAQs</li> -->
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <!--end col-->
      
    </div>
    <!--end row-->
  </div>
  <!--end container-->
  
</section>
<!--end section-->
<!-- Hero End -->

<!-- Start Section -->
<section class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-4 col-md-5 col-12 d-none d-md-block">
        <div class="rounded shadow p-4 sticky-bar">
          <ul class="list-unstyled mb-0">
            <li><a href="javascript: void(0);" [ngxScrollTo]="'#tech'" class="mouse-down h6 text-dark">India to Anywhere</a></li>
            <li class="mt-4"><a href="javascript: void(0);" [ngxScrollTo]="'#general'"
                class="mouse-down h6 text-dark">Anywhere to India
                </a></li>
            <li class="mt-4"><a href="javascript: void(0);" [ngxScrollTo]="'#payment'"
                class="mouse-down h6 text-dark">Payments
                Questions</a></li>
            <li class="mt-4"><a href="javascript: void(0);" [ngxScrollTo]="'#support'"
                class="mouse-down h6 text-dark">Support
                Questions</a></li>
          </ul>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-8 col-md-7 col-12">
        <div class="section-title" id="tech">
          <h4>India to Anywhere</h4>
        </div>
        <div class="faq-content mt-4 pt-2">
          <!-- #acc="ngbAccordion" -->
          <ngb-accordion activeIds="ngb-panel-0" closeOthers="true">
            <ngb-panel id="ngb-panel-0" cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 bg-light" id="headingone">
                  <h6 class="title mb-0 text-dark">What Does <span class="text-primary">Gross Weight </span>Means? ? </h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans">Gross Weight means the weight of the box after the packing.</p>
              </ng-template>
            </ngb-panel>
            <ngb-panel cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 bg-light" id="headingtwo">
                  <h6 class="title mb-0 text-dark">While Dispatching Of Goods If The Parcel Was Damaged Is There Any Replacement Of Goods? </h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans">The Parcel will be properly packed under your supervision to your satisfaction. 
                  We are not responsible for the damage of the goods intransit.</p>
              </ng-template>
            </ngb-panel>
            <ngb-panel cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 bg-light" id="headingthree">
                  <h6 class="title mb-0 text-dark">What Does Clearance Delay Mean in International Shipments? </h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans">Most international shipments are required to go through a customs clearance procedure. When this happens, 
                  a clearance delay may occur which slows down the clearance process and delivery of the goods. 
                  We are not responsible for such delays as it is beyond our control.</p>
              </ng-template>
            </ngb-panel>
            <ngb-panel cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 bg-light" id="headingfour">
                  <h6 class="title mb-0 text-dark"> Can we send Home Made Foods? </h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans">Yes, any homemade foods like pickles, Sweets, Snacks can be sent.However there are some restrictions applied for some countries due to the COVID situation. 
                  Please check with your local agent for more details..</p>
              </ng-template>
            </ngb-panel>
            <ngb-panel cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 bg-light" id="headingfour">
                  <h6 class="title mb-0 text-dark"> What does our quoted rate of 450 Rs Plus Tax include? </h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans">Our rate of 450 Rs per Kg includes the labor cost of packing but not materials used for packing,
                   which would be charged at nominal rates..</p>
              </ng-template>
            </ngb-panel>
            <ngb-panel cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 bg-light" id="headingfour">
                  <h6 class="title mb-0 text-dark"> How much would it cost me to send a package to USA? </h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans">The rates vary according to weight and promotional rates available at the current time. The rates page has the most current details. 
                  The quoted rates does not include Service Tax which the government is presently charging at 18%.</p>
                </ng-template>
              </ngb-panel>
              <ngb-panel cardClass="border-0 rounded shadow mb-2">
                <ng-template ngbPanelTitle>
                  <div class="border-0 bg-light" id="headingfour">
                    <h6 class="title mb-0 text-dark"> What Will The Charges be For Packing? </h6>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                  <p class="text-muted mb-0 faq-ans">All Food materials should be compulsorily packed with 
                    NRI packing (Vaccum sealed packing) to avoid damage to the package any special packing required it will be chargeable.</p>
                  </ng-template>
                </ngb-panel>
                <ngb-panel cardClass="border-0 rounded shadow mb-2">
                  <ng-template ngbPanelTitle>
                    <div class="border-0 bg-light" id="headingfour">
                      <h6 class="title mb-0 text-dark"> Any weight restrictions for the packages? </h6>
                    </div>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <p class="text-muted mb-0 faq-ans">No weight restriction, however if about 150 kgs, Please contact for better discounted rates. 
                      These heavier packages will go in as one consignment but with multiple boxes for easier handling.</p>
                    </ng-template>
                  </ngb-panel>
                  <ngb-panel cardClass="border-0 rounded shadow mb-2">
                    <ng-template ngbPanelTitle>
                      <div class="border-0 bg-light" id="headingfour">
                        <h6 class="title mb-0 text-dark"> Is the office open on sunday's? </h6>
                      </div>
                    </ng-template>
                    <ng-template ngbPanelContent>
                      <p class="text-muted mb-0 faq-ans"> No, Office will be closed on Sundays .</p>
                      </ng-template>
                    </ngb-panel>
                    <ngb-panel cardClass="border-0 rounded shadow mb-2">
                      <ng-template ngbPanelTitle>
                        <div class="border-0 bg-light" id="headingfour">
                          <h6 class="title mb-0 text-dark"> How is the weight calculated? </h6>
                        </div>
                      </ng-template>
                      <ng-template ngbPanelContent>
                        <p class="text-muted mb-0 faq-ans">Weight for payment is the higher of the physical and volumetric weight. The Volumetric weight calculation varies between domestic and internation and that calculation is provided under "Volume" tab on the website. For example, if the actual weight is 10 kgs and the volumetric weight is 12 kgs, then the charges are based on 12 kgs.
                           Also the weights are always rounded to the nearest 0.5 kg. If the weight is 10.2 kgs, then payment is taken for 10.5 kgs.</p>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
        </div>
    
        <div class="section-title mt-5" id="general">
          <h4>Anywhere to India</h4>
        </div>

        <div class="faq-content mt-4 pt-3">
          <!-- #acc="ngbAccordion"  -->
          <ngb-accordion activeIds="ngb-panelfaq" closeOthers="true">
            <ngb-panel id="ngb-panelfaq" cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 bg-light" id="headingone">
                  <h6 class="title mb-0 text-dark"> Any restrictions on  <span class="text-primary">sending Electronic</span>  Items? </h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans"> 1. Any type of electronics like phone, tablets, tv etc are accepted. </p>
                <p class="text-muted mb-0 faq-ans">2. Customs/duties and other related charges/fees is the responsibility of the customer  </p>
                <p class="text-muted mb-0 faq-ans">3. If the consignment is subject to any customs/duties, destination customs authorities will contact receiver and inform the customs/taxes charges. Once that is paid, packages will be cleared next working day for delivery to the address on the package.  </p>
                <p class="text-muted mb-0 faq-ans">4. Proper care should be taken to pack the materials to avoid damage with regular handling.  </p>
                <p class="text-muted mb-0 faq-ans">6. The courier charges will depend upon the size of the box and weight of the box as the charges are based on both physical and volumetric weight.  </p>
                <p class="text-muted mb-0 faq-ans">7. We are not responsible for any customs/taxes/duties/charges that are levied by the local governmental agencies and/or authorities.  </p>
                <p class="text-muted mb-0 faq-ans">8. All consignments are handled by DHL and any loss/damage claims will have to be made directly with DHL. </p>
             </ng-template>
           <!-- </ngb-panel>
            <ngb-panel cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 bg-light" id="headingtwo">
                  <h6 class="title mb-0 text-dark"> What is the main process open account ? </h6>
                </div>
              </ng-template> 
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans">There are many variations of passages of Lorem Ipsum
                  available, but
                  the majority have suffered alteration in some form.</p> 
              </ng-template> -->
            <!-- </ngb-panel>
            <ngb-panel cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 bg-light" id="headingthree">
                  <h6 class="title mb-0 text-dark"> How to make unlimited data entry ? </h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans">There are many variations of passages of Lorem Ipsum
                  available, but
                  the majority have suffered alteration in some form.</p>
              </ng-template> 
            </ngb-panel>
            <ngb-panel cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 bg-light" id="headingfour">
                  <h6 class="title mb-0 text-dark"> Is <span class="text-primary">xoomship</span> safer to use with my
                    account ? </h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans">There are many variations of passages of Lorem Ipsum
                  available, but
                  the majority have suffered alteration in some form.</p>
              </ng-template>
            </ngb-panel>
            <ngb-panel cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 bg-light" id="headingfour">
                  <h6 class="title mb-0 text-dark"> How can i contact your technical team ? </h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans">There are many variations of passages of Lorem Ipsum
                  available, but
                  the majority have suffered alteration in some form.</p>
              </ng-template>  -->
            </ngb-panel> 
          </ngb-accordion>
        </div> 

        <div class="section-title mt-5" id="payment">
          <h4>Payments Questions</h4>
        </div>

        <div class="faq-content mt-4 pt-3">
          <!-- #acc="ngbAccordion" -->
          <ngb-accordion  activeIds="ngb-panelpayment" closeOthers="true">
            <ngb-panel id="ngb-panelpayment" cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 bg-light" id="headingone">
                  <h6 class="title mb-0 text-dark"> How our <span class="text-primary">xoomship</span> work ? </h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans">There are many variations of passages of Lorem Ipsum
                  available, but
                  the majority have suffered alteration in some form.</p>
              </ng-template>
            </ngb-panel>
            <ngb-panel cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 bg-light" id="headingtwo">
                  <h6 class="title mb-0 text-dark"> What is the main process open account ? </h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans">There are many variations of passages of Lorem Ipsum
                  available, but
                  the majority have suffered alteration in some form.</p>
              </ng-template>
            </ngb-panel>
            <ngb-panel cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 bg-light" id="headingthree">
                  <h6 class="title mb-0 text-dark"> How to make unlimited data entry ? </h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans">There are many variations of passages of Lorem Ipsum
                  available, but
                  the majority have suffered alteration in some form.</p>
              </ng-template>
            </ngb-panel>
            <ngb-panel cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 bg-light" id="headingfour">
                  <h6 class="title mb-0 text-dark"> Is <span class="text-primary">xoomship</span> safer to use with my
                    account ? </h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans">There are many variations of passages of Lorem Ipsum
                  available, but
                  the majority have suffered alteration in some form.</p>
              </ng-template>
            </ngb-panel>
            <ngb-panel cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 bg-light" id="headingfour">
                  <h6 class="title mb-0 text-dark"> How can i contact your technical team ? </h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans">There are many variations of passages of Lorem Ipsum
                  available, but
                  the majority have suffered alteration in some form.</p>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
        </div> 

        <div class="section-title mt-5" id="support">
          <h4>Support Questions</h4>
        </div>

        <div class="faq-content mt-4 pt-3">
          <!-- #acc="ngbAccordion" -->
          <ngb-accordion  activeIds="ngb-panelsupport" closeOthers="true">
            <ngb-panel id="ngb-panelsupport" cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 bg-light" id="headingone">
                  <h6 class="title mb-0 text-dark"> How our <span class="text-primary">xoomship</span> work ? </h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans">There are many variations of passages of Lorem Ipsum
                  available, but
                  the majority have suffered alteration in some form.</p>
              </ng-template>
            </ngb-panel>
            <ngb-panel cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 bg-light" id="headingtwo">
                  <h6 class="title mb-0 text-dark"> What is the main process open account ? </h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans">There are many variations of passages of Lorem Ipsum
                  available, but
                  the majority have suffered alteration in some form.</p>
              </ng-template>
            </ngb-panel>
            <ngb-panel cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 bg-light" id="headingthree">
                  <h6 class="title mb-0 text-dark"> How to make unlimited data entry ? </h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans">There are many variations of passages of Lorem Ipsum
                  available, but
                  the majority have suffered alteration in some form.</p>
              </ng-template>
            </ngb-panel>
            <ngb-panel cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 bg-light" id="headingfour">
                  <h6 class="title mb-0 text-dark"> Is <span class="text-primary">xoomship</span> safer to use with my
                    account ? </h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans">There are many variations of passages of Lorem Ipsum
                  available, but
                  the majority have suffered alteration in some form.</p>
              </ng-template>
            </ngb-panel>
            <ngb-panel cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 bg-light" id="headingfour">
                  <h6 class="title mb-0 text-dark"> How can i contact your technical team ? </h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans">There are many variations of passages of Lorem Ipsum
                  available, but
                  the majority have suffered alteration in some form.</p>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>

        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row">
      <div class="col-lg-6 col-md-6 col-12">
        <div class="media align-items-center shadow rounded p-4 features">
          <div class="icons m-0 rounded h2 text-primary text-center px-3">
            <i class="uil uil-envelope-check"></i>
          </div>
          <div class="content ml-4">
            <h5 class="mb-1"><a href="javascript:void(0)" class="text-dark">Get in Touch !</a></h5>
            <p class="text-muted mb-0">This is required when, for text is not yet available.</p>
            <div class="mt-2">
              <a href="javascript:void(0)" class="btn btn-sm btn-soft-primary">Submit a Request</a>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-6 col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="media align-items-center shadow rounded p-4 features">
          <div class="icons m-0 rounded h2 text-primary text-center px-3">
            <i class="uil uil-webcam"></i>
          </div>
          <div class="content ml-4">
            <h5 class="mb-1"><a href="javascript:void(0)" class="text-dark">Start a Meeting</a></h5>
            <p class="text-muted mb-0">This is required when, for text is not yet available.</p>
            <div class="mt-2">
              <a href="javascript:void(0)" class="btn btn-sm btn-soft-primary">Start Video Chat</a>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- End Section -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-soft-primary back-to-top" id="back-to-top"
  [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->
import { Component } from '@angular/core';
import axios from 'axios';

@Component({
  selector: 'app-phonepesuccess',
  standalone: true,
  imports: [],
  templateUrl: './phonepesuccess.component.html',
  styleUrl: './phonepesuccess.component.css'
})
export class PhonepesuccessComponent {

  

// const config = {
//   merchantId: 'M22ZJB51ZB608',
//   saltKey: '1536553e-24c2-4733-875a-259dc886dedd',
//   saltIndex: 1
// };

// const phonepe = new (class PhonePe {
//   async paymentStatus(transactionId, environment = 'PRODUCTION') {
//     try {
//       const response = await axios.get(`https://api.phonepe.com/v1/transaction/status/${this.config.merchantId}/${transactionId}?environment=${environment}`);
//       return response.data;
//     } catch (error) {
//       console.error(error);
//       return null;
//     }
//   }
// })();

// if (
//   typeof req.body.code !== 'undefined' &&
//   req.body.code !== '' &&
//   req.body.code === 'PAYMENT_SUCCESS' &&
//   typeof req.body.transactionId !== 'undefined' &&
//   req.body.transactionId !== ''
// ) {
//   const transactionId = req.body.transactionId;
//   const response = await phonepe.paymentStatus(transactionId);

//   if (response && response.status === 'SUCCESS') {
//     const transactionDetails = await new Promise((resolve, reject) => {
//       conn.query(
//         'SELECT userid, amount FROM course_by WHERE tranjactionid = ?',
//         [transactionId],
//         (err, result) => {
//           if (err) {
//             reject(err);
//           } else {
//             resolve(result[0]);
//           }
//         }
//       );
//     });

//     if (transactionDetails) {
//       const { userid, amount } = transactionDetails;
//       res.redirect('/paymentsuccess', {
//         userid,
//         amount
//       });
//     }
//   } else {
//     res.send('Invalid request');
//   }
// } else {
//   res.send('Invalid request');
// }



}

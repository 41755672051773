import { Component, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { first, map, Observable, of, takeWhile, timer } from 'rxjs';
import { Global } from 'src/app/global';
import { AppService } from 'src/app/services/app.service';
import { OnlyCountriesService } from 'src/app/services/only-countries.service';
import Swal from 'sweetalert2';
declare var $: any;
@Component({
  selector: 'app-index-landing-two',
  templateUrl: './index-landing-two.component.html',
  styleUrls: ['./index-landing-two.component.css']
})

/***
 * Landing Two Component
 */
export class IndexLandingTwoComponent implements OnInit {
  navClass = 'nav-light';
  userId:any;
  constructor(private appService: AppService, private onlyCountriesService: OnlyCountriesService,
    private modalService: NgbModal,private router: Router,private fb: FormBuilder) {
      this.href = this.router.url;
      this.userId = this.appService.currentUserValue?.userName;
    
   }
   @ViewChild('content1') content;
  //countryList = this.onlyCountriesService.countriesList
  countryList: any;
  selected_country = 'India';
  selected_delivery = '';
  selected_delivery1 = '';
  showCalculation = false
  showSignUp = false;
  isInter: boolean = false;
  isDomestic: Boolean = false;
  calculator: string = '';
  isNonDoc: boolean = true;
  isDoc: boolean = false;
  rateValue: any;
  href:string='';
  modalOption: NgbModalOptions = {}; 
  //////////////////////////login/////////
  loginForm: FormGroup;
  hasError: boolean;
  returnUrl: string;
  isLoading$: Observable<boolean>;
  isOtpLoading$: Observable<boolean>;
  passwordVisible: boolean = false;
  otpValue: any;
  isOtp: boolean = false;
  ////////////////////
  countDown: Observable<string>;
  isSendOtp: boolean = false;
  service_zones_ist:any;
  bookingOrderData:any;
  international_ratecard_list:any;
  international_ratecard_list1:any;

  ///////////////
  formInput = ['input1', 'input2', 'input3', 'input4', 'input5', 'input6'];
  @ViewChildren('formRow') rows: any;
  @ViewChild('closebutton') closebutton;
  

  // showCalculationDiv = 
  password:string='';
  loginId:string='';
 
  year = new Date().getFullYear()

  checkin = new Date();
  checkout = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 2
      },
      600: {
        items: 5
      },
      900: {
        items: 8
      }
    },
    nav: false
  };
  customAdOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 2
      },
      900: {
        items: 3
      }
    },
    nav: false
  };

  testiOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 2
      },
      900: {
        items: 3
      }
    },
    nav: false
  };
  delivery_mode_list: any
  shipment_info_list: any;
  international_data_list:any
  international_data_list1:any
  seleted_del_mode: any
  seleted_del_mode1: any
  rateCardList: any
  delivery_mode_id: any
  shipment_id: any
  price_per_kg: any
  freight_rate: any
  gst_price: any
  total_tax_per: any
  total_selling_price: any
  pack_loader: any
  packagesList: any
  modalReference: any;
  public static hasAdvertBeenShown = false;
  isOpenBox:boolean=false;

  imageObject = [{
    image: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/5.jpg',
    thumbImage: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/5.jpg',
    title: 'Book Now'
}, {
    image: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/9.jpg',
    thumbImage: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/9.jpg',
    title: 'Book Now'
}, {
    image: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/4.jpg',
    thumbImage: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/4.jpg',
    title: 'Book Now'
},{
    image: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/7.jpg',
    thumbImage: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/7.jpg',
    title: 'Book Now'
}, {
    image: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/1.jpg',
    thumbImage: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/1.jpg',
    title: 'Book Now'
}, {
    image: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/2.jpg',
    thumbImage: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/2.jpg',
    title: 'Book Now'
}];
  ngAfterViewInit() {
    
    //this.modalReference= this.openModal()
  }

  onClickDelmode(option) {
    this.seleted_del_mode = option.label
    this.delivery_mode_id = option.value
  }
  onClickDelmode1(option) {
    debugger
    this.seleted_del_mode1 = option.label
    this.shipment_id = option.value
  }

  imageClickHandler(e) {
    
    this.router.navigate(['/order/add']);
    this.modalService.dismissAll();
  }

  // getRateCards() {
  //   this.appService.get_data('/get_sales_rateCardBasedOnType/Sales')
  //     .subscribe(data => {
  //       let msg
  //       msg = data as JSON
  //       if (typeof (msg) == 'object') {
  //         this.rateCardList = msg
  //       }
  //     })
  // }

  calculateRate() {
    debugger
    if (!this.seleted_del_mode) {
      this.appService.openModel('Please Select Delivery Mode', 'warning')
      return
    }
    if (!this.selected_country) {
      this.appService.openModel('Please Select Delivery Country', 'warning')
      return
    }

    if (!this.pack_loader) {
      this.appService.openModel('Please Enter Package Weight', 'warning')
      return
    }
    let ratesList = []
    let obj = {
      cid: 1046,
      deliveryModeId: this.delivery_mode_id,
      fromCountry: "India",
      mid: "MIN#05",
      shipmentTypeId: this.shipment_id,
      toCountry: this.selected_country,
      weight: this.pack_loader
    }
    this.appService.postJson(Global.LogisticCommon_EndPoint + 'GetByRatecardCalInt', obj)
      .subscribe(data => {
        this.rateValue = data as JSON;
        const list = this.rateValue;
        if (list !== undefined && list !== null && list !== '' && list.length > 0) {
          this.freight_rate = list[0]?.grossAmount
          this.total_tax_per = list[0]?.taxPercentage
          this.gst_price = list[0]?.totalGst
          this.total_selling_price = list[0]?.totalPrice
          this.price_per_kg = list[0]?.perKgRate
          this.showCalculation = true
        } else {
          this.showCalculation = false
        }
      })
    // for(let i=0;i<this.rateCardList.length;i++){
    //   debugger
    //   let delivery_mode_details = JSON.parse(this.rateCardList[i].Gen_details.delivery_mode_details)
    //   let list = delivery_mode_details.find(x=>x.transaction_id==this.delivery_mode_id)
    //   if((this.rateCardList[i].Gen_details.Eligibility_country.includes(this.selected_country))&&(list)){

    //     let rateCardRates = this.rateCardList[i]['RateCard_details']
    //     for(let j=0;j<rateCardRates.length;j++){
    //       if((rateCardRates[j]['Weight']==this.pack_loader)&&((rateCardRates[j]['delivery_mode']==this.seleted_del_mode)||(rateCardRates[j]['delivery_mode_id']==this.delivery_mode_id))){
    //         let rates = rateCardRates[j]
    //         rates.vendor = this.rateCardList[i].Gen_details.Vendor
    //         rates.rateCardId = this.rateCardList[i].Gen_details.transaction_id
    //         rates.Rate_card_info = this.rateCardList[i].Gen_details.Rate_card_info
    //         ratesList.push(rates)
    //       }
    //     }
    //   }
    // }
    // if(ratesList.length>0){
    //   debugger
    //   var lowest = Number.POSITIVE_INFINITY;
    //   var lowest_id 
    //   var tmp
    //   for(let i=0;i<ratesList.length;i++){
    //     tmp = ratesList[i].Total_Rate;
    //     if (tmp < lowest){ 
    //       lowest = tmp
    //       lowest_id = i
    //     }
    //   }
    // let list = this.rateValue;
    // this.freight_rate = list[0].grossAmount
    // this.total_tax_per = list[0].taxPercentage
    // this.gst_price = list[0].totalGst
    // this.total_selling_price = list[0].totalPrice
    // this.price_per_kg = list[0].perKgRate
    // this.showCalculation = true

    // else{
    //   this.appService.openModelWithText('','No Prices Available for your requirement, Please Contact us for more Information','warning')
    // }
  }

  onClickReset() {
    this.selected_country = 'India'
    this.delivery_mode_id = ''
    this.seleted_del_mode = ''
    this.seleted_del_mode1 = undefined
    this.shipment_id = undefined
    this.pack_loader = undefined
    this.freight_rate = undefined
    this.total_tax_per = undefined
    this.gst_price = undefined
    this.total_selling_price = undefined
    this.price_per_kg = undefined

  }


  getDeliveryMode() {
    let obj = {
      "mid": "MIN#05",
      "cid": 1046
    };
    this.appService.postJson(Global.LogisticCommon_EndPoint + 'GetDeliveryModeByMid', obj)
      .subscribe(data => {
        this.delivery_mode_list = data as JSON;
      })
  }

  getSipmentInfo() {
    let obj = {
      "mid": "MIN#05",
      "cid": 1046
    };
    this.appService.postJson(Global.LogisticCommon_EndPoint + 'GetShipmentInfoByMid', obj)
      .subscribe(data => {
        this.shipment_info_list = data as JSON;
      })
  }

  getBookingOrderData() {
    let obj = {
      "mid": "MIN#05",
      "cid": 1046,
      "registredUser": "web2.devhub@gmail.com"
    };
    this.appService.postJson(Global.LogisticConsignmentOrder_EndPoint + 'List', obj)
      .subscribe(data => {
        this.bookingOrderData = data as JSON;
      })
  }

  getCountry() {
    let obj = {
      cid: 1046, from: "International",
      mid: "MIN#05",
      type: "ToCountry"
    }
    this.appService.postJson(Global.LogisticCommon_EndPoint + 'GetLogisticValues', obj)
      .subscribe(data => {
        this.countryList = data as JSON;
      })
  }

  // getPackages() {
  //   this.appService.get_data('/getAllPAcksRatecardPrices')
  //     .subscribe(data => {
  //       this.packagesList = data as JSON

  //       if (this.packagesList) {
  //         for (let i = 0; i < this.packagesList.length; i++) {
  //           if (this.packagesList[i]['packDetails']['descDetails'].trim()) {
  //             this.packagesList[i]['packDetails']['descDetails'] = this.packagesList[i]['packDetails']['descDetails'].split(',')
  //           }
  //           else {
  //             this.packagesList[i]['packDetails']['descDetails'] = []
  //           }
  //         }
  //       }
  //       console.log(this.packagesList)
  //     })
  // }


  ngOnInit(): void {

    this.initForm();
    this.getSipmentInfo();
    this.getDeliveryMode()
    this.getCountry();
    this.getServiceZonesLookup();
    this.getInternationalOrderInfo();
    // this.openModal()
  }
  
  domesticCklick() {
    this.isDomestic = true;
    this.isInter = false;
    this.calculator = 'Domestic';
  }
  internationalClick() {
    this.isInter = true;
    this.isDomestic = false;
    this.calculator = 'International';
  }
  docClick() {
    this.isDoc = true;
    this.isNonDoc = false;

  }
  nonDocClick() {
    this.isNonDoc = true;
    this.isDoc = false;
  }
  ////////////////////////////////////////login//////////////////////////////////
  openModal(){
    if(!IndexLandingTwoComponent.hasAdvertBeenShown) {
      this.modalOption.backdrop = 'static';
    this.modalOption.keyboard = false;
    this.modalOption.windowClass= 'my-modal-popup';
    this.modalService.open(this.content, this.modalOption);
      IndexLandingTwoComponent.hasAdvertBeenShown = true;
      
   }
    
   
  }
  
  get f() {
    debugger
    return this.loginForm.controls;
  }

  initForm() {
    this.loginForm = this.fb.group({
      email: [
        '',
        Validators.compose([
          Validators.required,
          Validators.email,
          Validators.minLength(3),
          Validators.maxLength(320), // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
        ]),
      ],
      password: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(100),
        ]),
      ],
      otp: this.fb.group({
        input1: [''],
        input2: [''],
        input3: [''],
        input4: [''],
        input5: [''],
        input6: ['']
      })
    });
  }

  submit() {
    // this.hasError = false;
    // const otpForm = this.loginForm.get('otp') as FormGroup;
    // let otp: string = Object.values(otpForm.value).join('');

    // switch (this.isOtp) {
    //   case true: this.f.password.setValue('');
    //     break;
    //   case false: otp = '';
    //     break;
    // }
debugger
    const loginSubscr = this.appService
      // .login(this.f.email.value, this.f.password.value)
      .login(this.f.email.value, this.f.password.value)
      .pipe(first())
      .subscribe((token: any | undefined) => {
        if (token) {
          this.modalService.dismissAll();
        } else {
          Swal.fire({
            position: 'top',
            title: "Invalid Credentials",
            showConfirmButton: true,
            confirmButtonText:"OK",
            timer: 1500
          });
        }
      });
    //this.unsubscribe.push(loginSubscr);
  }
  sendOTP(e: any) {
    e.preventDefault();
    this.isOtp = true;
    this.isOtpLoading$ = of(true);
    console.log("sendOTP", this.f.email.value)
    this.appService.sendMailOtp(this.f.email.value).subscribe((result: any) => {
      console.log("result from email api", result)
      debugger
      if (result.status) {
        this.isOtpLoading$ = of(false);
        this.isSendOtp = true;
        this.countDownTimer(5);
      }else{
        this.isOtpLoading$ = of(false);
        this.isSendOtp = false;
      }
    })
  }
  clickOnPass() {
    this.isOtp = false;
  }

  keyUpEvent(event: any, index: any) {
    let pos = index;
    if (event.keyCode === 8 && event.which === 8) {
      pos = index - 1;
    } else {
      pos = index + 1;
    }
    if (pos > -1 && pos < this.formInput.length) {
      this.rows._results[pos].nativeElement.focus();
    }
  }

  countDownTimer(minute: any) {

    const duration = minute * 60;
    this.countDown = timer(0, 1000).pipe(map((i) => duration - i),
      takeWhile((time) => time >= 0),
      map((time) => {
        if (time === 0) {
          this.isSendOtp = false;
        }
        return this.formatTime(time)
      }));
  }

  formatTime(time: number): string {
    const minute = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minute}: ${seconds < 10 ? '0' : ''}${seconds}`;
  }

  getServiceZonesLookup(): void {
    this.appService.getJson(Global.WebLinkedLookup_EndPoint + 'GetBranchLocationLookup/' + 1046).subscribe(result => {
      if (result) {
        debugger
        this.service_zones_ist = result[0].address;
      }
    });
  }

  getInternationalOrderInfo() {
    let obj = {
      "mid": "MIN#05",
      "cid": 1046,
      "registredUser":"admin@xoomship.com"
    };
    this.appService.postJson(Global.LogisticConsignmentOrder_EndPoint + 'List', obj)
      .subscribe(data => {
        debugger
        this.international_data_list = data as JSON;
      })
  }
  
  searchDetails(event:Event)
  {
    this.isOpenBox = true;
    let searchText1 = (<HTMLInputElement>document.getElementById("order")).value;
    let obj = {
      "mid": "MIN#05",
      "cid": 1046
    };
    this.appService.Post(Global.LogisticConsignmentOrder_EndPoint + 'List', obj)
      .subscribe(data => {
        debugger
        this.international_data_list = data.data as JSON;
        this.international_data_list1 = this.international_data_list?.filter((b:any) => ((b.booking_Id.toLowerCase().includes(searchText1.toLowerCase())) || (b.registredUser.toLowerCase().includes(searchText1.toLowerCase()))));
     debugger

     let obj = {
      cid: 1046,
      deliveryModeId: Number(this.international_data_list1[0].deliveryMode_Id) ?? null,
      fromCountry: "India",
      mid: "MIN#05",
      //shipmentTypeId:this.shipmentId,
      shipmentTypeId:Number(this.international_data_list1[0].shipmentType_Id) ?? 0,
      toCountry: 'United States of America (the)',
      weight: this.international_data_list1[0].load_weight ?? 0
      // weight: this.sdControls.load_weight.value ?? 0
    }
    debugger
    this.appService.postJson(Global.LogisticCommon_EndPoint + 'GetByRatecardCalInt', obj)
      .subscribe(data => {
        debugger
        this.international_ratecard_list = data;
        this.international_ratecard_list1 = this.international_data_list?.filter((b:any) => ((b.deliverModeId == this.international_data_list1[0].deliveryMode_Id)));
      
      });
      let obj2 = {
        "mid": "MIN#05",
        "cid": 1046
      };
      this.appService.postJson(Global.LogisticCommon_EndPoint + 'GetShipmentInfoByMid', obj2)
        .subscribe(data => {
          debugger
          let data1 = data;
          this.shipment_info_list =  data1?.filter((b:any) => ((b.value == this.international_data_list1[0].shipmentType_Id)));
        })
      })

     

  }
  openOrderModal() {
    $("#myModal3").modal('show');
   
    
  }

}
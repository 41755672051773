<!-- Hero Start -->
<section class="bg-half bg-light d-table w-100" id="home">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12 text-center">
          <div class="page-next-level">
            <h4 class="title"> Terms of Services </h4>
            <div class="page-next">
              <nav aria-label="breadcrumb" class="d-inline-block">
                <ul class="breadcrumb bg-white rounded shadow mb-0">
                  <li class="breadcrumb-item"><a routerLink="/">XOOMSHIP</a></li>
                  <!--<li class="breadcrumb-item"><a href="#">Page</a></li>
                  <li class="breadcrumb-item"><a href="#">Utility</a></li> -->
                  <li class="breadcrumb-item active" aria-current="page">Terms</li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
    <!--end container-->
  </section>
  <!--end section-->
  <!-- Hero End -->
  
  <!-- Shape Start -->
  <div class="position-relative">
    <div class="shape overflow-hidden text-white">
      <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
      </svg>
    </div>
  </div>
  <!--Shape End-->
  
  <!-- Start Terms & Conditions -->
  <section class="section">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-9">
          <div class="card shadow border-0 rounded">
            <div class="card-body">
              <h5 class="card-title">Terms & Conditions :</h5>
  
  <p class="text-muted mb-0 faq-ans"> 1.	The customer is responsible for customs/ duties/ charges and will not hold XoomShip (Herein after called as XOOMSHIP) against any of the associated charges and/or delays arising due to customs clearance. </p>
  <p class="text-muted mb-0 faq-ans">2.	The customer is solely responsible for the dimensions and weight specified to be correct and if there are any variations to the weight and/or dimensions, the customer could be charged for the extra weight and additional processing charges occurring due to change in dimensions/weight declared and also the shipments could be stopped due to discrepancy in dimensions specified. </p>
  <p class="text-muted mb-0 faq-ans">3.	XOOMSHIP is not responsible for shipment held for any reason beyond our control like government agencies, natural calamities or unforeseen events. </p>
  <p class="text-muted mb-0 faq-ans">4.	The customer also agrees not to ship any prohibited items listed in XOOMSHIP website (subject to changes from time to time) and is solely responsible for the consequences arising out of it if sent. </p>
  <p class="text-muted mb-0 faq-ans">5.	Return shipment charges and also possible custom charges will be borne only by the Customer provided if there is no mistake of XOOMSHIP. </p>
  <p class="text-muted mb-0 faq-ans">6.	Claims on Shipment value above Rs.1000/- would be entertained only upon insurance of the packages. </p>
  <p class="text-muted mb-0 faq-ans">7.	In case of damage of shipments  because of solely  XOOMSHIP  mistake, refund will be given in 90 days. </p>
  <p class="text-muted mb-0 faq-ans">8.	Claiming conditions: 
  Complaint must be given within 72hrs in writing from the shipment delivery date, along with proof. </p>
  <p class="text-muted mb-0 faq-ans">9.	</p>
              <h5 class="card-title">a.	XOOMSHIP is not liable For the Following </h5>
  <p class="text-muted mb-0 faq-ans">i.	Delayed shipments: we will make every effort to deliver your shipment according to our regular schedules. XOOMSHIP is not liable for any delays, </p>
  <p class="text-muted mb-0 faq-ans">ii.	on account of  Picking up a shipment </p>
  <p class="text-muted mb-0 faq-ans">iii.	Transporting  a shipment (including delays causes by diversions) </p>
  <p class="text-muted mb-0 faq-ans">iv.	Delivering a shipment </p>
  <p class="text-muted mb-0 faq-ans">b.	We reserve the right , without admitting liability to refund transportation/shipping chages so. But XOOMSHIP is not obligated to do so. </p>
  <p class="text-muted mb-0 faq-ans">i.	circumstances beyond our control : XOOMSHIP is not liable if shipment is lost(includes misdelivered) or damaged because of circumstances beyond our control. Those circumstances includes: </p>
  <p class="text-muted mb-0 faq-ans">ii.	An act of God for example  earthquake , cyclone, storm or flood etc… </p>
  <p class="text-muted mb-0 faq-ans">iii.	Force Majeure for example war, plane crash or embargo. </p>
  <p class="text-muted mb-0 faq-ans">iv.	Any defect or characteristic to do with the nature of the shipment. Even if known to us when we accepted it
  <p class="text-muted mb-0 faq-ans">c.	consequential damages: XOOMSHIP is not liable for the following , whether they arise in contract or any other form of civil aviation, including negligence , and even if they are our fault. </p>
  <p class="text-muted mb-0 faq-ans">i.	Consequential or special damages of loss. </p>
  <p class="text-muted mb-0 faq-ans">ii.	Other direct loss </p>
  <p class="text-muted mb-0 faq-ans">iii.	Breach of other contracts </p>
  <p class="text-muted mb-0 faq-ans">10.	</p>
              <h5 class="card-title">a.	XOOMSHIP is liable for </h5>
  <p class="text-muted mb-0 faq-ans">i.	Extent of our liability: our liability for lost or damaged shipment is limited to the lowest of these 3 amounts: </p>
  <p class="text-muted mb-0 faq-ans">ii.	INR 1000/- or </p>
  <p class="text-muted mb-0 faq-ans">iii.	The actual amount of loss or damage as per the XOOMSHIP Invoice </p>
  <p class="text-muted mb-0 faq-ans">iv.	The actual value of the package. This does not include any commercial utility or special value to the shipper or any other person. </p>
  <p class="text-muted mb-0 faq-ans">b.	What ‘actual value’ Means: the lowest of the following amounts determined as the time and the place we accepted the shipment documents (meaning any shipment without commercial value).  </p>
  <p class="text-muted mb-0 faq-ans">i.	Package meaning any shipment with commercial value.  </p>
  <p class="text-muted mb-0 faq-ans">ii.	The cost of repairing or replacing the parcel or  </p>
  <p class="text-muted mb-0 faq-ans">iii.	The resale of fair market value of the parcel  </p>
  <p class="text-muted mb-0 faq-ans">iv.	The actual value of the parcel cannot be more than the original cost to you plus 10 percent.  </p>
  <p class="text-muted mb-0 faq-ans">c.	any claims on documents will be entertained upon taking the insurance– DHL/Fedex/UPS etc.  </p>
  <h5 class="card-title">11.	All disputes are subject to Hyderabad jurisdiction only. </h5>
  
  
            <!--  <h5 class="card-title">User Agreements :</h5>
              <p class="text-muted">The most well-known dummy text is the 'Lorem Ipsum', which is said to have <b
                  class="text-danger">originated</b> in the 16th century. Lorem Ipsum is <b
                  class="text-danger">composed</b> in a pseudo-Latin language which more or less <b
                  class="text-danger">corresponds</b> to 'proper' Latin. It contains a series of real Latin words. This
                ancient dummy text is also <b class="text-danger">incomprehensible</b>, but it imitates the rhythm of most
                European languages in Latin script. The <b class="text-danger">advantage</b> of its Latin origin and the
                relative <b class="text-danger">meaninglessness</b> of Lorum Ipsum is that the text does not attract
                attention to itself or distract the viewer's <b class="text-danger">attention</b> from the layout.</p>
              <p class="text-muted">There is now an <b class="text-danger">abundance</b> of readable dummy texts. These
                are usually used when a text is <b class="text-danger">required purely</b> to fill a space. These
                alternatives to the classic Lorem Ipsum texts are often amusing and tell short, funny or <b
                  class="text-danger">nonsensical</b> stories.</p>
              <p class="text-muted">It seems that only <b class="text-danger">fragments</b> of the original text remain in
                the Lorem Ipsum texts used today. One may speculate that over the course of time certain letters were
                added or deleted at various positions within the text.</p>
  
              <h5 class="card-title">Restrictions :</h5>
              <p class="text-muted">You are specifically restricted from all of the following :</p>
              <ul class="list-unstyled text-muted">
                <li>
                  <i-feather name="arrow-right" class="fea icon-sm mr-2"></i-feather>
                  Digital Marketing Solutions for Tomorrow
                </li>
                <li>
                  <i-feather name="arrow-right" class="fea icon-sm mr-2"></i-feather>
                  Our Talented & Experienced Marketing Agency
                </li>
                <li>
                  <i-feather name="arrow-right" class="fea icon-sm mr-2"></i-feather>
                  Create your own skin to match your brand
                </li>
                <li>
                  <i-feather name="arrow-right" class="fea icon-sm mr-2"></i-feather>
                  Digital Marketing Solutions for Tomorrow
                </li>
                <li>
                  <i-feather name="arrow-right" class="fea icon-sm mr-2"></i-feather>
                  Our Talented & Experienced Marketing Agency
                </li>
                <li>
                  <i-feather name="arrow-right" class="fea icon-sm mr-2"></i-feather>
                  Create your own skin to match your brand
                </li>
              </ul>
  
              <h5 class="card-title">Users Question & Answer :</h5>
              <div class="faq-content mt-4">
                <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0" closeOthers="true">
                  <ngb-panel>
                    <ng-template ngbPanelTitle>
                      <div class="border-0 bg-light text-dark" id="headingone">
                        <h6 class="title mb-0"> How our XOOMSHIP work ? </h6>
                      </div>
                    </ng-template>
                    <ng-template ngbPanelContent>
                      <p class="text-muted mb-0 faq-ans">There are many variations of passages of Lorem Ipsum available,
                        but
                        the majority have suffered alteration in some form.</p>
                    </ng-template>
                  </ngb-panel>
  
                  <ngb-panel>
                    <ng-template ngbPanelTitle>
                      <div class="border-0 bg-light text-dark" id="headingtwo">
                        <h6 class="title mb-0"> What is the main process open account ? </h6>
                      </div>
                    </ng-template>
                    <ng-template ngbPanelContent>
                      <p class="text-muted mb-0 faq-ans">There are many variations of passages of Lorem Ipsum available,
                        but
                        the majority have suffered alteration in some form.</p>
                    </ng-template>
                  </ngb-panel>
                  <ngb-panel>
                    <ng-template ngbPanelTitle>
                      <div class="border-0 bg-light text-dark" id="headingfour">
                        <h6 class="title mb-0"> How to make unlimited data entry ? </h6>
                      </div>
                    </ng-template>
                    <ng-template ngbPanelContent>
                      <p class="text-muted mb-0 faq-ans">There are many variations of passages of Lorem Ipsum available,
                        but
                        the majority have suffered alteration in some form.</p>
                    </ng-template>
                  </ngb-panel>
                  <ngb-panel>
                    <ng-template ngbPanelTitle>
                      <div class="border-0 bg-light text-dark" id="headingfive">
                        <h6 class="title mb-0"> Is <span class="text-primary">XOOMSHIP</span> safer to use with my
                          account ?
                        </h6>
                      </div>
                    </ng-template>
                    <ng-template ngbPanelContent>
                      <p class="text-muted mb-0 faq-ans">There are many variations of passages of Lorem Ipsum available,
                        but
                        the majority have suffered alteration in some form.</p>
                    </ng-template>
                  </ngb-panel>
                  <ngb-panel>
                    <ng-template ngbPanelTitle>
                      <div class="border-0 bg-light text-dark" id="headingfive">
                        <h6 class="title mb-0"> How can i contact your technical team ?
                        </h6>
                      </div>
                    </ng-template>
                    <ng-template ngbPanelContent>
                      <p class="text-muted mb-0 faq-ans">There are many variations of passages of Lorem Ipsum available,
                        but
                        the majority have suffered alteration in some form.</p>
                    </ng-template> 
                  </ngb-panel> 
                </ngb-accordion> 
              </div> -->
  
              <div class="mt-3">
               <!-- <a href="javascript:void(0)" class="btn btn-primary mt-2 mr-2">Accept</a>
                <a href="javascript:void(0)" class="btn btn-outline-primary mt-2">Decline</a> -->
              </div>
            </div>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
    <!--end container-->
  </section>
  <!--end section-->
  <!-- End Terms & Conditions -->
  
  <!-- Back to top -->
  <a href="javascript: void(0);" class="btn btn-icon btn-soft-primary back-to-top" id="back-to-top"
    [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
  </a>
  <!-- Back to top -->
  
<section>
    <div class="container mt-100 mt-60">
      <div class="row align-items-center">
        <div class="col-lg-5 col-md-5 col-12">
          <video width="620" height="340" autoplay loop class="img-fluid rounded-md">
            <source src="/assets/images/app/Running_Vehicle (1).mp4" type="video/mp4">
          </video>
          <!--<img src="assets/images/app_download.png" class="img-fluid mx-auto d-block" alt=""> -->
        </div>
        <!--end col-->
  
        <div class="col-lg-7 col-md-7 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
          <div class="section-title">
            <div class="alert  alert-pills" role="alert" style="background-color:#4b57e5">
              <span class="badge badge-primary badge-pill mr-1">Apps</span>
              <span class="content">Download now
                <i-feather name="chevron-right" class="fea icon-sm"></i-feather>
              </span>
            </div>
            <h4 class="title mb-4"> xoomship Available for your <br> Smartphones </h4>
            <p class="text-muted para-desc mb-0">For your convenience <span
                class="text-primary font-weight-bold">download</span> the apps and get the control in your hand .Shop / Ship your packages from your phone.Get notified of the latest deals and products directly to your phone
                .</p>
            <div class="my-4">
              <a href="javascript:void(0)" class="btn btn-lg btn-dark mt-2 mr-2"><i class="mdi mdi-apple"></i> App
                Store</a>&nbsp;&nbsp;
              <a href="javascript:void(0)" class="btn btn-lg btn-dark mt-2"><i class="mdi mdi-google-play"></i> Play
                Store</a>
            </div>
  
            <div class="d-inline-block">
              <div class="pt-4 d-flex align-items-center border-top">
                <!-- <i-feather name="smartphone" class="fea icon-md mr-2 text-primary"></i-feather> -->
                <div class="content">
                   <!--<h6 class="mb-0">Install app now on your cellphones</h6>
                  <a href="javascript:void(0)" class="text-primary">Learn More <i class="mdi mdi-arrow-right"></i></a> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
  </section>

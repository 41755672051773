import { Injectable, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, finalize, map, switchMap, tap } from 'rxjs/operators';
import {
  Event,
  Router, ActivatedRoute,
  NavigationStart,
  NavigationEnd,
  RouterEvent
} from "@angular/router";
import Swal from 'sweetalert2'
import { Global } from '../global';
import { formatDate } from '@angular/common';
import { environment } from 'src/environments/environment';
declare var $: any;
declare var require: any;
// const pdfMakeX = require('pdfmake/build/pdfmake.js');
// const pdfFontsX = require('pdfmake/build/vfs_fonts.js');

// pdfMakeX.vfs = pdfFontsX.pdfMake.vfs;
export type UserType = UserModel | undefined;

const httpXmlOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/xml',
    'Authorization': 'jwt-token'
  })
};

const httpJsonOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'jwt-token'
  })
};

@Injectable({ providedIn: 'root' })
export class AppService {
  msg_heading: any;
  msg_body: any;
  data: any;
  message: any;

  file_value: any;
  file_error = '';
  title = 'app';
  public csvRecords: any[] = [];
  csvData: any;
  csvRecordsArray: any;
  headersRow: any;
  isPrinting = false
  delMsg: any;
  appRoleList = ['Courier Boy', 'Customer', 'Staff User', 'Help Desk', 'vendor', 'Logistics Vendor']

  private autocompleteService!: google.maps.places.AutocompleteService;
  private placesService!: google.maps.places.PlacesService;

  isLoadingSubject: BehaviorSubject<boolean>;
  authLocalStorageToken:any;
  currentUserSubject: BehaviorSubject<any>;
  bearerToken:string='';
  configUrl: string = environment.apiUrl + "/Common";
  apiLink: string = environment.apiLink
  constructor(private httpClient: HttpClient, public router: Router, private ngZone: NgZone) { 
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.currentUserSubject = new BehaviorSubject<any>(undefined);
  }
  get currentUserValue(): UserType {
    return this.currentUserSubject.value;
  }

  set currentUserValue(user: UserType) {
    this.currentUserSubject.next(user);
  }

  logout() {
    localStorage.removeItem(this.authLocalStorageToken);
    localStorage.clear();
    this.currentUserValue = undefined;
    this.router.navigate(['/login'], {
      queryParams: {},
    });
  }
  
  insert_contact(id, email_id, full_name, mobile_no, role) {
    this.postXml('/add_contact_general?username=' + email_id + '&relation_with=' + role + '&customer_type=user' + '&transaction_id=' + id + '&user_info=' + full_name + '&contact_email=' + email_id + '&mobile_no=' + mobile_no)
      .subscribe(data => {

      })
    this.postXml('/add_contact_company?username=' + email_id + '&company_name=&company_email=&company_phone=&contact_email=' + email_id + '&currency=&transaction_id=' + id + '&exchange_rate=0&isgst=false&isvat=false&gst_no=&gst_descrption=&vat_no=&vat_descripton=')
      .subscribe(data => { })



    this.postXml('/add_contact_billing?username=' + email_id + '&contact_email=' + email_id + '&address1=&address2=&country=&city=&transaction_id=' + id + '&address_state=&zipcode=&pincode=&contact_no=')
      .subscribe(data => { })


    this.postXml('/add_contact_shipping?username=' + email_id + '&contact_email=' + email_id + '&address1=&address2=&country=&city=&transaction_id=' + id + '&address_state=&zipcode=&pincode=&contact_no=')
      .subscribe(data => { })


    this.postXml('/add_contact_social?username=' + email_id + '&contact_email=' + email_id + '&facebook_id=&instagram_id=&twitter_id=&transaction_id=' + id)
      .subscribe(data => { })


    this.postXml('/add_contact_bank?username=' + email_id + '&contact_email=' + email_id + '&bank_name=&account_no=&ifsc_code=&transaction_id=' + id + '&branch=&upi_id=')
      .subscribe(data => { })


    this.postXml('/add_contact_creditUnit?username=' + email_id + '&contact_email=' + email_id + '&credit_period=&limit_value=&transaction_id=' + id)
      .subscribe(data => { })

  }


  printDocument(documentName: string, documentData: string[]) {
    this.isPrinting = true;
    this.router.navigate(['/',
      {
        outlets: {
          'print': ['print', documentName, documentData.join()]
        }
      }]);
  }

  onDataReady() {
    setTimeout(() => {
      window.print();
      this.isPrinting = false;
      this.router.navigate([{ outlets: { print: null } }]);
    });
  }


  downloadFile(data, filename = 'data', header, type, headingList) {
    let csvData = this.ConvertToCSV(data, header, headingList);
    // console.log(csvData)
    let blob = new Blob(['\ufeff' + csvData], { type: 'text/' + type + ';charset=utf-8;' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + "." + type);
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  downloadExcel(data, filename = 'data', header, headingList) {
    let csvData = this.ConvertToCSV(data, header, headingList);
    // console.log(csvData)
    let blob = new Blob(['\ufeff' + csvData], { type: 'application/pdf;charset=utf-8;' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".pdf");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  ConvertToCSV(objArray, headerList, headingList) {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = 'S.No,';
    //  let row = '';

    for (let index in headingList) {
      row += headingList[index] + ',';

    }
    //  console.log(row)
    row = row.slice(0, -1);
    //  console.log(row)
    str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
      let line = (i + 1) + ',';
      //  console.log(line)
      //  let line = '';
      for (let index in headerList) {
        //  console.log(typeof(index))
        let head = headerList[index];
        if (index == '0') {
          line += array[i][head];
        }
        else {
          line += ',' + array[i][head];
        }
      }
      str += line + '\r\n';
    }
    return str;
  }


  generate_random_string(n) {//generate transaction id
    let outString: string = '';
    let inOptions: string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';

    for (let i = 0; i < n; i++) {

      outString += inOptions.charAt(Math.floor(Math.random() * inOptions.length));

    }
    // console.log(outString)
    return outString;

  }

  generate_random_stringsmall(n) {//generate transaction id
    let outString: string = '';
    let inOptions: string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz*0123456789';

    for (let i = 0; i < n; i++) {

      outString += inOptions.charAt(Math.floor(Math.random() * inOptions.length));

    }
    // console.log(outString)
    return outString;

  }



  get_data(url) {
    return this.httpClient.get('https://pil4u.finobooks.com' + url)
      .pipe(
        catchError(this.handleError()) // then handle the error
      );
  }

  getMaps() {
    const key = Global.GMapsKey ?? '';
    return this.httpClient.get(`https://maps.googleapis.com/maps/api/js?key=${key}&libraries=places`);
  }


  postXml(url, obj?) {
    return this.httpClient.post(url, obj, httpXmlOptions)
  }

  postJson(url, obj) {
    return this.httpClient.post<any>(url, obj, httpJsonOptions)
  }

  getJson(url) {
    return this.httpClient.get<any>(url);
  }

  post_data_any_url(url) {
    return this.httpClient.post('url' + url, httpXmlOptions)
  }

  get_image_url() {
    return Global.apiUrl;
  }

  sendMailOtpRegistration(EmailToId: string, senderName: string) {
    let obj = {
      "EmailToId": EmailToId,
      "CompanyKey": Global.CompanyKey,
      "EmailTime": formatDate(new Date(), 'yyyy-MM-ddThh:mm:ss.SSS', 'en-US'),
      "UserName": senderName,
    };
    return this.httpClient.post<any>(Global.Mail_EndPoint + 'Registration', obj);
  };

  sendMailOtp(EmailToId: string) {
    const EmailTime = formatDate(new Date(), 'yyyy-MM-ddThh:mm:ss.SSS', 'en-US');
    return this.httpClient.post<any>(Global.Mail_EndPoint + 'SendOtp', {
      EmailToId,
      EmailTime
    });
  }

  verifyMailOtp(EmailToId: string, otp: string) {
    let obj = {
      "EmailId": EmailToId,
      "EmailTime": formatDate(new Date(), 'yyyy-MM-ddThh:mm:ss.SSS', 'en-US'),
      "OTP": otp,
    };
    return this.httpClient.post<any>(Global.Mail_EndPoint + 'Verify', obj);
  }


  public upload(formData, url) {
    return this.httpClient.post('https://pil4u.finobooks.com' + url, formData, {
      reportProgress: true,
      observe: 'events'
    });
  }

  // openModel(msg_head,msg_body) {
  //   console.log('service modal  ')
  //   $("#myModalSave").modal('show');
  //   this.msg_heading = msg_head
  //   this.msg_body = msg_body
  //   // 
  // }


  delete(url, msg, func) {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: false
    });

    swalWithBootstrapButtons
      .fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, cancel!',
        reverseButtons: true
      })
      .then(result => {

        if (result.value) {
          // if(confirm("Are you sure to delete all user")){
          this.get_data(url)
            .subscribe(data => {
              this.delMsg = data as JSON
              if (this.delMsg == 'success') {

                swalWithBootstrapButtons.fire(
                  'Deleted!',
                  msg,
                  'success'

                );
              } else {
                swalWithBootstrapButtons.fire(
                  'Failed!',
                  'Failed to delete.',
                  'error'

                );
              }
            })



        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            'Cancelled',
            'Your Record is safe :)',
            'error'
          );

        }

      });


  }


  openModel(msg, icon) {//icon can be error,success,warning
    Swal.fire({
      position: 'top-end',
      icon: icon,
      title: msg,
      showConfirmButton: false,
      timer: 1500
    });
  }

  openModelWithText(head, msg, icon) {
    Swal.fire(head, msg, icon);
  }


  generatePdf(action = 'open', list, cols, heading) {
    // const documentDefinition = this.generate_doc(list,cols,heading);

    // documentDefinition.content[2]['table']['widths'] =
    //        Array(documentDefinition.content[2]['table'].body[0].length + 1).join('%').split('');
    // switch (action) {
    //   case 'open': pdfMake.createPdf(documentDefinition).open();    
    //   break;
    //   case 'print': pdfMake.createPdf(documentDefinition).print(); 
    //   break;
    //   case 'download':     
    //   pdfMake.createPdf(documentDefinition).download(); 
    //   break;
    //   default: pdfMake.createPdf(documentDefinition).open(); 
    //   break;
    // }
  }


  getDocumentDefinition(list) {
    let a = { 'name': 'Manasa', 'id': 1 }
    return {
      content: [
        {
          text: 'PROFILE',
          bold: true,
          fontSize: 20,
          alignment: 'center',
          margin: [0, 0, 0, 20]
        },
        {
          columns: [
            // list
            [{
              text: 'FirstName = Manasa', //+ this.profile.firstname
            },
              // {
              //   text: 'Lastname : Bingi' //+ this.profile.lastname
              // },
              // {
              //   text: 'Username : Manasa' //+ this.profile.username
              // },
              // {
              //   text: 'Email : manasa'  //this.profile.email
              // }
            ]
          ]
        }],
      styles: {
        name: {
          fontSize: 16,
          bold: true
        }
      }
    };
  }

  // var externalDataRetrievedFromServer = [
  //   { name: 'Bartek', age: 34 },
  //   { name: 'John', age: 27 },
  //   { name: 'Elizabeth', age: 30 },
  // ];

  buildTableBody(data, columns) {
    var body = [];

    body.push(columns);

    data.forEach(function (row) {
      var dataRow = [];

      columns.forEach(function (column) {
        if (row[column]) {
          dataRow.push(row[column].toString());
        }
        else {
          dataRow.push(' ')
        }
      })

      body.push(dataRow);
    });

    return body;
  }

  table(data, columns) {
    return {
      table: {
        headerRows: 1,
        body: this.buildTableBody(data, columns)
      }
    };
  }

  generate_doc(list, cols, heading) {//convert json to doc
    let a = [{ 'name': 'ramu', 'age': 19 }, { 'name': 'ramu1', 'age': 20 }]

    return {
      // extend: 'pdfHtml5',
      //         orientation: 'landscape',//landscape give you more space
      //         pageSize: 'A4',//A0 is the largest A5 smallest(A0,A1,A2,A3,legal,A4,A5,letter))
      content: [
        {
          text: heading,
          bold: true,
          fontSize: 20,
          alignment: 'center',
          margin: [0, 0, 0, 20]
        },
        { text: '', style: 'header' },
        // this.table(a, ['name','age'])
        this.table(list, cols)
      ],
      styles: {
        name: {
          fontSize: 16,
          bold: true
        }
      }
    };
  }

  // var dd = {
  //   content: [
  //       { text: 'Dynamic parts', style: 'header' },
  //       table(externalDataRetrievedFromServer, ['name', 'age'])
  //   ]
  // }


  /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
  /** Log a HeroService message with the MessageService */
  private log(message: string) {
    // this.messageService.add(`HeroService: ${message}`);
    console.log(message)
  }


  //read CSV file data


  isCSVFile(file: any) {
    return file.name.endsWith(".csv");
  }

  getHeaderArray(csvRecordsArr: any) {
    let headers = csvRecordsArr[0].split(',');
    let headerArray = [];

    for (let j = 0; j < headers.length; j++) {
      headerArray.push(headers[j]);
    }
    // console.log('headerarray is'+headerArray)      
    return headerArray;
  }

  login(email: string, password: string): Observable<UserType> {
    debugger
    this.isLoadingSubject.next(true);
   
    return this.login1(email, password).pipe(
      map((auth: AuthModel) => {
        debugger
        const result = this.setAuthFromLocalStorage(auth);
        return result;
      }),
      switchMap(() => this.getUserByToken()),
      catchError((err) => {
        console.error('err', err);
        return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  private setAuthFromLocalStorage(auth: any): boolean {
    if (auth && auth.authToken) {
      localStorage.setItem(this.authLocalStorageToken, JSON.stringify(auth));
      return true;
    }
    return false;
  }

  public getAuthFromLocalStorage(): any | undefined {
    try {
      const lsValue = localStorage.getItem(this.authLocalStorageToken);
      if (this.isTokenExpired()) {
      //  this.logout();
        return;
      }
      return lsValue ? JSON.parse(lsValue).authToken : "";
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  getUserByToken(): Observable<UserType> {
    debugger
    const auth = this.getAuthFromLocalStorage();
    if (!auth) {
      return of(undefined);
    }

    this.isLoadingSubject.next(true);
    return this.getUserByToken1(auth).pipe(
      map((user: any) => {
        debugger
        if (user == undefined) {
          this.logout();
        }
        debugger
        const userauth = this.getAuthDataFromLocalStorage();
        this.currentUserSubject.next(userauth);
        return userauth;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }
  public getAuthDataFromLocalStorage(): any | undefined {
    try {
      const lsValue = localStorage.getItem(this.authLocalStorageToken);
      if (this.isTokenExpired()) {
        //this.logout();
        return;
      }
      return lsValue ? JSON.parse(lsValue) : null;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }
  isTokenExpired() {
    let lsValue = localStorage.getItem(this.authLocalStorageToken);
    if (lsValue) {
      const expiry = (JSON.parse(atob(JSON.parse(lsValue).authToken.split('.')[1]))).exp;
      return expiry * 1000 < Date.now();
    } else {
      return true;
    }
  }

  getUserByToken1(token: string): Observable<UserModel> {
    debugger
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    return this.httpClient.get<UserModel>(`${Global.apiUrl}/auth/me`, {
      headers: httpHeaders,
    });
  }
  login1(email: string, password: string): Observable<any> {
    debugger;
    const userTime = formatDate(new Date(), 'yyyy-MM-ddThh:mm:ss.SSS', 'en-US');
    console.log("user login time", userTime)
    debugger
    return this.httpClient.post<any>(`${Global.apiUrl}/auth/login`, {
      email,
      password,
      userTime
    });
   
  }

  public Post(configUrl: string, modelInfo: any) {
    debugger;
    this.bearerToken = 'Bearer ' + this.getAuthFromLocalStorage();
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'Authorization': this.bearerToken
      })
    };
    return this.httpClient.post<any>(configUrl, modelInfo, options);
  }

  getDataById(configUrl: string, ID: any) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*',
        // 'Authorization': ('Bearer ' + bearerToken)
      })
    };
    return this.httpClient.get<any>(configUrl + ID, options);
  }

  public UploadTempFile(formData:any) {
    debugger;
    const headers: HttpHeaders= new HttpHeaders()
    .append('Accept',  'application/json');
    // .append('Authorization', 'Bearer ' + bearerToken);
    let totalUrl = this.configUrl + "/UploadTempFile";
    const options = {
      headers: headers
    };
    return this.httpClient.post(totalUrl, formData, options);
  }

  public  generateUUID():string
  {
    var d = new Date().getTime();
    
    if( window.performance && typeof window.performance.now === "function" )
    {
      d += performance.now();
    }
    
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c)
    {
      var r = (d + Math.random()*16)%16 | 0;
      d = Math.floor(d/16);
      return (c=='x' ? r : (r&0x3|0x8)).toString(16);
    });
  
  return uuid;
  }

}
















export class AuthModel {
  authToken: string;
  refreshToken: string;
  expiresIn: Date;

  setAuth(auth: AuthModel) {
    this.authToken = auth.authToken;
    this.refreshToken = auth.refreshToken;
    this.expiresIn = auth.expiresIn;
  }
}



export class UserModel extends AuthModel {
  id: number;
  userName: string;
  password: string;
  userPassword:string;
  fullname: string;
  email: string;
  cid:number;
  cbid:number;
  defaultCompany:number;
  selectedCompany:number;
  selectedCompanyKey: string;
  mid:string;
  midnames:string;
  //companies:MstCompany[];
  pic: string;
  roles: number[] = [];
  occupation: string;
  companyImage:string;
  companyName: string;
  phone: string;
  // address?: AddressModel;
  // socialNetworks?: SocialNetworksModel;
  // personal information
  firstname: string;
  lastname: string;
  website: string;
  // account information
  language: string;
  timeZone: string;
  communication: {
    email: boolean;
    sms: boolean;
    phone: boolean;
    
  };
  otp:string;
    userTime:string;
  // email settings
  emailSettings?: {
    emailNotification: boolean;
    sendCopyToPersonalEmail: boolean;
    activityRelatesEmail: {
      youHaveNewNotifications: boolean;
      youAreSentADirectMessage: boolean;
      someoneAddsYouAsAsAConnection: boolean;
      uponNewOrder: boolean;
      newMembershipApproval: boolean;
      memberRegistration: boolean;
    };
    updatesFromKeenthemes: {
      newsAboutKeenthemesProductsAndFeatureUpdates: boolean;
      tipsOnGettingMoreOutOfKeen: boolean;
      thingsYouMissedSindeYouLastLoggedIntoKeen: boolean;
      newsAboutMetronicOnPartnerProductsAndOtherServices: boolean;
      tipsOnMetronicBusinessProducts: boolean;
    };
  };

  setUser(_user: unknown) {
    const user = _user as UserModel;
    this.id = user.id;
    this.userName = user.userName || '';
    this.password = user.password || '';
    this.fullname = user.fullname || '';
    this.email = user.email || '';
    this.pic = user.pic || './assets/media/avatars/blank.png';
    this.roles = user.roles || [];
    this.occupation = user.occupation || '';
    this.companyName = user.companyName || '';
    this.phone = user.phone || '';
    // this.address = user.address;
    // this.socialNetworks = user.socialNetworks;
  }
}

<!-- Communiaction Priority Start -->

<section class="section">

  <div class="container mt-100 mt-60">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4" data-aos="fade-up" data-aos-duration="1000">Book Your Shipment </h4>
          <p class="text-muted para-desc mb-0 mx-auto" data-aos="fade-up" data-aos-duration="1400">We give the best
            rates possible with Quality Service
            <span class="text-primary font-weight-bold">Your Satisfaction is guaranteed . </span> Order Now !
          </p>
        </div>
      </div>
    </div>
    <!--end row-->

    <div class="col mt-4 pt-2" id="nav-tabs">
      <div class="component-wrapper rounded shadow">
        <div class="p-4 border-bottom">
          <h4 class="title mb-0"> Book Your Shipment </h4>
        </div>

        <div class="p-4">
          <div class="row">
            <div class="col-lg-12">
              <ul ngbNav #nav="ngbNav" class="nav-pills nav-justified">

                <li ngbNavItem>
                  <a ngbNavLink class=" rounded">
                    <div class="text-center pt-1 pb-1">
                      <h4 class="title font-weight-normal mb-0">Destination Address </h4>
                    </div>
                  </a>
                  <ng-template ngbNavContent>
                    <div class="row align-items-center">

                      <div class="col-lg-6 col-md-6 col-12 mt-4 pt-2">
                        <img src="assets/images/customer/XS1.png" data-aos="fade-up" data-aos-duration="1400"
                          class="img-fluid" alt="">
                      </div>

                      <div class="col-lg-6 col-md-6 col-12 mt-4 pt-2">
                        <div class="card rounded shadow border-0 ml-lg-4" data-aos="fade-up" data-aos-duration="1400">
                          <div class="card-body p-4">
                            <form>
                              <div class="row">

                                <div class="col-md-6">
                                  <div class="form-group position-relative">
                                    <label>Receiver Name :</label>
                                    <i-feather name="user" class="fea icon-sm icons"></i-feather>
                                    <input name="p_name" id="p_name" type="text" class="form-control pl-5"
                                      [(ngModel)]="consignmentOrder.receiver_PersonName" placeholder="Receiver Name :">
                                  </div>
                                </div>
                                <!--end col-->

                                <div class="col-md-6">
                                  <div class="form-group position-relative">
                                    <label>Receiver Mobile No :</label>
                                    <i-feather name="phone" class="fea icon-sm icons"></i-feather>
                                    <input name="p_mobileno" id="p_mobileno" type="text" class="form-control pl-5"
                                      [(ngModel)]="consignmentOrder.receiver_Contact" placeholder="Receiver Mobile No :"
                                      (keypress)='numberOnlyMob($event)'>
                                  </div>
                                </div>
                                <!--end col-->
                                <div class="col-md-12">
                                  <div class="form-group position-relative">
                                    <label>Search your Destination Location :</label>
                                    <i-feather name="map-pin" class="fea icon-sm icons"></i-feather>
                                    <input name="desct_search" id="desct_search" type="text"
                                      [(ngModel)]="consignmentOrder.receiver_Address"
                                      (keydown.enter)="$event.preventDefault()" (keyup)="changeAdr('prim')"
                                      class="form-control pl-5" placeholder="Search Your location:" autocorrect="off"
                                      autocapitalize="off" spellcheck="off" type="text" #search>
                                  </div>
                                </div>

                                <div class="col-md-12">
                                  <div class="form-group position-relative">
                                    <label>Country <span class="text-danger">*</span></label>
                                    <select class="form-control custom-select"
                                      [(ngModel)]='consignmentOrder.receiver_Country' name='p_country'>
                                      <option *ngFor='let option of countryList' [selected]='option.name'>
                                        {{option.name}}</option>
                                    </select>
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div class="form-group position-relative">
                                    <label>House/Flat No :</label>
                                    <i-feather name="book" class="fea icon-sm icons"></i-feather>
                                    <input name="p_flat_no" id="p_flat_no" class="form-control pl-5"
                                      placeholder="House/Flat no :" [(ngModel)]='consignmentOrder.receiver_Landmark'>
                                  </div>
                                </div>

                                <div class="col-md-6">
                                  <div class="form-group position-relative">
                                    <label>Steet Address :</label>
                                    <i-feather name="book" class="fea icon-sm icons"></i-feather>
                                    <input name="p_street_name" id="p_street_name" class="form-control pl-5"
                                      placeholder="Street Address :" [(ngModel)]='consignmentOrder.receiver_Landmark'>
                                  </div>
                                </div>

                                <div class="col-md-6">
                                  <div class="form-group position-relative">
                                    <label>City :</label>
                                    <i-feather name="book" class="fea icon-sm icons"></i-feather>
                                    <input name="p_city" id="p_city" class="form-control pl-5" placeholder="City :"
                                      [(ngModel)]='consignmentOrder.receiver_City'>
                                  </div>
                                </div>

                                <div class="col-md-6">
                                  <div class="form-group position-relative">
                                    <label>State :</label>
                                    <i-feather name="book" class="fea icon-sm icons"></i-feather>
                                    <input name="p_state" id="p_state" class="form-control pl-5" placeholder="State :"
                                      [(ngModel)]='consignmentOrder.receiver_State'>
                                  </div>
                                </div>

                                <div class="col-md-6">
                                  <div class="form-group position-relative">
                                    <label>ZipCode :</label>
                                    <i-feather name="book" class="fea icon-sm icons"></i-feather>
                                    <input name="p_zipcode" id="p_zipcode" class="form-control pl-5"
                                      placeholder="ZipCode :" [(ngModel)]='consignmentOrder.receiver_ZipCode'
                                      (keypress)='numberOnlyMob($event)'>
                                  </div>
                                </div>

                                <!--end col-->
                              </div>
                              <!--end row-->

                            </form>
                          </div>
                        </div>
                      </div>

                    </div>
                  </ng-template>
                </li>

                <li ngbNavItem>
                  <a ngbNavLink class=" rounded">
                    <div class="text-center pt-1 pb-1">
                      <h4 class="title font-weight-normal mb-0">Shipment Details</h4>
                    </div>
                  </a>
                  <ng-template ngbNavContent>
                    <div class="row ">


                      <div class="col-lg-6 col-md-7 col-12 mt-4 pt-2 align-items-center">
                        <div class="card rounded shadow border-0 ml-lg-4" data-aos="fade-up" data-aos-duration="1400">
                          <div class="card-body p-4">
                            <form>
                              <div class="row">
                                <div class="col-md-12">
                                  <div class="form-group position-relative">
                                    <label>Delivery Mode : <span class="text-danger">*</span></label>
                                    <div>
                                      <div class="custom-control custom-radio custom-control-inline"
                                        *ngFor='let option of delivery_mode_list;let i= index;'>
                                        <div class="form-group mb-0">
                                          <input type="radio" id="customRadioDel{{i}}" name="customRadioDel"
                                            class="custom-control-input" (click)='onClickDelmode(option)'>
                                          <label class="custom-control-label"
                                            for="customRadioDel{{i}}">{{option.name}}</label>
                                        </div>
                                      </div>

                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-12">
                                  <label>Package Type: <span class="text-danger">*</span></label>
                                </div>
                                <div class="col-md-4">

                                  <div class="form-group position-relative">
                                    <div class="form-group position-relative">
                                      <div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" name='customCheckDoc'
                                          id="customCheckDoc" [(ngModel)]='document_type'
                                          (ngModelChange)='onChangeBoxType()'>
                                        <label class="custom-control-label" style="margin-left:5px"
                                          for="customCheckDoc">Document </label>
                                      </div>
                                    </div>
                                    <!-- <div >
                                            <div class="custom-control custom-radio custom-control-inline" *ngFor='let option of packTypeList;let i= index;'>
                                              <div class="form-group mb-0">
                                                <input type="radio" id="customRadioPacking{{i}}" name="customRadioPacking" class="custom-control-input" (click)='onClickPackingMode(option)'>
                                                <label class="custom-control-label" for="customRadioPacking{{i}}">{{option}}</label>
                                              </div>
                                            </div>
                                           
                                          </div> -->
                                  </div>

                                </div>
                                <div class="col-md-8">

                                  <div class="form-group position-relative">
                                    <div class="form-group position-relative">
                                      <div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" name='customCheckBox'
                                          id="customCheckBox" [(ngModel)]='box_type'>
                                        <label class="custom-control-label" for="customCheckBox"
                                          style="margin-left:5px">Box </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                
                                <div class="col-md-12" *ngIf='document_type==true'>
                                  <div class="form-group position-relative">
                                    <label>Envelope Type: <span class="text-danger">*</span></label>
                                    <div>
                                      <div class="custom-control1 custom-radio custom-control1-inline"
                                        *ngFor='let option of envelopeTypeList;let i= index;'>
                                        <div class="form-group mb-0" style=" display: inline-flex;">
                                          <input type="radio" id="customRadioEnvelope{{i}}" name="customRadioEnvelope"
                                            class="custom-control-input" style="
                                                  margin-bottom: 10px;
                                                  font-family: inherit;
                                                  font-size: inherit;
                                                  line-height: inherit;
                                              ">
                                          <label class="custom-control-label" style="margin-left:7px"
                                            for="customRadioEnvelope{{i}}">{{option}}</label>
                                        </div>
                                      </div>

                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-6" *ngIf='box_type==true'>
                                  <div class="form-group position-relative">
                                    <label>No Of Boxes :</label>
                                    <i-feather name="pack" class="fea icon-sm icons"></i-feather>
                                    <input name="no_of_packs" id="no_of_packs" type="number" min='1'
                                      class="form-control pl-5" [(ngModel)]='no_of_packs' placeholder="No of Boxes:"
                                      (ngModelChange)='onChangenoOfpacks()'>
                                  </div>
                                </div>
                                <div class="col-md-8" *ngIf='box_type==true'>
                                  <div class="form-group position-relative">
                                    <label>Search Category <span class="text-danger">*</span></label>
                                    <select class="form-control custom-select" [(ngModel)]='category' name='category'>
                                      <option *ngFor='let option of categoryList' [selected]='option.type'>
                                        {{option.type}}</option>

                                    </select>
                                  </div>
                                </div>
                                <!--end col-->
                                <div class="col-md-4" *ngIf='box_type==true'>
                                  <a class="btn btn-outline-primary btn-sm" style='margin-top: 30px;font-size: 13px;'
                                    (click)='openModel("Add Items","add","","","")'>Add Items <i
                                      class="mdi mdi-add"></i></a>
                                </div>



                                <!--end col-->


                                <div class="col-md-12" *ngIf='box_type==true'>
                                  <div class="form-group position-relative">
                                    <label>Packages <span class="text-danger">*</span></label>
                                    <select class="form-control custom-select" placeholder="Select a Pack"
                                      [(ngModel)]='package' name='package' (ngModelChange)='onChangePackage()'>
                                      <option value='custom_pack'>Choose Custom Pack</option>
                                      <option *ngFor='let option of packagesList'
                                        [selected]='option.packDetails.transaction_id'>
                                        {{option.packDetails.pack_title}}({{option.packDetails.package_loader}}Kgs)
                                      </option>

                                    </select>
                                  </div>
                                </div>

                                <div class="col-md-6" *ngIf='(package=="custom_pack")||(document_type==true)'>
                                  <div class="form-group position-relative">
                                    <label>Choose Weight :</label>
                                    <i-feather name="pack" class="fea icon-sm icons"></i-feather>
                                    <input name="pack_loader" id="pack_loader" type="number" min='1'
                                      class="form-control pl-5" placeholder="Choose Weight :" [(ngModel)]='pack_loader'
                                      (ngModelChange)='onChangeCustomPackage()'>
                                  </div>
                                </div>
                                <!--end col-->


                                <div class="col-md-12">
                                  <div class="form-group position-relative">
                                    <label>Notes :</label>
                                    <i-feather name="message-circle" class="fea icon-sm icons"></i-feather>
                                    <textarea name="comments" id="comments" rows="4" [(ngModel)]='notes'
                                      class="form-control pl-5" placeholder="Notes About Shipment :"></textarea>
                                  </div>
                                </div>
                                <!--end col-->

                                <!--end col-->
                              </div>
                              <!--end row-->


                              <!--end row-->
                            </form>
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-6 col-md-5 col-12 mt-4 pt-2">
                        <h4 *ngIf='itemsList1.length>0'>Items List</h4>
                        <div class="faq-content mt-4 pt-3">
                          <!-- #acc="ngbAccordion" -->
                          <ngb-accordion activeIds="ngb-panelsupport" closeOthers="true">
                            <ngb-panel cardClass="border-0 rounded shadow mb-2"
                              *ngFor='let option of itemsList1;let j = index;'>
                              <ng-template ngbPanelTitle>
                                <div class="border-0 bg-light" id="heading{{j}}">
                                  <h6 class="title mb-0 text-dark"> {{option.category}} </h6>
                                </div>
                              </ng-template>
                              <ng-template ngbPanelContent>
                                <table class="table">
                                  <thead>
                                    <tr>
                                      <!-- <th>Sno</th> -->
                                      <th>Item</th>
                                      <th>No of Items</th>
                                      <th>Value of Goods</th>
                                      <th>Description</th>
                                      <th>Action</th>
                                    </tr>
                                  </thead>

                                  <tbody>
                                    <tr *ngFor='let option1 of option.items;let i= index;'>
                                      <!-- <td>{{i+1}}</td> -->
                                      <td>{{option1.item}}</td>
                                      <td>{{option1.no_of_items}}</td>
                                      <td>{{option1.value_of_items}}</td>
                                      <td><a href='javascript:void(0)' (click)='details(option1.description)'
                                          *ngIf='option1.description'>Description</a></td>
                                      <td>
                                        <i class="mdi mdi-pencil"
                                          (click)='openModel("Update Item","edit",i,j,option1)'></i>
                                        <i class="mdi mdi-bookmark-remove" (click)='removeItem(i,option,"item")'></i>
                                        <!-- <i class='bx bx-pencil' (click)='openModel("Update Item","edit",i,j,option1)' matTooltip="Edit" ></i>
                                                    <i class='bx bx-trash' matTooltip="Delete" (click)='removeItem(i,option,"item")'></i> -->
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </ng-template>
                            </ngb-panel>




                          </ngb-accordion>

                        </div>
                        <div class="faq-content mt-4 pt-3" *ngIf='(packDetails.length>0)&&(box_type==true)'>
                          <h4>Box Details</h4>
                          <!-- #acc="ngbAccordion" -->
                          <ngb-accordion activeIds="ngb-panelsupport" closeOthers="true">
                            <ngb-panel cardClass="border-0 rounded shadow mb-2"
                              *ngFor='let option of packDetails;let j = index;'>
                              <ng-template ngbPanelTitle>
                                <div class="border-0 bg-light" id="heading{{j}}">
                                  <h6 class="title mb-0 text-dark">Box{{j+1}} Details </h6>
                                </div>
                              </ng-template>
                              <ng-template ngbPanelContent>
                                <div class="row">
                                  <div class="col-md-6">
                                    <div class="form-group position-relative">
                                      <label>Width (In Inches) :</label>
                                      <i-feather name="pack" class="fea icon-sm icons"></i-feather>
                                      <input name="width{{j}}" id="width{{j}}" type="number" min='0'
                                        class="form-control pl-5" placeholder="Width (In Inches) :"
                                        [(ngModel)]='option.width'>
                                    </div>
                                  </div>
                                  <div class="col-md-6">
                                    <div class="form-group position-relative">
                                      <label>Height (In Inches) :</label>
                                      <i-feather name="pack" class="fea icon-sm icons"></i-feather>
                                      <input name="Height{{j}}" id="Height{{j}}" type="number" min='0'
                                        class="form-control pl-5" placeholder="Height (In Inches) :"
                                        [(ngModel)]='option.height'>
                                    </div>
                                  </div>
                                  <div class="col-md-6">
                                    <div class="form-group position-relative">
                                      <label>Depth (In Inches) :</label>
                                      <i-feather name="pack" class="fea icon-sm icons"></i-feather>
                                      <input name="Depth{{j}}" id="Depth{{j}}" type="number" min='0'
                                        class="form-control pl-5" placeholder="Depth (In Inches) :"
                                        [(ngModel)]='option.depth'>
                                    </div>
                                  </div>
                                  <div class="col-md-6">
                                    <div class="form-group position-relative">
                                      <label>Weight (In Kgs) :</label>
                                      <i-feather name="pack" class="fea icon-sm icons"></i-feather>
                                      <input name="Weight{{j}}" id="Weight{{j}}" type="number" min='0'
                                        class="form-control pl-5" placeholder="Weight (In Kgs) :"
                                        [(ngModel)]='option.weight'>
                                    </div>
                                  </div>
                                </div>

                              </ng-template>
                            </ngb-panel>
                          </ngb-accordion>
                        </div>


                      </div>
                    </div>
                  </ng-template>
                </li>

                <li ngbNavItem>
                  <a ngbNavLink class=" rounded">
                    <div class="text-center pt-1 pb-1">
                      <h4 class="title font-weight-normal mb-0"> Payment Details</h4>
                    </div>
                  </a>
                  <ng-template ngbNavContent>
                    <div class="row">

                      <div class="col-lg-6 col-md-5 col-12 mt-4 pt-2">
                        <div class="card rounded shadow border-0 ml-lg-4" data-aos="fade-up" data-aos-duration="1400">
                          <div class="card-body p-4">

                            <div class="rounded shadow-lg p-4">
                              <div class="d-flex mb-4 justify-content-between">
                                <h4 style='color: #0068b3;'>Summary</h4>
                                <!-- <a class="text-muted h6">Summary</a> -->
                              </div>
                              <div class="table-responsive">
                                <table class="table table-center table-padding mb-0" *ngIf='show_summary'>
                                  <tbody>
                                    <tr>
                                      <td class="h6 border-0">Total Volume </td>
                                      <td class="text-center font-weight-bold border-0">{{pack_loader}} Kgs</td>
                                    </tr>
                                    <tr>
                                      <td class="h6"> Gross Amt </td>
                                      <td class="text-center font-weight-bold">{{freight_rate| currency:'INR':true}}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td class="h6"> Discount Amt </td>
                                      <td class="text-center font-weight-bold" style='color: red;'>
                                        -{{discount_amt|currency:'INR':true}}</td>
                                    </tr>
                                    <tr>
                                      <td class="h6"> Total Gross Amt </td>
                                      <td class="text-center font-weight-bold">
                                        {{discount_freight_amt|currency:'INR':true}}</td>
                                    </tr>
                                    <tr>
                                      <td class="h6"> Tax({{total_tax_per}}%) </td>
                                      <td class="text-center font-weight-bold">{{gst_price|currency:'INR':true}}</td>
                                    </tr>
                                    <tr class="bg-light">
                                      <td class="h5 font-weight-bold">Total Price</td>
                                      <td class="text-center text-primary h4 font-weight-bold">
                                        {{total_selling_price|currency:'INR':true}}</td>
                                    </tr>
                                  </tbody>
                                </table>

                                <!-- <ul class="list-unstyled mt-4 mb-0">
                                        <li *ngFor='let option of paymodeList;let i= index;'>
                                          <div class="custom-control custom-radio custom-control-inline">
                                            <div class="custom-control custom-radio custom-control-inline" >
                                              <div class="form-group mb-0">
                                                <input type="radio" id="customRadioPay{{i}}" name="customRadioPay" class="custom-control-input" (click)='onClickPaymentMode(option)'>
                                                <label class="custom-control-label" for="customRadioPay{{i}}">{{option}}</label>
                                              </div>
                                            </div>
                                          </div>
                                        </li>
                          
                                       
                                      </ul> -->
                                <div class="mt-4 pt-2">
                                  <div class="form-group position-relative">
                                    <div class="custom-control custom-checkbox">
                                      <input type="checkbox" class="custom-control-input" id="customCheck2"
                                        [(ngModel)]='apply_couponcheck' (ngModelChange)='onChangeCoupon()'>
                                      <label class="custom-control-label" for="customCheck2">Apply Coupon </label>
                                    </div>
                                  </div>
                                </div>

                                <div class="mt-4 pt-2" *ngIf='discount_type=="Coupon"'>
                                  <div class="form-group position-relative">
                                    <label>Enter Coupon Code :</label>
                                    <i-feather name="pack" class="fea icon-sm icons"></i-feather>
                                    <input name=" Coupon_Code" id=" Coupon_Code" type="text" class="form-control pl-5"
                                      placeholder=" Coupon Code:" [(ngModel)]='Coupon'>
                                  </div>
                                </div>
                                <span style='color: red;'>{{couponError}}</span>

                                <a class="btn btn-primary" (click)='onCouponApply()'
                                  *ngIf='discount_type=="Coupon"'>Apply Coupon </a>


                                <div class="mt-4 pt-2">
                                  <div class="form-group position-relative">
                                    <div class="custom-control custom-checkbox">
                                      <input type="checkbox" class="custom-control-input" id="customCheck1"
                                        [(ngModel)]='i_accept'>
                                      <label class="custom-control-label" for="customCheck1">I Accept <a
                                          routerLink='/page-terms' class="text-primary">Terms And Condition</a></label>
                                    </div>
                                  </div>
                                </div>



                                <div class="mt-4 pt-2">
                                  <a class="btn btn-block btn-primary" (click)='initPayPalButton()'>Pay Now</a>
                                </div>

                                <div id="smart-button-container" style='margin-top: 10px;'>
                                  <div style="text-align: center;">
                                    <div id="paypal-button-container"></div>
                                  </div>
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>

                        <!-- <img src="assets/images/customer/XS1.png" data-aos="fade-up" data-aos-duration="1400" class="img-fluid" -->
                        <!-- alt=""> -->
                      </div>

                      <div class="col-lg-6 col-md-7 col-12 mt-4 pt-2">
                        <div class="card rounded shadow border-0 ml-lg-4" data-aos="fade-up" data-aos-duration="1400">
                          <div class="card-body p-4">
                            <div>
                              <div class="form-group position-relative">
                                <div class="custom-control custom-checkbox">
                                  <input type="checkbox" class="custom-control-input" id="customCheckPick"
                                    [(ngModel)]='show_pickUp'>
                                  <label class="custom-control-label" for="customCheckPick">Confirm Your PickUp Address
                                  </label>
                                </div>
                              </div>
                            </div>
                            <!-- <h4 *ngIf='show_pickUp'>PickUp Address</h4> -->
                            <form *ngIf='show_pickUp'>
                              <div class="row">

                                <div class="col-md-6">
                                  <div class="form-group position-relative">
                                    <label>Your Name :</label>
                                    <i-feather name="user" class="fea icon-sm icons"></i-feather>
                                    <input name="s_name" id="s_name" type="text" class="form-control pl-5"
                                      [(ngModel)]="s_name" placeholder="First Name :">
                                  </div>
                                </div>
                                <!--end col-->

                                <div class="col-md-6">
                                  <div class="form-group position-relative">
                                    <label>Your Mobile No :</label>
                                    <i-feather name="phone" class="fea icon-sm icons"></i-feather>
                                    <input name="s_mobileno" id="s_mobileno" type="text" class="form-control pl-5"
                                      [(ngModel)]="s_mobileno" placeholder="Your Mobile No :"
                                      (keypress)='numberOnlyMob($event)'>
                                  </div>
                                </div>
                                <div class="col-md-12" *ngIf='!app_role_name'>
                                  <div class="form-group position-relative">
                                    <label>Your Email :</label>
                                    <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                                    <input name="s_email" id="s_email" type="email" class="form-control pl-5"
                                      placeholder="Your email :" [(ngModel)]="username">
                                  </div>
                                </div>
                                <!--end col-->
                                <div class="col-md-12">
                                  <div class="form-group position-relative">
                                    <label>Search your Pickup Location :</label>
                                    <i-feather name="map-pin" class="fea icon-sm icons"></i-feather>
                                    <input name="desct_search" id="desct_search" type="text" class="form-control pl-5"
                                      placeholder="Search Your location:" autocorrect="off" autocapitalize="off"
                                      spellcheck="off" type="text" #sec_search>
                                    <!-- (keydown.enter)="$event.preventDefault()" (keyup)="changeAdr('sec')" -->
                                  </div>
                                </div>

                                <div class="col-md-12">
                                  <div class="form-group position-relative">
                                    <label>Country <span class="text-danger">*</span></label>
                                    <select class="form-control custom-select" [(ngModel)]='s_country' name='s_country'>
                                      <option *ngFor='let option of countryList' [selected]='option.name'>
                                        {{option.name}}</option>

                                    </select>
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div class="form-group position-relative">
                                    <label>House/Flat No :</label>
                                    <i-feather name="book" class="fea icon-sm icons"></i-feather>
                                    <input name="s_flat_no" id="s_flat_no" class="form-control pl-5"
                                      placeholder="House/Flat no :" [(ngModel)]='s_flat_no'>
                                  </div>
                                </div>

                                <div class="col-md-6">
                                  <div class="form-group position-relative">
                                    <label>Steet Address :</label>
                                    <i-feather name="book" class="fea icon-sm icons"></i-feather>
                                    <input name="s_street_name" id="s_street_name" class="form-control pl-5"
                                      placeholder="Street Address :" [(ngModel)]='s_street_name'>
                                  </div>
                                </div>

                                <div class="col-md-6">
                                  <div class="form-group position-relative">
                                    <label>City :</label>
                                    <i-feather name="book" class="fea icon-sm icons"></i-feather>
                                    <input name="s_city" id="s_city" class="form-control pl-5" placeholder="City :"
                                      [(ngModel)]='s_city'>
                                  </div>
                                </div>

                                <div class="col-md-6">
                                  <div class="form-group position-relative">
                                    <label>State :</label>
                                    <i-feather name="book" class="fea icon-sm icons"></i-feather>
                                    <input name="s_state" id="s_state" class="form-control pl-5" placeholder="State :"
                                      [(ngModel)]='s_state'>
                                  </div>
                                </div>

                                <div class="col-md-6">
                                  <div class="form-group position-relative">
                                    <label>ZipCode :</label>
                                    <i-feather name="book" class="fea icon-sm icons"></i-feather>
                                    <input name="s_zipcode" id="s_zipcode" class="form-control pl-5"
                                      placeholder="ZipCode :" [(ngModel)]='s_zipcode'
                                      (keypress)='numberOnlyMob($event)'>
                                  </div>
                                </div>
                                <div class="col-md-6"></div>
                                <div class="col-md-6">
                                  <div class="form-group position-relative">
                                    <label>Choose Id Proof <span class="text-danger">*</span></label>
                                    <select class="form-control custom-select" placeholder="Select a Id proof"
                                      [(ngModel)]='docs_for_ver' name='docs_for_ver'>
                                      <option value='Aadhar'>Aadhar</option>
                                      <option value='Voter_id'>Voter Id</option>
                                      <option value='Passport'>Passport</option>

                                    </select>
                                  </div>
                                </div>

                                <div class="col-md-6">
                                  <div class="form-group position-relative">
                                    <label>Upload Id Proof :</label>
                                    <input type="file" class="form-control-file" id="fileupload1"
                                      (change)='fileChangeListener($event,"id_proof")' accept="image/*">
                                  </div>
                                </div>

                                <div class="col-md-6">
                                  <div class="form-group position-relative">
                                    <label>Choose an Address Proof <span class="text-danger">*</span></label>
                                    <select class="form-control custom-select" placeholder="Choose an Address Proof"
                                      [(ngModel)]='address_proof_doc' name='address_proof_doc'>
                                      <option value='Aadhar'>Aadhar</option>
                                      <option value='Voter_id'>Voter Id</option>
                                      <option value='Passport'>Passport</option>
                                      <option value='Driving License'>Driving License</option>
                                    </select>
                                  </div>
                                </div>

                                <div class="col-md-6">
                                  <div class="form-group position-relative">
                                    <label>Upload Address Proof :</label>
                                    <input type="file" class="form-control-file" id="fileupload2"
                                      (change)='fileChangeListener($event,"address_proof")' accept="image/*">
                                  </div>
                                </div>
                                <span style='color: red;'>{{file_error}}</span>

                                <!--end col-->

                              </div>
                              <!--end row-->

                              <!-- <div class="row">
                                      <div class="col-sm-12">
                                        <input type="submit" id="submit" name="send" class="btn btn-primary" value="Submit">
                                      </div>
                                    </div> -->
                              <!--end row-->
                            </form>
                          </div>
                        </div>
                      </div>

                    </div>
                  </ng-template>
                </li>

              </ul>
              <div [ngbNavOutlet]="nav" class="pt-2"></div>
            </div>
            <!--end col-->
          </div>
          <!--end row-->
        </div>

      </div>
    </div>
  </div>
</section>
<!-- Communiaction Priority End -->


<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-soft-primary back-to-top" id="back-to-top"
  [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->

<div class="container">

  <!-- The Modal -->
  <div class="modal fade" id="myModal2">
    <div class="modal-dialog modal-md" style="max-width:600px">
      <div class="modal-content">

        <!-- Modal Header -->
        <div class="modal-header">
          <h4 class="modal-title" style="padding-bottom:7px">{{modal_heading}}</h4>
          <button type="button" class="close" data-dismiss="modal">&times;</button>
        </div>

        <!-- Modal body -->
        <div class="modal-body" style="padding: 20px; padding-top:20px">
          <div class="container-fluid">
            <div class="my-container-popup">
              <div class="content-form">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group position-relative">
                      <label>Search Category <span class="text-danger">*</span></label>
                      <select class="form-control custom-select" [(ngModel)]='category' name='category'>
                        <option *ngFor='let option of categoryList' [selected]='option.type'>{{option.type}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group position-relative">
                      <label>Item :</label>
                      <i-feather name="book" class="fea icon-sm icons"></i-feather>
                      <input name="item" id="item" class="form-control pl-5" placeholder="Item :" [(ngModel)]='item'>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group position-relative">
                      <label>No Of Items :</label>
                      <i-feather name="book" class="fea icon-sm icons"></i-feather>
                      <input name="no_of_items" id="no_of_items" type='number' class="form-control pl-5"
                        placeholder="No OfItems :" [(ngModel)]='no_of_items'>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group position-relative">
                      <label>Value Of Goods :</label>
                      <i-feather name="book" class="fea icon-sm icons"></i-feather>
                      <input name="value_of_goods" id="value_of_goods" type='number' class="form-control pl-5"
                        placeholder="Value of Goods :" [(ngModel)]='value_of_goods'>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group position-relative">
                      <label>Description :</label>
                      <i-feather name="message-circle" class="fea icon-sm icons"></i-feather>
                      <textarea name="Description" id="Description" rows="4" [(ngModel)]='descriptionModal'
                        class="form-control pl-5" placeholder="Description About Item :"></textarea>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group position-relative">
                      <label>Upload Bill :</label>
                      <input type="file" class="form-control-file" id="fileupload"
                        (change)='fileChangeListenerItem($event)' accept="image/*">
                    </div>
                  </div>

                  <span style="color: red;">{{pop_up_error}}</span>
                </div>

              </div>
            </div>
          </div>
        </div>

        <!-- Modal footer -->
        <div class="modal-footer" style="border-top:1px solid #eee;">
          <button mat-button color="primary" data-dismiss="modal" style='margin-right: 10px;'>Cancel</button>
          <button mat-button color="primary" (click)="saveModalData()">Save</button>
          <!--data-dismiss="modal"-->
        </div>

      </div>
    </div>
  </div>

</div>


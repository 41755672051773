import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Global } from 'src/app/global';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-page-privacy',
  templateUrl: './page-privacy.component.html',
  styleUrls: ['./page-privacy.component.css']
})

/**
 * PAge Privacy Component
 */
export class PagePrivacyComponent implements OnInit {

  // Set Topbar Option
  Menuoption = 'center';
  Settingicon = true;
  list:any;
data1:any;
titleId:any;
date:any;
  constructor(private appService: AppService, private route: ActivatedRoute,private router: Router) { 
   this.titleId = this.route.snapshot.params['id'] ?? '';
   this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  async ngOnInit(){
   this.getList();
    
   
  }

  ngAfterViewInit()
  {
   
  }

   getList(){
    
    let obj = {
      "userId": "admin@finobooks.com",
      "cid": 1046
      
    }
    debugger
     this.appService.getDataById(Global.WebLinkedLookup_EndPoint +'GetCompanyWebPolicyLookup/',1046 +'/'+0 )
    .subscribe({
      next: (res: any) => {
        debugger;
        this.data1 = res.filter((e:any) => e.titleId == this.titleId)
        this.date = formatDate(this.data1[0].insertedDate, 'yyyy-MM-dd', 'en-US')
     
      },
      error: (error) => {
        console.log(error);
      }
    })

    
  }
  

  
  

 
}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class OnlyCountriesService {
  countriesList = [ 
    { code: "AF", code3: "AFG", name: "Afghanistan", countryId: "004" },
    { code: "AL", code3: "ALB", name: "Albania", countryId: "008" },
    { code: "DZ", code3: "DZA", name: "Algeria", countryId: "012" },
    { code: "AS", code3: "ASM", name: "American Samoa", countryId: "016" },
    { code: "AD", code3: "AND", name: "Andorra", countryId: "020" },
    { code: "AO", code3: "AGO", name: "Angola", countryId: "024" },
    { code: "AI", code3: "AIA", name: "Anguilla", countryId: "660" },
    { code: "AQ", code3: "ATA", name: "Antarctica", countryId: "010" },
    { code: "AG", code3: "ATG", name: "Antigua and Barbuda", countryId: "028" },
    { code: "AR", code3: "ARG", name: "Argentina", countryId: "032" },
    { code: "AM", code3: "ARM", name: "Armenia", countryId: "051" },
    { code: "AW", code3: "ABW", name: "Aruba", countryId: "533" },
    { code: "AU", code3: "AUS", name: "Australia", countryId: "036" },
    { code: "AT", code3: "AUT", name: "Austria", countryId: "040" },
    { code: "AZ", code3: "AZE", name: "Azerbaijan", countryId: "031" },
    { code: "BS", code3: "BHS", name: "Bahamas ", countryId: "044" },
    { code: "BH", code3: "BHR", name: "Bahrain", countryId: "048" },
    { code: "BD", code3: "BGD", name: "Bangladesh", countryId: "050" },
    { code: "BB", code3: "BRB", name: "Barbados", countryId: "052" },
    { code: "BY", code3: "BLR", name: "Belarus", countryId: "112" },
    { code: "BE", code3: "BEL", name: "Belgium", countryId: "056" },
    { code: "BZ", code3: "BLZ", name: "Belize", countryId: "084" },
    { code: "BJ", code3: "BEN", name: "Benin", countryId: "204" },
    { code: "BM", code3: "BMU", name: "Bermuda", countryId: "060" },
    { code: "BT", code3: "BTN", name: "Bhutan", countryId: "064" },
    { code: "BO", code3: "BOL", name: "Bolivia (Plurinational State of)", countryId: "068" },
    { code: "BQ", code3: "BES", name: "Bonaire, Sint Eustatius and Saba", countryId: "535" },
    { code: "BA", code3: "BIH", name: "Bosnia and Herzegovina", countryId: "070" },
    { code: "BW", code3: "BWA", name: "Botswana", countryId: "072" },
    { code: "BV", code3: "BVT", name: "Bouvet Island", countryId: "074" },
    { code: "BR", code3: "BRA", name: "Brazil", countryId: "076" },
    { code: "IO", code3: "IOT", name: "British Indian Ocean Territory ", countryId: "086" },
    { code: "BN", code3: "BRN", name: "Brunei Darussalam", countryId: "096" },
    { code: "BG", code3: "BGR", name: "Bulgaria", countryId: "100" },
    { code: "BF", code3: "BFA", name: "Burkina Faso", countryId: "854" },
    { code: "BI", code3: "BDI", name: "Burundi", countryId: "108" },
    { code: "CV", code3: "CPV", name: "Cabo Verde", countryId: "132" },
    { code: "KH", code3: "KHM", name: "Cambodia", countryId: "116" },
    { code: "CM", code3: "CMR", name: "Cameroon", countryId: "120" },
    { code: "CA", code3: "CAN", name: "Canada", countryId: "124" },
    { code: "KY", code3: "CYM", name: "Cayman Islands ", countryId: "136" },
    { code: "CF", code3: "CAF", name: "Central African Republic ", countryId: "140" },
    { code: "TD", code3: "TCD", name: "Chad", countryId: "148" },
    { code: "CL", code3: "CHL", name: "Chile", countryId: "152" },
    { code: "CN", code3: "CHN", name: "China", countryId: "156" },
    { code: "CX", code3: "CXR", name: "Christmas Island", countryId: "162" },
    { code: "CC", code3: "CCK", name: "Cocos (Keeling) Islands ", countryId: "166" },
    { code: "CO", code3: "COL", name: "Colombia", countryId: "170" },
    { code: "KM", code3: "COM", name: "Comoros ", countryId: "174" },
    { code: "CD", code3: "COD", name: "Congo (the Democratic Republic of the)", countryId: "180" },
    { code: "CG", code3: "COG", name: "Congo ", countryId: "178" },
    { code: "CK", code3: "COK", name: "Cook Islands ", countryId: "184" },
    { code: "CR", code3: "CRI", name: "Costa Rica", countryId: "188" },
    { code: "HR", code3: "HRV", name: "Croatia", countryId: "191" },
    { code: "CU", code3: "CUB", name: "Cuba", countryId: "192" },
    { code: "CW", code3: "CUW", name: "Curaçao", countryId: "531" },
    { code: "CY", code3: "CYP", name: "Cyprus", countryId: "196" },
    { code: "CZ", code3: "CZE", name: "Czechia", countryId: "203" },
    { code: "CI", code3: "CIV", name: "Côte d'Ivoire", countryId: "384" },
    { code: "DK", code3: "DNK", name: "Denmark", countryId: "208" },
    { code: "DJ", code3: "DJI", name: "Djibouti", countryId: "262" },
    { code: "DM", code3: "DMA", name: "Dominica", countryId: "212" },
    { code: "DO", code3: "DOM", name: "Dominican Republic ", countryId: "214" },
    { code: "EC", code3: "ECU", name: "Ecuador", countryId: "218" },
    { code: "EG", code3: "EGY", name: "Egypt", countryId: "818" },
    { code: "SV", code3: "SLV", name: "El Salvador", countryId: "222" },
    { code: "GQ", code3: "GNQ", name: "Equatorial Guinea", countryId: "226" },
    { code: "ER", code3: "ERI", name: "Eritrea", countryId: "232" },
    { code: "EE", code3: "EST", name: "Estonia", countryId: "233" },
    { code: "SZ", code3: "SWZ", name: "Eswatini", countryId: "748" },
    { code: "ET", code3: "ETH", name: "Ethiopia", countryId: "231" },
    { code: "FK", code3: "FLK", name: "Falkland Islands  [Malvinas]", countryId: "238" },
    { code: "FO", code3: "FRO", name: "Faroe Islands ", countryId: "234" },
    { code: "FJ", code3: "FJI", name: "Fiji", countryId: "242" },
    { code: "FI", code3: "FIN", name: "Finland", countryId: "246" },
    { code: "FR", code3: "FRA", name: "France", countryId: "250" },
    { code: "GF", code3: "GUF", name: "French Guiana", countryId: "254" },
    { code: "PF", code3: "PYF", name: "French Polynesia", countryId: "258" },
    { code: "TF", code3: "ATF", name: "French Southern Territories ", countryId: "260" },
    { code: "GA", code3: "GAB", name: "Gabon", countryId: "266" },
    { code: "GM", code3: "GMB", name: "Gambia ", countryId: "270" },
    { code: "GE", code3: "GEO", name: "Georgia", countryId: "268" },
    { code: "DE", code3: "DEU", name: "Germany", countryId: "276" },
    { code: "GH", code3: "GHA", name: "Ghana", countryId: "288" },
    { code: "GI", code3: "GIB", name: "Gibraltar", countryId: "292" },
    { code: "GR", code3: "GRC", name: "Greece", countryId: "300" },
    { code: "GL", code3: "GRL", name: "Greenland", countryId: "304" },
    { code: "GD", code3: "GRD", name: "Grenada", countryId: "308" },
    { code: "GP", code3: "GLP", name: "Guadeloupe", countryId: "312" },
    { code: "GU", code3: "GUM", name: "Guam", countryId: "316" },
    { code: "GT", code3: "GTM", name: "Guatemala", countryId: "320" },
    { code: "GG", code3: "GGY", name: "Guernsey", countryId: "831" },
    { code: "GN", code3: "GIN", name: "Guinea", countryId: "324" },
    { code: "GW", code3: "GNB", name: "Guinea-Bissau", countryId: "624" },
    { code: "GY", code3: "GUY", name: "Guyana", countryId: "328" },
    { code: "HT", code3: "HTI", name: "Haiti", countryId: "332" },
    { code: "HM", code3: "HMD", name: "Heard Island and McDonald Islands", countryId: "334" },
    { code: "VA", code3: "VAT", name: "Holy See ", countryId: "336" },
    { code: "HN", code3: "HND", name: "Honduras", countryId: "340" },
    { code: "HK", code3: "HKG", name: "Hong Kong", countryId: "344" },
    { code: "HU", code3: "HUN", name: "Hungary", countryId: "348" },
    { code: "IS", code3: "ISL", name: "Iceland", countryId: "352" },
    { code: "IN", code3: "IND", name: "India", countryId: "356" },
    { code: "ID", code3: "IDN", name: "Indonesia", countryId: "360" },
    { code: "IR", code3: "IRN", name: "Iran (Islamic Republic of)", countryId: "364" },
    { code: "IQ", code3: "IRQ", name: "Iraq", countryId: "368" },
    { code: "IE", code3: "IRL", name: "Ireland", countryId: "372" },
    { code: "IM", code3: "IMN", name: "Isle of Man", countryId: "833" },
    { code: "IL", code3: "ISR", name: "Israel", countryId: "376" },
    { code: "IT", code3: "ITA", name: "Italy", countryId: "380" },
    { code: "JM", code3: "JAM", name: "Jamaica", countryId: "388" },
    { code: "JP", code3: "JPN", name: "Japan", countryId: "392" },
    { code: "JE", code3: "JEY", name: "Jersey", countryId: "832" },
    { code: "JO", code3: "JOR", name: "Jordan", countryId: "400" },
    { code: "KZ", code3: "KAZ", name: "Kazakhstan", countryId: "398" },
    { code: "KE", code3: "KEN", name: "Kenya", countryId: "404" },
    { code: "KI", code3: "KIR", name: "Kiribati", countryId: "296" },
    { code: "KP", code3: "PRK", name: "Korea (the Democratic People's Republic of)", countryId: "408" },
    { code: "KR", code3: "KOR", name: "Korea (the Republic of)", countryId: "410" },
    { code: "KW", code3: "KWT", name: "Kuwait", countryId: "414" },
    { code: "KG", code3: "KGZ", name: "Kyrgyzstan", countryId: "417" },
    { code: "LA", code3: "LAO", name: "Lao People's Democratic Republic ", countryId: "418" },
    { code: "LV", code3: "LVA", name: "Latvia", countryId: "428" },
    { code: "LB", code3: "LBN", name: "Lebanon", countryId: "422" },
    { code: "LS", code3: "LSO", name: "Lesotho", countryId: "426" },
    { code: "LR", code3: "LBR", name: "Liberia", countryId: "430" },
    { code: "LY", code3: "LBY", name: "Libya", countryId: "434" },
    { code: "LI", code3: "LIE", name: "Liechtenstein", countryId: "438" },
    { code: "LT", code3: "LTU", name: "Lithuania", countryId: "440" },
    { code: "LU", code3: "LUX", name: "Luxembourg", countryId: "442" },
    { code: "MO", code3: "MAC", name: "Macao", countryId: "446" },
    { code: "MG", code3: "MDG", name: "Madagascar", countryId: "450" },
    { code: "MW", code3: "MWI", name: "Malawi", countryId: "454" },
    { code: "MY", code3: "MYS", name: "Malaysia", countryId: "458" },
    { code: "MV", code3: "MDV", name: "Maldives", countryId: "462" },
    { code: "ML", code3: "MLI", name: "Mali", countryId: "466" },
    { code: "MT", code3: "MLT", name: "Malta", countryId: "470" },
    { code: "MH", code3: "MHL", name: "Marshall Islands ", countryId: "584" },
    { code: "MQ", code3: "MTQ", name: "Martinique", countryId: "474" },
    { code: "MR", code3: "MRT", name: "Mauritania", countryId: "478" },
    { code: "MU", code3: "MUS", name: "Mauritius", countryId: "480" },
    { code: "YT", code3: "MYT", name: "Mayotte", countryId: "175" },
    { code: "MX", code3: "MEX", name: "Mexico", countryId: "484" },
    { code: "FM", code3: "FSM", name: "Micronesia (Federated States of)", countryId: "583" },
    { code: "MD", code3: "MDA", name: "Moldova (the Republic of)", countryId: "498" },
    { code: "MC", code3: "MCO", name: "Monaco", countryId: "492" },
    { code: "MN", code3: "MNG", name: "Mongolia", countryId: "496" },
    { code: "ME", code3: "MNE", name: "Montenegro", countryId: "499" },
    { code: "MS", code3: "MSR", name: "Montserrat", countryId: "500" },
    { code: "MA", code3: "MAR", name: "Morocco", countryId: "504" },
    { code: "MZ", code3: "MOZ", name: "Mozambique", countryId: "508" },
    { code: "MM", code3: "MMR", name: "Myanmar", countryId: "104" },
    { code: "NA", code3: "NAM", name: "Namibia", countryId: "516" },
    { code: "NR", code3: "NRU", name: "Nauru", countryId: "520" },
    { code: "NP", code3: "NPL", name: "Nepal", countryId: "524" },
    { code: "NL", code3: "NLD", name: "Netherlands ", countryId: "528" },
    { code: "NC", code3: "NCL", name: "New Caledonia", countryId: "540" },
    { code: "NZ", code3: "NZL", name: "New Zealand", countryId: "554" },
    { code: "NI", code3: "NIC", name: "Nicaragua", countryId: "558" },
    { code: "NE", code3: "NER", name: "Niger ", countryId: "562" },
    { code: "NG", code3: "NGA", name: "Nigeria", countryId: "566" },
    { code: "NU", code3: "NIU", name: "Niue", countryId: "570" },
    { code: "NF", code3: "NFK", name: "Norfolk Island", countryId: "574" },
    { code: "MP", code3: "MNP", name: "Northern Mariana Islands ", countryId: "580" },
    { code: "NO", code3: "NOR", name: "Norway", countryId: "578" },
    { code: "OM", code3: "OMN", name: "Oman", countryId: "512" },
    { code: "PK", code3: "PAK", name: "Pakistan", countryId: "586" },
    { code: "PW", code3: "PLW", name: "Palau", countryId: "585" },
    { code: "PS", code3: "PSE", name: "Palestine, State of", countryId: "275" },
    { code: "PA", code3: "PAN", name: "Panama", countryId: "591" },
    { code: "PG", code3: "PNG", name: "Papua New Guinea", countryId: "598" },
    { code: "PY", code3: "PRY", name: "Paraguay", countryId: "600" },
    { code: "PE", code3: "PER", name: "Peru", countryId: "604" },
    { code: "PH", code3: "PHL", name: "Philippines ", countryId: "608" },
    { code: "PN", code3: "PCN", name: "Pitcairn", countryId: "612" },
    { code: "PL", code3: "POL", name: "Poland", countryId: "616" },
    { code: "PT", code3: "PRT", name: "Portugal", countryId: "620" },
    { code: "PR", code3: "PRI", name: "Puerto Rico", countryId: "630" },
    { code: "QA", code3: "QAT", name: "Qatar", countryId: "634" },
    { code: "MK", code3: "MKD", name: "Republic of North Macedonia", countryId: "807" },
    { code: "RO", code3: "ROU", name: "Romania", countryId: "642" },
    { code: "RU", code3: "RUS", name: "Russian Federation ", countryId: "643" },
    { code: "RW", code3: "RWA", name: "Rwanda", countryId: "646" },
    { code: "RE", code3: "REU", name: "Réunion", countryId: "638" },
    { code: "BL", code3: "BLM", name: "Saint Barthélemy", countryId: "652" },
    { code: "SH", code3: "SHN", name: "Saint Helena, Ascension and Tristan da Cunha", countryId: "654" },
    { code: "KN", code3: "KNA", name: "Saint Kitts and Nevis", countryId: "659" },
    { code: "LC", code3: "LCA", name: "Saint Lucia", countryId: "662" },
    { code: "MF", code3: "MAF", name: "Saint Martin (French part)", countryId: "663" },
    { code: "PM", code3: "SPM", name: "Saint Pierre and Miquelon", countryId: "666" },
    { code: "VC", code3: "VCT", name: "Saint Vincent and the Grenadines", countryId: "670" },
    { code: "WS", code3: "WSM", name: "Samoa", countryId: "882" },
    { code: "SM", code3: "SMR", name: "San Marino", countryId: "674" },
    { code: "ST", code3: "STP", name: "Sao Tome and Principe", countryId: "678" },
    { code: "SA", code3: "SAU", name: "Saudi Arabia", countryId: "682" },
    { code: "SN", code3: "SEN", name: "Senegal", countryId: "686" },
    { code: "RS", code3: "SRB", name: "Serbia", countryId: "688" },
    { code: "SC", code3: "SYC", name: "Seychelles", countryId: "690" },
    { code: "SL", code3: "SLE", name: "Sierra Leone", countryId: "694" },
    { code: "SG", code3: "SGP", name: "Singapore", countryId: "702" },
    { code: "SX", code3: "SXM", name: "Sint Maarten (Dutch part)", countryId: "534" },
    { code: "SK", code3: "SVK", name: "Slovakia", countryId: "703" },
    { code: "SI", code3: "SVN", name: "Slovenia", countryId: "705" },
    { code: "SB", code3: "SLB", name: "Solomon Islands", countryId: "090" },
    { code: "SO", code3: "SOM", name: "Somalia", countryId: "706" },
    { code: "ZA", code3: "ZAF", name: "South Africa", countryId: "710" },
    { code: "GS", code3: "SGS", name: "South Georgia and the South Sandwich Islands", countryId: "239" },
    { code: "SS", code3: "SSD", name: "South Sudan", countryId: "728" },
    { code: "ES", code3: "ESP", name: "Spain", countryId: "724" },
    { code: "LK", code3: "LKA", name: "Sri Lanka", countryId: "144" },
    { code: "SD", code3: "SDN", name: "Sudan ", countryId: "729" },
    { code: "SR", code3: "SUR", name: "Suriname", countryId: "740" },
    { code: "SJ", code3: "SJM", name: "Svalbard and Jan Mayen", countryId: "744" },
    { code: "SE", code3: "SWE", name: "Sweden", countryId: "752" },
    { code: "CH", code3: "CHE", name: "Switzerland", countryId: "756" },
    { code: "SY", code3: "SYR", name: "Syrian Arab Republic", countryId: "760" },
    { code: "TW", code3: "TWN", name: "Taiwan", countryId: "158" },
    { code: "TJ", code3: "TJK", name: "Tajikistan", countryId: "762" },
    { code: "TZ", code3: "TZA", name: "Tanzania, United Republic of", countryId: "834" },
    { code: "TH", code3: "THA", name: "Thailand", countryId: "764" },
    { code: "TL", code3: "TLS", name: "Timor-Leste", countryId: "626" },
    { code: "TG", code3: "TGO", name: "Togo", countryId: "768" },
    { code: "TK", code3: "TKL", name: "Tokelau", countryId: "772" },
    { code: "TO", code3: "TON", name: "Tonga", countryId: "776" },
    { code: "TT", code3: "TTO", name: "Trinidad and Tobago", countryId: "780" },
    { code: "TN", code3: "TUN", name: "Tunisia", countryId: "788" },
    { code: "TR", code3: "TUR", name: "Turkey", countryId: "792" },
    { code: "TM", code3: "TKM", name: "Turkmenistan", countryId: "795" },
    { code: "TC", code3: "TCA", name: "Turks and Caicos Islands ", countryId: "796" },
    { code: "TV", code3: "TUV", name: "Tuvalu", countryId: "798" },
    { code: "UG", code3: "UGA", name: "Uganda", countryId: "800" },
    { code: "UA", code3: "UKR", name: "Ukraine", countryId: "804" },
    { code: "AE", code3: "ARE", name: "United Arab Emirates ", countryId: "784" },
    { code: "GB", code3: "GBR", name: "United Kingdom of Great Britain and Northern Ireland ", countryId: "826" },
    { code: "UM", code3: "UMI", name: "United States Minor Outlying Islands ", countryId: "581" },
    { code: "US", code3: "USA", name: "United States of America ", countryId: "840" },
    { code: "UY", code3: "URY", name: "Uruguay", countryId: "858" },
    { code: "UZ", code3: "UZB", name: "Uzbekistan", countryId: "860" },
    { code: "VU", code3: "VUT", name: "Vanuatu", countryId: "548" },
    { code: "VE", code3: "VEN", name: "Venezuela (Bolivarian Republic of)", countryId: "862" },
    { code: "VN", code3: "VNM", name: "Viet Nam", countryId: "704" },
    { code: "VG", code3: "VGB", name: "Virgin Islands (British)", countryId: "092" },
    { code: "VI", code3: "VIR", name: "Virgin Islands (U.S.)", countryId: "850" },
    { code: "WF", code3: "WLF", name: "Wallis and Futuna", countryId: "876" },
    { code: "EH", code3: "ESH", name: "Western Sahara", countryId: "732" },
    { code: "YE", code3: "YEM", name: "Yemen", countryId: "887" },
    { code: "ZM", code3: "ZMB", name: "Zambia", countryId: "894" },
    { code: "ZW", code3: "ZWE", name: "Zimbabwe", countryId: "716" },
    { code: "AX", code3: "ALA", name: "Åland Islands", countryId: "248" }
  ]
  // countriesList = [ 
  //   {"name": "Afghanistan", "code": "AF"}, 
  //   {"name": "land Islands", "code": "AX"}, 
  //   {"name": "Albania", "code": "AL"}, 
  //   {"name": "Algeria", "code": "DZ"}, 
  //   {"name": "American Samoa", "code": "AS"}, 
  //   {"name": "AndorrA", "code": "AD"}, 
  //   {"name": "Angola", "code": "AO"}, 
  //   {"name": "Anguilla", "code": "AI"}, 
  //   {"name": "Antarctica", "code": "AQ"}, 
  //   {"name": "Antigua and Barbuda", "code": "AG"}, 
  //   {"name": "Argentina", "code": "AR"}, 
  //   {"name": "Armenia", "code": "AM"}, 
  //   {"name": "Aruba", "code": "AW"}, 
  //   {"name": "Australia", "code": "AU"}, 
  //   {"name": "Austria", "code": "AT"}, 
  //   {"name": "Azerbaijan", "code": "AZ"}, 
  //   {"name": "Bahamas", "code": "BS"}, 
  //   {"name": "Bahrain", "code": "BH"}, 
  //   {"name": "Bangladesh", "code": "BD"}, 
  //   {"name": "Barbados", "code": "BB"}, 
  //   {"name": "Belarus", "code": "BY"}, 
  //   {"name": "Belgium", "code": "BE"}, 
  //   {"name": "Belize", "code": "BZ"}, 
  //   {"name": "Benin", "code": "BJ"}, 
  //   {"name": "Bermuda", "code": "BM"}, 
  //   {"name": "Bhutan", "code": "BT"}, 
  //   {"name": "Bolivia", "code": "BO"}, 
  //   {"name": "Bosnia and Herzegovina", "code": "BA"}, 
  //   {"name": "Botswana", "code": "BW"}, 
  //   {"name": "Bouvet Island", "code": "BV"}, 
  //   {"name": "Brazil", "code": "BR"}, 
  //   {"name": "British Indian Ocean Territory", "code": "IO"}, 
  //   {"name": "Brunei Darussalam", "code": "BN"}, 
  //   {"name": "Bulgaria", "code": "BG"}, 
  //   {"name": "Burkina Faso", "code": "BF"}, 
  //   {"name": "Burundi", "code": "BI"}, 
  //   {"name": "Cambodia", "code": "KH"}, 
  //   {"name": "Cameroon", "code": "CM"}, 
  //   {"name": "Canada", "code": "CA"}, 
  //   {"name": "Cape Verde", "code": "CV"}, 
  //   {"name": "Cayman Islands", "code": "KY"}, 
  //   {"name": "Central African Republic", "code": "CF"}, 
  //   {"name": "Chad", "code": "TD"}, 
  //   {"name": "Chile", "code": "CL"}, 
  //   {"name": "China", "code": "CN"}, 
  //   {"name": "Christmas Island", "code": "CX"}, 
  //   {"name": "Cocos (Keeling) Islands", "code": "CC"}, 
  //   {"name": "Colombia", "code": "CO"}, 
  //   {"name": "Comoros", "code": "KM"}, 
  //   {"name": "Congo", "code": "CG"}, 
  //   {"name": "Congo, The Democratic Republic of the", "code": "CD"}, 
  //   {"name": "Cook Islands", "code": "CK"}, 
  //   {"name": "Costa Rica", "code": "CR"}, 
  //   {"name": "Cote DIvoire", "code": "CI"}, 
  //   {"name": "Croatia", "code": "HR"}, 
  //   {"name": "Cuba", "code": "CU"}, 
  //   {"name": "Cyprus", "code": "CY"}, 
  //   {"name": "Czech Republic", "code": "CZ"}, 
  //   {"name": "Denmark", "code": "DK"}, 
  //   {"name": "Djibouti", "code": "DJ"}, 
  //   {"name": "Dominica", "code": "DM"}, 
  //   {"name": "Dominican Republic", "code": "DO"}, 
  //   {"name": "Ecuador", "code": "EC"}, 
  //   {"name": "Egypt", "code": "EG"}, 
  //   {"name": "El Salvador", "code": "SV"}, 
  //   {"name": "Equatorial Guinea", "code": "GQ"}, 
  //   {"name": "Eritrea", "code": "ER"}, 
  //   {"name": "Estonia", "code": "EE"}, 
  //   {"name": "Ethiopia", "code": "ET"}, 
  //   {"name": "Falkland Islands (Malvinas)", "code": "FK"}, 
  //   {"name": "Faroe Islands", "code": "FO"}, 
  //   {"name": "Fiji", "code": "FJ"}, 
  //   {"name": "Finland", "code": "FI"}, 
  //   {"name": "France", "code": "FR"}, 
  //   {"name": "French Guiana", "code": "GF"}, 
  //   {"name": "French Polynesia", "code": "PF"}, 
  //   {"name": "French Southern Territories", "code": "TF"}, 
  //   {"name": "Gabon", "code": "GA"}, 
  //   {"name": "Gambia", "code": "GM"}, 
  //   {"name": "Georgia", "code": "GE"}, 
  //   {"name": "Germany", "code": "DE"}, 
  //   {"name": "Ghana", "code": "GH"}, 
  //   {"name": "Gibraltar", "code": "GI"}, 
  //   {"name": "Greece", "code": "GR"}, 
  //   {"name": "Greenland", "code": "GL"}, 
  //   {"name": "Grenada", "code": "GD"}, 
  //   {"name": "Guadeloupe", "code": "GP"}, 
  //   {"name": "Guam", "code": "GU"}, 
  //   {"name": "Guatemala", "code": "GT"}, 
  //   {"name": "Guernsey", "code": "GG"}, 
  //   {"name": "Guinea", "code": "GN"}, 
  //   {"name": "Guinea-Bissau", "code": "GW"}, 
  //   {"name": "Guyana", "code": "GY"}, 
  //   {"name": "Haiti", "code": "HT"}, 
  //   {"name": "Heard Island and Mcdonald Islands", "code": "HM"}, 
  //   {"name": "Holy See (Vatican City State)", "code": "VA"}, 
  //   {"name": "Honduras", "code": "HN"}, 
  //   {"name": "Hong Kong", "code": "HK"}, 
  //   {"name": "Hungary", "code": "HU"}, 
  //   {"name": "Iceland", "code": "IS"}, 
  //   {"name": "India", "code": "IN"}, 
  //   {"name": "Indonesia", "code": "ID"}, 
  //   {"name": "Iran, Islamic Republic Of", "code": "IR"}, 
  //   {"name": "Iraq", "code": "IQ"}, 
  //   {"name": "Ireland", "code": "IE"}, 
  //   {"name": "Isle of Man", "code": "IM"}, 
  //   {"name": "Israel", "code": "IL"}, 
  //   {"name": "Italy", "code": "IT"}, 
  //   {"name": "Jamaica", "code": "JM"}, 
  //   {"name": "Japan", "code": "JP"}, 
  //   {"name": "Jersey", "code": "JE"}, 
  //   {"name": "Jordan", "code": "JO"}, 
  //   {"name": "Kazakhstan", "code": "KZ"}, 
  //   {"name": "Kenya", "code": "KE"}, 
  //   {"name": "Kiribati", "code": "KI"}, 
  //   {"name": "Korea, Democratic People'S Republic of", "code": "KP"}, 
  //   {"name": "Korea, Republic of", "code": "KR"}, 
  //   {"name": "Kuwait", "code": "KW"}, 
  //   {"name": "Kyrgyzstan", "code": "KG"}, 
  //   {"name": "Lao People'S Democratic Republic", "code": "LA"}, 
  //   {"name": "Latvia", "code": "LV"}, 
  //   {"name": "Lebanon", "code": "LB"}, 
  //   {"name": "Lesotho", "code": "LS"}, 
  //   {"name": "Liberia", "code": "LR"}, 
  //   {"name": "Libyan Arab Jamahiriya", "code": "LY"}, 
  //   {"name": "Liechtenstein", "code": "LI"}, 
  //   {"name": "Lithuania", "code": "LT"}, 
  //   {"name": "Luxembourg", "code": "LU"}, 
  //   {"name": "Macao", "code": "MO"}, 
  //   {"name": "Macedonia, The Former Yugoslav Republic of", "code": "MK"}, 
  //   {"name": "Madagascar", "code": "MG"}, 
  //   {"name": "Malawi", "code": "MW"}, 
  //   {"name": "Malaysia", "code": "MY"}, 
  //   {"name": "Maldives", "code": "MV"}, 
  //   {"name": "Mali", "code": "ML"}, 
  //   {"name": "Malta", "code": "MT"}, 
  //   {"name": "Marshall Islands", "code": "MH"}, 
  //   {"name": "Martinique", "code": "MQ"}, 
  //   {"name": "Mauritania", "code": "MR"}, 
  //   {"name": "Mauritius", "code": "MU"}, 
  //   {"name": "Mayotte", "code": "YT"}, 
  //   {"name": "Mexico", "code": "MX"}, 
  //   {"name": "Micronesia, Federated States of", "code": "FM"}, 
  //   {"name": "Moldova, Republic of", "code": "MD"}, 
  //   {"name": "Monaco", "code": "MC"}, 
  //   {"name": "Mongolia", "code": "MN"}, 
  //   {"name": "Montenegro", "code": "ME"},
  //   {"name": "Montserrat", "code": "MS"},
  //   {"name": "Morocco", "code": "MA"}, 
  //   {"name": "Mozambique", "code": "MZ"}, 
  //   {"name": "Myanmar", "code": "MM"}, 
  //   {"name": "Namibia", "code": "NA"}, 
  //   {"name": "Nauru", "code": "NR"}, 
  //   {"name": "Nepal", "code": "NP"}, 
  //   {"name": "Netherlands", "code": "NL"}, 
  //   {"name": "Netherlands Antilles", "code": "AN"}, 
  //   {"name": "New Caledonia", "code": "NC"}, 
  //   {"name": "New Zealand", "code": "NZ"}, 
  //   {"name": "Nicaragua", "code": "NI"}, 
  //   {"name": "Niger", "code": "NE"}, 
  //   {"name": "Nigeria", "code": "NG"}, 
  //   {"name": "Niue", "code": "NU"}, 
  //   {"name": "Norfolk Island", "code": "NF"}, 
  //   {"name": "Northern Mariana Islands", "code": "MP"}, 
  //   {"name": "Norway", "code": "NO"}, 
  //   {"name": "Oman", "code": "OM"}, 
  //   {"name": "Pakistan", "code": "PK"}, 
  //   {"name": "Palau", "code": "PW"}, 
  //   {"name": "Palestinian Territory, Occupied", "code": "PS"}, 
  //   {"name": "Panama", "code": "PA"}, 
  //   {"name": "Papua New Guinea", "code": "PG"}, 
  //   {"name": "Paraguay", "code": "PY"}, 
  //   {"name": "Peru", "code": "PE"}, 
  //   {"name": "Philippines", "code": "PH"}, 
  //   {"name": "Pitcairn", "code": "PN"}, 
  //   {"name": "Poland", "code": "PL"}, 
  //   {"name": "Portugal", "code": "PT"}, 
  //   {"name": "Puerto Rico", "code": "PR"}, 
  //   {"name": "Qatar", "code": "QA"}, 
  //   {"name": "Reunion", "code": "RE"}, 
  //   {"name": "Romania", "code": "RO"}, 
  //   {"name": "Russian Federation", "code": "RU"}, 
  //   {"name": "RWANDA", "code": "RW"}, 
  //   {"name": "Saint Helena", "code": "SH"}, 
  //   {"name": "Saint Kitts and Nevis", "code": "KN"}, 
  //   {"name": "Saint Lucia", "code": "LC"}, 
  //   {"name": "Saint Pierre and Miquelon", "code": "PM"}, 
  //   {"name": "Saint Vincent and the Grenadines", "code": "VC"}, 
  //   {"name": "Samoa", "code": "WS"}, 
  //   {"name": "San Marino", "code": "SM"}, 
  //   {"name": "Sao Tome and Principe", "code": "ST"}, 
  //   {"name": "Saudi Arabia", "code": "SA"}, 
  //   {"name": "Senegal", "code": "SN"}, 
  //   {"name": "Serbia", "code": "RS"}, 
  //   {"name": "Seychelles", "code": "SC"}, 
  //   {"name": "Sierra Leone", "code": "SL"}, 
  //   {"name": "Singapore", "code": "SG"}, 
  //   {"name": "Slovakia", "code": "SK"}, 
  //   {"name": "Slovenia", "code": "SI"}, 
  //   {"name": "Solomon Islands", "code": "SB"}, 
  //   {"name": "Somalia", "code": "SO"}, 
  //   {"name": "South Africa", "code": "ZA"}, 
  //   {"name": "South Georgia and the South Sandwich Islands", "code": "GS"}, 
  //   {"name": "Spain", "code": "ES"}, 
  //   {"name": "Sri Lanka", "code": "LK"}, 
  //   {"name": "Sudan", "code": "SD"}, 
  //   {"name": "Suriname", "code": "SR"}, 
  //   {"name": "Svalbard and Jan Mayen", "code": "SJ"}, 
  //   {"name": "Swaziland", "code": "SZ"}, 
  //   {"name": "Sweden", "code": "SE"}, 
  //   {"name": "Switzerland", "code": "CH"}, 
  //   {"name": "Syrian Arab Republic", "code": "SY"}, 
  //   {"name": "Taiwan, Province of China", "code": "TW"}, 
  //   {"name": "Tajikistan", "code": "TJ"}, 
  //   {"name": "Tanzania, United Republic of", "code": "TZ"}, 
  //   {"name": "Thailand", "code": "TH"}, 
  //   {"name": "Timor-Leste", "code": "TL"}, 
  //   {"name": "Togo", "code": "TG"}, 
  //   {"name": "Tokelau", "code": "TK"}, 
  //   {"name": "Tonga", "code": "TO"}, 
  //   {"name": "Trinidad and Tobago", "code": "TT"}, 
  //   {"name": "Tunisia", "code": "TN"}, 
  //   {"name": "Turkey", "code": "TR"}, 
  //   {"name": "Turkmenistan", "code": "TM"}, 
  //   {"name": "Turks and Caicos Islands", "code": "TC"}, 
  //   {"name": "Tuvalu", "code": "TV"}, 
  //   {"name": "Uganda", "code": "UG"}, 
  //   {"name": "Ukraine", "code": "UA"}, 
  //   {"name": "United Arab Emirates", "code": "AE"}, 
  //   {"name": "United Kingdom", "code": "GB"}, 
  //   {"name": "United States", "code": "US"}, 
  //   {"name": "United States Minor Outlying Islands", "code": "UM"}, 
  //   {"name": "Uruguay", "code": "UY"}, 
  //   {"name": "Uzbekistan", "code": "UZ"}, 
  //   {"name": "Vanuatu", "code": "VU"}, 
  //   {"name": "Venezuela", "code": "VE"}, 
  //   {"name": "Viet Nam", "code": "VN"}, 
  //   {"name": "Virgin Islands, British", "code": "VG"}, 
  //   {"name": "Virgin Islands, U.S.", "code": "VI"}, 
  //   {"name": "Wallis and Futuna", "code": "WF"}, 
  //   {"name": "Western Sahara", "code": "EH"}, 
  //   {"name": "Yemen", "code": "YE"}, 
  //   {"name": "Zambia", "code": "ZM"}, 
  //   {"name": "Zimbabwe", "code": "ZW"} 
  //   ]
  constructor() { }
}

<!-- Hero Start -->
<section class="bg-half-170 d-table w-100 overflow-hidden" id="home">
  <div class="container">
    <div class="row align-items-center pt-5">
      <div class="col-lg-7 col-md-6">
        <div class="title-heading">
          <h1 class="heading mb-3">Everything you <br> need to do <br> better work</h1>
          <p class="para-desc text-muted">Launch your campaign and benefit from our expertise on designing and managing
            conversion centered bootstrap v5 html page.</p>
          <div class="mt-4 pt-2">
            <a routerLink="/index-classic-saas" class="btn btn-primary">Buy Now<span
                class="badge rounded-pill bg-danger ms-2">v4.2</span></a>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-5 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <div class="classic-saas-image position-relative">
          <div class="bg-saas-shape position-relative">
            <img src="assets/images/saas/classic01.png" class="mx-auto d-block" alt="">
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Partners start -->
<section class="pt-5">
  <div class="container">
    <app-clients-logo brand_class="col-lg-2 col-md-2 col-6 text-center"></app-clients-logo>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Partners End -->

<!-- Section Start -->
<section class="section overflow-hidden">
  <div class="container pb-5 mb-md-5">
    <app-services [servicesData]="servicesData" service_class="features feature-primary text-center"></app-services>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container pb-5 mb-md-5 mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-7">
        <div class="saas-feature-shape-left position-relative">
          <img src="assets/images/saas/classic02.png" class="img-fluid mx-auto d-block rounded shadow" alt="">
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-5 mt-4 pt-2 mt-lg-0 pt-lg-0">
        <div class="section-title ms-lg-4">
          <h1 class="title mb-3">Build your site for using this app</h1>
          <p class="para-desc text-muted">Launch your campaign and benefit from our expertise on designing and managing
            conversion centered bootstrap v5 html page.</p>

          <owl-carousel-o id="customer-testi" [options]="customOptions">
            <ng-template carouselSlide>
              <div class="customer-testi py-0">
                <img src="assets/images/client/01.jpg" class="img-fluid avatar avatar-small rounded-circle shadow"
                  alt="">
                <p class="text-muted mt-4">" It seems that only fragments of the original text remain in the Lorem Ipsum
                  texts used today. "</p>
                <h6 class="text-primary">- Thomas Israel</h6>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="customer-testi py-0">
                <img src="assets/images/client/02.jpg" class="img-fluid avatar avatar-small rounded-circle shadow"
                  alt="">
                <p class="text-muted mt-4">" The most well-known dummy text is the 'Lorem Ipsum', which is said to have
                  originated in the 16th century. "</p>
                <h6 class="text-primary">- Carl Oliver</h6>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="customer-testi py-0">
                <img src="assets/images/client/03.jpg" class="img-fluid avatar avatar-small rounded-circle shadow"
                  alt="">
                <p class="text-muted mt-4">" One disadvantage of Lorum Ipsum is that in Latin certain letters appear
                  more frequently than others. "</p>
                <h6 class="text-primary">- Barbara McIntosh</h6>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="customer-testi py-0">
                <img src="assets/images/client/04.jpg" class="img-fluid avatar avatar-small rounded-circle shadow"
                  alt="">
                <p class="text-muted mt-4">" Thus, Lorem Ipsum has only limited suitability as a visual filler for
                  German texts. "</p>
                <h6 class="text-primary">- Jill Webb</h6>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="customer-testi py-0">
                <img src="assets/images/client/05.jpg" class="img-fluid avatar avatar-small rounded-circle shadow"
                  alt="">
                <p class="text-muted mt-4">" There is now an abundance of readable dummy texts. These are usually used
                  when a text is required. "</p>
                <h6 class="text-primary">- Dean Tolle</h6>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="customer-testi py-0">
                <img src="assets/images/client/06.jpg" class="img-fluid avatar avatar-small rounded-circle shadow"
                  alt="">
                <p class="text-muted mt-4">" According to most sources, Lorum Ipsum can be traced back to a text
                  composed by Cicero. "</p>
                <h6 class="text-primary">- Christa Smith</h6>
              </div>
              <!-- </div> -->
            </ng-template>
          </owl-carousel-o>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-5 order-2 order-lg-1 mt-4 pt-2 mt-lg-0 pt-lg-0">
        <div class="section-title me-lg-4">
          <h1 class="title mb-3">Why Choose us ?</h1>
          <p class="para-desc text-muted">Launch your campaign and benefit from our expertise on designing and managing
            conversion centered bootstrap v5 html page.</p>

          <div class="row">
            <div class="col-12">
              <div class="d-flex align-items-center pt-4">
                <h2>
                  <i-feather name="shield" class="fea icon-m-md text-primary"></i-feather>
                </h2>
                <div class="ms-3">
                  <h5>Fully Secured</h5>
                  <p class="text-muted mb-0">Moreover, in Latin only words at the beginning of sentences are
                    capitalized.</p>
                </div>
              </div>
            </div>
            <!--end col-->

            <div class="col-12">
              <div class="d-flex align-items-center pt-4">
                <h2>
                  <i-feather name="cpu" class="fea icon-m-md text-primary"></i-feather>
                </h2>
                <div class="ms-3">
                  <h5>Best Performance</h5>
                  <p class="text-muted mb-0">If the fill text is intended to illustrate the characteristics of
                    sometimes.</p>
                </div>
              </div>
            </div>
            <!--end col-->

            <div class="col-12 pt-4">
              <a href="javascript:void(0)" class="btn btn-outline-primary">Install Now <i
                  class="uil uil-angle-right-b"></i></a>
            </div>
            <!--end col-->
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-7 order-1 order-lg-2">
        <div class="saas-feature-shape-right position-relative">
          <img src="assets/images/saas/classic02.png" class="img-fluid mx-auto d-block rounded shadow" alt="">
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container pt-4 mt-100 mt-60">
    <div class="row justify-content-center" id="counter">
      <div class="col-12 text-center">
        <div class="section-title">
          <h4 class="title mb-4">Overall <span class="text-primary">
              <span class="counter-value" >
                <!-- [countUp]="333" [options]="option" -->
              </span>k+</span> client are using,
            Get Started</h4>
          <p class="text-muted para-desc mx-auto mb-0">Build responsive, mobile-first projects on the web with the
            world's most popular front-end component library.</p>
          <div class="watch-video mt-4">
            <a href="javascript:void(0)" class="btn btn-primary m-1 me-2">Get Started <i
                class="mdi mdi-chevron-right"></i></a>
            <a (click)="openWindowCustomClass(content)" href="javascript:void(0)" data-type="youtube"
              data-id="yba7hPeTSjk" class="btn btn-icon btn-pills btn-primary m-1">
              <i-feather name="video" class="icons"></i-feather>
            </a><span class="fw-bold text-uppercase small align-middle ms-1">Watch Now</span>
            <ng-template #content let-modal>
              <div class="modal-header">
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <youtube-player [videoId]="'jNTZpfXYJa4'" height="450" width="780">
                </youtube-player>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Our Pricing Rates</h4>
          <p class="text-muted para-desc mb-0 mx-auto">Start working with <span
              class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness,
            drive traffic, connect.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
    <app-pricing [pricingData]="pricingData"></app-pricing>
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="rounded bg-primary bg-gradient p-lg-5 p-4">
      <div class="row align-items-end">
        <div class="col-md-8">
          <div class="section-title text-md-start text-center">
            <h4 class="title mb-3 text-white title-dark">Start your free 2 week trial today</h4>
            <p class="text-white-50 mb-0">Start working with Landrick that can provide everything you need to generate
              awareness, drive traffic, connect.</p>
          </div>
        </div>
        <!--end col-->

        <div class="col-md-4 mt-4 mt-sm-0">
          <div class="text-md-end text-center">
            <a href="javascript:void(0)" class="btn btn-light">Get Started</a>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Section End -->


<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->
<!-- Hero Start -->
<section class="bg-half bg-light d-table w-100" id="home">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="page-next-level">
          <h4 class="title"> Jobs / Careers </h4>
          <div class="page-next">
            <nav aria-label="breadcrumb" class="d-inline-block">
              <ul class="breadcrumb bg-white rounded shadow mb-0">
                <li class="breadcrumb-item"><a routerLink="/index">Landrick</a></li>
                <li class="breadcrumb-item"><a href="javascript:void(0)">Page</a></li>
                <li class="breadcrumb-item"><a href="javascript:void(0)">Careers</a></li>
                <li class="breadcrumb-item active" aria-current="page">Jobs</li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-color-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->

<!-- Job List Start -->
<section class="section">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="border-bottom pb-4">
          <div class="row">
            <div class="col-lg-9 col-md-8">
              <div class="section-title">
                <h4 class="title mb-2">All Jobs</h4>
                <p class="text-muted mb-0">Most Relevance Job</p>
              </div>
            </div>
            <!--end col-->

            <div class="col-lg-3 col-md-4 mt-4 mt-sm-0 pt-2 pt-sm-0">
              <div class="form custom-form">
                <div class="">
                  <label class="form-label">Types of jobs :</label>
                  <select class="form-select form-control" aria-label="Default select example" id="Sortbylist-job">
                    <option selected>All Jobs</option>
                    <option>Full Time</option>
                    <option>Part Time</option>
                    <option>Remote</option>
                    <option>Work From Home</option>
                  </select>
                </div>
              </div>
            </div>
            <!--end col-->
          </div>
          <!--end row-->
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row">
      <div class="col-12 mt-4 pt-2">
        <div class="section-title">
          <h5 class="mb-0">Recommended Job :</h5>
        </div>
      </div>
      <!--end col-->

      @for(data of jobsData;track $index){
      <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
        <div class="card job-box rounded shadow border-0 overflow-hidden">
          <div class="border-bottom">
            <div class="position-relative">
              <img src="{{data.image}}" class="img-fluid" alt="">
              <div class="job-overlay bg-white"></div>
            </div>
            <div class="mb-0 position"><a routerLink="/page-job-detail" class="text-dark h5">{{data.designation}}</a>
              <ul class="list-unstyled h6 mb-0 text-warning">
                <li class="list-inline-item mb-0"><i class="mdi mdi-star"></i></li>
                <li class="list-inline-item mb-0"><i class="mdi mdi-star"></i></li>
                <li class="list-inline-item mb-0"><i class="mdi mdi-star"></i></li>
                <li class="list-inline-item mb-0"><i class="mdi mdi-star"></i></li>
                <li class="list-inline-item mb-0"><i class="mdi mdi-star"></i></li>
              </ul>
            </div>
            <ul class="list-unstyled head mb-0">
              <li class="badge  rounded-pill"
                [ngClass]="{'bg-success': data.message === 'Full Time', 'bg-warning': data.message === 'Part Time', 'bg-danger': data.message === 'Remote'}">
                {{data.message}}</li>
            </ul>
          </div>

          <div class="card-body content position-relative">
            <div class="firm-logo rounded-circle shadow bg-light text-center">
              <img src="{{data.icon}}" class="avatar avatar-md-sm" alt="">
            </div>
            <div class="company-detail text-center mt-3">
              <h5 class="mb-0"><a routerLink="/page-job-company" class="text-dark company-name">{{data.name}}</a></h5>
              <p class="text-muted"><a href="javascript:void(0)" class="text-muted">
                  <i-feather name="map-pin" class="fea icon-sm"></i-feather> {{data.location}}
                </a></p>
            </div>
            <ul class="job-facts list-unstyled">
              @for(item of data.list;track $index){
              <li class="list-inline-item text-muted">
                <i-feather name="check" class="fea icon-sm text-success me-1"></i-feather> {{item}}
              </li>
            }
            </ul>
            <div class="d-grid">
              <a routerLink="/page-job-detail" class="btn btn-outline-primary">Apply Now</a>
            </div>
          </div>
        </div>
      </div>
    }
      <!--end col-->

      <!-- PAGINATION START -->
      <div class="col-12 mt-4 pt-2">
        <ul class="pagination justify-content-center mb-0">
          <li class="page-item"><a class="page-link" href="javascript:void(0)" aria-label="Previous">Prev</a></li>
          <li class="page-item active"><a class="page-link" href="javascript:void(0)">1</a></li>
          <li class="page-item"><a class="page-link" href="javascript:void(0)">2</a></li>
          <li class="page-item"><a class="page-link" href="javascript:void(0)">3</a></li>
          <li class="page-item"><a class="page-link" href="javascript:void(0)" aria-label="Next">Next</a></li>
        </ul>
      </div>
      <!--end col-->
      <!-- PAGINATION END -->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Job List End -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->
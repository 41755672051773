import { Component, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first, map, Observable, of, Subscription, takeWhile, timer } from 'rxjs';
import { AppService } from 'src/app/services/app.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-auth-login',
  templateUrl: './auth-login.component.html',
  styleUrls: ['./auth-login.component.css']
})

/**
 * Auth Login Component
 */
export class AuthLoginComponent implements OnInit {
  password:string='';
  loginId:string='';
  returnUrl:string='';
  hasError: boolean = false;
  year = new Date().getFullYear()
  constructor(private appService: AppService,private router: Router,private fb: FormBuilder, private route: ActivatedRoute,) {
    // this.loginId = 'admin@xoomship.com';
    // this.password = '123456*SHIP'
   }

   loginForm: FormGroup;
   private unsubscribe: Subscription[] = []; 
   isLoading$: Observable<boolean>;
   isOtpLoading$: Observable<boolean>;
   passwordVisible: boolean = false;
   otpValue: any;
   isOtp: boolean = false;
   ////////////////////
   countDown: Observable<string>;
   isSendOtp: boolean = false;
 
   ///////////////
   formInput = ['input1', 'input2', 'input3', 'input4', 'input5', 'input6'];
   @ViewChildren('formRow') rows: any;
   @ViewChild('closebutton') closebutton;
   
 
   // showCalculationDiv = 
  
  ngOnInit(): void {
    this.returnUrl =
      this.route.snapshot.queryParams['returnUrl'.toString()] || '/';
      this.initForm();
     
  }
  // submit() {
  //   this.appService.login(this.loginId,this.password).pipe(first())
  //   .subscribe((token: any | undefined) => {
  //     if (token) {
  //        this.router.navigate([this.returnUrl]);
  //     } else {
  //       this.hasError = true;
  //     }
  //   })
  //   this.router.navigate([this.returnUrl]);
  // }
  get f() {
    debugger
    return this.loginForm.controls;
  }

  initForm() {
    this.loginForm = this.fb.group({
      email: [
        '',
        Validators.compose([
          Validators.required,
          Validators.email,
          Validators.minLength(3),
          Validators.maxLength(320), // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
        ]),
      ],
      password: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(100),
        ]),
      ],
      otp: this.fb.group({
        input1: [''],
        input2: [''],
        input3: [''],
        input4: [''],
        input5: [''],
        input6: ['']
      })
    });
  }

  submit() {
    // this.hasError = false;
    // const otpForm = this.loginForm.get('otp') as FormGroup;
    // let otp: string = Object.values(otpForm.value).join('');

    // switch (this.isOtp) {
    //   case true: this.f.password.setValue('');
    //     break;
    //   case false: otp = '';
    //     break;
    // }

    const loginSubscr = this.appService
      // .login(this.f.email.value, this.f.password.value)
      .login(this.f.email.value, this.f.password.value)
      .pipe(first())
      .subscribe((token: any | undefined) => {
        if (token) {
          // this.modalService.dismissAll();
          this.router.navigate([this.returnUrl]);
        } else {
          Swal.fire({
            position: 'top',
            title: "Invalid Credentials",
            showConfirmButton: true,
            confirmButtonText:"OK",
            timer: 1500
          });
        }
      });
    //this.unsubscribe.push(loginSubscr);
  }
  sendOTP(e: any) {
    e.preventDefault();
    this.isOtp = true;
    this.isOtpLoading$ = of(true);
    console.log("sendOTP", this.f.email.value)
    this.appService.sendMailOtp(this.f.email.value).subscribe((result: any) => {
      console.log("result from email api", result)
      debugger
      if (result.status) {
        this.isOtpLoading$ = of(false);
        this.isSendOtp = true;
        this.countDownTimer(5);
      }else{
        this.isOtpLoading$ = of(false);
        this.isSendOtp = false;
      }
    })
  }
  clickOnPass() {
    this.isOtp = false;
  }

  keyUpEvent(event: any, index: any) {
    let pos = index;
    if (event.keyCode === 8 && event.which === 8) {
      pos = index - 1;
    } else {
      pos = index + 1;
    }
    if (pos > -1 && pos < this.formInput.length) {
      this.rows._results[pos].nativeElement.focus();
    }
  }

  countDownTimer(minute: any) {

    const duration = minute * 60;
    this.countDown = timer(0, 1000).pipe(map((i) => duration - i),
      takeWhile((time) => time >= 0),
      map((time) => {
        if (time === 0) {
          this.isSendOtp = false;
        }
        return this.formatTime(time)
      }));
  }

  formatTime(time: number): string {
    const minute = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minute}: ${seconds < 10 ? '0' : ''}${seconds}`;
  }


  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }

}


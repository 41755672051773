import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-page-pricing',
  templateUrl: './page-pricing.component.html',
  styleUrls: ['./page-pricing.component.css']
})

/**
 * Page Pricing Component
 */
export class PagePricingComponent implements OnInit {

  constructor(private appService:AppService) { }

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 2
      },
      900: {
        items: 3
      }
    },
    nav: false
  };
  packagesList : any
  getPackages(){
    this.appService.get_data('/getAllPAcksRatecardPrices')
    .subscribe(data=>{
      this.packagesList = data as JSON
      
      if(this.packagesList){
        for(let i=0;i<this.packagesList.length;i++){
          if(this.packagesList[i]['packDetails']['descDetails'].trim()){
            this.packagesList[i]['packDetails']['descDetails'] =  this.packagesList[i]['packDetails']['descDetails'].split(',')
          }
          else{
            this.packagesList[i]['packDetails']['descDetails'] = []
          }
        }
      }
      console.log(this.packagesList)
    })
  }

  ngOnInit(): void {
    this.getPackages()
  }

}


<!-- Hero Start -->

  
  <!--Blog Lists Start-->
  <section class="section">
    <div class="container " style="margin-top:20px">
    <div class="row justify-content-center">
        <div class="col-12 text-center">
            <div class="section-title mb-4 pb-2">
                <h4 class="title mb-4" data-aos="fade-up" data-aos-duration="1000">Book Your Shipment</h4>
                <p class="text-muted para-desc mb-0 mx-auto" data-aos="fade-up" data-aos-duration="1400">We give the
                    best rates possible with Quality Service.
                    <span class="text-primary font-weight-bold">Your Satisfaction is guaranteed.</span> Order Now!
                </p>
            </div>
        </div>
    </div>
    <div class="container">
      <div class="row">
       
        <div class="col-lg-8 col-12">
          <div class="row">
            <div class="col mt-4 pt-2" id="nav-tabs">
                <div class="component-wrapper rounded shadow" style="background-color: #fff;">
                    <div class="row">
                    <div class="p-4 border-bottom col-md-6" style="background-color: #f1f1f1;">
                        <h3 class="title mb-0">Book Your Shipment</h3>
                    </div>
                    <div class="p-4 border-bottom col-md-6" style="background-color: #f1f1f1;" >
                        <!-- <span style="font-size:18px">Receiver Details</span><br/>
                        <div class="col-md-12 row">
                        <div class="col-md-6">Receiver Name: {{this.daControls.receiver_PersonName.value}}</div>&nbsp;&nbsp;
                        <div class="col-md-6">Receiver Mobile: {{this.daControls.receiver_Contact.value}}</div>
                        </div> -->
    
                    </div>
                    </div>
                    <div class="accordion-item rounded mt-2">
                        <h2 class="accordion-header" id="headingsix">
                            <button class="accordion-button border-0 bg-light collapsed" type="button"
                                data-bs-toggle="collapse" data-bs-target="#collapsefive" aria-expanded="true"
                                aria-controls="collapsesix">
                                Shipment Details
                            </button>
                        </h2>
                        <div id="collapsefive" class="accordion-collapse border-0 collapse show" aria-labelledby="headingsix"
                            data-bs-parent="#generalquestion">
                            <div class="accordion-body text-muted">
                                <div class="row align-items-flex-start">
                                    <div class="col-lg-12 col-md-12 col-12 mt-4 pt-2">
                                        <div class="card rounded shadow border-0 ml-lg-4" data-aos="fade-up"
                                            data-aos-duration="1400">
                                            <div class="card-body p-4">
                                                <form [formGroup]="shipmentDetailsFG">
                                                    <div class="row">

                                                        <div class="col-md-12">
                                                            <div class="form-group position-relative">
                                                                <label [style]="font_style1">ServiceZone: <span
                                                                        class="text-danger">*</span></label>
                                                                        <span style="color:red;font-size: 12px;">(Choose your nearest drop-off location.)</span>
                                                                        <!-- <a href="javascript:void(0)" style="margin-left:80px"
                                                                        class="btn btn-outline-primary btn-sm mt-0 mr-2 ml-auto text-right"
                                                                        (click)='(showCalculation=!showCalculation);onClickReset()'>Reset</a> -->
<!--                                                                                
                                                                        <div class="dropdown-menu pt-0" aria-labelledby="dropdownMenuButton">
                                                                            <input type="text" class="w-100 p-2 searchInput" [ngModel]="searchValue" (ngModelChange)="filterDropdown($event)">
                                                                            <a *ngFor="let option of service_zones_ist; let i=index" class="dropdown-item pointer text-contain"
                                                                                [ngClass]="{'alternateBackground': i%2 == 0 }" (click)="selectValue(option.name)">
                                                                                {{option.name}}
                                                                            </a>
                                                                            <div *ngIf="filteredList.length <=0" class="text-center text-muted mt-1">No expert found</div>
                                                                        </div> -->

                                                                      

                                                                <select class="form-control custom-select" [style]="font_style" data-live-search="true"
                                                                    formControlName="service_zone"
                                                                    name='service_zone'  (ngModelChange)="onChange($event)">
                                                                    <option *ngFor='let option of service_zones_ist'
                                                                        [ngValue]='option'>{{option.label}}
                                                                    </option>
                                                                </select>
                                                            </div>
                                                           

                                                            <!-- <label>ServiceZone: <span
                                                                class="text-danger">*</span></label>
                                                            <input list="menace" class="form-control custom-select" name="service_zone" formControlName="service_zone" (change)="onChange($event)">

                                                            
                                                            <datalist id="menace" >
                                                                <option class="form-control custom-select" *ngFor="let option of service_zones_ist" [ngValue]='option'>{{option.label}} </option>
                                                            </datalist> -->

                                                            
                                                        </div>

                                                        <div class="col-md-12">
                                                            <div class="form-group position-relative">
                                                                <label [style]="font_style1">Country: <span
                                                                        class="text-danger">*</span></label>
                                                                        <span style="color:red;font-size: 12px;">(Choose the country where you want your package delivered.)</span>
                                                                <select class="form-control custom-select" [style]="font_style"
                                                                    formControlName="receiver_Country"
                                                                    name='receiver_Country' (ngModelChange)="onCountryChange($event)">
                                                                    <option *ngFor='let option of countryList'
                                                                        [value]='option.label'>{{option.label}}
                                                                    </option>
                                                                </select>
                                                            </div>
                                                        </div>

                                                        <div class="col-md-12">
                                                          
                                                            <div class="form-group position-relative">
                                                                <label [style]="font_style1">Package Type: <span
                                                                        class="text-danger">*</span></label>
                                                                <div class="flex-wrap"
                                                                    style="max-width: 100%;">
                                                                    <div style="color:red;font-size: 12px;">(Choose package type: Document or Non-Document (Tick box for Non-Document.)</div> 
                                                                    &nbsp;<div class="custom-control custom-radio custom-control-inline" style="position: relative;
                                                                    display: inline-block;"
                                                                        *ngFor="let option of shipment_info_list; let i = index;">
                                                                       
                                                                         <input type="radio" [style]="font_style"
                                                                            [value]="option.value"
                                                                            class="custom-control-input"
                                                                            formControlName="shipmentType_Id"
                                                                            name="shipmentType_Id"
                                                                            id="radio{{i}}">&nbsp;&nbsp;
                                                                        <label [style]="font_style1" class="custom-control-label"
                                                                            for="radio{{i}}"
                                                                            style="margin-right: 20px;">{{option.label}}</label>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-md-12"
                                                            *ngIf='this.sdControls.shipmentType_Document.value === true'>
                                                            <div class="form-group position-relative">
                                                                <label [style]="font_style1">Choose Weight Grams:</label>
                                                                <i-feather name="pack"
                                                                    class="fea icon-sm icons"></i-feather>
                                                                    <span style="color:red;font-size: 12px;">(Enter your package weight in kilograms.)</span>
                                                                <input name="pack_loader" id="pack_loader" [style]="font_style"
                                                                    type="number" min='1'
                                                                    class="form-control pl-5"
                                                                    placeholder="100gm, 200gm, ... 999gm."
                                                                    formControlName="load_weight"
                                                                    [ngbTooltip]="load_weightValidateTooltip() && load_weightTooltipMessage"
                                                                    placement="right"
                                                                    [ngClass]="{'is-invalid': sdControls.load_weight.invalid && sdControls.load_weight.touched}">
                                                            </div>
                                                        </div>

                                                        <div class="col-md-12"
                                                            *ngIf='this.sdControls.shipmentType_NonDocument.value === true'>
                                                            <div class="form-group position-relative">
                                                                <label [style]="font_style1">Choose Weight Kgs:</label>
                                                                <i-feather name="pack"
                                                                    class="fea icon-sm icons"></i-feather>
                                                                    <span style="color:red;font-size: 12px;">(Enter your package weight in kilograms.)</span>
                                                                <input name="pack_loader" id="pack_loader" [style]="font_style"
                                                                    type="number" min='1'
                                                                    class="form-control pl-5"
                                                                    placeholder="1kg, 2.5kg, ...99.5kg"
                                                                    formControlName="load_weight"
                                                                    [ngbTooltip]="load_weightValidateTooltip() && load_weightTooltipMessage"
                                                                    placement="right"
                                                                    [ngClass]="{'is-invalid': sdControls.load_weight.invalid && sdControls.load_weight.touched}">
                                                            </div>
                                                        </div>

                                                        <div class="col-md-12"
                                                            *ngIf="!sdControls.load_weight.invalid && !this.sdControls.receiver_Country.invalid && (this.sdControls.shipmentType_NonDocument.value === true || this.sdControls.shipmentType_Document.value === true)">
                                                            <div class="form-group position-relative">
                                                                <label [style]="font_style1">Choose Package Type: <span
                                                                        class="text-danger">*</span></label>
                                                                        <span style="color:red;font-size: 12px;">(Enter your package weight in kilograms.)</span>
                                                                <div
                                                                    style="text-align:center;margin:0; padding:10;">
                                                                    <ng-image-slider #nav
                                                                        [images]="logistic_boxby_weight_list"
                                                                        [infinite]="false" [autoSlide]="1"
                                                                        [imagePopup]="false"
                                                                        [imageSize]="{ width: 100, height: 100 }"
                                                                        slideImage="1"
                                                                        (imageClick)="onSelectSlide($event)">
                                                                    </ng-image-slider>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-12 col-md-12 col-12 mt-4 pt-2" *ngIf='showCalculation && this.sdControls.load_weight.value !== "" '>
                                        <div class="card rounded shadow border-0 ml-lg-4" data-aos="fade-up"
                                            data-aos-duration="1400">
                                            <div class="card-body p-4">
                                                <form [formGroup]="deliveryModeFG">
                                                    <div class="row" >
                                                        <!-- <img src="assets/images/blog/2xoom_Banner.gif"
                                                            style='width: auto; height: auto;' alt=""
                                                            *ngIf='!showCalculation '> -->

                                                        <div class="col-md-12" *ngIf='showCalculation '>
                                                            <div class="d-flex justify-content-between mb-4">
                                                                <label [style]="font_style1">Pick a delivery mode from the list below :<span
                                                                        class="text-danger">*</span>
                                                                        
                                                                    <span
                                                                        *ngIf='this.sdControls.shipmentType_NonDocument.value === true'
                                                                        class="text-primary h6 font-weight-bold"
                                                                        style='font-family: "Nunito", sans-serif;'>{{sdControls.load_weight.value}}
                                                                        Kgs</span>
                                                                    <span
                                                                        *ngIf='this.sdControls.shipmentType_Document.value === true'
                                                                        class="text-primary h6 font-weight-bold"
                                                                        style='font-family: "Nunito", sans-serif;'>{{sdControls.load_weight.value}}
                                                                        Grams</span>
                                                                </label>

                                                                <!-- <a href="javascript:void(0)"
                                                                    class="btn btn-outline-primary btn-sm mt-0 mr-2 ml-auto text-right"
                                                                    (click)='(showCalculation=!showCalculation);onClickReset()'>Reset</a> -->
                                                            </div>
                                                            <div class="form-group position-relative">
                                                                <div class="col-md-12">
                                                                    <div class="mb-4  justify-content-between" style="border: 2px solid #e2e1e1; border-radius: 10px;"
                                                                        *ngFor='let option of international_ratecard_list; let i = index;'>
                                                                            <!-- <div
                                                                            class="d-flex justify-content-between" > -->
                                                                            <div id="button{{i}}" type="button" [ngStyle]="(value1==option.deliverModeId && vendorName == option.vendorName) ? {'background-color': '#2f55d4','color': 'white'} :  {'background-color': 'rgba(47, 85, 212, .05)'}"  style="padding: 8px;
                                                                            outline: 0;
                                                                            text-decoration: none;
                                                                            font-size: 16px;
                                                                            letter-spacing: .5px;
                                                                            -webkit-transition: all .3s;
                                                                            transition: all .3s;
                                                                            font-weight: 600;
                                                                            border-radius: 6px;" (click)="changeColor1(option);change1(option)"
                                                                           [value]="option">  
                                                                            <div class="col-md-12 row">
                                                                            <span class="col-md-9">{{option.deliveryModeName}}:
                                                                                {{option.vendorName}}</span>
                                                                            <span class="col-md-3 ml-auto text-right">
                                                                                {{option.totalPrice |
                                                                                currency:'INR'}}</span>
                                                                                </div>
                                                                            <small style="padding:10px" class="pt-0">{{option.deliveryModeName}}
                                                                                delivery service across the globe
                                                                                through {{option.vendorName}}
                                                                                network.</small>
                                                                            </div>
                                                                                <!-- </div> -->


                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item rounded mt-2">
                        <h2 class="accordion-header" id="headingsix">
                            <button class="accordion-button border-0 bg-light collapsed" type="button"
                                data-bs-toggle="collapse" data-bs-target="#collapsesix" aria-expanded="false"
                                aria-controls="collapsesix">
                                Sender & Receiver Details
                            </button>
                        </h2>
                        <div id="collapsesix" class="accordion-collapse border-0 collapse" aria-labelledby="headingsix"
                            data-bs-parent="#generalquestion">
                            <div class="accordion-body text-muted">
                                <div class="row align-items-center">
                                    <div class="accordion-item rounded mt-2">
                                        <h2 class="accordion-header" id="headingone">
                                            <button class="accordion-button border-0 bg-light collapsed" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#collapseone" aria-expanded="false"
                                                aria-controls="collapseone">
                                               Sender Details
                                            </button>
                                        </h2>
                                        <div id="collapseone" class="accordion-collapse border-0 collapse" aria-labelledby="headingone"
                                            data-bs-parent="#generalquestion">
                                            <div class="accordion-body text-muted">
                                                <div class="col-lg-12 col-md-12 col-12 mt-4 pt-2">
                                                    <div class="card rounded shadow border-0 ml-lg-4" data-aos="fade-up"
                                                        data-aos-duration="1400">
                                                        <div class="card-body p-4">
                                                            <form [formGroup]="senderAddressFG">
                                                                <div class="row">
                                                                     
                                                                    <div class="col-md-12">
                                                                        <div class="form-group position-relative">
                                                                            <label [style]="font_style1" for="sender_Address">Search Your Pickup Location
                                                                                :</label>
                                                                            <i-feather name="map-pin"
                                                                                class="fea icon-sm icons"></i-feather>
                                                                               <!-- <span style="color:red;font-size: 12px;">Enter email ID for registration verification</span> -->
                                                                            <input #placesRef="ngx-places" [style]="font_style"
                                                                                ngx-gp-autocomplete name="sender_Address"
                                                                                id="sender_Address" type="text"
                                                                                [options]="senderNgxOptions"
                                                                                formControlName="sender_Address"
                                                                                (onAddressChange)="onSenderAddressSelected($event)"
                                                                                class="form-control pl-5"
                                                                                placeholder="Search Your location:"
                                                                                [ngClass]="{'is-invalid': saControls.sender_Address.invalid && saControls.sender_Address.touched}">
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-md-12">
                                                                        <div class="row">
                                                                            <div class="col-md-6">
                                                                                <div class="form-group position-relative">
                                                                                    <label [style]="font_style1" for="sender_PersonName">Sender
                                                                                        Name
                                                                                        :</label>
                                                                                    <i-feather name="user"
                                                                                        class="fea icon-sm icons">
                                                                                    </i-feather>
                                                                                    <input name="sender_PersonName" [style]="font_style"
                                                                                        id="sender_PersonName" type="text"
                                                                                        class="form-control pl-5"
                                                                                        formControlName="sender_PersonName"
                                                                                        placeholder="Sender Name :"
                                                                                        [ngClass]="{'is-invalid': saControls.sender_PersonName.invalid && saControls.sender_PersonName.touched}">
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-md-6">
                                                                                 <div class="mb-3">
                                                                                    <label [style]="font_style1" for="sender_Contact">Sender Mobile No
                                                                                        :</label>
                                                                                    <i-feather name="phone"
                                                                                        class="fea icon-sm icons"></i-feather>
                                                                                    <div class="form-icon position-relative">
                                                                                        <span style="width: 32px;
                                                                                        position: absolute;
                                                                                            top: 7px;
                                                                                            left: 5px;">{{countryCode}}</span>
                                                                                       <input name="sender_Contact" [style]="font_style"
                                                                                       id="sender_Contact" type="text"
                                                                                       class="form-control " style="padding-left: 2.3rem !important;"
                                                                                       formControlName="sender_Contact"
                                                                                       placeholder="Sender Mobile No :"
                                                                                       (keypress)='numberOnlyMob($event)'
                                                                                       [ngClass]="{'is-invalid': saControls.sender_Contact.invalid && saControls.sender_Contact.touched}">
                                                                                    </div>
                                                                                </div>
            
                                                                                <!-- <div class="form-group position-relative">
                                                                                    <label for="sender_Contact">Sender
                                                                                        Mobile No
                                                                                        :</label>
                                                                                    <i-feather name="phone"
                                                                                        class="fea icon-sm icons">
                                                                                    </i-feather>
                                                                                    
                                                                                    <input name="sender_Contact"
                                                                                        id="sender_Contact" type="text"
                                                                                        class="form-control pl-5"
                                                                                        formControlName="sender_Contact"
                                                                                        placeholder="Sender Mobile No :"
                                                                                        (keypress)='numberOnlyMob($event)'
                                                                                        [ngClass]="{'is-invalid': saControls.sender_Contact.invalid && saControls.sender_Contact.touched}">
                                                                                </div> -->
                                                                                <div *ngIf="saControls.sender_Contact.invalid && saControls.sender_Contact.touched" style=" color: green;font-size: 12px;">Please, Enter 10 digit Mobile Number.</div>  
                                                                                <span *ngIf="!saControls.sender_Contact.touched"  style="color:red;font-size: 12px;">Enter your valid contact number.</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
            
                                                                    <div class="container">
                                                                        <div class="row align-items-end">
                                                                            <div class="col-md-6">
                                                                                <div class="form-group position-relative">
                                                                                    <label [style]="font_style1" for="sender_EmailId">Sender
                                                                                        Email:</label>
                                                                                    <i-feather name="mail"
                                                                                        class="fea icon-sm icons">
                                                                                    </i-feather>
                                                                                    
                                                                                    <input id="sender_EmailId" type="email" [style]="font_style"
                                                                                        class="form-control pl-5"
                                                                                        formControlName="sender_EmailId"
                                                                                        placeholder="Enter Sender Email"
                                                                                        [ngClass]="{'is-invalid': saControls.sender_EmailId.invalid && saControls.sender_EmailId.touched}"
                                                                                        aria-describedby="senderEmailHelp">
                                                                                        <i-feather *ngIf="otpVerified"
                                                                                            name="check" class="check-icon"
                                                                                            style="position: absolute; right: 3px; top: 70%; transform: translateY(-50%); color: green;">
                                                                                        </i-feather>
                                                                                        
                                                                                    <div  style=" color: green;font-size: 12px;" *ngIf="isAvailable==true && saControls.sender_EmailId.valid">
                                                                                        Email already register.
                                                                                    </div>
                                                                                    <span *ngIf="!saControls.sender_EmailId.touched" style="color:red;font-size: 12px;">Enter email ID for registration verification</span>
                                                                                </div>
                                                                            </div>
            
                                                                            <div class="col-md-6 align-items-center" style="display: flex;" *ngIf="enable_Otp && !otpVerified && isAvailable == false">
                                                                                <div class="form-group position-relative" style="margin-bottom: 0;">
                                                                                    <div class="d-flex align-items-center">
                                                                                        <div  *ngIf="enable_SendOtpBtn" >
                                                                                            <button type="button" class="btn btn-outline-secondary" (click)="sendOtpSenderMail()">Send OTP</button>
                                                                                        </div>
                                                                                       
            
                                                                                        <span *ngIf="sendOtp_SenderMailLoading" class="spinner-border spinner-border-sm ms-2"></span>
                                                                                        
                                                                                        <div *ngIf="enter_SendOtp" class="form-control pl-5 d-flex align-items-center position-relative flex-grow-1 ms-2">
                                                                                            <one-time-password-input class="me-4" formControlName="sender_MailOtp" 
                                                                                            [ngClass]="{'is-invalid': saControls.sender_MailOtp.invalid && saControls.sender_MailOtp.touched}"></one-time-password-input>
                                                                                            <button type="button" class="btn p-0" (click)="verifyOtp()" style="position: absolute; right: 0; top: 50%; transform: translateY(-50%);">
                                                                                                <i-feather name="send" class="send-icon"></i-feather>
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
            
                                                                            <div class="col-md-6 align-items-center" style="display: flex;" *ngIf="otpVerified">
                                                                                <div class="form-group position-relative" style="margin-bottom: 0;">
                                                                                    <div class="d-flex align-items-center">
                                                                                        <div *ngIf="otpVerified">
                                                                                            <button type="button" class="btn btn-outline-secondary"
                                                                                                (click)="contactRegistration()">Register</button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            
            
                                                                            <!-- <div class="col-md-6 align-items-center"
                                                                                style="display: flex;" *ngIf="enable_Otp">
                                                                                <div class="form-group position-relative"
                                                                                    style="margin-bottom: 0;">
                                                                                    <div class="d-flex align-items-center">
                                                                                        <div *ngIf="enable_SendOtpBtn">
                                                                                            <button type="button"
                                                                                                class="btn btn-outline-secondary"
                                                                                                (click)="sendOtpSenderMail()">Send
                                                                                                OTP</button>
                                                                                        </div>
                                                                                        <span
                                                                                            *ngIf="sendOtp_SenderMailLoading"
                                                                                            class="spinner-border spinner-border-sm ms-2"></span>
            
                                                                                        <div *ngIf="enter_SendOtp">
                                                                                            <one-time-password-input
                                                                                            class="form-control ms-2"
                                                                                            formControlName="sender_MailOtp">
                                                                                        </one-time-password-input>
                                                                                        <i-feather
                                                                                            name="send" class="send-icon"
                                                                                            style="position: absolute; right: 10px; top: 50%; transform: translateY(-50%);">
                                                                                        </i-feather>
                                                                                        </div>
            
                                                                                    </div>
                                                                                </div>
                                                                            </div> -->
            
                                                                        </div>
                                                                    </div>
            
            
            
                                                                    
            
                                                                    <div class="col-md-12">
                                                                        <div class="form-group position-relative">
                                                                            <label [style]="font_style1" for="sender_Country">Country <span
                                                                                    class="text-danger">*</span></label>
                                                                            <input name="sender_Country" id="sender_Country" [style]="font_style"
                                                                                class="form-control pl-5"
                                                                                placeholder="House/Flat no :"
                                                                                formControlName="sender_Country"
                                                                                [ngClass]="{'is-invalid': saControls.sender_Country.invalid && saControls.sender_Country.touched}">
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6">
                                                                        <div class="form-group position-relative">
                                                                            <label [style]="font_style1">House/Flat No :</label>
                                                                            <i-feather name="book"
                                                                                class="fea icon-sm icons"></i-feather>
                                                                            <input name="sender_Landmark" [style]="font_style"
                                                                                id="sender_Landmark"
                                                                                class="form-control pl-5"
                                                                                placeholder="House/Flat no :"
                                                                                formControlName="sender_Landmark"
                                                                                [ngClass]="{'is-invalid': saControls.sender_Landmark.invalid && saControls.sender_Landmark.touched}">
                                                                        </div>
                                                                    </div>
            
                                                                    <div class="col-md-6">
                                                                        <div class="form-group position-relative">
                                                                            <label [style]="font_style1" for="sender_City">City :</label>
                                                                            <i-feather name="book"
                                                                                class="fea icon-sm icons"></i-feather>
                                                                            <input name="sender_City" id="sender_City" [style]="font_style"
                                                                                class="form-control pl-5"
                                                                                placeholder="City :"
                                                                                formControlName="sender_City"
                                                                                [ngClass]="{'is-invalid': saControls.sender_City.invalid && saControls.sender_City.touched}">
                                                                        </div>
                                                                    </div>
            
                                                                    <div class="col-md-6">
                                                                        <div class="form-group position-relative">
                                                                            <label [style]="font_style1" for="sender_State">State :</label>
                                                                            <i-feather name="book"
                                                                                class="fea icon-sm icons"></i-feather>
                                                                            <input name="sender_State" id="sender_State" [style]="font_style"
                                                                                class="form-control pl-5"
                                                                                placeholder="State :"
                                                                                formControlName="sender_State"
                                                                                [ngClass]="{'is-invalid': saControls.sender_State.invalid && saControls.sender_State.touched}">
                                                                        </div>
                                                                    </div>
            
                                                                    <div class="col-md-6">
                                                                        <div class="form-group position-relative">
                                                                            <label [style]="font_style1" for="sender_ZipCode">ZipCode :</label>
                                                                            <i-feather name="book"
                                                                                class="fea icon-sm icons"></i-feather>
                                                                            <input name="sender_ZipCode" id="sender_ZipCode" [style]="font_style"
                                                                                class="form-control pl-5"
                                                                                placeholder="ZipCode :"
                                                                                formControlName="sender_ZipCode"
                                                                                [ngClass]="{'is-invalid': saControls.sender_ZipCode.invalid && saControls.sender_ZipCode.touched}"
                                                                                (keypress)='numberOnlyMob($event)'>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="accordion-item rounded mt-2">
                                        <h2 class="accordion-header" id="headingtwo">
                                            <button class="accordion-button border-0 bg-light collapsed" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#collapsetwo" aria-expanded="false"
                                                aria-controls="collapsetwo">
                                               Receiver Details
                                            </button>
                                        </h2>
                                        <div id="collapsetwo" class="accordion-collapse border-0 collapse" aria-labelledby="headingtwo"
                                            data-bs-parent="#generalquestion">
                                            <div class="accordion-body text-muted">
                                                <div class="col-lg-12 col-md-12 col-12 mt-4 pt-2">
                                                    <div class="card rounded shadow border-0 ml-lg-4" data-aos="fade-up"
                                                        data-aos-duration="1400">
                                                        <div class="card-body p-4">
                                                            <form [formGroup]="destinationAddressFG">
                                                                <div class="row">
            

                                                                    <div class="col-md-12">
                                                                        <div class="form-group position-relative">
                                                                            <label [style]="font_style1" for="receiver_Address">Search your
                                                                                Destination Location
                                                                                :</label>
                                                                            <i-feather name="map-pin"
                                                                                class="fea icon-sm icons"></i-feather>
                                                                                <!-- [autocomplete]="autocomplete" -->
                                                                            <input #placesRef="ngx-places"
                                                                                ngx-gp-autocomplete name="receiver_Address" [style]="font_style"
                                                                                id="receiver_Address" type="text" [options]='receiverNgxOptions'
                                                                                formControlName="receiver_Address"
                                                                                (onAddressChange)="onDestinationAddressSelected($event)"
                                                                                class="form-control pl-5"  
                                                                                placeholder="Search Your location:"
                                                                                [ngClass]="{'is-invalid': daControls.receiver_Address.invalid && daControls.receiver_Address.touched}">
                                                                                
            
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-md-6">
                                                                        <div class="form-group position-relative">
                                                                            <label [style]="font_style1" for="receiver_PersonName">Receiver Name
                                                                                :</label>
                                                                            <i-feather name="user"
                                                                                class="fea icon-sm icons"></i-feather>
                                                                            <input name="receiver_PersonName" [style]="font_style"
                                                                                id="receiver_PersonName" type="text"
                                                                                class="form-control pl-5"
                                                                                formControlName="receiver_PersonName"
                                                                                placeholder="Receiver Name :">
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6">
                                                                        <div class="form-group position-relative">
                                                                            <!-- <label for="receiver_Contact">Receiver Mobile No
                                                                                :</label>
                                                                            <i-feather name="phone"
                                                                                class="fea icon-sm icons"></i-feather> -->
            
                                                                                <div class="row">
                                                                                    <div class="col-md-12">
                                                                                        <div class="mb-3">
                                                                                            <label [style]="font_style1" for="receiver_Contact">Receiver Mobile No
                                                                                                :</label>
                                                                                            <i-feather name="phone"
                                                                                                class="fea icon-sm icons"></i-feather>
                                                                                            <div class="form-icon position-relative">
                                                                                                <span style="width: 42px;
                                                                                                position: absolute;
                                                                                                    top: 7px;
                                                                                                    left: 5px;">{{countryCode1}}</span>
                                                                                                <input name="receiver_Contact" [style]="font_style"
                                                                                id="receiver_Contact" type="text"
                                                                                class="form-control ps-5"
                                                                                formControlName="receiver_Contact"
                                                                                placeholder="Receiver Mobile No :"
                                                                                (keypress)='numberOnlyMob($event)'>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    </div>
            
            
            
            
                                                                                <!-- <span class="border-end country-code px-2">{{countryCode1}}</span>
                                                                            <input name="receiver_Contact"
                                                                                id="receiver_Contact" type="text"
                                                                                class="form-control pl-5"
                                                                                formControlName="receiver_Contact"
                                                                                placeholder="Receiver Mobile No :"
                                                                                (keypress)='numberOnlyMob($event)'> -->
                                                                        </div>
                                                                        <div *ngIf="daControls.receiver_Contact.invalid && daControls.receiver_Contact.touched" style=" color: green;font-size: 12px;">Please, Enter 10 digit Mobile Number.</div>  
                                                                                <span *ngIf="!daControls.receiver_Contact.touched"  style="color:red;font-size: 12px;">Enter your valid contact number.</span>
                                                                    </div>
                                                                    <div class="col-md-6">
                                                                        <div class="form-group position-relative">
                                                                            <label [style]="font_style1" for="receiver_EmailId">Receiver Email
                                                                                :</label>
                                                                            <i-feather name="mail"
                                                                                class="fea icon-sm icons"></i-feather>
                                                                            <input name="receiver_EmailId" [style]="font_style"
                                                                                id="receiver_EmailId" type="text"
                                                                                class="form-control pl-5"
                                                                                formControlName="receiver_EmailId"
                                                                                placeholder="Receiver Email :">
                                                                        </div>
                                                                    </div>
                                                                  
            
                                                                    <div class="col-md-12">
                                                                        <div class="form-group position-relative">
                                                                            <label [style]="font_style1" for="receiver_Country">Country <span
                                                                                    class="text-danger">*</span></label>
                                                                                    <input name="receiver_Country" id="receiver_Country" [style]="font_style"
                                                                                    class="form-control pl-5"
                                                                                    placeholder="Country:"
                                                                                    formControlName="receiver_Country"
                                                                                    [ngClass]="{'is-invalid': daControls.receiver_Country.invalid && daControls.receiver_Country.touched}">
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6">
                                                                        <div class="form-group position-relative">
                                                                            <label [style]="font_style1">House/Flat No : <span
                                                                                class="text-danger">*</span></label>
                                                                            <i-feather name="book"
                                                                                class="fea icon-sm icons"></i-feather>
                                                                            <input name="receiver_Landmark" [style]="font_style"
                                                                                id="receiver_Landmark"
                                                                                class="form-control pl-5"
                                                                                placeholder="House/Flat no :"
                                                                                formControlName="receiver_Landmark">
                                                                        </div>
                                                                    </div>
            
                                                                    <div class="col-md-6">
                                                                        <div class="form-group position-relative">
                                                                            <label [style]="font_style1" for="receiver_City">City :</label>
                                                                            <i-feather name="book"
                                                                                class="fea icon-sm icons"></i-feather>
                                                                            <input name="receiver_City" id="receiver_City" [style]="font_style"
                                                                                class="form-control pl-5"
                                                                                placeholder="City :"
                                                                                formControlName="receiver_City">
                                                                        </div>
                                                                    </div>
            
                                                                    <div class="col-md-6">
                                                                        <div class="form-group position-relative">
                                                                            <label [style]="font_style1" for="receiver_State">State :</label>
                                                                            <i-feather name="book"
                                                                                class="fea icon-sm icons"></i-feather>
                                                                            <input name="receiver_State" id="receiver_State" [style]="font_style"
                                                                                class="form-control pl-5"
                                                                                placeholder="State :"
                                                                                formControlName="receiver_State">
                                                                        </div>
                                                                    </div>
            
                                                                    <div class="col-md-6">
                                                                        <div class="form-group position-relative">
                                                                            <label [style]="font_style1" for="receiver_ZipCode">ZipCode :</label>
                                                                            <i-feather name="book"
                                                                                class="fea icon-sm icons"></i-feather>
                                                                            <input name="receiver_ZipCode" [style]="font_style"
                                                                                id="receiver_ZipCode"
                                                                                class="form-control pl-5"
                                                                                placeholder="ZipCode :"
                                                                                formControlName="receiver_ZipCode"
                                                                                (keypress)='numberOnlyMob($event)'>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

    
                                    

                                  

                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item rounded mt-2" *ngIf="isShowImageDetails">
                        <h2 class="accordion-header" id="headingImage">
                            <button class="accordion-button border-0 bg-light collapsed" type="button"
                                data-bs-toggle="collapse" data-bs-target="#headingImage" aria-expanded="false"
                                aria-controls="headingImage">
                               Package Image Details
                            </button>
                        </h2>
                        <div id="headingImage" class="accordion-collapse border-0 collapse" aria-labelledby="headingImage"
                            data-bs-parent="#generalquestion">
                            <div class="accordion-body text-muted">
                                <div class="row align-items-center">
                                    

                                    <div class="col-sm-4 col-xl-4 d-none d-sm-inline-block">
                                        <div class="">
                                          <div class="flex-nowrap border-0 pt-1" style="padding:0 0.90rem;    margin-bottom: -22px;">
                                            <div class="card-title m-0" style="    font-weight: 600;
                                            font-size: 1.275rem;
                                            color: #3b529d;">
                                              Upload Image
                                            </div>
                                            <div class="-toolbar m-0">
                                    
                                            </div>
                                          </div>
                                          <div class="d-flex flex-column mb-5  bg-white " style="border: 2px solid #e2e1e1;
                                            border-radius: 10px;
                                            margin: 30px;     padding: 10px;">
                                            <div class="col-md-12 col-lg-12">
                                              <input type="file" class="btn-check" id="kt_create_account_form_account_type_personal" value="personal"
                                                #uploadFiles (change)="onChangeFile(uploadFiles.files)" accept=".png, .jpg, .jpeg" [disabled]="readonly" />
                                              <div style="border-color: #3e519c;
                                              background-color: #F1FAFF !important;     margin-right: 5px;
                                               min-height: 90px;" class="
                                                    btn btn-outline btn-outline-dashed btn-outline-default
                                                    p-4
                                                    align-items-center
                                                    mb-6
                                                    mt-6
                                                  ">
                                                <label for="kt_create_account_form_account_type_personal">
                                                  <span class="d-block fw-bold">
                                                    <!-- <span style="font-size:1.0rem !important;" class="text-dark fw-bolder d-block fs-4 ">Icon should be png
                                                      or
                                                      SVG.</span> -->
                                                    <span class="text-gray-400 fw-bold fs-6" style="color:#1e98e2  !important;">
                                                      Upload Image
                                                    </span>
                                                  </span>
                                                </label>
                                                <div class="image-input-wrapper" *ngIf="logisticInternationalOrderModel?.bookingImage">
                                                  <div (click)="deleteImagePath()" class="btn btn-sm btn-icon btn-active-light-primary"><span
                                                      class="svg-icon svg-icon-2" ng-reflect-inline-s-v-g="./assets/media/icons/duotune/a"><svg width="24"
                                                        height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)"
                                                          fill="currentColor"></rect>
                                                        <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)"
                                                          fill="currentColor"></rect>
                                                      </svg></span>
                                                  </div>
                                                  <img id="imgThumb" width="80px" height="80px" [src]="appService.apiLink+logisticInternationalOrderModel?.bookingImage" 
                                                  (click)="openImage(myModel,logisticInternationalOrderModel?.bookingImage)"/>
                                                </div>
                                              </div>
                                            </div>
                                    
                                    
                                          </div>
                                    
                                          <!----------------------------------------------------------->
                                    
                                          <!---------------------------------------------------------------->
                                    
                                        </div>
                                      </div>
                                    

                                  

                                </div>
                            </div>
                        </div>
                    </div>

                    

                
                </div>
            </div>
        </div>
          </div>
        
        <div class="col-lg-4 col-12 mt-4 mt-lg-0 pt-2 pt-lg-0">
          <div class="card border-0 sidebar sticky-bar ms-lg-4">
              <div class="bg-light d-block py-2 rounded shadow text-left h6 mb-0" style="padding:10px">
                  <!-- Author -->
                  <div class="col-md-12 row">
                    <div class="col-md-9">
                        <span style="color:blue;font-size:17px;font-weight:800;">Order Summary</span>
                    </div>
                    <div class="col-md-3">
                        <a href="javascript:void(0)" style="float:right"
                        class="btn btn-outline-primary btn-sm mt-0 mr-2 ml-auto text-right"
                        (click)='onClickReset()'>Reset</a>
                    </div>
                </div>
                  <!-- Author -->
  
                  <!-- RECENT POST -->
                <div class="widget mt-4">
                    <div class="mt-4" *ngIf="this.sdControls.shipmentType_NonDocument.value === true ">
                        <div class="d-flex align-items-center">
                            <div class=" col-lg-12 col-md-12 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0 ">
                                <div class="row">
                                    <div class="col-md-7" [style]="font_style">Total <span
                                            *ngIf='this.sdControls.shipmentType_NonDocument.value === true'
                                            class=" h6 font-weight-bold "
                                            style='font-family: "Nunito", sans-serif;color:blue;'>{{sdControls.load_weight.value}}
                                            Kgs</span>
                                        <span *ngIf='this.sdControls.shipmentType_Document.value === true' class=" h6 font-weight-bold"
                                            style='font-family: "Nunito", sans-serif;color:blue;'>{{sdControls.load_weight.value}}
                                            Grams</span>
                                    </div>
                                    <div class="col-md-5" [style]="font_style"><span
                                            style="float:right;color:blue;">{{((internationalData && isShowSummaryDetails) ?
                                            consignmentCharges : 0 ) | number:'.2-2'}}</span></div>
                                </div>
                
                                <div class=" row" style="margin-top:5px">
                                    <div class="col-md-7" style="font-size:16px;font-weight:500;color:blue">Price per Kg</div>
                                    <div class="col-md-5" style="font-size:16px;font-weight:500;color:blue"><span
                                            style="float:right">{{((internationalData?.perKgRate && isShowSummaryDetails) ? perKgRate :
                                            0 ) |
                                            number:'.2-2'}}</span></div>
                                </div>
                
                                <div class="row" style="margin-top:5px">
                                    <div class="col-md-7" style="font-size:16px;font-weight:500;color:blue">Total Gross Amt</div>
                                    <div class="col-md-5" style="font-size:16px;font-weight:500;color:blue"><span
                                            style="float:right">{{((internationalData?.intlGrossAmt && isShowSummaryDetails) ?
                                            totalGross : 0 ) |
                                            number:'.2-2'}}</span></div>
                                </div>
                
                                <div class="row" style="margin-top:5px">
                                    <div class="col-md-7" style="font-size:16px;font-weight:500;color:blue">Total tax GST 18%</div>
                                    <div class="col-md-5" style="font-size:16px;font-weight:500;color:blue"><span
                                            style="float:right">{{((internationalData?.taxAmount && isShowSummaryDetails) ? totalTax : 0
                                            ) |
                                            number:'.2-2'}}</span></div>
                                </div>
                
                                <div class="row" style="margin-top:5px">
                                    <div class="col-md-7" style="font-size:16px;font-weight:500;color:blue">Total Amount</div>
                                    <div class="col-md-5" style="font-size:16px;font-weight:500;color:blue"><span
                                            style="float:right">{{((internationalData?.intlTotalAmt && isShowSummaryDetails) ?
                                            consignmentCharges : 0 ) |
                                            number:'.2-2'}}</span></div>
                                </div>
                                <div class="row" style="margin-top:5px">
                                    <div class="col-md-7" style="color:blue;font-size:16px;font-weight:500;">No. of Boxes</div>
                                    <div class="col-md-5" style="font-size:16px;font-weight:500;">
                                        <span style="float:right">{{(noOBoxes && isShowSummaryDetails) ? noOBoxes : 0}}</span>
                                        </div>
                                </div>
                
                                <span style="color:blue;font-size:16px;font-weight:500;text-decoration: underline;">Packing
                                    Charges</span>
                                    
                                <div class="row" style="margin-top:5px">
                                    <div class="col-md-7" style="font-size:16px;font-weight:500;">GrossAmt</div>
                                    <div class="col-md-5" style="font-size:16px;font-weight:500;"><span
                                            style="float:right">{{(packingGross && consignmentCharges && isShowSummaryDetails ?
                                            packingGross : 0 ) | number:'.2-2'}}</span></div>
                                </div>
                
                                <div class="row" style="margin-top:5px">
                                    <div class="col-md-7" style="font-size:16px;font-weight:500;">TaxAmt</div>
                                    <div class="col-md-5" style="font-size:16px;font-weight:500;"><span
                                            style="float:right">{{(packingTax && consignmentCharges && isShowSummaryDetails ? packingTax
                                            : 0 ) | number:'.2-2'}}</span></div>
                                </div>
                
                                <div class="row" style="margin-top:5px">
                                    <div class="col-md-7" style="font-size:16px;font-weight:500;">Total Packing Charges</div>
                                    <div class="col-md-5" style="font-size:16px;font-weight:500;"><span
                                            style="float:right">{{(packingTotal && consignmentCharges && isShowSummaryDetails ?
                                            packingTotal : 0 ) | number:'.2-2'}}</span></div>
                                </div>
                
                                <div *ngIf="this.sdControls.pickupRequest.value == true">
                                    <div> <span style="color:blue;font-size:16px;font-weight:500;text-decoration: underline;">Pickup
                                            Charges</span></div>
                                    <div class="row">
                                        <div class="col-md-7" style="font-size:15px;font-weight:500">Total <span
                                                *ngIf='this.sdControls.shipmentType_NonDocument.value === true'
                                                class=" h6 font-weight-bold"
                                                style='font-family: "Nunito", sans-serif;'>{{sdControls.load_weight.value}}
                                                Kgs</span>
                                            <span *ngIf='this.sdControls.shipmentType_Document.value === true'
                                                class=" h6 font-weight-bold"
                                                style='font-family: "Nunito", sans-serif;'>{{sdControls.load_weight.value}}
                                                Grams</span>
                                        </div>
                                        <div class="col-md-5 " style="font-size:15px;font-weight:500"><span
                                                style="float:right;">{{(pickCharges && isShowSummaryDetails ? pickCharges : 0 ) |
                                                number:'.2-2'}}</span></div>
                                    </div>
                                </div>
                                <div class="row" style="margin-top:5px">
                                    <div class="col-md-7" style="font-size:16px;font-weight:500;color:blue">Total Gross Amt</div>
                                    <div class="col-md-5" style="font-size:16px;font-weight:500;color:blue" ><span style="float:right">{{((totalGross && isShowSummaryDetails  ? totalGross : 0 ) + (packingGross && isShowSummaryDetails  ? packingGross : 0)) |  number:'.2-2'}}</span></div>
                                  </div>
                        
                                  <div class="row" style="margin-top:5px">
                                    <div class="col-md-7" style="font-size:16px;font-weight:500;color:blue">Total Tax Amt</div>
                                    <div class="col-md-5" style="font-size:16px;font-weight:500;color:blue" ><span style="float:right">{{((totalTax && isShowSummaryDetails ? totalTax : 0 ) + (packingTax && isShowSummaryDetails ? packingTax : 0)) |  number:'.2-2'}}</span></div>
                                  </div>
                        
                                  <div class="row" style="margin-top:5px">
                                    <div class="col-md-7" style="font-size:16px;font-weight:500;color:blue">Discount Amt</div>
                                    <div class="col-md-5" style="font-size:16px;font-weight:500;color:blue" ><span style="float:right">{{(0 ) |  number:'.2-2'}}</span></div>
                                  </div>
                        
                                  <div class="row" style="margin-top:5px">
                                    <div class="col-md-7" style="font-size:16px;font-weight:500;color:blue">Total Amt</div>
                                    <div class="col-md-5" style="font-size:16px;font-weight:500;color:blue" ><span style="float:right">{{(((internationalData?.intlTotalAmt && isShowSummaryDetails ) ? consignmentCharges : 0 ) + (packingTotal && consignmentCharges && isShowSummaryDetails  ? packingTotal : 0 )) |  number:'.2-2'}}</span></div>
                                  </div>
                    
                                  <div class="row" style="margin-top:5px">
                                    <div class="col-md-7" style="font-size:16px;font-weight:500;color:blue">Pending Amt</div>
                                    <div class="col-md-5" style="font-size:16px;font-weight:500;color:blue" ><span style="float:right">{{(((internationalData?.intlTotalAmt && isShowSummaryDetails ) ? consignmentCharges : 0 ) - (bookingAmount && isShowSummaryDetails  ? bookingAmount : 0 )) |  number:'.2-2'}}</span></div>
                                  </div>
                                  <br/>
                                  <div class="col-md-12">Pickup Request<br />
                                    <label class="switch">
                                        <input type="checkbox" [checked]="isPickup" formControlName="pickupRequest" (change)="togglebutton($event.target.checked == true ? 'Yes': 'No')">
                                        <span class="slider round" [ngStyle]="(isShow == false) ? {'bottom': '170px'} :  {'bottom': '130px'}"></span>
                                    </label>
                                    </div>

                                    <div style="font-size:15px;">
                                        <input type="checkbox" style="position:absolute"
                                        [value]="isChecked2"
                                        class="custom-control-input"   [(ngModel)]='isChecked2'  id="chckbx" #chkbox (change)="checkValue2()">
                                    <label  style="margin-left: 30px;
                                    margin-top: -5px;" class="custom-control-label"
                                        ><a routerLink="/Prohibited-List/16">I have read and accept the prohibited <br/>list.</a><span
                                        class="text-danger">*</span></label>
                                       
                                      </div>
                
                                <div style="font-size:15px;">
                                    <input type="checkbox"
                                    [value]="isChecked1"
                                    class="custom-control-input"   [(ngModel)]='isChecked1'  id="chckbx1" #chkbox1 (change)="checkValue1()">
                                <label style="margin-left:10px" class="custom-control-label "
                                    ><a routerLink="/Terms-&-Conditions/13">I Accept Terms And Condition</a><span
                                    class="text-danger">*</span></label>
                                   
                                  </div>

                                  <div *ngIf="isSelect" style="color:red;font-size: 12px;">Please read and accept terms and condtion and prohibited list.</div><br/>
                                  <button *ngIf="!isShow" class="btn btn-primary"  id="rzp-button1" (click)="payNow(chkbox1,chkbox)">Pay and Book Now{{this.bookingAmount ? (this.bookingAmount |  number:'.2-2') : '' }}<span *ngIf="bookingAmount" style="font-size:12px">(InclTax)</span></button>
                               
                        <!-- <div>
                            <button *ngIf="!isShow" class="btn btn-primary" style="position:fixed;bottom:10px;right:13%;left:auto" id="rzp-button1" (click)="payNow(chkbox1,chkbox)">Pay and Book Now{{this.bookingAmount ? (this.bookingAmount |  number:'.2-2') : '' }}<span *ngIf="bookingAmount" style="font-size:12px">(InclTax)</span></button>
                            <button *ngIf="isShow "  class="btn btn-primary login-btn-primary" style="position:fixed;bottom:10px;right:3%;left:auto" (click)="selectTab(1)">Continue to fill sender and receiver details</button>&nbsp;&nbsp;
                <button *ngIf="!isShow " class="btn btn-danger login-btn-danger" style="position:fixed;bottom:10px;right:3%;left:auto" (click)="selectTab(0)">Cancel</button>
                        </div> -->
                                  
                            </div>
                        </div>
                    </div>
                
                    <div class="mt-4"*ngIf="this.sdControls.shipmentType_Document.value === true ">
                        <div class="d-flex align-items-center">
                            <div class=" col-lg-12 col-md-12 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0 ">
                                <div class="row">
                                    <div class="col-md-7" [style]="font_style">Total <span
                                            *ngIf='this.sdControls.shipmentType_NonDocument.value === true'
                                            class=" h6 font-weight-bold "
                                            style='font-family: "Nunito", sans-serif;color:blue;'>{{sdControls.load_weight.value}}
                                            Kgs</span>
                                        <span *ngIf='this.sdControls.shipmentType_Document.value === true' class=" h6 font-weight-bold"
                                            style='font-family: "Nunito", sans-serif;color:blue;'>{{sdControls.load_weight.value}}
                                            Grams</span>
                                    </div>
                                    <div class="col-md-5" [style]="font_style"><span
                                            style="float:right;color:blue;">{{((internationalData && isShowSummaryDetails) ?
                                            consignmentCharges : 0 ) | number:'.2-2'}}</span></div>
                                </div>
                
                               
                
                                <div class="row" style="margin-top:5px">
                                    <div class="col-md-7" style="font-size:16px;font-weight:500;color:blue">Total Gross Amt</div>
                                    <div class="col-md-5" style="font-size:16px;font-weight:500;color:blue"><span
                                            style="float:right">{{((internationalData?.intlGrossAmt && isShowSummaryDetails) ?
                                            totalGross : 0 ) |
                                            number:'.2-2'}}</span></div>
                                </div>
                
                                <div class="row" style="margin-top:5px">
                                    <div class="col-md-7" style="font-size:16px;font-weight:500;color:blue">Total tax GST 18%</div>
                                    <div class="col-md-5" style="font-size:16px;font-weight:500;color:blue"><span
                                            style="float:right">{{((internationalData?.taxAmount && isShowSummaryDetails) ? totalTax : 0
                                            ) |
                                            number:'.2-2'}}</span></div>
                                </div>
                
                                <div class="row" style="margin-top:5px">
                                    <div class="col-md-7" style="font-size:16px;font-weight:500;color:blue">Total Amount</div>
                                    <div class="col-md-5" style="font-size:16px;font-weight:500;color:blue"><span
                                            style="float:right">{{((internationalData?.intlTotalAmt && isShowSummaryDetails) ?
                                            consignmentCharges : 0 ) |
                                            number:'.2-2'}}</span></div>
                                </div>
                            
                
                                <div *ngIf="this.sdControls.pickupRequest.value == true">
                                    <div> <span style="color:blue;font-size:16px;font-weight:500;text-decoration: underline;">Pickup
                                            Charges</span></div>
                                    <div class="row">
                                        <div class="col-md-7" style="font-size:15px;font-weight:500">Total <span
                                                *ngIf='this.sdControls.shipmentType_NonDocument.value === true'
                                                class=" h6 font-weight-bold"
                                                style='font-family: "Nunito", sans-serif;'>{{sdControls.load_weight.value}}
                                                Kgs</span>
                                            <span *ngIf='this.sdControls.shipmentType_Document.value === true'
                                                class=" h6 font-weight-bold"
                                                style='font-family: "Nunito", sans-serif;'>{{sdControls.load_weight.value}}
                                                Grams</span>
                                        </div>
                                        <div class="col-md-5 " style="font-size:15px;font-weight:500"><span
                                                style="float:right;">{{(pickCharges && isShowSummaryDetails ? pickCharges : 0 ) |
                                                number:'.2-2'}}</span></div>
                                    </div>
                                </div>
                                <div class="row" style="margin-top:5px">
                                    <div class="col-md-7" style="font-size:16px;font-weight:500;color:blue">Total Gross Amt</div>
                                    <div class="col-md-5" style="font-size:16px;font-weight:500;color:blue" ><span style="float:right">{{((totalGross && isShowSummaryDetails  ? totalGross : 0 ) ) |  number:'.2-2'}}</span></div>
                                  </div>
                        
                                  <div class="row" style="margin-top:5px">
                                    <div class="col-md-7" style="font-size:16px;font-weight:500;color:blue">Total Tax Amt</div>
                                    <div class="col-md-5" style="font-size:16px;font-weight:500;color:blue" ><span style="float:right">{{((totalTax && isShowSummaryDetails ? totalTax : 0 ) ) |  number:'.2-2'}}</span></div>
                                  </div>
                        
                                  <div class="row" style="margin-top:5px">
                                    <div class="col-md-7" style="font-size:16px;font-weight:500;color:blue">Discount Amt</div>
                                    <div class="col-md-5" style="font-size:16px;font-weight:500;color:blue" ><span style="float:right">{{(0 ) |  number:'.2-2'}}</span></div>
                                  </div>
                        
                                  <div class="row" style="margin-top:5px">
                                    <div class="col-md-7" style="font-size:16px;font-weight:500;color:blue">Total Amt</div>
                                    <div class="col-md-5" style="font-size:16px;font-weight:500;color:blue" ><span style="float:right">{{(((internationalData?.intlTotalAmt && isShowSummaryDetails ) ? consignmentCharges : 0 ) + (packingTotal && consignmentCharges && isShowSummaryDetails  ? packingTotal : 0 )) |  number:'.2-2'}}</span></div>
                                  </div>
                    
                                  <div class="row" style="margin-top:5px">
                                    <div class="col-md-7" style="font-size:16px;font-weight:500;color:blue">Pending Amt</div>
                                    <div class="col-md-5" style="font-size:16px;font-weight:500;color:blue" ><span style="float:right">{{(((internationalData?.intlTotalAmt && isShowSummaryDetails ) ? consignmentCharges : 0 ) - (bookingAmount && isShowSummaryDetails  ? bookingAmount : 0 )) |  number:'.2-2'}}</span></div>
                                  </div>
                                  <br/>
                                  <div class="col-md-12">Pickup Request<br />
                                    <label class="switch">
                                        <input type="checkbox" [checked]="isPickup" formControlName="pickupRequest" (change)="togglebutton($event.target.checked == true ? 'Yes': 'No')">
                                        <span class="slider1 round" [ngStyle]="(isShow == false) ? {'bottom': '170px'} :  {'bottom': '130px'}"></span>
                                    </label>
                                    </div>

                                    <div style="font-size:15px;">
                                        <input type="checkbox" style="position:absolute"
                                        [value]="isChecked2"
                                        class="custom-control-input"   [(ngModel)]='isChecked2'  id="chckbx" #chkbox (change)="checkValue2()">
                                    <label  style="margin-left: 30px;
                                    margin-top: -5px;" class="custom-control-label"
                                        ><a routerLink="/Prohibited-List/16">I have read and accept the prohibited <br/>list.</a><span
                                        class="text-danger">*</span></label>
                                       
                                      </div>
                
                                <div style="font-size:15px;">
                                    <input type="checkbox"
                                    [value]="isChecked1"
                                    class="custom-control-input"   [(ngModel)]='isChecked1'  id="chckbx1" #chkbox1 (change)="checkValue1()">
                                <label style="margin-left:10px" class="custom-control-label "
                                    ><a routerLink="/Terms-&-Conditions/13">I Accept Terms And Condition</a><span
                                    class="text-danger">*</span></label>
                                   
                                  </div>

                                  <div *ngIf="isSelect" style="color:red;font-size: 12px;">Please read and accept terms and condtion and prohibited list.</div><br/>
                                  <button *ngIf="!isShow" class="btn btn-primary"  id="rzp-button1" (click)="payNow(chkbox1,chkbox)">Pay and Book Now{{this.bookingAmount ? (this.bookingAmount |  number:'.2-2') : '' }}<span *ngIf="bookingAmount" style="font-size:12px">(InclTax)</span></button>
                               
                        <!-- <div>
                            <button *ngIf="!isShow" class="btn btn-primary" style="position:fixed;bottom:10px;right:13%;left:auto" id="rzp-button1" (click)="payNow(chkbox1,chkbox)">Pay and Book Now{{this.bookingAmount ? (this.bookingAmount |  number:'.2-2') : '' }}<span *ngIf="bookingAmount" style="font-size:12px">(InclTax)</span></button>
                            <button *ngIf="isShow "  class="btn btn-primary login-btn-primary" style="position:fixed;bottom:10px;right:3%;left:auto" (click)="selectTab(1)">Continue to fill sender and receiver details</button>&nbsp;&nbsp;
                <button *ngIf="!isShow " class="btn btn-danger login-btn-danger" style="position:fixed;bottom:10px;right:3%;left:auto" (click)="selectTab(0)">Cancel</button>
                        </div> -->
                                  
                            </div>
                        </div>
                    </div>
                </div>
               
              </div>
          </div>
      </div><!--end col-->
    </div>
      <!-- END SIDEBAR -->
      </div>
      <!--end row-->
    </div>
    <!--end container-->


</section>
  <!--end section -->
  <!--Blog Lists End-->
  
  <!-- Back to top -->
  <a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
  </a>
  <!-- Back to top -->

  <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          ...
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary">Save changes</button>
        </div>
      </div>
    </div>
  </div>

  <ng-template #myModel>
           
    <div class="row g-6 g-xl-9">
    
      <div class="col-sm-12 col-xl-12 col-md-12 col-lg-12">
    
        <div class="card" >
          <img  [src]="appService.apiLink+this.Image" />
        </div>
      </div>
    </div>
    </ng-template>

























































  